import React, { useEffect, useState } from 'react';
import { Text } from 'library';
import { useHistory } from 'react-router';

import AddStaffModal from './AddStaffModal';
import { AddCenterInput, OnboardingStep, AddStaffInput, SubscriptionStatus } from '../types';
import AddCenterModal from './AddCenterModal';
import StepSelector from './StepSelector';
import StaffTable from './StaffTable';
import CompleteOnboardingModal from './CompleteOnboardingModal';
import ComplianceLogo from '../components/ComplianceLogo';
import CentersTable from './CentersTable';
import { getSubscriptionStatus } from '../api';

interface Props {
    existingCenters?: AddCenterInput[];
    userFirstName?: string;
}

const Onboarding = ({ userFirstName, existingCenters }: Props) => {
    const stepsOrdered: OnboardingStep[] = ['Account Information', 'Add Staff'];
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [addWorkerModalOpen, setAddWorkerModalOpen] = useState(false);
    const [addCenterModalOpen, setAddCenterModalOpen] = useState(false);
    const [centers, setCenters] = useState<AddCenterInput[]>(existingCenters ?? []);
    const [staff, setStaff] = useState<AddStaffInput[]>([]);
    const [centerToEdit, setCenterToEdit] = useState<AddCenterInput>();
    const [staffToEdit, setStaffToEdit] = useState<AddStaffInput>();
    const history = useHistory();

    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus>();

    useEffect(() => {
        getSubscriptionStatus().then((res) => setSubscriptionStatus(res));
    }, []);

    function handleSkipAddStaff() {
        history.push('/compliance/locations');
    }

    const addCenter = (input: AddCenterInput, previousInput?: AddCenterInput) => {
        let previousList = centers;
        if (previousInput) {
            previousList = centers.filter((it) => it !== previousInput);
        }
        setCenters([...previousList, input]);
    };

    const openCenterModal = (editItem?: AddCenterInput) => {
        if (editItem) {
            setCenterToEdit(editItem);
        } else {
            setCenterToEdit(undefined);
        }
        setAddCenterModalOpen(true);
    };

    const addStaff = (input: AddStaffInput, previousInput?: AddStaffInput) => {
        let previousList = staff;
        if (previousInput) {
            previousList = staff.filter((it) => it !== previousInput);
        }
        setStaff([...previousList, input]);
    };

    const openWorkerModal = (editItem?: AddStaffInput) => {
        if (editItem) {
            setStaffToEdit(editItem);
        } else {
            setStaffToEdit(undefined);
        }
        setAddWorkerModalOpen(true);
    };

    const TableForStep = () => {
        const currentStep = currentStepIndex < stepsOrdered.length ? stepsOrdered[currentStepIndex] : 'Add Staff';
        const nextStep = () => setCurrentStepIndex(currentStepIndex + 1);

        switch (currentStep) {
            case 'Account Information':
                return <CentersTable centers={centers} openAddModal={openCenterModal} onContinue={nextStep} />;
            case 'Add Staff':
                return (
                    <StaffTable
                        staff={staff}
                        openAddModal={openWorkerModal}
                        onContinue={nextStep}
                        skip={handleSkipAddStaff}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 40,
            }}
        >
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <ComplianceLogo width={180} />
            </div>
            <Text variant="display">{`Hi ${userFirstName}! Let's set up your account.`}</Text>
            <div
                style={{ display: 'flex', flexDirection: 'row', gap: 8, width: '40%', marginTop: 30, marginBottom: 60 }}
            >
                {stepsOrdered.map((it, i) => {
                    return (
                        <StepSelector
                            key={i}
                            stepName={it}
                            stepIndex={i}
                            currentIndex={currentStepIndex}
                            setIndex={setCurrentStepIndex}
                        />
                    );
                })}
            </div>
            <TableForStep />
            <AddStaffModal
                isOpen={addWorkerModalOpen}
                setIsOpen={setAddWorkerModalOpen}
                centers={centers}
                addOnOnboarding={addStaff}
                staffToEdit={staffToEdit}
                subscriptionStatus={subscriptionStatus}
            />
            <AddCenterModal
                isOpen={addCenterModalOpen}
                setIsOpen={setAddCenterModalOpen}
                addCenter={addCenter}
                centerToEdit={centerToEdit}
            />
            <CompleteOnboardingModal
                isOpen={currentStepIndex === stepsOrdered.length}
                closeModal={() => setCurrentStepIndex(currentStepIndex - 1)}
                centers={centers}
                staff={staff}
                subscriptionStatus={subscriptionStatus}
            />
        </div>
    );
};

export default Onboarding;
