import PaginatedAdminTable from 'internal/shared/tables/PaginatedAdminTable';
import { JobType, MilestoneCheckin } from 'internal/substitutes/substituteModels';
import { BasicDialog, Colors, TextButton } from 'library';
import { BusinessJobWithAppointments, PagingResult } from 'models';
import React, { useEffect } from 'react';
import { client, queryStringifyObject } from 'shared';
import TrialRunMilestoneRow from './TrialRunMilestoneRow';

export default function TrialRunMilestones({ job, userId }: { job: BusinessJobWithAppointments; userId?: number }) {
    const [open, setOpen] = React.useState(false);
    const [checkins, setCheckins] = React.useState<PagingResult<MilestoneCheckin>>({
        count: 0,
        per_page: 0,
        num_pages: 0,
        results: [],
    });
    const [page, setPage] = React.useState(1);

    function fetchMilestoneCheckins() {
        if (!open) {
            return;
        }
        client(`internal/user-flow-history/?${queryStringifyObject({ job: job.id, page, user: userId })}`).then(
            setCheckins,
        );
    }
    useEffect(() => {
        fetchMilestoneCheckins();
    }, [open]);

    return job.business_job_type === JobType.TRIAL_RUN && job.milestone_count > 0 ? (
        <>
            <TextButton buttonStyle={styles.button} textStyle={styles.link} onClick={() => setOpen(true)}>
                View Milestone Checkins ({job.milestone_count})
            </TextButton>
            <BasicDialog
                style={{ minWidth: '90%' }}
                closeButton
                dialogTitle="Milestone Checkins"
                isOpen={open}
                onClose={() => setOpen(false)}
            >
                <PaginatedAdminTable
                    data={checkins}
                    columns={['Milestone', 'Worker', 'last updated', 'responses']}
                    page={page}
                    updatePage={setPage}
                >
                    {checkins.results?.map((checkin) => (
                        <TrialRunMilestoneRow key={`${checkin.user_flow.id}-${checkin.user.id}`} checkin={checkin} />
                    ))}
                </PaginatedAdminTable>
            </BasicDialog>
        </>
    ) : null;
}

const styles = {
    button: {
        width: undefined,
        marginLeft: 10,
    },
    link: {
        color: Colors.terraCotta,
    },
};
