import React from 'react';
import { Text, Colors } from 'library';
import { OnboardingStep, StepState } from '../types';

const StepSelector = ({
    stepName,
    stepIndex,
    currentIndex,
    setIndex,
}: {
    stepName: OnboardingStep;
    stepIndex: number;
    currentIndex: number;
    setIndex: (index: number) => void;
}) => {
    let stepState: StepState = 'Next';
    if (stepIndex < currentIndex) {
        stepState = 'Previous';
    } else if (stepIndex === currentIndex) {
        stepState = 'Current';
    }

    const stepStateToColor: Record<StepState, Colors> = {
        Previous: Colors.darkNavy,
        Current: Colors.turquoise,
        Next: Colors.white,
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={() => setIndex(stepIndex)}
        >
            <Text variant="h2" bold>
                {stepName}
            </Text>
            <div
                style={{
                    border: `1px solid ${Colors.darkNavy}`,
                    height: 14,
                    width: '100%',
                    backgroundColor: `${stepStateToColor[stepState]}`,
                }}
            ></div>
        </div>
    );
};

export default StepSelector;
