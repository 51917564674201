import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { consoleLogInDev } from 'shared';
import { Job, Provider } from 'models';
import useQueryParams from 'shared/QueryParamsHook';
import { useUserContext } from 'UserContext';
import {
    emptyProvider,
    emptyQualifications,
    initialContext,
    ProviderProfileContextType,
    ProviderQualifications,
} from './providerProfileModels';
import { getChildcareCenterInformation, getJob, getProvider, getProviderQualifications } from './providerProfileApi';

export const ProviderProfileContext = React.createContext<ProviderProfileContextType>(initialContext());

export function ProviderProfileContextProvider({ children }: { children: React.ReactNode }) {
    const { role } = useUserContext();
    const { id, jobId } = useParams<{ id: string; jobId: string }>();
    const query = useQueryParams();
    const [provider, setProvider] = useState<Provider>(emptyProvider);
    const [providerQualifications, setProviderQualifications] = useState<ProviderQualifications>(emptyQualifications);
    const [childcareCenterQualifications, setChildcareCenterQualifications] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [job, setJob] = useState<Job>();

    const loadProvider = (providerUserId?: number) => {
        setInitialized(false);
        setLoading(true);
        const providerParam = providerUserId ?? id;
        const jobQueryParam = query.get('job');
        const jobParam = jobId || jobQueryParam ? `?job=${jobId ? jobId : jobQueryParam}` : '';
        getProvider(providerParam, jobParam)
            .then(setProvider)
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    };

    const loadJobData = () => {
        getJob(jobId).then(setJob).catch(consoleLogInDev);
    };

    useEffect(() => {
        if (id) loadProvider();
        if (jobId) loadJobData();
    }, []);

    useEffect(() => {
        if (initialized) {
            getChildcareCenterInformation(provider.id).then(setChildcareCenterQualifications).catch(consoleLogInDev);
        }
    }, [initialized]);

    useEffect(() => {
        if (provider.id > 0) setInitialized(true);
    }, [provider]);

    useEffect(() => {
        if (role === 'business_active' && provider.user.id > 0)
            getProviderQualifications(provider.user.id).then(setProviderQualifications).catch(consoleLogInDev);
    }, [role, provider]);

    const contextValue = {
        provider,
        providerQualifications,
        job,
        refreshJob: loadJobData,
        refreshProvider: loadProvider,
        childcareCenterQualifications: childcareCenterQualifications,
        loading,
    };
    return <ProviderProfileContext.Provider value={contextValue}>{children}</ProviderProfileContext.Provider>;
}

export const useProviderProfileContext = () => {
    return useContext(ProviderProfileContext);
};
