import { useClient } from 'shared';
import type { ExtendedOptions } from 'shared/commonHooks/useClient';

export interface LeanAppointment {
    id: number;
    start_date: string;
    end_date: string;
    timezone: string;
}

export interface PastHireRequest {
    ongoing_request_id: number;
    worker_id: number | null;
    appointment_id: number | null;
    appointment_date: Date | null;
    start_time: Date | undefined | null;
    end_time: Date | undefined | null;
    pay: string;
}

export function usePastAppointments({ ongoingRequestId }: { ongoingRequestId: number }) {
    return useClient<LeanAppointment[]>({ url: `internal/ongoing-request/${ongoingRequestId}/past-appointments/` });
}

export function usePastHire({ options }: { options?: ExtendedOptions }) {
    return useClient<{ message: string } | null>({
        url: 'internal/ongoing-request/past-hire/',
        method: 'POST',
        options,
    });
}
