import React from 'react';
import { WorkerTab } from 'parent-portal/home/new/components/WorkerTab';

import { hoursReported, actions } from './utils';

import type { TimesheetWorker, UnclockedInTimesheetWorker } from 'parent-portal/home/new/types';

export function SmallTimesheetsTable({
    data,
    editAction,
}: {
    data: TimesheetWorker[] | UnclockedInTimesheetWorker[];
    editAction: (worker: TimesheetWorker | UnclockedInTimesheetWorker) => void;
}) {
    return (
        <>
            {data.map((timesheet: TimesheetWorker | UnclockedInTimesheetWorker) => (
                <WorkerTab
                    key={`${timesheet.user.id}-${timesheet.start_date}`}
                    worker={{
                        user: timesheet.user,
                        profile_image: timesheet.profile_image,
                        ...hoursReported(timesheet),
                    }}
                    small={true}
                    onClick={() => editAction(timesheet)}
                    action={actions(timesheet, editAction, true)}
                />
            ))}
        </>
    );
}
