import React from 'react';
import { Text } from 'library';
import { Checkbox, Grid } from '@material-ui/core';
import CheckboxLabel from 'reusableComponents/checkboxLabel';
import { IAppointment, TimeChange } from './jobChangeModels';
import { isAfter, isBefore } from 'date-fns';
import { format12HourTime } from 'shared/Dates';

export default function GenerateAppointments({
    generateBefore,
    setGenerateBefore,
    generateAfter,
    setGenerateAfter,
    timeChange,
    appt,
    timezone,
}: {
    generateBefore: number[];
    setGenerateBefore: (n: number[]) => void;
    generateAfter: number[];
    setGenerateAfter: (n: number[]) => void;
    timeChange: TimeChange;
    appt: IAppointment | undefined;
    timezone: string;
}) {
    function startCovered() {
        const date1 = new Date(timeChange.start);
        const date2 = appt ? new Date(appt.start_date) : new Date();
        return !isAfter(date1, date2) || format12HourTime(date1, timezone) === format12HourTime(date2, timezone);
    }

    function endCovered() {
        const date1 = new Date(timeChange.end);
        const date2 = appt ? new Date(appt.end_date) : new Date();
        return !isBefore(date1, date2) || format12HourTime(date1, timezone) === format12HourTime(date2, timezone);
    }

    return !appt ? null : (
        <>
            {!startCovered() || !endCovered() ? (
                <>
                    <Text variant="body1">Fill remaining hours with new provider:</Text>
                    <Grid justify="space-between" container item>
                        {!startCovered() ? (
                            <CheckboxLabel
                                value={timeChange.appointment}
                                control={
                                    <Checkbox
                                        checked={generateBefore.includes(timeChange.appointment)}
                                        color="primary"
                                        onChange={() =>
                                            generateBefore.includes(timeChange.appointment)
                                                ? setGenerateBefore(
                                                      generateBefore.filter((i) => i !== timeChange.appointment),
                                                  )
                                                : setGenerateBefore([...generateBefore, timeChange.appointment])
                                        }
                                    />
                                }
                                label={
                                    <Text variant="body1">
                                        {format12HourTime(appt.start_date)} - {format12HourTime(timeChange.start)}
                                    </Text>
                                }
                            />
                        ) : null}
                        {!endCovered() ? (
                            <CheckboxLabel
                                value={timeChange.appointment}
                                control={
                                    <Checkbox
                                        checked={generateAfter.includes(timeChange.appointment)}
                                        color="primary"
                                        onChange={() =>
                                            generateAfter.includes(timeChange.appointment)
                                                ? setGenerateAfter(
                                                      generateAfter.filter((i) => i !== timeChange.appointment),
                                                  )
                                                : setGenerateAfter([...generateAfter, timeChange.appointment])
                                        }
                                    />
                                }
                                label={
                                    <Text variant="body1">
                                        {format12HourTime(timeChange.end, timezone)} -{' '}
                                        {format12HourTime(appt.end_date, timezone)}
                                    </Text>
                                }
                            />
                        ) : null}
                    </Grid>
                </>
            ) : null}
        </>
    );
}
