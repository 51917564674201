import { Grid, styled, TablePagination } from '@mui/material';
import { LoadingSpinner, Text } from 'library';
import useLoading from 'library/hooks/useLoading';
import { PagingResult } from 'models';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { client, consoleLogInDev } from 'shared';
import { ApplicantApproval, ApplicantSelection } from './models';
import ApplicantApprovalWorkerCard from './components/ApplicantApprovalWorkerCard';
import ApplicantApprovalPageHeader from './components/ApplicantApprovalPageHeader';

export default function BusinessApplicantsApproval() {
    const { id } = useParams<{ id: string }>();
    const [applicants, setApplicants] = useState<PagingResult<ApplicantApproval>>({
        count: 0,
        results: [],
        per_page: 0,
        num_pages: 0,
    });
    const [error, setError] = useState(false);
    const { loading, setLoading } = useLoading();
    const [page, setPage] = useState(1);
    const [selectedApplicants, setSelectedApplicants] = useState<ApplicantSelection[]>([]);
    const onPageChange = (_: React.MouseEvent | null, page: number) => setPage(page + 1);

    function getApplicants() {
        setLoading(true);
        if (id) {
            client(`api/applicant-approval/?job=${id}&page=${page}`)
                .then((result) => {
                    setApplicants(result);
                    setSelectedApplicants([]);
                })
                .catch((e) => {
                    setError(true);
                    consoleLogInDev(e);
                })
                .finally(() => setLoading(false));
        }
    }

    useEffect(getApplicants, [id]);

    function toggleAppointmentApproved(applicantId: number, appointmentId: number) {
        setSelectedApplicants((prev) =>
            prev.map((item) => {
                if (item.applicant.id === applicantId) {
                    return {
                        applicant: item.applicant,
                        approvedAppointmentIds: item.approvedAppointmentIds.includes(appointmentId)
                            ? item.approvedAppointmentIds.filter((id) => id !== appointmentId)
                            : [...item.approvedAppointmentIds, appointmentId],
                    };
                }
                return item;
            }),
        );
    }

    return (
        <Grid container flexDirection="column" justifyContent="center" marginTop={2} marginLeft={4}>
            <ApplicantApprovalPageHeader
                job={applicants.results[0]?.job}
                selectedApplicants={selectedApplicants}
                toggleAppointmentApproved={toggleAppointmentApproved}
                refresh={getApplicants}
            />
            {error || (!loading && applicants.results.length === 0) ? (
                <ErrorContainer container>
                    <Text>
                        You don&apos;t have any requests to approve right now. We&apos;re still working to fill your
                        shifts.
                    </Text>
                </ErrorContainer>
            ) : null}
            {loading ? (
                <LoadingSpinner />
            ) : (
                <CardContainer>
                    {applicants.results.map((applicant) => (
                        <ApplicantApprovalWorkerCard
                            key={applicant.id}
                            applicant={applicant}
                            refresh={getApplicants}
                            selected={!!selectedApplicants.find((selected) => selected.applicant.id === applicant.id)}
                            toggleSelected={(apptIds) => {
                                setSelectedApplicants((prev) =>
                                    prev.find((existing) => existing.applicant.id === applicant.id)
                                        ? prev.filter((item) => item.applicant.id !== applicant.id)
                                        : [...prev, { applicant: applicant, approvedAppointmentIds: apptIds }],
                                );
                            }}
                        />
                    ))}
                </CardContainer>
            )}
            {applicants.results.length > applicants.per_page && (
                <Grid container justifyContent="flex-end">
                    <Pagination
                        count={applicants.count || 0}
                        rowsPerPage={applicants.per_page || 0}
                        page={page - 1}
                        onPageChange={onPageChange}
                        rowsPerPageOptions={[]}
                    />
                </Grid>
            )}
        </Grid>
    );
}

const CardContainer = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    maxWidth: 1440,
    marginTop: 20,
});

const ErrorContainer = styled(Grid)({
    padding: 20,
    height: '40vh',
    justifyContent: 'center',
    alignItems: 'center',
});

const Pagination = styled(TablePagination)({
    marginRight: '16%',
});
