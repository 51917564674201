import React from 'react';
import { PrimaryButton, Text, Colors } from 'library';
import { Grid } from '@mui/material';
import { ApplicantApprovalJob, ApplicantSelection } from '../models';
import { client } from 'shared';
import { daysOfTheWeek, formatShortMonthDayYear, to12HourTime } from 'shared/Dates';
import ApplicantApprovalTimes from './ApplicantApprovalTimes';

export default function ApplicantApprovalPageHeader({
    job,
    selectedApplicants,
    toggleAppointmentApproved,
    refresh,
}: {
    job?: ApplicantApprovalJob;
    selectedApplicants: ApplicantSelection[];
    toggleAppointmentApproved: (applicantId: number, appointmentId: number) => void;
    refresh: () => void;
}) {
    function approveSelected() {
        if (!job) return;
        client(`api/applicant-approval/${job.id}/approve/`, {
            method: 'POST',
            body: {
                applicants: selectedApplicants.map((applicant) => ({
                    id: applicant.applicant.id,
                    approved_appointment_ids: applicant.approvedAppointmentIds,
                })),
            },
        }).then(refresh);
    }
    const [confirmOpen, setConfirmOpen] = React.useState(false);

    return (
        <>
            <Text variant="display" bold>
                Review Interested Workers
            </Text>
            <Text textStyle={{ marginTop: 16 }}>
                <b>Please review below.</b> Tandem did not approve these workers for your job because they requested
                different times or don&apos;t have the base requirements for the job.
            </Text>
            <Text textStyle={{ marginTop: 16 }}>
                If you approve a worker, we will reach out to them to confirm they are still available before booking
                them for your job. If you approve multiple requests, the first one to accept will be booked.
            </Text>
            {job && (
                <Grid
                    borderLeft={`5pt solid ${Colors.darkNavy}`}
                    marginTop={3}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ display: 'flex', flexDirection: 'row', maxWidth: 1130 }}
                >
                    <Grid padding={2}>
                        <Text variant="h2" bold>
                            {job.headline}{' '}
                            <Text variant="body2" inline>
                                {formatShortMonthDayYear(job.start_date)} - {formatShortMonthDayYear(job.end_date)}
                            </Text>
                        </Text>
                        {job.daily_schedules.map((day) => (
                            <Text variant="body2" key={day.id}>
                                {daysOfTheWeek[day.day]} {to12HourTime(day.start_time)} - {to12HourTime(day.end_time)}
                            </Text>
                        ))}
                    </Grid>
                    <PrimaryButton
                        onClick={() => setConfirmOpen(true)}
                        buttonStyle={{ width: 'auto', height: 45 }}
                        disabled={selectedApplicants.length === 0}
                    >
                        Approve All Selected
                    </PrimaryButton>
                </Grid>
            )}
            <ApplicantApprovalTimes
                applicants={selectedApplicants}
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={approveSelected}
                toggleAppointmentApproved={toggleAppointmentApproved}
            />
        </>
    );
}
