import { Checkbox, Colors, Text } from 'library';
import React from 'react';
import TeacherCardBaseHeader from 'parent-portal/providers/cards/shared/TeacherCardBaseHeader';
import { ITeacher } from 'models';
import { styled } from '@mui/material';
import { PermissionType, useUserContext } from 'UserContext';

export default function TeacherShowcaseHeader({
    worker,
    selected,
    onChange,
}: {
    worker: ITeacher;
    selected: boolean;
    onChange: (choice: ITeacher) => void;
}) {
    const { hasPermission } = useUserContext();
    const canPostJobs = hasPermission(PermissionType.PostJob);

    return (
        <TeacherCardBaseHeader
            worker={worker}
            leftHeaderComponent={
                <Pill>
                    <Text color={Colors.terraCotta} variant="body2">
                        SHOWCASE
                    </Text>
                </Pill>
            }
            rightHeaderComponent={
                canPostJobs ? (
                    <Checkbox
                        label=""
                        labelTitle="Select this teacher"
                        checked={selected}
                        onChange={() => onChange(worker)}
                        style={{ width: 'auto', marginRight: 10 }}
                        checkboxStyle={{ backgroundColor: Colors.white }}
                    />
                ) : (
                    <></>
                )
            }
        />
    );
}

const Pill = styled('div')({
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 8px',
});
