import { TextField } from '@material-ui/core';
import { BasicDialog, Colors, PrimaryButton, Text } from 'library';
import React, { useEffect, useState, useTransition } from 'react';
import { LeanBusinessLocation, SendWorkerInput, SubscriptionStatus } from '../types';
import { getSubscriptionStatus, startFreeTrial, useSendInviteToWorker } from '../api';
import BusinessLocationSelect from './BusinessLocationSelect';
import { formatE164USPhoneNumber, formatPhoneAsYouType, validateEmail } from 'shared';
import { Grid } from '@mui/material';
import SubscriptionBanner from './SubscriptionBanner';

interface Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
    centers?: LeanBusinessLocation[];
    refreshWorkers: () => void;
}

const AddWorkerModal = ({ isOpen, setIsOpen, centers, refreshWorkers }: Props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedCenters, setSelectedCenters] = useState(centers && centers.length > 0 ? [centers[0].id] : []);
    const [isPending, startTransition] = useTransition();
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus>();
    const { sendInvite } = useSendInviteToWorker();

    useEffect(() => {
        getSubscriptionStatus().then((res) => setSubscriptionStatus(res));
    }, []);

    const onClose = () => {
        setIsOpen(false);
        clearForm();
    };

    function clearForm() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSelectedCenters(centers && centers.length > 0 ? [centers[0].id] : []);
    }

    const initiateInvite = async () => {
        const input: SendWorkerInput = {
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: formatE164USPhoneNumber(phone),
            business_location_ids: selectedCenters.length ? selectedCenters : [centers![0].id],
        };
        startTransition(() => {
            if (subscriptionStatus === 'Inactive') {
                startFreeTrial();
            }
            sendInvite(input)
                .then((res) => {
                    if (res?.message) {
                        alert(res.message);
                    } else {
                        alert('Invite sent');
                    }
                    setIsOpen(false);
                    clearForm();
                    refreshWorkers();
                })
                .catch(() => alert('Error sending invite'));
        });
    };

    function formatPhoneNumber(phone?: string) {
        if (!phone) {
            return setPhone('');
        }
        setPhone(formatPhoneAsYouType(phone));
    }

    function selectCenter(center_ids: number[]) {
        setSelectedCenters(center_ids.length ? center_ids : [centers![0].id]);
    }

    const buttonDisabled = !firstName || !lastName || !email || !validateEmail(email) || !selectedCenters || !phone;

    if (!centers || centers.length === 0) {
        return <></>;
    }

    return (
        <BasicDialog closeButton isOpen={isOpen} onClose={onClose}>
            <div>
                <Text variant="h1">Invite Staff Member</Text>
                <SubscriptionBanner subscriptionStatus={subscriptionStatus} />
                <Text variant="h2" bold textStyle={{ marginTop: 16, marginBottom: 2 }}>
                    Account information*
                </Text>
                <Text variant="caption">We will send the staff member an invite link to get started.</Text>
            </div>
            <div style={{ margin: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 16 }}>
                    <TextField value={firstName} label="First Name" onChange={(e) => setFirstName(e.target.value)} />
                    <TextField value={lastName} label="Last Name" onChange={(e) => setLastName(e.target.value)} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 16 }}>
                    <Grid container item direction="column" xs={6}>
                        <TextField value={email} label="Email" onChange={(e) => setEmail(e.target.value)} />
                        {email && !validateEmail(email) && (
                            <Text variant="caption" textStyle={{ color: Colors.mediumRed }}>
                                Please enter a valid email
                            </Text>
                        )}
                    </Grid>
                    <TextField value={phone} label="Phone Number" onChange={(e) => formatPhoneNumber(e.target.value)} />
                </div>
                <BusinessLocationSelect
                    centers={centers}
                    selectedCenters={selectedCenters}
                    selectCenter={selectCenter}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
                <PrimaryButton
                    loading={isPending}
                    buttonStyle={{ maxWidth: 200 }}
                    onClick={initiateInvite}
                    disabled={buttonDisabled}
                >
                    Send Invite
                </PrimaryButton>
            </div>
        </BasicDialog>
    );
};

export default AddWorkerModal;
