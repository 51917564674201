import { styled } from '@mui/material';

export const Row = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
});

export const PaddedRow = styled(Row)({
    alignItems: 'center',
    paddingTop: 10,
});
