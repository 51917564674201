import React, { useCallback, useState } from 'react';
import { Text, Colors, IconButton } from 'library';
import { FilterList } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { TextField, Button } from '@material-ui/core';
import FilterWorkersModal from './FilterWorkersModal';
import AddWorkerModal from './AddWorkerModal';
import { CenterWorkerViewProps, WORKER_FILTER_MAP, WorkerFilter, WorkerFilters } from '../types';
import BusinessLocationSelect from './BusinessLocationSelect';
import { useUserContext } from 'UserContext';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import WorkerDetailTable from './WorkerDetailTable';

export default function CenterWorkersView({
    setSelectedWorker,
    selectedWorker,
    detailedView,
    refreshWorkers,
    workers,
    workersLoading,
    centers,
}: CenterWorkerViewProps) {
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [filters, setFilters] = useState<WorkerFilters>({
        page: 1,
    });

    const debouncedRefreshWorkers = useCallback(
        debounce((currentFilters) => {
            refreshWorkers(currentFilters);
        }, 500),
        [refreshWorkers],
    );

    const { user } = useUserContext();
    const searchForWorker = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFilters = { ...filters, search_string: e.target.value };
        debouncedRefreshWorkers(newFilters);
        setFilters(newFilters);
    };

    const filterWorkers = (selectedFilters: WorkerFilter[] | null) => {
        const hasFilters = selectedFilters && selectedFilters?.length > 0;
        const newStatusFilters = hasFilters
            ? selectedFilters?.map((it) => WORKER_FILTER_MAP[it as keyof typeof WORKER_FILTER_MAP])
            : undefined;
        const newFilters = { ...filters, worker_statuses: newStatusFilters };

        refreshWorkers(newFilters);
        setFilters(newFilters);
    };

    const selectCenter = (center_ids: number[]) => {
        const newFilters = { ...filters, business_location_ids: center_ids ?? [] };
        debouncedRefreshWorkers(newFilters);
        setFilters(newFilters);
    };

    const isExampleData = workers?.results.some((it) => it.is_example_worker);

    return (
        <Grid item container direction="column" style={{ padding: 20, backgroundColor: Colors.white }}>
            <Text variant="display">Hi {user?.first_name}!</Text>
            {isExampleData && (
                <Grid style={{ borderRadius: 18, backgroundColor: Colors.lightTurq, padding: 20, marginTop: 16 }}>
                    <Text variant="body1">
                        <Text variant="body1" bold>
                            {`You haven't added your staff yet.`}
                        </Text>{' '}
                        {`The teachers shown below are examples. When you add your own staff, or when you have Tandem
                        teachers booked for upcoming shifts, you'll see those instead.`}
                    </Text>
                </Grid>
            )}
            <BusinessLocationSelect
                centers={centers}
                selectedCenters={filters.business_location_ids ?? []}
                selectCenter={selectCenter}
                viewAll={true}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 8,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <TextField value={filters?.search_string} label="Search Worker" onChange={searchForWorker} />
                <div>
                    <Button
                        style={{
                            maxHeight: 30,
                            marginRight: 8,
                            backgroundColor: Colors.darkNavy,
                            color: Colors.white,
                            fontSize: 16,
                            fontFamily: 'Outfit',
                            textTransform: 'none',
                            borderRadius: 70,
                            padding: 14,
                        }}
                        onClick={() => setAddModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faCirclePlus} color={Colors.white} style={{ marginRight: 8 }} />
                        <Text variant="body2" color={Colors.white}>
                            Add User
                        </Text>
                    </Button>
                    <IconButton
                        backgroundColor={Colors.darkNavy}
                        icon={FilterList}
                        color={Colors.white}
                        onClick={() => setFilterModalOpen(true)}
                    />
                </div>
            </div>
            <WorkerDetailTable
                workers={workers}
                setSelectedWorker={setSelectedWorker}
                selectedWorker={selectedWorker}
                refreshWorkers={() => refreshWorkers(filters)}
                detailedView={detailedView}
                setPage={(newPage: number) => {
                    const newFilters = { ...filters, page: newPage };
                    refreshWorkers(newFilters);
                    setFilters(newFilters);
                }}
                page={filters.page ?? 1}
                workersLoading={workersLoading}
            />
            <FilterWorkersModal isOpen={filterModalOpen} setIsOpen={setFilterModalOpen} filterWorkers={filterWorkers} />
            <AddWorkerModal
                isOpen={addModalOpen}
                setIsOpen={setAddModalOpen}
                centers={centers}
                refreshWorkers={() => refreshWorkers({ ...filters, clear_cache: new Date().toISOString() })}
            />
        </Grid>
    );
}
