import React from 'react';
import { Text, Colors, TextButton } from 'library';
import { AddStaffInput } from '../types';
import AddEntryButton from './AddEntryButton';
import { Button } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { formatPhoneAsYouType } from 'shared';

interface Props {
    staff: AddStaffInput[];
    openAddModal: (editItem?: AddStaffInput) => void;
    onContinue: () => void;
    skip: () => void;
}

const StaffTable = ({ staff, openAddModal, onContinue, skip }: Props) => {
    const SkipButton = () => {
        return (
            <Button
                style={{
                    maxHeight: 40,
                    marginRight: 8,
                    backgroundColor: Colors.darkNavy,
                    color: Colors.white,
                    textTransform: 'none',
                    borderRadius: 70,
                    padding: 16,
                }}
                onClick={skip}
            >
                <Text variant="body2" color={Colors.white}>
                    {'Skip for now'}
                </Text>
            </Button>
        );
    };

    if (staff.length === 0) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, gap: 16 }}>
                <Text variant="h2">No staff added yet.</Text>
                <AddEntryButton entryName={'Staff'} openAddModal={() => openAddModal(undefined)} />
                <SkipButton />
            </div>
        );
    }

    const displayCenters = (centers: string[]) => {
        if (centers.length > 1) {
            const additional = centers.length - 1;
            return `${centers[0]} (+${additional} more)`;
        } else {
            return centers[0];
        }
    };

    return (
        <>
            <div style={{ width: '50%' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 15,
                    }}
                >
                    <Text variant="h2" bold>
                        {'Invite your staff to create an account.'}
                    </Text>
                    <AddEntryButton entryName={'Staff'} openAddModal={openAddModal} />
                </div>
                <TableContainer component={Paper} style={{ maxWidth: '100%', overflowY: 'auto', maxHeight: '40vh' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#D6D6D6' }}>
                                <TableCell style={{ fontWeight: 'bold' }}>{'First Name'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Last Name'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Email'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Phone'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Centers'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align="center">
                                    {'Actions'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {staff.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell>{row.firstName}</TableCell>
                                    <TableCell>{row.lastName}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{formatPhoneAsYouType(row.phoneNumber)}</TableCell>
                                    <TableCell>{displayCenters(row.centerNames)}</TableCell>
                                    <TableCell align="center">
                                        <TextButton onClick={() => openAddModal(row)}>{'Edit'}</TextButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '50%',
                    justifyContent: 'end',
                    marginTop: 30,
                }}
            >
                <Button
                    style={{
                        maxHeight: 40,
                        marginRight: 8,
                        backgroundColor: Colors.darkNavy,
                        color: Colors.white,
                        textTransform: 'none',
                        borderRadius: 70,
                        padding: 16,
                    }}
                    onClick={onContinue}
                >
                    <Text variant="body2" color={Colors.white}>
                        {'Continue'}
                    </Text>
                </Button>
                {Object.keys(staff).length === 0 && <SkipButton />}
            </div>
        </>
    );
};

export default StaffTable;
