import { createStringDate } from './utils';

export const exampleCriticalActions = [
    {
        key: 'FIRST_AID_CPR',
        name: 'First Aid & CPR',
        description: 'Connect or create an OCCRRA account through the Tandem app.',
        status: 'WARNING',
        due_date: createStringDate(90),
    },
];

export const exampleCriticalActionsById = {
    '-1': exampleCriticalActions,
    '-2': exampleCriticalActions,
};
