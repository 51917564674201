import React, { useRef, useState } from 'react';
import { Box, Drawer, Grid, IconButton, useMediaQuery } from '@mui/material';
import ComplianceUserDetailView from '../ComplianceUserDetailView';
import { Colors } from 'library';
import { CenterWorkerViewProps, ManagedWorker } from '../types';
import CenterWorkersView from '../components/CenterWorkersView';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import theme from 'theme';
import { styled } from '@mui/material/styles';
import { useCenters } from '../api';

const StyledDrawer = styled(Drawer)(({ theme, open }) => {
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    return {
        '& .MuiDrawer-paper': {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            ...(open && {
                width: isMdUp ? '40%' : '100%',
            }),
        },
    };
});

export default function DrawerView({
    setSelectedWorker,
    selectedWorker,
    refreshWorkers,
    workers,
    workersLoading,
}: Omit<CenterWorkerViewProps, 'detailedView'>) {
    const drawerRef = useRef<HTMLDivElement>(null);
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [open, setOpen] = useState(isMdUp);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const drawerVariant = isMdUp ? 'permanent' : 'temporary';
    const { centers } = useCenters();

    return (
        <Grid container direction="row" ref={drawerRef} style={{ backgroundColor: Colors.lightGrey, height: '100%' }}>
            {!isMdUp && !open && (
                <Box
                    sx={{
                        width: '30px',
                        height: '80vh',
                        top: '75px',
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        flexDirection: 'column',
                        boxShadow: '0px 3px 6px #00000029',
                        backgroundColor: Colors.white,
                        borderTopRightRadius: 18,
                        borderBottomRightRadius: 18,
                        position: 'sticky',
                    }}
                    onClick={handleDrawerOpen}
                >
                    <ChevronRightIcon />
                </Box>
            )}
            <StyledDrawer variant={drawerVariant} open={open} onClose={handleDrawerClose}>
                {!isMdUp ? (
                    <IconButton
                        onClick={() => setOpen(!open)}
                        sx={{ position: 'absolute', top: 8, right: 8, border: 0 }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                ) : null}
                <CenterWorkersView
                    setSelectedWorker={(worker?: ManagedWorker) => {
                        if (!isMdUp) setOpen(false);
                        setSelectedWorker(worker);
                    }}
                    selectedWorker={selectedWorker}
                    detailedView={false}
                    refreshWorkers={refreshWorkers}
                    workers={workers}
                    workersLoading={workersLoading}
                    centers={centers}
                />
            </StyledDrawer>
            <Grid
                item
                container
                sx={{ flexGrow: 1, overflowY: 'auto', marginLeft: isMdUp ? '45%' : '20px', minHeight: '100vh' }}
                xs={10}
                md={5}
            >
                <ComplianceUserDetailView
                    selectedWorker={selectedWorker}
                    refreshWorkers={refreshWorkers}
                    setSelectedWorker={setSelectedWorker}
                    centers={centers}
                />
            </Grid>
        </Grid>
    );
}
