import React from 'react';
import { Text } from 'library';
import { styled } from '@mui/material';

import { Link } from './Link';
import { Notification } from './Notification';

import type { NotificationType } from 'models';
import type { DashboardSummary } from '../types';

export function Notifications({ data }: { data: DashboardSummary }) {
    const unreads: NotificationType[] | undefined = data?.notifications?.unread;
    const unreadCount: number | undefined = data?.notifications?.unread_count;
    const max = data?.jobs?.drafts?.length ? unreads?.length : 2;

    return (
        <CardBody>
            <Inner>
                <Row>
                    <Text variant="h1" bold>
                        Latest Notifications
                    </Text>
                    {!!unreadCount && (
                        <Text variant="caption">
                            {max}/{unreadCount}
                        </Text>
                    )}
                </Row>
                <NotificationBody>
                    {!unreadCount ? (
                        <Text variant="body2">You&apos;re all caught up! 🎉</Text>
                    ) : (
                        unreads.map(
                            (notification: NotificationType, index: number) =>
                                index < max && <Notification key={notification.id} notification={notification} />,
                        )
                    )}
                </NotificationBody>
            </Inner>
            <BottomRow>{!!unreadCount && <Link to="/notifications">View All</Link>}</BottomRow>
        </CardBody>
    );
}

const CardBody = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
});

const Inner = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
});

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
});

const NotificationBody = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 4px',
    width: '100%',
});

const BottomRow = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
});
