import { E164Number } from 'libphonenumber-js/types';
import { BusinessLocation, PagingResult } from 'models';

export interface CenterWorkersForBusiness {
    workers: Worker[];
}

export interface Worker {
    id: number;
    name: string;
    status: WorkerStatus;
    center_id: number;
}

// TODO: Determine statues and filters for workers.
export type WorkerStatus = 'SATISFIED' | 'WARNING' | 'READY_TO_WORK' | 'INVITED' | 'BLOCKER' | 'INCOMPLETE';
export type WorkerFilter = 'Action Required' | 'Compliant' | 'Invited' | 'Action Needed Soon';
export const WORKER_FILTERS: WorkerFilter[] = ['Action Required', 'Action Needed Soon', 'Invited', 'Compliant'];
export const WORKER_FILTER_MAP: Record<WorkerFilter, string> = {
    'Action Required': 'BLOCKER',
    Compliant: 'READY_TO_WORK',
    Invited: 'INVITED',
    'Action Needed Soon': 'WARNING',
};

export interface AddStaffInput {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    centerNames: string[];
}
export interface AddCenterInput {
    id?: number;
    centerName: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    programId: string;
}

export type StepState = 'Previous' | 'Current' | 'Next';
export type OnboardingStep = 'Account Information' | 'Add Staff';
export type OnboardingEntryName = 'Center' | 'Staff';
export type SendWorkerInput = {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: E164Number;
    business_location_ids: number[];
};

export type ComplianceEnrollment = {
    id: number;
    user_id: number;
    business_location: BusinessLocation;
    can_see_jobs: boolean;
    enrolled_at: string;
    unenrolled_at: string;
};

export type CentersForOnboarding = {
    id: number;
    name: string;
    address: string;
    city: string;
    state: string;
    zip_code: string;
    program_id: string;
};

export type OnboardingInput = {
    centers: {
        id: number | undefined;
        center_name: string;
        address: string;
        city: string;
        state: string;
        zip_code: string;
        program_id: string;
    }[];
    staff: { first_name: string; last_name: string; email: string; phone_number: E164Number; center_names: string[] }[];
};

export type ManagedWorker = {
    user_id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    start_date: string;
    compliance_status: WorkerStatus;
    business_location_id?: number;
    compliance_enrollment_id?: number;
    compliance_summary: ComplianceSummary;
    is_example_worker?: boolean;
    business_locations?: LeanBusinessLocation[];
};

export type LeanBusinessLocation = {
    id: number;
    name: string;
};

export type ComplianceSummaryEntry = {
    name: string;
    status: WorkerStatus;
    due_date?: string | null;
};

export type ComplianceSummary = {
    compliance_core: ComplianceSummaryEntry[];
    trainings: ComplianceSummaryEntry[];
};

export interface CenterWorkerViewProps {
    setSelectedWorker: (worker?: ManagedWorker) => void;
    selectedWorker?: ManagedWorker;
    detailedView: boolean;
    refreshWorkers: (filters?: WorkerFilters) => void;
    workers?: PagingResult<ManagedWorker>;
    workersLoading?: boolean;
    centers?: LeanBusinessLocation[];
}

export interface UserTableProps extends Omit<CenterWorkerViewProps, 'refreshWorkers'> {
    workers?: PagingResult<ManagedWorker>;
    refreshWorkers: () => void;
    page: number;
    setPage: (page: number) => void;
}

export interface WorkerFilters {
    business_location_ids?: number[];
    worker_statuses?: Omit<WorkerStatus, 'SATISIFIED'>[];
    search_string?: string;
    page?: number;
    clear_cache?: string;
}
export type SubscriptionEvent = {
    id: number;
    user_id: number;
    business_id: number;
    event: SubscriptionEventType;
    added_at: string;
    expires_at: string;
};

export type SubscriptionEventType = 'Credit' | 'Payment' | 'Grace' | 'Cancel';
export type SubscriptionStatus = 'Active' | 'Inactive';
