import React from 'react';
import { Text, Colors } from 'library';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';

import { AddCenterInput, AddStaffInput, OnboardingEntryName } from '../types';

interface Props {
    entryName: OnboardingEntryName;
    openAddModal: ((editItem?: AddCenterInput) => void) | ((editItem?: AddStaffInput) => void);
}
const AddEntryButton = ({ entryName, openAddModal }: Props) => {
    return (
        <Button
            style={{
                maxHeight: 30,
                marginRight: 8,
                backgroundColor: Colors.darkNavy,
                color: Colors.white,
                fontSize: 16,
                fontFamily: 'Outfit',
                textTransform: 'none',
                borderRadius: 70,
                padding: 14,
            }}
            onClick={() => openAddModal()}
        >
            <FontAwesomeIcon icon={faCirclePlus} color={Colors.white} style={{ marginRight: 8 }} />
            <Text variant="body2" color={Colors.white}>
                {`Add ${entryName}`}
            </Text>
        </Button>
    );
};

export default AddEntryButton;
