import React, { useState } from 'react';
import { BusinessApplicant } from 'internal/applicants/ApplicantModels';
import { MenuItem, Grid, styled } from '@mui/material';
import { TextButton, Text, Colors, OnProbationChip } from 'library';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { BusinessJobWithAppointments } from 'models';
import JobChangeRequestDetails from 'internal/applicants/JobChangeRequestDetails';
import AddProvider from '../AddProvider';
import { format12HourTime, formatMonthDayTime } from 'shared/Dates';
import { ignore, unignore } from 'internal/applicants/businessApplicantApi';
import { consoleLogInDev } from 'shared';
import ProviderBookings from './ProviderBookings';
import ProviderStatsCell from 'internal/shared/ProviderStatsCell';
import AutobookDetails from './ReverseMatching/components/AutobookDetails';

export default function BusinessApplicantMenuItem({
    applicant,
    job,
    refreshJobs,
    week,
    bookingDates,
    refreshApplicants,
}: {
    applicant: BusinessApplicant;
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    week?: Date;
    bookingDates?: string[];
    refreshApplicants: () => void;
}) {
    const [detailsOpen, setDetailsOpen] = useState(false);

    function onIgnoreClick(applicationId: number, ignored: boolean) {
        if (ignored) {
            ignore(applicationId).then(refreshApplicants).catch(consoleLogInDev);
        } else {
            unignore(applicationId).then(refreshApplicants).catch(consoleLogInDev);
        }
    }

    return (
        <>
            <MenuItem disableRipple style={{ justifyContent: 'space-between', marginTop: 8 }}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12} display="flex" style={{ marginBottom: 4, maxWidth: 600 }}>
                            <ProviderStatsCell
                                user={applicant.applied_user}
                                providerStats={applicant.applied_user_stats}
                                isModal={detailsOpen}
                                setIsModal={setDetailsOpen}
                                hiringGroupOpened={
                                    applicant.hiring_group_opened
                                        ? formatMonthDayTime(applicant.hiring_group_opened)
                                        : undefined
                                }
                            />
                        </Grid>
                        {!!applicant.applied_user_on_probation && <OnProbationChip />}
                        <Text
                            bold
                            variant="body2"
                            color={Colors.turquoise}
                            textStyle={{ backgroundColor: Colors.lightGreen, marginRight: 10 }}
                        >
                            {applicant.applicant_type}
                        </Text>
                        <AddProvider
                            job={job}
                            refreshJobs={refreshJobs}
                            initialProviderUserId={applicant.applied_user.id}
                        />
                        <TextButton
                            buttonStyle={{ width: undefined }}
                            onClick={() => onIgnoreClick(applicant.id, !applicant.ignored)}
                        >
                            {applicant.ignored ? 'Un-ignore' : 'Ignore'}
                        </TextButton>
                    </div>
                    <Grid container>
                        <InfoContainer item>
                            {applicant.cancelled_appointments.length > 0 && (
                                <Grid>
                                    <Text bold variant="body2" color={Colors.error}>
                                        This user has cancelled the following shifts on this job:
                                    </Text>
                                    {applicant.cancelled_appointments.map((appt) => (
                                        <Text variant="body2" key={appt.id}>
                                            {formatMonthDayTime(appt.start_date)} - {formatMonthDayTime(appt.end_date)}
                                        </Text>
                                    ))}
                                </Grid>
                            )}
                            <AutobookDetails applicant={applicant} />
                            <Text variant="body2">
                                Last updated: {formatMonthDayTime(new Date(applicant.last_updated))}
                            </Text>
                            {applicant.changes?.map((request) => (
                                <JobChangeRequestDetails
                                    key={request.id}
                                    jobChangeRequest={request}
                                    filterWeek={week}
                                    timezone={applicant.timezone}
                                />
                            ))}
                            {applicant.provider_last_minute_arrival ? (
                                <Text variant="caption">
                                    <Text bold inline variant="caption">
                                        Last Minute Arrival Time:
                                    </Text>{' '}
                                    {format12HourTime(applicant.provider_last_minute_arrival)}
                                </Text>
                            ) : null}
                        </InfoContainer>
                        <Grid>
                            <ProviderBookings
                                userId={applicant.applied_user.id}
                                week={week}
                                job={job}
                                initialBookingDates={bookingDates}
                                disableBookingFetch={true}
                            />
                        </Grid>
                    </Grid>
                </div>
            </MenuItem>
            <ProviderDetailsOutreachModal
                providerUserId={applicant.applied_user.id}
                onClose={() => setDetailsOpen(false)}
                open={detailsOpen}
            />
        </>
    );
}

const InfoContainer = styled(Grid)({
    maxWidth: '50%',
});
