import React, { useState, useEffect } from 'react';
import JobFormPage from '../JobFormPage';
import { Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { useUserContext } from 'UserContext';
import FormField from '../../FormField';
import ConnectPayment from 'parent-portal/payments/Components/ConnectPaymentModal/ConnectPayment';

interface PaySetupPageProps {
    name: string;
}

export default function PaySetupPage({ name }: PaySetupPageProps) {
    const { goToNext } = useJobFormContext();
    const { user } = useUserContext();
    const [valid, setValid] = useState(false);

    useEffect(() => {
        const paymentSetup = !!user?.payment_information && user?.payment_information?.length > 0;
        setValid(paymentSetup);
    }, [user]);

    return (
        <JobFormPage
            title={'Time to connect payment.'}
            onNextClicked={() => goToNext(name)}
            nextText={'Next'}
            nextDisabled={!valid}
            id={name}
        >
            <FormField title="To continue, you'll need to set up payment." style={{ marginTop: 30 }}>
                <Text variant="body1" textStyle={{ marginTop: 10, marginBottom: 30 }}>
                    You’ll be charged on a weekly basis for the hours that a provider works at your center including
                    fees. You will have an opportunity to review hour reports on a daily basis and view all charges on
                    the payment page at any time.
                </Text>
                <ConnectPayment inJobForm />
            </FormField>
        </JobFormPage>
    );
}
