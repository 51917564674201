import React from 'react';
import { Colors, Text } from 'library';
import { Avatar, Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatLongWeekdayMonthDayYear, format12HourTime } from 'shared/Dates';

import { hoursReported, actions, needsReview } from './utils';

import type { TimesheetWorker, UnclockedInTimesheetWorker } from 'parent-portal/home/new/types';

export function LargeTimesheetsTable({
    data,
    editAction,
}: {
    data: TimesheetWorker[] | UnclockedInTimesheetWorker[];
    editAction: (timesheet: TimesheetWorker | UnclockedInTimesheetWorker) => void;
}) {
    function middleAndBottomText(timesheet: TimesheetWorker | UnclockedInTimesheetWorker) {
        const text = hoursReported(timesheet);
        return (
            <>
                {text.middle}
                {text?.bottom ?? null}
            </>
        );
    }

    return (
        <Table>
            <StyledHead>
                <TableCell>
                    <Text variant="body2">Name</Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2">Shift Scheduled</Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2">Hours Reported</Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2">Actions</Text>
                </TableCell>
            </StyledHead>
            <TableBody>
                {data.map((timesheet: TimesheetWorker | UnclockedInTimesheetWorker) => (
                    <TableRow key={`${timesheet.user.id}-${timesheet.start_date}`}>
                        <StyledCell>
                            <WorkerAvatar src={timesheet.profile_image?.image} />
                            <Link to={`/provider/${timesheet.user.id}`} style={{ paddingRight: 10 }}>
                                <Text variant="body2" textStyle={{ marginLeft: 10, textDecoration: 'underline' }}>
                                    {timesheet.user.first_name} {timesheet.user.last_name}
                                </Text>
                            </Link>
                            {needsReview(timesheet)}
                        </StyledCell>
                        <TableCell>
                            {timesheet.end_date ? (
                                <>
                                    <Text variant="body2">
                                        {formatLongWeekdayMonthDayYear(timesheet.start_date, timesheet.timezone)}
                                    </Text>
                                    <Text variant="body2">
                                        {format12HourTime(timesheet.start_date)} -{' '}
                                        {format12HourTime(timesheet.end_date)}
                                    </Text>
                                </>
                            ) : (
                                <Text variant="body2">No shift schedule found</Text>
                            )}
                        </TableCell>
                        <TableCell>{middleAndBottomText(timesheet)}</TableCell>
                        <TableCell>{actions(timesheet, editAction)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

const StyledHead = styled(TableHead)({
    backgroundColor: Colors.lightGrey,
    borderBottomColor: 'transparent',
});

const WorkerAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'inline-flex',
});

const StyledCell = styled(TableCell)({
    alignItems: 'center',
    display: 'flex',
});
