import { Text } from 'library';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { formatMonthYear } from 'shared/Dates';
import Highlight from './Highlight';
import { SlimEmploymentHistory } from 'models';

export default function ExperienceHighlight({ employment }: { employment: SlimEmploymentHistory[] }) {
    const [latestEmployment, setLatestEmployment] = useState<SlimEmploymentHistory>();

    useEffect(() => {
        employment.sort((a, b) => moment(b.start_date).unix() - moment(a.start_date).unix());
        setLatestEmployment(employment[0]);
    }, [employment]);

    return (
        <Highlight title="Recent Employment">
            {latestEmployment && (
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                    <Text>
                        <b>{latestEmployment.title}</b>{' '}
                        {latestEmployment.start_date ? formatMonthYear(latestEmployment.start_date) : ''}
                        {latestEmployment.end_date ? ` - ${formatMonthYear(latestEmployment.end_date)}` : ''}
                    </Text>
                    <Text variant="body2">{latestEmployment.employer}</Text>
                    <Text variant="body2">{latestEmployment.description}</Text>
                </div>
            )}
        </Highlight>
    );
}
