import React from 'react';
import { Grid } from '@material-ui/core';
import { Text, SizeableRoundedDialog, PrimaryButton, SecondaryButton } from 'library';
import { consoleLogInDev, useClient } from 'shared';
import { ShiftProviderUser } from 'models';

export default function ComplianceActionInfoModal({
    modalOpen,
    setModalOpen,
    actionText,
    provider_user,
    business_location_id,
    refresh,
}: {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    actionText: string;
    provider_user: ShiftProviderUser;
    business_location_id: number;
    refresh: () => void;
}) {
    const { loading, send } = useClient({
        url: 'api/compliance-action/initiate/',
        options: { sendImmediately: false },
    });
    const actionType = actionText.toLowerCase().replace(' ', '_');
    function onConfirm() {
        const body = {
            action: actionType,
            provider_user_id: provider_user?.id,
            business_location_id: business_location_id,
        };
        send({ method: 'POST', body })
            .then(() => {
                refresh();
            })
            .catch((e) => {
                alert('An error occurred. Please try again. If this persists please reach out to your ');
                consoleLogInDev(e);
            })
            .finally(() => setModalOpen(false));
    }

    return (
        <SizeableRoundedDialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={actionText}
            style={{ maxWidth: 600 }}
            closeButton
        >
            <Grid item container style={{ padding: 20, gap: 20 }}>
                <Text variant="h1">{actionText}</Text>
                <Text>
                    The background check for this worker has cleared and they have completed all other requirements
                    needed by the state, but there is action required before this worker starts.
                </Text>
                <Text bold>
                    For detailed instructions, please look for an email with the subject{' '}
                    {`"Action Required: ${actionText}"`} from Tandem in your inbox.
                </Text>
                <Text>
                    Once you have completed all actions, please click confirm below to alert us that you have done so.
                </Text>
                {actionType == 'initiate_resubmission' && (
                    <Text>
                        We will add a credit for the cost of resubmission to your account so you will be reimbursed.
                    </Text>
                )}
                <Grid item container direction="row" style={{ justifyContent: 'space-between' }}>
                    <SecondaryButton
                        loading={loading}
                        onClick={() => setModalOpen(false)}
                        buttonStyle={{ width: '45%' }}
                    >
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton loading={loading} onClick={onConfirm} buttonStyle={{ width: '45%' }}>
                        Confirm
                    </PrimaryButton>
                </Grid>
            </Grid>
        </SizeableRoundedDialog>
    );
}
