import React, { useEffect, useState } from 'react';
import DollarIcon from 'assets/icons/tandempay/dollar-icon-circle.svg';
import BankIcon from 'assets/icons/tandempay/bank-circle.svg';
import CreditCardIcon from 'assets/icons/tandempay/credit-card-circle.svg';
import ModalOptionRow from '../ModalOptionRow';
import PageContent from '../PageContent';
import ModalPage from '../ModalPage';
import { client } from 'shared';

interface IntroPageProps {
    goToConnectBankAccount: () => void;
    goToConnectCreditCard: () => void;
    subtitle?: string;
    showHeader: boolean;
}

export default function IntroPage({
    goToConnectBankAccount,
    goToConnectCreditCard,
    subtitle,
    showHeader,
}: IntroPageProps) {
    const [connectCreditCardActive, setConnectCreditCardActive] = useState(false);
    const [subtitleText, setSubtitleText] = useState(
        "You can connect a bank account to pay your Tandem staff. You'll be notified to confirm their hours.",
    );
    useEffect(() => {
        client('payment/api/payment-feature/connect-cc-active/').then((response: { is_active: boolean }) => {
            setConnectCreditCardActive(response.is_active);
            if (response.is_active) {
                setSubtitleText(
                    "You can connect a bank account or credit card to pay your Tandem staff. You will be able to review and confirm all payments before they're sent.",
                );
            }
        });
    }, []);

    return (
        <ModalPage
            iconSource={<DollarIcon />}
            headerText="Set up TandemPay"
            subtitleText={subtitle ?? subtitleText}
            showHeader={showHeader}
        >
            <PageContent>
                <ModalOptionRow
                    onClick={goToConnectBankAccount}
                    icon={<BankIcon />}
                    titleText="Connect Bank Account"
                    bodyText={
                        !connectCreditCardActive
                            ? 'Paying with your bank account incurs no fees.'
                            : 'We recommend this option. Paying with a bank account incurs no fees.'
                    }
                />
                {connectCreditCardActive ? (
                    <ModalOptionRow
                        onClick={goToConnectCreditCard}
                        icon={<CreditCardIcon />}
                        titleText="Connect Credit Card"
                        bodyText="Using a credit card to pay staff incurs a $0.30 + 2.9% fee by our credit card processor, Stripe."
                    />
                ) : (
                    <></>
                )}
            </PageContent>
        </ModalPage>
    );
}
