import { Grid } from '@mui/material';
import { Colors } from 'library';
import React from 'react';
import CenterWorkersView from '../components/CenterWorkersView';
import { CenterWorkerViewProps } from '../types';

export default function BoardView({
    selectedWorker,
    setSelectedWorker,
    workers,
    workersLoading,
    refreshWorkers,
}: Omit<CenterWorkerViewProps, 'detailedView'>) {
    return (
        <Grid container direction="column" style={{ backgroundColor: Colors.lightGrey, height: '100%' }}>
            <CenterWorkersView
                selectedWorker={selectedWorker}
                setSelectedWorker={setSelectedWorker}
                detailedView={true}
                refreshWorkers={refreshWorkers}
                workers={workers}
                workersLoading={workersLoading}
            />
        </Grid>
    );
}
