import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { client, consoleLogInDev } from 'shared';
import { Grid, useMediaQuery } from '@mui/material';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';
import ModalPage from '../ModalPage';
import PageContent from '../PageContent';
import { PrimaryButton, Text } from 'library';
import PayMethod from '../../PayMethod';
import { IPayMethod } from 'models';
import { CheckBox } from '@mui/icons-material';

export default function SuccessPage({ showHeaderAndFooter }: { showHeaderAndFooter: boolean }) {
    const [payMethods, setPayMethods] = useState<IPayMethod[]>([]);
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const margin = small ? 12 : 42;

    useEffect(() => {
        getPayments();
    }, []);

    const getPayments = async () => {
        try {
            const apiPayInfo = await client('api/get-payment-setup/');
            setPayMethods(apiPayInfo);
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    return (
        <ModalPage iconSource={<TadaIcon />} headerText="You're all set!" showHeader={showHeaderAndFooter}>
            <PageContent>
                <Text textStyle={{ marginBottom: 20, marginLeft: margin }}>
                    <CheckBox /> Your payment information has successfully been added to your profile.
                </Text>
                {payMethods.map((payMethod) => (
                    <Grid key={payMethod.id} container style={{ marginLeft: margin }} gap={2}>
                        <Text variant="h2" bold>
                            Payment Information
                        </Text>
                        <PayMethod payMethod={payMethod} />
                    </Grid>
                ))}
            </PageContent>
            {showHeaderAndFooter && (
                <PrimaryButton
                    onClick={() => window.location.reload()}
                    buttonStyle={{ marginRight: 40, width: undefined, alignSelf: 'flex-end' }}
                >
                    Done
                </PrimaryButton>
            )}
        </ModalPage>
    );
}
