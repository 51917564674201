import React from 'react';
import { Grid } from '@material-ui/core';
import { UserContextUser } from 'models';
import MainContactInfo from './MainContactInfo';
import Address from './Address';

export default function Basics({
    user,
    editContactInfo,
    setEditContactInfo,
}: {
    user: UserContextUser;
    editContactInfo: boolean;
    setEditContactInfo: (b: boolean) => void;
}) {
    const address = user.address.length && user.role !== 'BUSINESS_CONTACT' ? { ...user.address[0] } : undefined;
    return (
        <Grid container item direction="column" justify="space-between" style={{ gap: 10 }}>
            <MainContactInfo user={user} editing={editContactInfo} updateEditing={setEditContactInfo} />
            {address ? <Address user={user} address={address} /> : null}
        </Grid>
    );
}
