import React from 'react';
import { Text } from 'library';
import { Grid, Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

export default function CardInfo({
    title = '',
    content,
    infoText,
    bodyTextBold = true,
    titleTextBold = false,
}: {
    title?: string;
    content: string;
    infoText?: string;
    bodyTextBold?: boolean;
    titleTextBold?: boolean;
}) {
    return (
        <Grid item container direction="column" style={{ marginTop: 5 }}>
            <Text variant="caption" bold={titleTextBold}>
                {title}{' '}
                <Text variant="body2" bold={bodyTextBold}>
                    {content}
                    {infoText ? (
                        <Tooltip title={infoText}>
                            <InfoRounded fontSize="small" sx={{ marginTop: '-4px' }} />
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </Text>
            </Text>
        </Grid>
    );
}
