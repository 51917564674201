import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Message } from 'models/Message';
import { Colors } from 'library';
import moment from 'moment';
import { useUserContext } from 'UserContext';

interface IThreadProps {
    message: Message;
    user: any;
}

export const Thread = ({ message, user }: IThreadProps) => {
    const { user: contextUser } = useUserContext();

    return (
        <Grid
            container
            direction="column"
            alignItems={
                message.sender.id === user.id ||
                contextUser?.businesses_active?.some((x) => x.user.id === message.sender.id)
                    ? 'flex-end'
                    : 'flex-start'
            }
        >
            <Grid
                item
                lg={7}
                md={5}
                xs={7}
                style={{
                    backgroundColor: message.sender.id == user.id ? '#E8E8E8' : Colors.lightTurq,
                    borderRadius: 18,
                    padding: 10,
                    marginTop: 10,
                    wordWrap: 'break-word',
                }}
            >
                <Typography variant="subtitle1">{message.message}</Typography>
                {message.image && (
                    <img
                        src={message.image['image'] ? message.image['image'] : message.image['url']}
                        style={{ maxHeight: 150, maxWidth: 150, display: 'block' }}
                    ></img>
                )}
            </Grid>
            <Typography variant="subtitle2">{moment(message.sent_at).format('dddd M/D/YYYY h:mm A')}</Typography>
        </Grid>
    );
};
