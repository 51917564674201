import React from 'react';
import { Grid, styled } from '@mui/material';
import SnoozeBtn from './SnoozeBtn';
import OutreachSuspendedBtn from './OutreachSuspendedBtn';
import ManualWarningBtn from './ManualWarningBtn';
import { AdminProvider } from 'models';
import { TextButton } from 'library';

export default function OutreachTextBtns({
    provider,
    getProvider,
}: {
    provider: AdminProvider;
    getProvider: () => void;
}) {
    return (
        <Grid container direction={'column'}>
            <Grid container item direction={'row'} style={{ display: 'flex', flex: 1, marginTop: 10 }}>
                <ButtonContainer item xs={4}>
                    <OutreachSuspendedBtn user={provider.user} getProvider={getProvider} />
                </ButtonContainer>
                <ButtonContainer item xs={4}>
                    <ManualWarningBtn user={provider.user} getProvider={getProvider} />
                </ButtonContainer>
                <ButtonContainer item xs={4}>
                    <SnoozeBtn provider={provider} getProvider={getProvider} />
                </ButtonContainer>
            </Grid>
            <Grid container item>
                <TextButton
                    onClick={() => {
                        window.open(`http://api.join-tandem.com/admin/sitter/babysitter/${provider.id}/change/`);
                    }}
                >
                    Django Admin Babysitter
                </TextButton>
            </Grid>
        </Grid>
    );
}

const ButtonContainer = styled(Grid)({
    padding: 5,
});
