import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Colors, PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { GppGoodRounded, PauseCircleFilledRounded, TouchAppRounded } from '@mui/icons-material';
import WelcomeArt from 'assets/images/welcome-art.png';
import UserAttributesApi from 'shared/UserAttributesApi';
import { useUserContext } from 'UserContext';

export function WelcomeModal({ open, onClose }: { open: boolean; onClose: () => void }) {
    const [page, setPage] = useState<number>(0);
    const { user, refreshAlreadyLoggedInUser } = useUserContext();

    function closeModal() {
        if (user?.welcome_attribute) {
            UserAttributesApi.remove(user.welcome_attribute.id).then(refreshAlreadyLoggedInUser);
        }
        onClose();
    }

    return (
        <SizeableRoundedDialog
            id="welcome-modal"
            title="Welcome to your Center Portal"
            closeButton={true}
            open={open}
            onClose={closeModal}
            style={{ maxWidth: 600 }}
        >
            {page === 0 ? (
                <Container>
                    <Text variant="display">Welcome to Tandem</Text>
                    <Text variant="h2" textStyle={{ maxWidth: '80%', paddingTop: 10, textAlign: 'center' }}>
                        Find reliable childcare staffing solutions in a day. We connect qualified teachers with centers
                        needing coverage.
                    </Text>
                    <ArtContainer>
                        <img src={WelcomeArt} alt="welcomeArt" style={{ width: '90%' }} />
                    </ArtContainer>
                    <ButtonRow>
                        <PrimaryButton
                            id="welcome-modal-next-button"
                            onClick={() => setPage(1)}
                            buttonStyle={{ marginTop: 20, width: 'unset' }}
                            textStyle={{ padding: '0 20px' }}
                        >
                            Next
                        </PrimaryButton>
                    </ButtonRow>
                </Container>
            ) : (
                <Container>
                    <Text variant="display">How It Works</Text>
                    <Middle>
                        <StyledExplain backgroundColor={Colors.mediumTurq}>
                            <ImageContainer>
                                <TouchAppRounded
                                    style={{
                                        color: Colors.white,
                                        backgroundColor: Colors.darkNavy,
                                        height: 28,
                                        width: 28,
                                        padding: 4,
                                        borderRadius: 22,
                                    }}
                                />
                            </ImageContainer>
                            <TextBox>
                                <Text variant="h1">Post Your Jobs</Text>
                                <Text variant="body1">
                                    Post temporary or permanent needs and reach qualified workers instantly.
                                </Text>
                            </TextBox>
                        </StyledExplain>
                        <StyledExplain backgroundColor={Colors.lightTurq}>
                            <ImageContainer>
                                <PauseCircleFilledRounded
                                    style={{
                                        color: Colors.darkNavy,
                                        height: 32,
                                        width: 32,
                                        margin: 0,
                                    }}
                                />
                            </ImageContainer>
                            <TextBox>
                                <Text variant="h1">Wait While We Work</Text>
                                <Text variant="body1">
                                    Our software carefully matches your requirements with a qualified, compliant teacher
                                    in our network.
                                </Text>
                            </TextBox>
                        </StyledExplain>
                        <StyledExplain backgroundColor={Colors.lightTurq}>
                            <ImageContainer>
                                <GppGoodRounded
                                    style={{
                                        color: Colors.white,
                                        backgroundColor: Colors.darkNavy,
                                        height: 28,
                                        width: 28,
                                        padding: 4,
                                        borderRadius: 22,
                                    }}
                                />
                            </ImageContainer>
                            <TextBox>
                                <Text variant="h1">Meet Your Match</Text>
                                <Text variant="body1">
                                    Once matched, your teacher will begin their assignment. If they&apos;re a great fit,
                                    you can request them for future openings.
                                </Text>
                            </TextBox>
                        </StyledExplain>
                    </Middle>
                    <ButtonRow>
                        <PrimaryButton
                            id="welcome-modal-get-started-button"
                            onClick={closeModal}
                            buttonStyle={{ marginTop: 20, width: 'unset' }}
                            textStyle={{ padding: '0 20px' }}
                        >
                            Get Started
                        </PrimaryButton>
                    </ButtonRow>
                </Container>
            )}
        </SizeableRoundedDialog>
    );
}

const Container = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const ArtContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
});

const ButtonRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 20px',
    width: '100%',
});

const Middle = styled('div')({
    padding: 20,
});

const StyledExplain = styled('div')(({ backgroundColor }: { backgroundColor: string }) => ({
    alignItems: 'center',
    backgroundColor,
    border: `1px solid ${Colors.darkNavy}`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    padding: 10,
    width: '100%',
}));

const ImageContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    minWidth: 40,
    width: 40,
    flexDirection: 'column',
    justifyContent: 'center',
});

const TextBox = styled('div')({
    paddingLeft: 10,
});
