import React, { useEffect, useState } from 'react';
import { BasicDialog, Colors, LoadingSpinner, Text } from 'library';
import { styled } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';

import { useNotificationContext } from 'parent-portal/notifications/NotificationContext/NotificationContext';

import type { NotificationType } from 'models';

export function Notification({ notification }: { notification: NotificationType }) {
    const [loading, setLoading] = useState<boolean>(false);
    const [read, setRead] = useState<boolean>(notification.marked_as_read);
    const { notifications, clickNotification, actionMessage, setActionMessage, markNotificationRead } =
        useNotificationContext();

    const undismissable = ['verify your email address'].includes(notification.message.toLowerCase());

    function closeActionDialog() {
        setActionMessage(undefined);
    }

    function markRead() {
        setLoading(true);
        markNotificationRead(notification, () => {
            setLoading(false);
        });
    }

    useEffect(() => {
        const updatedNotification = notifications.find((n) => n.id === notification.id);

        if (updatedNotification && updatedNotification.marked_as_read === true) {
            setRead(true);
        }
    }, [notifications]);

    return (
        <NotificationMessage key={notification.id}>
            <MessageBody backgroundColor={undismissable ? Colors.lightRed : Colors.lightTurq}>
                <Row>
                    <Text
                        variant="caption"
                        bold
                        textStyle={{ flex: 1 }}
                        onClick={() => clickNotification(notification)}
                    >
                        {notification.added_at}
                    </Text>
                    <div>
                        {loading && (
                            <LoadingSpinner
                                style={{ width: 'fit-content', padding: 0 }}
                                loadingStyle={{ color: Colors.darkNavy, height: 20, width: 20 }}
                            />
                        )}
                        {!loading &&
                            !undismissable &&
                            (read ? (
                                <CheckRounded style={{ color: Colors.darkNavy, height: 20, width: 20 }} />
                            ) : (
                                <Text
                                    variant="caption"
                                    textStyle={{ textDecorationLine: 'underline' }}
                                    onClick={markRead}
                                >
                                    Mark Read
                                </Text>
                            ))}
                    </div>
                </Row>
                <Text variant="body2" onClick={() => clickNotification(notification)}>
                    {notification.message}
                </Text>
            </MessageBody>
            <BasicDialog
                closeButton
                buttonEnabled
                onSubmit={closeActionDialog}
                buttonText="OK"
                isOpen={!!actionMessage}
                onClose={closeActionDialog}
            >
                <Text variant="body1">{notification.message}</Text>
            </BasicDialog>
        </NotificationMessage>
    );
}

const NotificationMessage = styled('div')({
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    marginBottom: 10,
    '&:hover': {
        backgroundColor: Colors.lightGrey,
    },
    '&:focus': {
        backgroundColor: Colors.lightGrey,
    },
});

const MessageBody = styled('div')(({ backgroundColor }: { backgroundColor: string }) => ({
    backgroundColor,
    borderRadius: 10,
    cursor: 'pointer',
    padding: '5px 8px 5px',
    flex: 1,
    '&:hover': {
        backgroundColor: Colors.lightGrey,
    },
    '&:focus': {
        backgroundColor: Colors.lightGrey,
    },
}));

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
});
