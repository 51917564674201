import React from 'react';
import { Colors, Highlight, PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { styled } from '@mui/material';
import { useUserContext } from 'UserContext';

export function ReferralModal({ isOpen, onClose, small }: { isOpen: boolean; onClose: () => void; small?: boolean }) {
    const { user } = useUserContext();

    const refCode = user?.referral_code || user?.businesses_active?.[0].user.referral_code;
    const referralLink = `${window.location.origin}?referral=${refCode}`;

    return (
        <SizeableRoundedDialog
            open={isOpen}
            onClose={onClose}
            title="Refer a Center"
            fullScreen={false}
            contentStyle={{ padding: 0 }}
            style={{ width: small ? '100%' : '40%' }}
            closeButton
        >
            <Container small={(small ?? false).toString()}>
                <Text variant="h1">
                    <Highlight color={Colors.lightTurq}>Refer a Center</Highlight>
                </Text>
                <InnerContainer backgroundColor={Colors.lightTerraCotta}>
                    <Text variant="body1" textStyle={{ marginBottom: 10, marginLeft: 5 }} bold>
                        How does it work?
                    </Text>
                    <ul>
                        <BulletPoint>
                            <Text variant="body2">
                                Share your custom sign up link with a center that may be interested in using Tandem to
                                find staff.
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <Text variant="body2">
                                Your referral signs up and posts their first job on the Tandem site.
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <Text variant="body2">
                                Once your referral posts a job and uses their first worker,{' '}
                                <b>you will both get $50 added to your Tandem account.</b>
                            </Text>
                        </BulletPoint>
                    </ul>
                </InnerContainer>
                <InnerContainer backgroundColor={Colors.lightTurq}>
                    <Text variant="body1" textStyle={{ marginBottom: 10 }} bold>
                        Ready to refer a center?
                    </Text>
                    <Text variant="body2">Share your custom referral code:</Text>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <Text variant="h2" bold>
                            {refCode}
                        </Text>
                    </div>
                    <Text variant="body2" textStyle={{ marginTop: 10, display: 'flex' }}>
                        or
                        <Link>
                            <Text
                                variant="body2"
                                textStyle={{
                                    color: 'inherit',
                                    marginLeft: 5,
                                    textDecorationLine: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigator.clipboard.writeText(referralLink)}
                                bold
                            >
                                click here to copy your referral link!
                            </Text>
                        </Link>
                    </Text>
                </InnerContainer>
                <Row>
                    <PrimaryButton buttonStyle={{ marginTop: 20, width: 'auto' }} onClick={onClose}>
                        Close
                    </PrimaryButton>
                </Row>
            </Container>
        </SizeableRoundedDialog>
    );
}

const Container = styled('div')(({ small }: { small: string }) => ({
    padding: small === 'true' ? 15 : 20,
    paddingTop: 20,
}));

const InnerContainer = styled('div')(({ backgroundColor }: { backgroundColor: string }) => ({
    backgroundColor,
    borderRadius: 10,
    marginTop: 20,
    padding: 10,
}));

const BulletPoint = styled('li')({
    marginBottom: 10,
});

const Link = styled('div')({
    cursor: 'pointer',
    color: Colors.darkNavy,
    '&:hover': {
        color: Colors.darkGrey,
    },
    '&focus': {
        color: Colors.darkGrey,
    },
});

const Row = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});
