import { ShiftProviderUser } from 'models';
import React from 'react';
import ComplianceHoldActions from '../ComplianceActions/ComplianceHoldActions';
import AddToClearingHouse from '../ComplianceActions/AddToClearingHouse';
import { Grid } from '@material-ui/core';

export default function ComplianceActions({
    needs_center_action,
    refresh,
    has_completed_shift,
    provider_user,
    business_location_id,
}: {
    provider_user?: ShiftProviderUser;
    refresh: () => void;
    has_completed_shift: boolean;
    needs_center_action?: string[];
    business_location_id?: number;
}) {
    if (!needs_center_action || !needs_center_action.length || !provider_user || !business_location_id) return null;

    return (
        <Grid style={{ marginTop: 10, gap: 10 }} item container>
            <ComplianceHoldActions
                needs_center_action={needs_center_action}
                refresh={refresh}
                provider_user={provider_user}
                business_location_id={business_location_id}
            />
            {has_completed_shift ? <AddToClearingHouse needs_center_action={needs_center_action} /> : null}
        </Grid>
    );
}
