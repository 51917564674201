import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev, isValidPhone, validateEmail } from 'shared';
import { BasicDialog, Colors, PrimaryButton, SecondaryButton, Text } from 'library';
import { IBusinessLocation, PagingResult } from 'models';
import TextInput from 'parent-portal/BusinessLocation/TextInput';
import PhoneNumberInput from 'parent-portal/signup/PhoneNumberInput';
import { useUserContext } from 'UserContext';
import { IPermission } from './models';
import SelectPermissions from './SelectPermissions';
import SelectLocations from './SelectLocations';

interface IAddUserProps {
    onContactAdded: () => void;
}

interface IUserInfo {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
}

export default function AddUser({ onContactAdded }: IAddUserProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    const [phoneDisplay, setPhoneDisplay] = useState<string>('');
    const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [availableLocations, setAvailableLocations] = useState<IBusinessLocation[]>([]);
    const [availablePermissions, setAvailablePermissions] = useState<IPermission[]>([]);

    const { user } = useUserContext();

    useEffect(() => {
        if (!isOpen || (availableLocations.length && availablePermissions.length)) {
            return;
        }

        client(`api/business-location/?page_size=100`)
            .then((response: PagingResult<IBusinessLocation>) => setAvailableLocations(response.results))
            .catch((error) => {
                consoleLogInDev(error);
            });
        client(`api/permissions/?page_size=50`)
            .then((response: PagingResult<IPermission>) => setAvailablePermissions(response.results))
            .catch((error) => {
                consoleLogInDev(error);
            });
    }, [isOpen]);

    function onSubmitClick() {
        setErrorMessage('');
        setIsSubmitting(true);
        client(`api/business-contact/`, {
            body: {
                first_name: userInfo?.firstName,
                last_name: userInfo?.lastName,
                email: userInfo?.email,
                phone_number: userInfo?.phoneNumber,
                business_locations: selectedLocations.length !== availableLocations.length ? selectedLocations : [],
                permissions: selectedPermissions,
                business_id: user?.businesses_active?.[0].id,
            },
        })
            .then(() => {
                onContactAdded();
                closeModal();
            })
            .catch((error) => {
                if (error.email) {
                    setErrorMessage(error.email.join(' '));
                } else if (error.phone_number) {
                    setErrorMessage(error.phone_number.join(' '));
                } else {
                    setErrorMessage(' An error occurred while creating the contact');
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    function onUserInfoChange(value: string, key: keyof IUserInfo) {
        setUserInfo((prevVal) => {
            return {
                ...(prevVal || {}),
                [key]: value,
            } as IUserInfo;
        });
    }

    function onLocationSelect(id: number) {
        if (selectedLocations.includes(id)) {
            setSelectedLocations(selectedLocations.filter((x) => x !== id));
        } else {
            setSelectedLocations([...selectedLocations, id]);
        }
    }

    function onPermissionSelect(id: number) {
        if (selectedPermissions.includes(id)) {
            setSelectedPermissions(selectedPermissions.filter((x) => x !== id));
        } else {
            setSelectedPermissions([...selectedPermissions, id]);
        }
    }

    function onLocationSelectAll() {
        if (!selectedLocations.length || selectedLocations.length < availableLocations.length) {
            setSelectedLocations(availableLocations.map((x) => x.id));
        } else {
            setSelectedLocations([]);
        }
    }

    const isPhoneValid = !userInfo?.phoneNumber || isValidPhone(userInfo.phoneNumber);
    const isValidEmail = userInfo?.email && validateEmail(userInfo.email);
    const showEmailError = userInfo?.email ? !validateEmail(userInfo.email) : false;

    function isSubmitDisabled() {
        return (
            !userInfo?.firstName ||
            !userInfo?.lastName ||
            !isValidEmail ||
            !selectedLocations.length ||
            !isPhoneValid ||
            isSubmitting
        );
    }

    function closeModal() {
        setIsOpen(false);
        setUserInfo(undefined);
        setPhoneDisplay('');
        setSelectedLocations([]);
        setSelectedPermissions([]);
    }

    return (
        <>
            <PrimaryButton onClick={() => setIsOpen(true)} buttonStyle={{ width: 'fit-content' }}>
                Add User
            </PrimaryButton>
            <BasicDialog isOpen={isOpen} onClose={closeModal}>
                <Grid style={{ padding: 20 }} container item direction="column">
                    <Text bold variant="h1">
                        Add User
                    </Text>
                    <Text bold variant="h2" textStyle={{ marginTop: 10 }}>
                        Account Information
                    </Text>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <TextInput
                                label="First Name*"
                                value={userInfo?.firstName || ''}
                                onChange={(value) => onUserInfoChange(value, 'firstName')}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextInput
                                label="Last Name*"
                                value={userInfo?.lastName || ''}
                                onChange={(value) => onUserInfoChange(value, 'lastName')}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextInput
                                label="Email*"
                                value={userInfo?.email || ''}
                                onChange={(value) => onUserInfoChange(value, 'email')}
                                error={showEmailError}
                                helperText={showEmailError ? 'Invalid email' : ''}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <PhoneNumberInput
                                label="Phone Number"
                                value={phoneDisplay || ''}
                                onChange={(value, display) => {
                                    onUserInfoChange(value, 'phoneNumber');
                                    setPhoneDisplay(display);
                                }}
                                error={!isPhoneValid}
                                errorText="Invalid Format"
                            />
                        </Grid>
                    </Grid>
                    <SelectLocations
                        availableLocations={availableLocations}
                        selectedLocations={selectedLocations}
                        onLocationSelect={onLocationSelect}
                        onSelectAll={onLocationSelectAll}
                    />
                    <SelectPermissions
                        availablePermissions={availablePermissions}
                        selectedPermissions={selectedPermissions}
                        onPermissionSelect={onPermissionSelect}
                    />
                    <Grid container item xs={12} justify="space-around">
                        <SecondaryButton buttonStyle={{ maxWidth: '30%' }} onClick={closeModal}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            disabled={isSubmitDisabled()}
                            loading={isSubmitting}
                            buttonStyle={{ maxWidth: '30%' }}
                            onClick={onSubmitClick}
                        >
                            Add
                        </PrimaryButton>
                    </Grid>
                    <Text variant="caption" textStyle={{ color: Colors.error }}>
                        {errorMessage}
                    </Text>
                </Grid>
            </BasicDialog>
        </>
    );
}
