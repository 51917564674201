import React, { useState } from 'react';
import { Tabs } from 'library';
import { useWorkers } from './api';
import { ManagedWorker } from './types';
import DrawerView from './location-based-view/DrawerView';
import { Grid } from '@mui/material';
import BoardView from './location-based-view/BoardView';

export default function ComplianceByLocation() {
    const [selectedWorker, setSelectedWorker] = useState<ManagedWorker>();
    const [selectedTab, setSelectedTab] = useState('0');

    const { workers, workersLoading, refreshWorkers } = useWorkers();

    return (
        <Grid>
            <Tabs
                tabs={['Dashboard', 'Table']}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                values={[
                    <DrawerView
                        key="dashboard-worker-view"
                        setSelectedWorker={setSelectedWorker}
                        selectedWorker={selectedWorker}
                        workers={workers}
                        workersLoading={workersLoading}
                        refreshWorkers={refreshWorkers}
                    />,
                    <BoardView
                        key="board-worker-view"
                        selectedWorker={selectedWorker}
                        setSelectedWorker={setSelectedWorker}
                        workers={workers}
                        workersLoading={workersLoading}
                        refreshWorkers={refreshWorkers}
                    />,
                ]}
            />
        </Grid>
    );
}
