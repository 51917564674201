import React, { useEffect } from 'react';
import { ComplianceSummary, ComplianceSummaryEntry, ManagedWorker, UserTableProps } from '../types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    styled,
} from '@mui/material';
import WorkerNameWithIcon from './WorkerNameWithIcon';
import IconByStatus from '../user-details/IconByStatus';
import { LoadingSpinner } from 'library';

const notDetailColumns = ['Name', 'Compliance Status'];

export default function WorkerDetailTable({
    workers,
    selectedWorker,
    setSelectedWorker,
    detailedView,
    workersLoading,
    setPage,
    page,
}: UserTableProps) {
    function updatePage(_: React.MouseEvent | null, newPage: number) {
        setPage(newPage + 1);
    }

    useEffect(() => {
        if (!selectedWorker) setSelectedWorker(workers?.results[0]);
    }, [workers]);

    function getSummaryValues(worker?: ManagedWorker) {
        if (!worker) return [];

        const baseComplianceSummary = worker.compliance_summary;
        const summaryValues = Object.keys(baseComplianceSummary).reduce(
            (summaryList: ComplianceSummaryEntry[], currentValue) => {
                const value = baseComplianceSummary[currentValue as keyof ComplianceSummary];
                return [...summaryList, ...value];
            },
            [],
        );

        return summaryValues;
    }

    const detailColumns = getSummaryValues(workers?.results[0]).map((value) => value.name);
    const columns = detailedView ? [...notDetailColumns, ...detailColumns] : notDetailColumns;
    function DetailRow({ worker }: { worker: ManagedWorker }) {
        return (
            <>
                {getSummaryValues(worker).map((value) => (
                    <TableCell key={value.name} style={{ textAlign: 'center' }}>
                        <IconByStatus status={value.status} />
                    </TableCell>
                ))}
            </>
        );
    }

    const PaginationControls = () => (
        <Pagination
            count={workers?.count || 0}
            rowsPerPage={workers?.per_page || 0}
            page={page - 1}
            onPageChange={updatePage}
            rowsPerPageOptions={[]}
        />
    );

    const columnTitleAlginment = (column: string) => {
        const isCentered = column === 'Compliance Status' || detailedView;
        return isCentered ? 'center' : 'left';
    };
    return (
        <Container>
            <PaginationControls />

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column}
                                    style={{ fontWeight: 'bold', textAlign: columnTitleAlginment(column) }}
                                >
                                    {column}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workersLoading ? (
                            <TableCell colSpan={columns.length} align="center">
                                <LoadingSpinner />
                            </TableCell>
                        ) : (
                            workers &&
                            workers.results.map((worker: ManagedWorker) => {
                                return (
                                    <TableRow
                                        selected={worker === selectedWorker}
                                        key={worker.user_id}
                                        onClick={!detailedView ? () => setSelectedWorker(worker) : undefined}
                                        style={{ cursor: detailedView ? 'auto' : 'pointer' }}
                                    >
                                        <TableCell>
                                            <WorkerNameWithIcon worker={worker} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconByStatus status={worker.compliance_status} />
                                        </TableCell>
                                        {detailedView ? <DetailRow worker={worker} /> : null}
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationControls />
        </Container>
    );
}

const Pagination = styled(TablePagination)({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
});

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
});
