import React, { useState } from 'react';
import theme from 'theme';
import { styled, useMediaQuery } from '@mui/material';

import { NavigationBar } from './NavigationBar';
import { Sidebar } from './Sidebar';
import { Tray } from './Tray';

export function Navigation({ children }: { children: React.ReactNode | React.ReactNode[] }) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState<boolean>(!small);
    const [value, setValue] = useState<number>(0);

    return (
        <RootPage>
            <NavigationBar small={small} open={open} setOpen={setOpen} />
            <Page id="sidebar-container" small={small.toString()}>
                <Sidebar small={small} open={open} setOpen={setOpen} setValue={setValue} />
                <InnerPage open={open.toString()} small={small.toString()}>
                    <BoundsContainer>{children}</BoundsContainer>
                    {small && <Tray value={value} setValue={setValue} />}
                </InnerPage>
            </Page>
        </RootPage>
    );
}

const RootPage = styled('div')({
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
});

const Page = styled('div')(({ small }: { small: string }) => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: small === 'true' ? 50 : 60,
    overflow: 'hidden',
    left: 0,
    right: 0,
    bottom: 0,
}));

const InnerPage = styled('div')(({ open, small }: { open: string; small: string }) => ({
    display: 'flex',
    left: open === 'true' && small !== 'true' ? 250 : 0,
    bottom: small === 'true' ? 65 : 0,
    top: small === 'true' ? 50 : 60,
    right: 0,
    position: 'fixed',
    overflow: 'auto',
}));

const BoundsContainer = styled('div')({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    overflow: 'auto',
});
