import React, { useEffect, useState } from 'react';
import theme from 'theme';
import DateFnsUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useMediaQuery } from '@mui/material';
import { Colors, PrimaryButton, Text, SizeableRoundedDialog, RadioGroup, SecondaryButton } from 'library';
import { useClient } from 'shared';
import { AccessTimeFilled } from '@mui/icons-material';

import type { ShiftProviderUser } from 'models';
import type { IBusinessJobDetailsModel } from '../models';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export function HireWorkerModal({
    open,
    onClose,
    job,
    provider,
}: {
    open: boolean;
    onClose: () => void;
    job: IBusinessJobDetailsModel;
    provider: ShiftProviderUser;
}) {
    const [verbalCommit, setVerbalCommit] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Date | null>(null);

    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const { loading, status, error, send } = useClient({
        url: 'api/staff/hire/',
        method: 'PATCH',
        options: { sendImmediately: false },
    });

    function onClick() {
        send({
            method: 'PATCH',
            body: {
                user_id: provider.id,
                business_location_id: job.business_location.id,
                hire: true,
                hire_date: startDate,
            },
        });
    }

    useEffect(() => {
        if (status === 200) {
            onClose();
        }
    }, [status]);

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Text variant="h1" textStyle={{ paddingLeft: 20 }}>
                Hire Worker
            </Text>
            <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
                <Text>Congrats! Transitioning a Tandem teacher to your staff and payroll is a huge milestone.</Text>
                <Text bold variant="body1" textStyle={{ marginTop: 20 }}>
                    Please confirm the following details before hiring.
                </Text>
                <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Text>1. Did {provider.first_name} verbally commit to moving onto your payroll and staff?</Text>
                    <RadioGroup
                        value={verbalCommit.toString()}
                        setValue={(event: string) => setVerbalCommit(event === 'true')}
                        values={{
                            true: 'Yes',
                            false: 'No',
                        }}
                        radioStyle={{ marginLeft: 10 }}
                        horizontal
                    />
                    {!verbalCommit && (
                        <Text variant="caption" color={Colors.error}>
                            Please confirm with {provider.first_name} that she is ready to move onto your staff and
                            payroll permanently
                        </Text>
                    )}
                </div>
                <div>
                    <Text>2. What will {provider.first_name}&apos;s start date be to join your staff?</Text>
                    <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                format="MM/DD/YYYY"
                                mask="__/__/____"
                                value={startDate}
                                onChange={(date: MaterialUiPickersDate) => setStartDate(date?.toDate() || null)}
                                openTo="date"
                                showTodayButton={false}
                                placeholder="Start Date"
                                disablePast
                                keyboardIcon={<AccessTimeFilled style={{ color: Colors.darkNavy }} />}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <SecondaryButton onClick={onClose} buttonStyle={{ marginRight: 10, width: 'unset' }}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={onClick}
                        buttonStyle={{ width: 'unset' }}
                        disabled={!verbalCommit || !startDate || loading}
                        loading={loading}
                    >
                        Hire
                    </PrimaryButton>
                </div>
                {error && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Text variant="caption" textStyle={{ color: Colors.error }}>
                            Sorry, something went wrong trying to complete this action. Please try again.
                        </Text>
                    </div>
                )}
            </div>
        </SizeableRoundedDialog>
    );
}
