import React from 'react';
import theme from 'theme';
import { ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';
import { Colors } from '../../theme';
import Text from '../text/Text';
import { withStyles } from '@material-ui/core';

const StyledToggleButton = withStyles({
    root: {
        '&:focus': {
            outline: 0,
            border: 0,
        },
    },
})(ToggleButton);

export default function ToggleButtons({
    tabs,
    onTabSelect,
    selectedTab,
    height = 50,
}: {
    tabs: string[];
    onTabSelect: (s: string) => void;
    selectedTab: string;
    height?: number;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ToggleButtonGroup
            color="primary"
            exclusive
            value={selectedTab}
            onChange={(e, value: string) => value !== null && onTabSelect(value)}
            style={{ height: height }}
        >
            {tabs.map((tab: string, index) => {
                let selected = tab === selectedTab;
                return (
                    <StyledToggleButton
                        key={index}
                        value={tab}
                        id={`toggle-button-${tab.replace(' ', '-').toLowerCase()}`}
                        style={{
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingLeft: small ? 10 : 30,
                            paddingRight: small ? 10 : 30,
                            borderTopLeftRadius: tab === tabs[0] ? 50 : 0,
                            borderBottomLeftRadius: tab === tabs[0] ? 50 : 0,
                            borderTopRightRadius: tab === tabs[tabs.length - 1] ? 50 : 0,
                            borderBottomRightRadius: tab === tabs[tabs.length - 1] ? 50 : 0,
                            zIndex: selected ? 1 : 0,
                            backgroundColor: selected ? Colors.darkNavy : Colors.disabledGrey,
                            borderColor: selected ? Colors.darkNavy : Colors.disabledGrey,
                            textTransform: 'unset',
                            boxShadow: '0px 3px 6px 0px #00000029',
                        }}
                    >
                        <Text
                            variant={'body1'}
                            textStyle={{
                                marginLeft: 10,
                                marginRight: 10,
                                color: selected ? Colors.white : Colors.darkGrey,
                            }}
                        >
                            {tab}
                        </Text>
                    </StyledToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
}
