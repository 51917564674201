import React from 'react';
import { Text, Colors, TextButton } from 'library';
import { AddCenterInput } from '../types';
import AddEntryButton from './AddEntryButton';
import { Button } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

interface Props {
    centers: AddCenterInput[];
    openAddModal: (editItem?: AddCenterInput) => void;
    onContinue: () => void;
    skipText?: string;
}

const CentersTable = ({ centers, openAddModal, onContinue }: Props) => {
    if (centers.length === 0) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
                <Text variant="h2" textStyle={{ marginBottom: 16 }}>
                    No centers added yet.
                </Text>
                <AddEntryButton entryName={'Center'} openAddModal={() => openAddModal(undefined)} />
            </div>
        );
    }

    return (
        <>
            <div style={{ width: '50%' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 15,
                    }}
                >
                    <Text variant="h2" bold>
                        {'Confirm your centers.'}
                    </Text>
                    <AddEntryButton entryName={'Center'} openAddModal={openAddModal} />
                </div>
                <TableContainer component={Paper} style={{ maxWidth: '100%', overflowY: 'auto', maxHeight: '40vh' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#D6D6D6' }}>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Name'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Address'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'City'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'State'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Zip'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{'Program ID'}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align="center">
                                    {'Actions'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {centers.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell>{row.centerName}</TableCell>
                                    <TableCell>{row.address}</TableCell>
                                    <TableCell>{row.city}</TableCell>
                                    <TableCell>{row.state}</TableCell>
                                    <TableCell>{row.zipCode}</TableCell>
                                    <TableCell>{row.programId}</TableCell>
                                    <TableCell align="center">
                                        <TextButton onClick={() => openAddModal(row)}>{'Edit'}</TextButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '50%',
                    justifyContent: 'end',
                    marginTop: 30,
                }}
            >
                <Button
                    style={{
                        maxHeight: 40,
                        marginRight: 8,
                        backgroundColor: Colors.darkNavy,
                        color: Colors.white,
                        textTransform: 'none',
                        borderRadius: 70,
                        padding: 16,
                    }}
                    onClick={onContinue}
                >
                    <Text variant="body2" color={Colors.white}>
                        {'Continue'}
                    </Text>
                </Button>
            </div>
        </>
    );
};

export default CentersTable;
