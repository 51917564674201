import React, { useState } from 'react';
import theme from 'theme';
import { useMediaQuery } from '@mui/material';
import { Colors, PrimaryButton, Text, SizeableRoundedDialog, OutlinedTextInput, SecondaryButton } from 'library';
import { useClient } from 'shared';
import type { ShiftProviderUser } from 'models';

export function ReplaceWorkerModal({
    open,
    onClose,
    ongoingRequestId,
    provider,
}: {
    open: boolean;
    onClose: () => void;
    ongoingRequestId: number;
    provider: ShiftProviderUser;
}) {
    const [comment, setComment] = useState<string>('');

    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const { loading, status, error, send } = useClient({
        url: `api/business-job/${ongoingRequestId}/trial-run-feedback/`,
        method: 'POST',
        body: {},
        options: { sendImmediately: false },
    });

    function onClick() {
        send({ method: 'POST', body: { user_id: provider.id, comment: comment, continue: false } });
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Text variant="h1" textStyle={{ paddingLeft: 20 }}>
                Replace Worker
            </Text>
            <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
                {status !== 200 ? (
                    <>
                        <Text>
                            This will remove {provider.first_name} from all upcoming shifts related to the trial run.
                        </Text>
                        <Text bold variant="body1" textStyle={{ marginTop: 20 }}>
                            Please confirm you want to take this action.
                        </Text>
                        <OutlinedTextInput
                            label="Provide anything you'd like Tandem to know"
                            style={{ marginTop: 20 }}
                            multiline
                            fullWidth
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
                            value={comment}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                            <SecondaryButton onClick={onClose} buttonStyle={{ marginRight: 10, width: 'unset' }}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton onClick={onClick} buttonStyle={{ width: 'unset' }} loading={loading}>
                                Remove
                            </PrimaryButton>
                        </div>
                    </>
                ) : (
                    <>
                        <Text>
                            The worker has been successfully removed from the trial run and the shifts have been
                            re-opened. We will get started on finding your next qualified worker right away!
                        </Text>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                            <SecondaryButton onClick={onClose} buttonStyle={{ width: 'unset', marginTop: 20 }}>
                                Close
                            </SecondaryButton>
                        </div>
                    </>
                )}
                {error && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Text variant="caption" textStyle={{ color: Colors.error }}>
                            Sorry, something went wrong trying to complete this action. Please try again.
                        </Text>
                    </div>
                )}
            </div>
        </SizeableRoundedDialog>
    );
}
