import React, { useState, useEffect } from 'react';
import { consoleLogInDev, formatAddress } from 'shared';
import { Grid } from '@material-ui/core';
import { Chip, Paper, Text, Colors } from 'library';
import { CalendarColors } from '../BusinessCalendar/HiredShift';
import * as fromApi from './draftApi';
import Loading from './Loading';
import { EditDraft } from '../EditDraft';
import AccountMenuItem from 'parent-portal/navigation/AccountMenu/AccountMenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { DraftJob } from './types';
import { useUserContext, PermissionType } from 'UserContext';

export default function DraftList() {
    const { hasPermission } = useUserContext();
    const [ongoingRequests, setOngoingRequests] = useState<DraftJob[]>([]);
    const [loading, setLoading] = useState(false);

    const getDrafts = () => {
        return fromApi
            .getDrafts()
            .then((response) => {
                setOngoingRequests(structuredClone(response));
            })
            .catch(consoleLogInDev)
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteDraft = (draftId: number) => {
        return fromApi
            .deleteDraft(draftId)
            .then(() => {
                getDrafts();
            })
            .catch(consoleLogInDev);
    };

    useEffect(() => {
        setLoading(true);
        getDrafts();
    }, []);

    function canEditDraft(addressId: number) {
        return hasPermission(PermissionType.PostJob, addressId);
    }

    if (loading) return <Loading />;

    return (
        <Grid container item xs={12} style={{ margin: '20px 0', borderRadius: 18 }}>
            {!ongoingRequests.length ? (
                <Text>You have no draft jobs to finish. You&apos;re all caught up!</Text>
            ) : (
                ongoingRequests.map((ongoingRequest) => (
                    <Paper style={{ padding: 20, marginBottom: 10 }} elevation={3} key={ongoingRequest.id}>
                        <Grid container direction="row" alignItems="center" style={{ marginBottom: 10 }}>
                            <Text variant="h2" bold>
                                {ongoingRequest.headline}
                            </Text>
                            <Chip
                                label={ongoingRequest.business_job_type.replace('_', ' ').toLowerCase()}
                                style={{
                                    textTransform: 'capitalize',
                                    marginLeft: 10,
                                }}
                                backgroundColor={CalendarColors[ongoingRequest.business_job_type]}
                            />
                            <Chip
                                label={'Draft'}
                                style={{
                                    textTransform: 'capitalize',
                                    marginLeft: 10,
                                    color: 'white',
                                }}
                                backgroundColor={Colors.mediumRed}
                            />
                        </Grid>
                        <Grid container direction="row" alignItems="center" style={{ marginBottom: 10 }}>
                            <Text variant="body1">{formatAddress(ongoingRequest.address)}</Text>
                        </Grid>
                        {canEditDraft(ongoingRequest.address.id) && (
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <EditDraft jobId={ongoingRequest.id} onClose={getDrafts} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <AccountMenuItem
                                        action={() => deleteDraft(ongoingRequest.id)}
                                        text={'Delete Draft'}
                                        icon={<DeleteIcon style={{ color: Colors.darkNavy }} />}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                ))
            )}
        </Grid>
    );
}
