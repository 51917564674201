import React, { useEffect, useState } from 'react';
import { Colors, PrimaryButton, Text } from 'library';
import { Card, styled } from '@mui/material';
import { client, consoleLogInDev } from 'shared';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { ChevronRightRounded, StarRounded } from '@mui/icons-material';
import { useModalsContext } from 'ModalsContext';

import draftPresetMap from '../../../jobs/upcoming/Drafts/draftPresetMap';
import { Link } from './Link';

import type { DashboardSummary } from '../types';
import { PermissionType, useUserContext } from 'UserContext';

export function PostAJob({
    data,
    loading,
    error,
    small,
    refresh,
}: {
    data?: DashboardSummary;
    loading: boolean;
    error: boolean;
    small: boolean;
    refresh: () => void;
}) {
    const { openJobForm } = useModalsContext();
    const { hasPermission } = useUserContext();
    const canPostJobs = hasPermission(PermissionType.PostJob);
    const [randomDraft, setRandomDraft] = useState<DashboardSummary['jobs']['drafts'][0] | undefined>(undefined);
    const { upcoming, drafts } = data?.jobs ?? { upcoming: [], drafts: [] };

    useEffect(() => {
        if (drafts?.length > 0) {
            setRandomDraft(drafts[getRandomIntInclusive(0, drafts.length - 1)]);
        }
    }, [drafts]);

    const randomDraftHeadline = randomDraft?.headline;
    const randomDraftDates =
        randomDraft?.start_date && randomDraft?.end_date
            ? `${formatShortWeekdayMonthDay(randomDraft.start_date)} - ${formatShortWeekdayMonthDay(randomDraft.end_date)}`
            : 'Unknown dates and times';

    const recommendedTeachers = getRandomIntInclusive(1, 3);

    function getRandomIntInclusive(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function finishDraft() {
        client(`api/ongoing/${randomDraft.id}/get-draft/`)
            .then((draft) => {
                const jobFormPresets = draftPresetMap(draft);
                openJobForm('Edit Draft', jobFormPresets, refresh);
            })
            .catch(consoleLogInDev);
    }

    function postJob() {
        openJobForm('Business Dashboard');
    }

    return (
        <StyledCard small={small.toString()}>
            <Text variant="h1" bold>
                Post a Job
            </Text>
            <Text textStyle={{ paddingTop: 10 }}>
                Get your needs posted and you&apos;ll be notified as soon as we fill them.
            </Text>
            <Row>
                {!loading && !error && (
                    <Text bold>
                        {drafts?.length} incomplete job{drafts?.length === 1 ? '' : 's'}
                    </Text>
                )}
                {drafts?.length > 0 && <Link to="/jobs?initialTab=Drafts">View All</Link>}
            </Row>
            {!!randomDraft && hasPermission(PermissionType.PostJob, randomDraft.address.id) && (
                <DraftJob>
                    <div>
                        <Text bold>
                            {randomDraft?.business_job_type === 'SUBSTITUTE' ? 'Substitute' : 'Trial Run'} Position
                            {randomDraftHeadline ? `: ${randomDraftHeadline}` : ''}
                        </Text>
                        <Text variant="body2">{randomDraftDates}</Text>
                        <Chip>
                            <Star>
                                <StarRounded htmlColor={Colors.terraCotta} fontSize="small" />
                            </Star>
                            <Text variant="caption" textStyle={{ color: Colors.white, paddingRight: '6px' }}>
                                {recommendedTeachers} recommended teacher{recommendedTeachers === 1 ? '' : 's'}
                            </Text>
                        </Chip>
                    </div>
                    <FinishLink onClick={finishDraft}>
                        <Text bold textStyle={{ textDecorationLine: 'underline', color: 'inherit' }}>
                            Finish
                        </Text>
                        <ChevronRightRounded htmlColor="inherit" style={{ height: 28, width: 28 }} />
                    </FinishLink>
                </DraftJob>
            )}
            <Row>
                {!loading && !error && (
                    <Text bold>
                        {upcoming?.length} upcoming job{upcoming?.length === 1 ? '' : 's'}
                    </Text>
                )}
                {canPostJobs && (
                    <PrimaryButton buttonStyle={{ width: 'auto' }} onClick={postJob}>
                        Post Job
                    </PrimaryButton>
                )}
            </Row>
        </StyledCard>
    );
}

const StyledCard = styled(Card)(({ small }: { small: string }) => ({
    backgroundColor: Colors.lightTurq,
    borderRadius: 10,
    padding: 20,
    width: small === 'true' ? '100%' : '48%',
}));

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
});

const DraftJob = styled('div')({
    alignItems: 'center',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.terraCotta}`,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 10,
});

const FinishLink = styled('div')({
    alignItems: 'center',
    color: Colors.darkNavy,
    cursor: 'pointer',
    display: 'flex',
    '&:hover': {
        color: Colors.darkGrey,
    },
    '&:focus': {
        color: Colors.darkGrey,
    },
});

const Chip = styled('div')({
    alignItems: 'center',
    backgroundColor: Colors.terraCotta,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    padding: '3px 3px',
    width: 'fit-content',
});

const Star = styled('div')({
    backgroundColor: Colors.white,
    borderRadius: '50%',
    display: 'flex',
    height: 'fit-content',
    marginRight: 5,
    width: 'fit-content',
});
