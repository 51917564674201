import React from 'react';
import { Grid, styled } from '@mui/material';
import { Checkbox, Text } from 'library';
import { IPermission } from './models';

interface ISelectPermissionsProps {
    availablePermissions: IPermission[];
    selectedPermissions: number[];
    onPermissionSelect: (id: number) => void;
}

export default function SelectPermissions({
    availablePermissions,
    selectedPermissions,
    onPermissionSelect,
}: ISelectPermissionsProps) {
    return (
        <SelectionContainer container>
            <Text variant="h2" bold>
                Select Permissions
            </Text>
            {availablePermissions.map((x) => (
                <React.Fragment key={x.id}>
                    <Checkbox
                        label={x.name}
                        checked={selectedPermissions.includes(x.id)}
                        onChange={() => onPermissionSelect(x.id)}
                        style={{ alignItems: 'flex-start' }}
                    />
                    <Text variant="caption" textStyle={{ marginLeft: 40, marginBottom: 5 }}>
                        {x.description}
                    </Text>
                </React.Fragment>
            ))}
        </SelectionContainer>
    );
}

const SelectionContainer = styled(Grid)({
    padding: '10px 0',
});
