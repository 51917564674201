import { Grid, styled } from '@mui/material';
import React from 'react';
import { LoadingIndicator, Text } from 'library';
import UserDetailChip from './UserDetailChip';
import { sortActions, formatDueDate } from './utils';
import IconByStatus from './IconByStatus';
import { ManagedWorker } from '../types';
import { useGetWorkerComplianceSummary } from '../api';

const StyledGrid = styled(Grid)({
    gap: 10,
});

export default function ComplianceProgress({ selectedWorker }: { selectedWorker?: ManagedWorker }) {
    const { complianceSummary, complianceLoading, complianceError } = useGetWorkerComplianceSummary(
        selectedWorker?.user_id || 0,
    );

    if (complianceLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '94vh',
                }}
            >
                <LoadingIndicator size={48} />
            </div>
        );
    }

    if (complianceError || !complianceSummary) {
        return null;
    }

    return (
        <UserDetailChip title="Compliance Summary">
            <Grid item container direction="row" style={{ gap: 10 }}>
                {complianceSummary.compliance_core
                    .sort(sortActions)
                    .reverse()
                    .map((action) => (
                        <StyledGrid item container direction="row" alignItems="center" key={action.name}>
                            <IconByStatus status={action.status} />
                            <Grid item container direction="column" style={{ width: '40%' }}>
                                <Text>{action.name}</Text>
                                {action.due_date ? (
                                    <Text variant="caption">Due: {formatDueDate(action.due_date)}</Text>
                                ) : null}
                            </Grid>
                        </StyledGrid>
                    ))}
            </Grid>
            <Grid item container direction="row" style={{ gap: 10 }}>
                <Text bold>Trainings</Text>
                {complianceSummary.trainings
                    .sort(sortActions)
                    .reverse()
                    .map((action) => (
                        <StyledGrid item container direction="row" alignItems="center" key={action.name}>
                            <IconByStatus status={action.status} size="small" />
                            <Grid item container direction="column" style={{ width: '60%' }}>
                                <Text variant="caption">
                                    {action.name} {action.due_date && `(Due: ${formatDueDate(action.due_date)})`}
                                </Text>
                            </Grid>
                        </StyledGrid>
                    ))}
            </Grid>
        </UserDetailChip>
    );
}
