import React from 'react';
import { Colors, Text } from 'library';
import { Tooltip, styled } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';

import type { StyledComponentProps, TooltipProps } from '@mui/material';

export function ExplanatoryCredentialPill({
    label,
    title,
    style,
}: {
    label: string;
    title: React.ReactNode;
    style?: StyledComponentProps;
}) {
    return (
        <LightTooltip title={title} placement="bottom-end">
            <Text
                bold
                variant="caption"
                textStyle={{ textDecorationLine: 'underline', cursor: 'default', marginTop: 5 }}
            >
                {label}
            </Text>
        </LightTooltip>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: Colors.white,
        boxShadow: theme.shadows[1],
    },
}));
