import React, { useState } from 'react';
import { Colors, LoadingSpinner, Text } from 'library';
import { Pagination, styled } from '@mui/material';
import ChangeModal from 'parent-portal/subs/ConfirmSubHours/ChangeModal';
import { ClockInModal } from 'parent-portal/jobs/upcoming/BusinessCalendar/ShiftCard/ClockInButton';
import { DidNotWorkModal } from 'parent-portal/subs/ConfirmSubHours/DidNotWorkButton';

import { SmallTimesheetsTable } from './SmallTimesheetsTable';
import { LargeTimesheetsTable } from './LargeTimesheetsTable';
import { TimesheetOptionsModal } from './TimesheetOptionsModal';

import type { IHourReport } from 'internal/payments/HourReportModels';
import type { PagingResult } from 'models';
import type { TimesheetWorker, UnclockedInTimesheetWorker } from 'parent-portal/home/new/types';

export function TimesheetsTable({
    data,
    small,
    loading,
    refresh,
    page,
    setPage,
}: {
    data?: PagingResult<TimesheetWorker> | PagingResult<UnclockedInTimesheetWorker>;
    small: boolean;
    loading: boolean;
    refresh: () => void;
    page: number;
    setPage: (page: number) => void;
}) {
    const [changeReport, setChangeReport] = useState<IHourReport | undefined>(undefined);
    const [clockInWorker, setClockInWorker] = useState<TimesheetWorker | UnclockedInTimesheetWorker | undefined>(
        undefined,
    );
    const [finalChangeReport, setFinalChangeReport] = useState<IHourReport | undefined>(undefined);
    const [dnw, setDNW] = useState<IHourReport | undefined>(undefined);

    function openModal(timesheet: TimesheetWorker | UnclockedInTimesheetWorker) {
        if (timesheet.hour_report?.start && timesheet.hour_report?.end) {
            setFinalChangeReport({
                ...timesheet.hour_report,
                timezone: timesheet.timezone,
            });
        } else if (timesheet.hour_report) {
            setChangeReport({
                ...timesheet.hour_report,
                timezone: timesheet.timezone,
            });
        } else {
            setClockInWorker(timesheet);
        }
    }

    if (loading || !data) return <LoadingSpinner loadingStyle={{ color: Colors.darkNavy }} />;

    if (!data.results.length) {
        return <Text variant="body2">No timesheets found!</Text>;
    }

    const normalized: { firstName: string; hourReportId: number | undefined }[] = data.results.map(
        (timesheet: TimesheetWorker | UnclockedInTimesheetWorker) => ({
            firstName: timesheet.user.first_name,
            hourReportId: timesheet?.hour_report?.id,
        }),
    );
    const firstName = normalized.find(
        (normal: { firstName: string; hourReportId: number | undefined }) => dnw?.id === normal.hourReportId,
    )?.firstName;

    return (
        <div>
            {small ? (
                <SmallTimesheetsTable data={data.results} editAction={openModal} />
            ) : (
                <LargeTimesheetsTable data={data.results} editAction={openModal} />
            )}
            {changeReport && (
                <ChangeModal
                    changeReport={changeReport}
                    setChangeReport={setChangeReport}
                    updateHoursReported={refresh}
                    fullScreen={small}
                />
            )}
            {finalChangeReport && (
                <TimesheetOptionsModal
                    isOpen={!!finalChangeReport}
                    onClose={() => {
                        setFinalChangeReport(undefined);
                        setChangeReport(undefined);
                        setDNW(undefined);
                    }}
                    onComplete={refresh}
                    onEditTimes={(changeReport: IHourReport) => {
                        setChangeReport(changeReport);
                        setFinalChangeReport(undefined);
                    }}
                    onDNW={(report: IHourReport) => {
                        setDNW(report);
                        setFinalChangeReport(undefined);
                    }}
                    report={finalChangeReport}
                    small={small}
                />
            )}
            {clockInWorker && 'appointment_id' in clockInWorker && (
                <ClockInModal
                    open={!!clockInWorker}
                    onClose={() => setClockInWorker(undefined)}
                    onComplete={refresh}
                    appointmentId={clockInWorker.appointment_id}
                    startDate={clockInWorker.start_date}
                    providerName={`${clockInWorker.user.first_name} ${clockInWorker.user.last_name}`}
                    providerUserId={clockInWorker.user.id}
                    timezone={clockInWorker.timezone}
                />
            )}
            {dnw && (
                <DidNotWorkModal
                    name={firstName ?? 'this worker'}
                    hourReportId={dnw.id}
                    onComplete={refresh}
                    onClose={() => setDNW(undefined)}
                    open={!!dnw}
                />
            )}
            <PaginationContainer>
                <Pagination
                    count={data.num_pages}
                    page={page}
                    onChange={(_, page) => setPage(page)}
                    hideNextButton={page >= data.num_pages}
                />
            </PaginationContainer>
        </div>
    );
}

const PaginationContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
});
