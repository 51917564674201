import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import { Text, TextButton } from 'library';
import ConfirmDialog from '../../../reusableComponents/confirmDialog';
import { PayBackgroundPaper } from './PayPapers';
import PaySectionHeader from './PaySectionHeader';
import { Colors } from '../../../constants';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import usePublicSessionTracking, {
    PublicSessionEventType,
    EventAction,
} from 'parent-portal/shared/PublicSessionTracking';
import { Address, IPayMethod } from 'models';
import RenderPaymentMethod from '../RenderPaymentMethod';
import DefaultPaymentMethod from './DefaultPaymentMethod';
import { useUserContext } from 'UserContext';

const PayMethods = ({ setPayMethodsExist }: { setPayMethodsExist?: (b: boolean) => void }) => {
    const [payMethods, setPayMethods] = useState<IPayMethod[]>([]);
    const [idToDelete, setIdToDelete] = useState<number>();
    const [openPaySetup, setOpenPaySetup] = useState<boolean>(false);

    const { trackSession } = usePublicSessionTracking('Pay Methods Card');
    const { getDisplayText } = useUserContext();

    useEffect(() => {
        getPayments();
    }, []);

    useEffect(() => {
        setPayMethodsExist?.(payMethods.length > 0);
    }, [payMethods]);

    const getPayments = async () => {
        try {
            const apiPayInfo = await client('api/get-payment-setup/');
            setPayMethods(apiPayInfo);
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    const trackEvent = (trackMessage: string, data = {}, action: EventAction = 'CLICK') => {
        trackSession(action, trackMessage, PublicSessionEventType.User, data);
    };

    const openPayModalAndTrack = () => {
        trackEvent('Family opened payment setup modal', { fromRoute: 'payments' }, 'DIALOG_OPEN');
        setOpenPaySetup(true);
    };

    const onPayModalClose = () => {
        setOpenPaySetup(false);
        getPayments();
    };

    const deletePayMethod = () => {
        client(`api/payment-info/${idToDelete}/`, { method: 'DELETE' })
            .then(() => {
                getPayments();

                trackEvent('Payments: Delete Payment Method', { payInfoId: idToDelete });
            })
            .catch(() => {
                setPayMethods((currentState) => {
                    return currentState.map((x) => {
                        return {
                            ...x,
                            errorMessage: x.id === idToDelete ? 'An error occurred deleting your payment method' : '',
                            isLoading: false,
                        };
                    });
                });
            })
            .finally(() => {
                setIdToDelete(undefined);
            });
    };

    const renderPayMethod = (payMethod: IPayMethod) => {
        function updatePayMethods(addresses: Address[]) {
            const newMethod = { ...payMethod, addresses: addresses };
            const index = payMethods.findIndex((p) => p.id === payMethod.id);
            const addressIds = addresses.map((a) => a.id);
            const updatedMethods = payMethods.map((p) => {
                p.addresses = p.addresses.filter((a) => !addressIds.includes(a.id));
                return p;
            });

            const newMethods = Array.from(updatedMethods);
            if (index !== -1) {
                newMethods[index] = newMethod;
            }
            setPayMethods(newMethods);
        }

        return (
            <RenderPaymentMethod
                payMethod={payMethod}
                DefaultPayComponent={
                    <DefaultPaymentMethod
                        payMethod={payMethod}
                        setPayMethods={setPayMethods}
                        setIdToDelete={setIdToDelete}
                        trackEvent={trackEvent}
                    />
                }
                updatePayMethods={updatePayMethods}
            />
        );
    };

    const getBody = () => {
        if (!payMethods || !payMethods.length) {
            return (
                <Grid container style={{ padding: 5, color: Colors.darkNavy }} justify="center" alignItems="center">
                    <Text variant="body1">No payment methods</Text>
                </Grid>
            );
        }

        return payMethods.map(renderPayMethod);
    };

    return (
        <PayBackgroundPaper>
            <Grid container>
                <Grid container item xs={12} justify="space-between" alignItems="center">
                    <Grid item>
                        <PaySectionHeader>Payment Methods</PaySectionHeader>
                    </Grid>
                    <Grid item style={{ marginBottom: 25 }}>
                        <TextButton
                            onClick={openPayModalAndTrack}
                            textStyle={{ margin: '0 3px', textTransform: 'none', fontSize: 17 }}
                        >
                            Add new payment method
                        </TextButton>
                    </Grid>
                </Grid>
                <Text variant="body2" textStyle={{ marginBottom: '1.5em' }}>
                    You can add individual payment methods to be associated with your different{' '}
                    {getDisplayText('center')} locations. Your default payment will be used for all addresses that do
                    not have a specific payment method assigned.
                </Text>
                <Grid item xs={12}>
                    {getBody()}
                </Grid>
            </Grid>
            <ConfirmDialog
                open={typeof idToDelete !== 'undefined'}
                onClose={() => setIdToDelete(undefined)}
                onConfirm={deletePayMethod}
                message="Are you sure you want to delete this pay method?"
                title="Confirm"
            />
            <ConnectPaymentModal isOpen={openPaySetup} onClose={onPayModalClose} />
        </PayBackgroundPaper>
    );
};

export default PayMethods;
