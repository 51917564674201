import { PagingResult } from 'models';
import { IDiscount, IAdminDiscount, IBusinessUserDiscount, IDiscountSummary } from 'models/Discount';
import { client, queryStringifyObject } from 'shared';

export function list(searchObj: {
    addressId?: number;
    status?: string;
    page?: number;
    businessLocationId?: string;
    name?: string;
}): Promise<PagingResult<IAdminDiscount>> {
    const qs = queryStringifyObject({
        address_id: searchObj.addressId,
        status: searchObj.status,
        business_location_id: searchObj.businessLocationId,
        page: searchObj.page,
        name: searchObj.name,
    });
    const url = !qs ? 'payment/api/discount/' : `payment/api/discount/?${qs}`;
    return client(url);
}

export function getDiscount(id: string | number): Promise<IAdminDiscount> {
    return client(`payment/api/discount/${id}/`);
}

export function getSummaryByUser(userId: string | number): Promise<IDiscountSummary> {
    return client(`payment/api/discount/summary/?user_id=${userId}`);
}

export function getSummaryByLocation(businessLocationId: string | number): Promise<IDiscountSummary> {
    return client(`payment/api/discount/summary/?business_location_id=${businessLocationId}`);
}

export function listForBusiness(): Promise<PagingResult<IBusinessUserDiscount>> {
    return client(`payment/api/discount/?status=open`);
}

export function createDiscount(addressId: number, value: number, user: number, notes?: string): Promise<IDiscount> {
    return client('payment/api/discount/', {
        body: {
            value,
            address: addressId,
            type: 'FIXED_AMOUNT',
            user,
            notes,
        },
    });
}
