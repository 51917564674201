import React, { useState } from 'react';
import UserDetailChip from './UserDetailChip';
import { Grid } from '@mui/material';
import { Colors, LoadingIndicator, SizeableRoundedDialog, Text } from 'library';
import { ManagedWorker } from '../types';
import { useGetWorkerDocuments } from 'parent-portal/compliance-product/api';
import DocumentLinks, {
    downloadFile,
    getBackGroundCheckLink,
} from 'parent-portal/provider-profile/tabs/documents/DocumentShared';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

export default function DocumentsSection({ selectedWorker }: { selectedWorker: ManagedWorker }) {
    const { documents } = useGetWorkerDocuments(selectedWorker.user_id);
    const [downloading, setDownloading] = useState(false);
    const [open, setOpen] = useState(false);
    function download() {
        setDownloading(true);
        try {
            downloadFile(`api/providers/${documents?.id}/documents/`);
            const backgroundCheckLink = documents ? getBackGroundCheckLink(documents) : null;
            const hasBackgroundPDF = documents?.compliance_documents?.some(
                (x) => x.friendly_name === 'Background Check Document',
            );
            if (backgroundCheckLink && !hasBackgroundPDF) {
                window.open(backgroundCheckLink, '_blank');
            }
        } catch (e) {
            setDownloading(false);
            alert('Error downloading documents');
        } finally {
            setDownloading(false);
        }
    }
    const addDocumentIcon = (
        <CreateNewFolderIcon onClick={() => setOpen(true)} style={{ color: Colors.darkNavy, cursor: 'pointer' }} />
    );
    return (
        <UserDetailChip
            title="Verified Documents"
            buttonText="Download Folder"
            onClick={documents ? download : undefined}
            titleIcon={addDocumentIcon}
        >
            {downloading ? <LoadingIndicator size={24} /> : null}

            <Grid item container direction="column" style={{ gap: 10 }}>
                {documents ? (
                    <DocumentLinks provider={documents} />
                ) : (
                    <Text variant="body2">No documents to show for this user.</Text>
                )}
            </Grid>
            <SizeableRoundedDialog open={open} onClose={() => setOpen(false)} closeButton style={{ width: 600 }}>
                <Grid item container direction="column" style={{ gap: 10, paddingLeft: 40, paddingRight: 40 }}>
                    <Text variant="h1">Add Compliance Document</Text>
                    <Text variant="body2">
                        {
                            'Your workers can upload their own documents in the app and we are working quickly to add the ability for you to upload them on their behalf.'
                        }
                    </Text>
                    <Text variant="body2">
                        {"In the meantime, if you'd like to send over your worker's documents, please email them to"}{' '}
                        <a
                            href="mailto:documents@join-tandem.com"
                            style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}
                        >
                            documents@join-tandem.com
                        </a>{' '}
                        {
                            'with their name as a subject line to upload them for on their behalf. We will take care of the rest.'
                        }
                    </Text>
                    <Text variant="body2" textStyle={{ marginTop: 20 }}>
                        {' '}
                        - Tandem
                    </Text>
                </Grid>
            </SizeableRoundedDialog>
        </UserDetailChip>
    );
}
