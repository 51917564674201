import React, { useState } from 'react';
import { Text } from 'library';
import { styled } from '@mui/material';
import { format12HourTime, formatMonthDay } from 'shared/Dates';
import { groupBy } from 'lodash';

import ChangeModal from 'parent-portal/subs/ConfirmSubHours/ChangeModal';
import { ClockInModal } from 'parent-portal/jobs/upcoming/BusinessCalendar/ShiftCard/ClockInButton';
import { BasicCard } from './BasicCard';
import { Link } from './Link';
import { WorkerTab } from './WorkerTab';

import type { IHourReport } from 'internal/payments/HourReportModels';
import type { DashboardSummary, IWorkerTab, UnclockedInTimesheetWorker } from '../types';

interface ExtendedWorkerTab extends IWorkerTab {
    hour_report: IHourReport | null;
    worker: DashboardSummary['timesheets'][0];
}

export function Timesheets({
    loading,
    small,
    timesheets,
    refresh,
}: {
    loading: boolean;
    small: boolean;
    timesheets?: DashboardSummary['timesheets'];
    refresh: () => void;
}) {
    const [changeReport, setChangeReport] = useState<IHourReport | undefined>(undefined);
    const [clockInWorker, setClockInWorker] = useState<DashboardSummary['timesheets'][0] | undefined>(undefined);

    const hasTimesheets = !!timesheets && timesheets.length > 0;
    const groupedTimesheets = groupBy(timesheets, 'location_name');
    const mostTimesheets: UnclockedInTimesheetWorker[] = Object.values(groupedTimesheets).sort(
        (a, b) => b.length - a.length,
    )[0];

    const workers: ExtendedWorkerTab[] = hasTimesheets
        ? mostTimesheets.map((worker: UnclockedInTimesheetWorker) => ({
              user: worker.user,
              profile_image: worker.profile_image,
              ...middleAndBottomText(worker),
              hour_report: worker.hour_report,
              worker,
          }))
        : [];

    function middleAndBottomText(worker: DashboardSummary['timesheets'][0]) {
        if (!worker.hour_report || !worker.hour_report.start)
            return {
                middle: (
                    <Text variant="caption" bold>
                        Not clocked in
                    </Text>
                ),
            };

        if (worker.hour_report.end) {
            return {
                middle: (
                    <Text variant="caption" bold>
                        {formatMonthDay(worker.hour_report.start)},{' '}
                        {format12HourTime(worker.hour_report.start, worker.timezone)} -{' '}
                        {format12HourTime(worker.hour_report.end, worker.timezone)}
                    </Text>
                ),
                bottom: worker.hour_report.approved_break ? (
                    <Text variant="caption">{worker.hour_report.approved_break} minute break</Text>
                ) : (
                    <Text variant="caption">No break taken</Text>
                ),
            };
        }

        const clockedIn = {
            middle: (
                <Text variant="caption">
                    <b>Clock In:</b> {formatMonthDay(worker.hour_report.start)},{' '}
                    {format12HourTime(worker.hour_report.start, worker.timezone)}
                </Text>
            ),
        };

        if (worker.hour_report.approved_break) {
            return {
                ...clockedIn,
                bottom: <Text variant="caption">{worker.hour_report.approved_break} minute break</Text>,
            };
        }

        return clockedIn;
    }

    return (
        <BasicCard loading={loading} small={small} right={false}>
            <Row>
                <Text variant="h1">Timesheets</Text>
                {hasTimesheets && <Link to="/timesheets">View All</Link>}
            </Row>
            {!hasTimesheets && <Text variant="body2">No time sheets need attention right now! 🎉</Text>}
            {hasTimesheets && (
                <>
                    <Text variant="body2" bold textStyle={{ marginBottom: 10 }}>
                        {mostTimesheets[0].location_name} ({mostTimesheets.length})
                    </Text>
                    <Body small={small.toString()}>
                        {workers.map((worker: ExtendedWorkerTab, index: number) => (
                            <WorkerTab
                                key={worker.user.id}
                                worker={worker}
                                small={small}
                                onClick={() =>
                                    worker.hour_report
                                        ? setChangeReport({
                                              ...worker.hour_report,
                                              timezone: worker.worker.timezone,
                                          })
                                        : setClockInWorker(worker.worker)
                                }
                                style={{ marginRight: index % 2 === 0 ? '2%' : 0 }}
                            />
                        ))}
                    </Body>
                </>
            )}
            {changeReport && (
                <ChangeModal
                    changeReport={changeReport}
                    setChangeReport={setChangeReport}
                    updateHoursReported={refresh}
                    fullScreen={small}
                />
            )}
            {clockInWorker && (
                <ClockInModal
                    open={!!clockInWorker}
                    onClose={() => setClockInWorker(undefined)}
                    onComplete={refresh}
                    appointmentId={clockInWorker.appointment_id}
                    startDate={clockInWorker.start_date}
                    providerName={`${clockInWorker.user.first_name} ${clockInWorker.user.last_name}`}
                    providerUserId={clockInWorker.user.id}
                    timezone={clockInWorker.timezone}
                />
            )}
        </BasicCard>
    );
}

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    justifyContent: 'space-between',
    width: '100%',
});

const Body = styled('div')(({ small }: { small: string }) => ({
    display: 'flex',
    flexDirection: small === 'true' ? 'column' : 'row',
    flexWrap: 'wrap',
    width: '100%',
}));
