import React from 'react';
import UserDetailChip from './UserDetailChip';
import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { formatPhoneNumber } from 'shared';
import { Text } from 'library';
import { ManagedWorker } from '../types';

export default function ContactInformation({ selectedWorker }: { selectedWorker: ManagedWorker }) {
    if (!selectedWorker.compliance_enrollment_id) {
        return null;
    }
    return (
        <UserDetailChip title="Contact Information">
            <Grid item container direction="row">
                <Grid item container direction="column" xs={12} md={6} style={{ marginBottom: 10 }}>
                    <Text bold>Email</Text>
                    <Text>{selectedWorker.email}</Text>
                </Grid>
                {selectedWorker.phone ? (
                    <Grid item container direction="column" xs={12} md={6} style={{ marginBottom: 10 }}>
                        <Text bold>Phone</Text>
                        <Text>{formatPhoneNumber(selectedWorker.phone)}</Text>
                    </Grid>
                ) : null}
                {selectedWorker.start_date ? (
                    <Grid item container direction="column" xs={12} md={6} style={{ marginBottom: 10 }}>
                        <Text bold>Start Date:</Text>
                        <Text>{format(new Date(selectedWorker.start_date), 'MM/dd/yyyy')}</Text>
                    </Grid>
                ) : null}
            </Grid>
        </UserDetailChip>
    );
}
