import React from 'react';
import { consoleLogInDev } from 'shared';
import { LeanUser } from 'models';
import { hire } from 'api/StaffInfoApi';
import { Grid } from '@mui/material';
import { Colors, BasicDialog, IconButton, Text, PrimaryButton } from 'library';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default function PermanentlyHireWorkerModal({
    user,
    business_location_id,
    refreshData,
}: {
    user: LeanUser;
    business_location_id: number;
    refreshData?: () => void;
}) {
    const [open, setOpen] = React.useState(false);
    const [hireDate, setHireDate] = React.useState<MaterialUiPickersDate | Date>(new Date());

    const handleHire = async () => {
        try {
            await hire(user.id, business_location_id, true, hireDate);
            refreshData?.();
            setOpen(false);
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    return (
        <>
            <span style={{ marginLeft: '10px' }}>
                <IconButton
                    tooltip="Mark worker as permanently hired"
                    icon={PersonAddIcon}
                    onClick={() => setOpen(true)}
                />
            </span>
            <BasicDialog
                isOpen={open}
                onClose={() => setOpen(false)}
                dialogTitle="Mark as Permanently Hired"
                closeButton
            >
                <Text variant="h2" bold>
                    {user.first_name} {user.last_name}
                </Text>
                <Grid container direction="column" style={{ gap: 10 }}>
                    <Grid
                        item
                        container
                        direction="column"
                        style={{ borderRadius: 18, backgroundColor: Colors.white, gap: 10 }}
                    >
                        <Grid item container style={{ width: 400 }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Hire Date"
                                    value={hireDate}
                                    onChange={(date) => setHireDate(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <PrimaryButton onClick={handleHire}>Mark Hired</PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </BasicDialog>
        </>
    );
}
