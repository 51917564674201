import { ManagedWorker } from 'parent-portal/compliance-product/types';
import { createStringDate } from './utils';
import { PagingResult } from 'models';

export const exampleManageWorkerRes: PagingResult<ManagedWorker> = {
    count: 2,
    per_page: 10,
    num_pages: 1,
    results: [
        {
            user_id: '-1',
            first_name: 'Tiana',
            last_name: 'Smith (Example User)',
            email: 'max+testtwo@join-tandem.com',
            phone: '+12345678900',
            start_date: '2021-01-01',
            compliance_status: 'WARNING',
            compliance_enrollment_id: 3,
            is_example_worker: true,
            compliance_summary: {
                compliance_core: [
                    {
                        name: 'Background Check',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: 'Proof of Education',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: 'Medical Statement',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                ],
                trainings: [
                    {
                        name: 'Child Care Center Orientation',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: "Ohio's Overview of Child Development",
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: "Ohio's Approach to Quality",
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: 'Child Abuse Recognition and Prevention',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: 'First Aid',
                        status: 'WARNING',
                        due_date: createStringDate(90),
                    },
                    {
                        name: 'Pediatric CPR',
                        status: 'WARNING',
                        due_date: createStringDate(90),
                    },
                    {
                        name: 'Communicable Disease Training',
                        status: 'INCOMPLETE',
                        due_date: null,
                    },
                ],
            },
        },
        {
            user_id: '-2',
            first_name: 'Tessa',
            last_name: 'McIntyre (Example User)',
            email: 'tessa+1@join-tandem.com',
            phone: '+12345678900',
            start_date: '2021-01-01',
            compliance_status: 'INVITED',
            compliance_enrollment_id: 1,
            is_example_worker: true,
            compliance_summary: {
                compliance_core: [
                    {
                        name: 'Background Check',
                        status: 'BLOCKER',
                        due_date: null,
                    },
                    {
                        name: 'Proof of Education',
                        status: 'BLOCKER',
                        due_date: null,
                    },
                    {
                        name: 'Medical Statement',
                        status: 'BLOCKER',
                        due_date: null,
                    },
                ],
                trainings: [
                    {
                        name: 'Child Care Center Orientation',
                        status: 'BLOCKER',
                        due_date: null,
                    },
                    {
                        name: "Ohio's Overview of Child Development",
                        status: 'WARNING',
                        due_date: '2025-03-23',
                    },
                    {
                        name: "Ohio's Approach to Quality",
                        status: 'WARNING',
                        due_date: '2025-03-23',
                    },
                    {
                        name: 'Child Abuse Recognition and Prevention',
                        status: 'WARNING',
                        due_date: '2025-04-22',
                    },
                    {
                        name: 'First Aid',
                        status: 'WARNING',
                        due_date: createStringDate(90),
                    },
                    {
                        name: 'Pediatric CPR',
                        status: 'WARNING',
                        due_date: createStringDate(90),
                    },
                    {
                        name: 'Communicable Disease Training',
                        status: 'INCOMPLETE',
                        due_date: null,
                    },
                ],
            },
        },
        {
            user_id: '-3',
            first_name: 'Jaila',
            last_name: 'Thompson (Example User)',
            email: 'jake@example.com',
            phone: '+12345678900',
            start_date: '2021-01-01',
            compliance_status: 'READY_TO_WORK',
            is_example_worker: true,
            compliance_enrollment_id: undefined,
            compliance_summary: {
                compliance_core: [
                    {
                        name: 'Background Check',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: 'Proof of Education',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: 'Medical Statement',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                ],
                trainings: [
                    {
                        name: "Ohio's Overview of Child Development",
                        status: 'SATISFIED',
                        due_date: createStringDate(0, 1),
                    },
                    {
                        name: "Ohio's Approach to Quality",
                        status: 'SATISFIED',
                        due_date: createStringDate(0, 1),
                    },
                    {
                        name: 'Child Abuse Recognition and Prevention',
                        status: 'SATISFIED',
                        due_date: createStringDate(0, 1),
                    },
                    {
                        name: 'First Aid',
                        status: 'SATISFIED',
                        due_date: createStringDate(0, 2),
                    },
                    {
                        name: 'Pediatric CPR',
                        status: 'SATISFIED',
                        due_date: createStringDate(0, 2),
                    },
                    {
                        name: 'Child Care Center Orientation',
                        status: 'SATISFIED',
                        due_date: null,
                    },
                    {
                        name: 'Communicable Disease Training',
                        status: 'INCOMPLETE',
                        due_date: null,
                    },
                ],
            },
        },
    ],
};
