import React from 'react';
import { Text, Colors } from 'library';
import { AdminProvider, SlimEmploymentHistory, EmploymentHistory } from 'models';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';

export function WorkerPastExperience({ provider }: { provider: AdminProvider }) {
    const employmentHistory = provider.employment_history;
    const hasEmploymentHistory = employmentHistory && employmentHistory.length > 0;

    const formatDate = (date: string | undefined) => {
        if (!date) {
            return 'N/A';
        } else {
            return format(new Date(date), 'MM-dd-yyyy');
        }
    };

    const getEmploymentLocation = (exp: SlimEmploymentHistory | EmploymentHistory) => {
        const city = exp.city;
        const state = exp.state;
        const streetAddess = exp.street_address;

        if (city && state && streetAddess) {
            return `${streetAddess}, ${city}, ${state}`;
        } else if (city && state) {
            return `${city}, ${state}`;
        } else if (state) {
            return state;
        }

        return null;
    };

    return (
        <Grid container direction="row" item style={{ gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid xs={12}>
                <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                    Employment History
                </Text>
            </Grid>

            {hasEmploymentHistory ? (
                <Grid xs={6}>
                    {employmentHistory?.map((exp) => (
                        <div key={exp.id} style={{ margin: 10 }}>
                            <Text bold variant="caption">
                                {exp.employer} - {exp.title}
                            </Text>
                            <Text variant="caption">
                                {formatDate(exp.start_date)} - {formatDate(exp.end_date)}
                            </Text>
                            <Text variant="caption">{getEmploymentLocation(exp)}</Text>
                        </div>
                    ))}
                </Grid>
            ) : (
                <Text variant="caption">No employment history found</Text>
            )}
        </Grid>
    );
}
