import React from 'react';
import { IBusinessContact, IBusinessLocation } from 'models';
import { Colors, PrimaryButton, Text } from 'library';
import BusinessContact from './BusinessContact';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { PermissionType, useUserContext } from 'UserContext';

export default function LocationContactsSection({ businessLocation }: { businessLocation: IBusinessLocation }) {
    const { hasPermission } = useUserContext();
    const history = useHistory();

    return (
        <Grid
            container
            item
            style={{ backgroundColor: Colors.white, borderRadius: 18, padding: 20 }}
            direction="column"
        >
            <Grid item container direction="row" justifyContent="space-between">
                <Text variant="body1" bold>
                    Location Contacts
                </Text>
            </Grid>
            <Grid item container direction="column" style={{ marginTop: 10 }}>
                {businessLocation.business_contacts.length ? (
                    <Grid item container direction="row">
                        {businessLocation.business_contacts.map((c: IBusinessContact) => (
                            <BusinessContact key={c.id} businessContact={c} />
                        ))}
                    </Grid>
                ) : (
                    <Text variant="body1">
                        You currently have no additional contacts for this location. All communication will be sent to
                        the account owner.
                    </Text>
                )}
                {hasPermission(PermissionType.ManageAccount) && (
                    <Grid item container style={{ justifyContent: 'right', marginTop: 20 }}>
                        <PrimaryButton onClick={() => history.push('/manage-users')} buttonStyle={{ maxWidth: 200 }}>
                            Manage Users
                        </PrimaryButton>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
