import { useMediaQuery } from '@material-ui/core';
import { SizeableRoundedDialog } from 'library';
import React from 'react';
import theme from 'theme';
import ConnectPayment, { ConnectPaymentProps } from './ConnectPayment';

interface ConnectPaymentModalProps extends Omit<ConnectPaymentProps, 'showHeaderAndFooter'> {
    isOpen: boolean;
    onClose: () => void;
}

export default function ConnectPaymentModal({ isOpen, onClose, ...rest }: ConnectPaymentModalProps) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <SizeableRoundedDialog
            closeButton
            open={isOpen}
            onClose={onClose}
            style={{ height: small ? 794 : 715, width: '80%' }}
        >
            <ConnectPayment {...rest} />
        </SizeableRoundedDialog>
    );
}
