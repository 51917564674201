import { TextButton } from 'library';
import React from 'react';

export default function AddToClearingHouse({ needs_center_action }: { needs_center_action: string[] }) {
    const showClearingHouseReminder = needs_center_action.includes('add_to_clearinghouse');
    const openClearingHouseLink = () => {
        const chLink = 'https://crw.flclearinghouse.com/';
        window.open(chLink, '_blank');
    };

    if (!showClearingHouseReminder) return null;

    return <TextButton onClick={openClearingHouseLink}>Please remember to add this worker to Clearinghouse</TextButton>;
}
