import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { OutlinedTextInput, PrimaryButton, Text, SizeableRoundedDialog } from 'library';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { client } from 'shared';
import { useHistory } from 'react-router';

export default function CancelAllModal({
    open,
    onClose,
    ongoingRequestId,
    providerNames,
    refresh,
}: {
    open: boolean;
    onClose: () => void;
    ongoingRequestId?: number;
    providerNames?: string[];
    refresh: () => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();
    const [comment, setComment] = useState<string>();
    const history = useHistory();

    function submit() {
        setLoading(true);
        client(`api/ongoing/${ongoingRequestId}/cancel/`, {
            method: 'POST',
            body: { comment },
        })
            .then(refresh)
            .finally(() => {
                setLoading(false);
                onClose();
                history.push('/jobs');
            });
    }

    function getMessage() {
        let message =
            'We will cancel all filled and unfilled shifts and will no longer attempt to fill future shifts for this job.';
        if (!providerNames?.length) {
            return message;
        }

        const provider_names = providerNames.join(', ').replace(/, ([^,]*)$/, ' and $1');
        message = `We will cancel all filled and unfilled shifts and will no longer attempt to fill future shifts for this job. This will cancel all upcoming shifts including shifts for ${provider_names}.`;

        return message;
    }
    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Cancel this job including all upcoming shifts?</Text>
                <Text>{getMessage()}</Text>
                <OutlinedTextInput
                    label="Reason for cancelling"
                    style={{ padding: 10 }}
                    multiline
                    fullWidth
                    onChange={(e: any) => setComment(e.target.value)}
                    value={comment}
                />
                <PrimaryButton
                    loading={loading}
                    disabled={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Submit Cancellation
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
