import { format } from 'date-fns';
import { ComplianceSummaryEntry } from '../types';

export function sortActions(a: ComplianceSummaryEntry, b: ComplianceSummaryEntry) {
    const statusOrder = ['INCOMPLETE', 'BLOCKER', 'WARNING', 'SATISFIED', 'READY_TO_WORK', 'INVITED'];
    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
}

export function formatDueDate(dueDate: string) {
    return format(new Date(dueDate), 'MM/dd/yyyy');
}
