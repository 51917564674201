import { styled } from '@mui/material';
import { Colors } from 'library';
import { ITeacher } from 'models';
import React from 'react';
import { WorkerAvatar } from './WorkerAvatar';

export default function TeacherCardBaseHeader({
    worker,
    leftHeaderComponent,
    rightHeaderComponent,
    color,
}: {
    worker: ITeacher;
    leftHeaderComponent?: React.ReactNode;
    rightHeaderComponent?: React.ReactNode;
    color?: string;
}) {
    return (
        <Header color={color}>
            <Row>
                <SideSection justify="center">{leftHeaderComponent}</SideSection>
                <MiddleSection>
                    <WorkerAvatar src={worker.pictureUrl} />
                </MiddleSection>
                <SideSection justify="flex-end">{rightHeaderComponent}</SideSection>
            </Row>
        </Header>
    );
}

const Header = styled('div')(({ color }: { color?: string }) => ({
    background: `linear-gradient(180deg, ${color || Colors.terraCotta} calc(50% + 10px), transparent calc(50% + 10px))`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
}));

const SideSection = styled('div')(({ justify }: { justify: string }) => ({
    alignItems: 'center',
    display: 'flex',
    height: 'calc(50% + 10px)',
    justifyContent: justify,
    width: '33%',
}));

const MiddleSection = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '34%',
});

const Row = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
});
