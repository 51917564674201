import React from 'react';
import UserDetailChip from './UserDetailChip';
import { Grid } from '@mui/material';
import { ManagedWorker } from '../types';
import { Chip } from 'library';

export default function AssociatedBusinessLocations({
    selectedWorker,
    setOpen,
}: {
    selectedWorker: ManagedWorker;
    setOpen: (b: boolean) => void;
}) {
    if (!selectedWorker.business_locations) return null;
    return (
        <UserDetailChip title="Associated Locations" buttonText="Manage Locations" onClick={() => setOpen(true)}>
            <Grid item container direction="row" style={{ gap: 10 }}>
                {selectedWorker.business_locations.map((location) => (
                    <Chip key={location.id} label={location.name} style={{ maxWidth: location.name.length + 200 }} />
                ))}
            </Grid>
        </UserDetailChip>
    );
}
