import React from 'react';
import { AdminProvider, RawData } from 'models';
import { Grid, Popper } from '@mui/material';
import { Colors, Text } from 'library';
import { Gauge, LineChart } from '@mui/x-charts';
import { Info } from '@mui/icons-material';
import { formatMonthDay } from 'shared/Dates';

export default function DependabilityMetric({ provider }: { provider: AdminProvider }) {
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const latestScore =
        provider.dependability_scores.length > 0
            ? provider.dependability_scores[provider.dependability_scores.length - 1]
            : null;
    const value = latestScore ? parseFloat(latestScore.score.toFixed(2)) : 0;
    const dropdownAnchor = React.useRef<HTMLDivElement | null>(null);
    return (
        <>
            <Grid container direction="column" alignItems="flex-start">
                <Text bold>
                    Dependability Metric{' '}
                    <Info titleAccess="A score from 0 to 100 that reflects many factors about a worker's history. Scores close to 50 are okay while lower numbers represent more strongly negative experiences and higher numbers are strongly positive experiences. A score of 0 indicates no history. Click the score to see some raw counts that factor into the score." />
                </Text>
                <Grid component="div" onClick={() => setTooltipVisible(!tooltipVisible)}>
                    <Grid container direction="row">
                        <Gauge
                            ref={dropdownAnchor}
                            value={value}
                            width={100}
                            height={100}
                            startAngle={-100}
                            endAngle={100}
                            valueMin={0}
                            valueMax={100}
                            margin={{ top: -10, right: 0, bottom: 0, left: 0 }}
                            innerRadius={24}
                            sx={{ '& .MuiGauge-valueArc': { fill: value < 50 ? Colors.mediumRed : Colors.turquoise } }}
                        />
                        <LineChart
                            xAxis={[
                                {
                                    data: provider.dependability_scores.map((score) => new Date(score.added_at)),
                                    label: 'Date',
                                    scaleType: 'time',
                                    valueFormatter: (val) => formatMonthDay(val),
                                },
                            ]}
                            yAxis={[
                                {
                                    min: 0,
                                    max: 100,
                                    tickSize: 2,
                                    tickLabelInterval: (val) => val % 100 === 0,
                                    label: 'Score',
                                },
                            ]}
                            series={[
                                { data: provider.dependability_scores.map((score) => score.score), showMark: false },
                            ]}
                            height={140}
                            width={180}
                            margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
                        />
                    </Grid>
                    <Popper
                        open={tooltipVisible}
                        anchorEl={dropdownAnchor.current}
                        placement="bottom-start"
                        style={{ backgroundColor: Colors.white, padding: 10, border: 'solid black 1px', zIndex: 1 }}
                        disablePortal
                    >
                        <Text variant="body2" bold>
                            From the 90 days prior to the last point history change:
                        </Text>
                        {latestScore?.raw_data.map((data: RawData) => (
                            <Text variant="body2" key={data.feature_name}>
                                {data.feature_name}: {data.raw_value}
                            </Text>
                        ))}
                    </Popper>
                </Grid>
            </Grid>
        </>
    );
}
