import { Grid } from '@material-ui/core';
import { OutlineButton, Text, Colors } from 'library';
import ErrorIcon from '@mui/icons-material/Error';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ShiftProviderUser } from 'models';
import React, { useState } from 'react';
import ComplianceActionInfoModal from './ComplianceActionInfoModal';

export default function ComplianceHoldActions({
    needs_center_action,
    business_location_id,
    refresh,
    provider_user,
}: {
    provider_user: ShiftProviderUser;
    business_location_id: number;
    refresh: () => void;
    needs_center_action: string[];
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const initiateAgencyReviewRequired = needs_center_action.includes('initiate_agency_review');
    const initiateResubmissionRequired = needs_center_action.includes('initiate_resubmission');

    if (!initiateAgencyReviewRequired && !initiateResubmissionRequired) return null;
    const actionText = initiateAgencyReviewRequired ? 'Initiate Agency Review' : 'Initiate Resubmission';

    const ctaText = (
        <Text bold variant="caption" inline>
            Action Needed:
        </Text>
    );

    return (
        <>
            <OutlineButton
                boldText={false}
                onClick={() => setModalOpen(true)}
                textStyle={{ textAlign: 'left', lineHeight: 10 }}
                buttonStyle={{
                    padding: '0 2px',
                }}
            >
                <Grid item container direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid item container direction="row" style={{ gap: 10, alignItems: 'center' }} xs={10}>
                        <Grid item container xs={2} style={{ justifyContent: 'center' }}>
                            <ErrorIcon style={{ color: Colors.error }} />
                        </Grid>
                        <Grid item container xs={8}>
                            <Text variant="caption">
                                {ctaText} {actionText}
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1}>
                        <ChevronRightIcon style={{ color: Colors.darkNavy }} />
                    </Grid>
                </Grid>
            </OutlineButton>
            <ComplianceActionInfoModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                actionText={actionText}
                provider_user={provider_user}
                business_location_id={business_location_id}
                refresh={refresh}
            />
        </>
    );
}
