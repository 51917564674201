import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Text, TextButton } from 'library';
import { ComplianceProgress, ContactInformation, DocumentsSection, RequiredActions } from './user-details';
import { LeanBusinessLocation, ManagedWorker, WorkerFilters } from './types';
import ManageWorkerModal from './user-details/ManageWorkerModal';
import AssociatedBusinessLocations from './user-details/AssociatedBusinessLocations';

export default function ComplianceUserDetailView({
    selectedWorker,
    refreshWorkers,
    setSelectedWorker,
    centers,
}: {
    selectedWorker?: ManagedWorker;
    refreshWorkers: (filters?: WorkerFilters) => void;
    setSelectedWorker: (worker?: ManagedWorker) => void;
    centers?: LeanBusinessLocation[];
}) {
    const [open, setOpen] = useState(false);

    if (!selectedWorker) {
        return (
            <Grid
                item
                container
                direction="column"
                justifyContent={'center'}
                alignItems={'center'}
                style={{ gap: 20, height: '95vh' }}
            >
                <Text variant="h1">No user selected</Text>
                <Text>
                    Please add users to manage their compliance or select a user in the menu on the left to proceed.
                </Text>
            </Grid>
        );
    }

    return (
        <Grid item container direction="column" style={{ paddingRight: 20, gap: 20, marginTop: 40, marginBottom: 60 }}>
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Text bold variant="h1">
                    {selectedWorker.first_name} {selectedWorker.last_name}
                </Text>
                {selectedWorker.compliance_enrollment_id ? (
                    <TextButton
                        buttonStyle={{ width: 'unset' }}
                        textStyle={{ fontSize: 14 }}
                        onClick={() => setOpen(true)}
                    >
                        Manage Staff
                    </TextButton>
                ) : null}
            </Grid>

            {selectedWorker.compliance_status === 'INVITED' ? null : (
                <>
                    <RequiredActions selectedWorker={selectedWorker} />
                    <ComplianceProgress selectedWorker={selectedWorker} />
                    <DocumentsSection selectedWorker={selectedWorker} />
                </>
            )}
            <AssociatedBusinessLocations selectedWorker={selectedWorker} setOpen={setOpen} />
            <ContactInformation selectedWorker={selectedWorker} />
            <ManageWorkerModal
                open={open}
                setOpen={setOpen}
                selectedWorker={selectedWorker}
                setSelectedWorker={setSelectedWorker}
                centers={centers}
                refreshWorkers={refreshWorkers}
            />
        </Grid>
    );
}
