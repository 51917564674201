import { Colors, PrimaryButton, Text } from 'library';
import React, { ChangeEvent, useEffect, useState } from 'react';

import CheckIcon from 'assets/icons/tandempay/personal-check-circle.svg';
import Input from '../../../../../reusableComponents/input';
import { useStripe } from '../StripeContext';
import ErrorText from '../../ErrorText';
import { useUserContext } from 'UserContext';
import { track } from 'shared/Tracking';
import client from 'shared/ApiClient';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import PageActionsFooter from '../PageActionsFooter';
import ModalPage from '../ModalPage';
import PageContent from '../PageContent';
import FormArea from '../FormArea';
import { Grid } from '@mui/material';
import PaymentPartners from 'parent-portal/payments/TandemPayInfo/PaymentPartners';

interface ManualVerificationPageProps {
    goToSuccess: () => void;
    goBack: () => void;
    showHeaderAndFooter: boolean;
}

export default function ConnectManualVerificationPage({
    goToSuccess,
    goBack,
    showHeaderAndFooter,
}: ManualVerificationPageProps) {
    const { stripe, stripeLoaded } = useStripe();
    const { user } = useUserContext();

    const [accountHolder, setAccountHolder] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(!stripeLoaded);
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        const accountHolderValid = !!accountHolder && accountHolder.length > 0;
        const routingNumberValid = /\d{9}/.test(routingNumber);
        const accountNumberValid = !!accountNumber && accountNumber.length > 0;
        setValid(accountHolderValid && routingNumberValid && accountNumberValid);
    }, [accountHolder, routingNumber, accountNumber]);

    function onAccountHolderNameChanged(event: ChangeEvent<HTMLInputElement>) {
        setAccountHolder(event.target.value);
    }

    function onRoutingNumberChanged(event: ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        if (newValue.length <= 9) setRoutingNumber(newValue);
    }

    function onAccountNumberChanged(event: ChangeEvent<HTMLInputElement>) {
        setAccountNumber(event.target.value);
    }

    async function submit() {
        const response = await stripe!.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: routingNumber,
            account_number: accountNumber,
            account_holder_name: accountHolder,
            account_holder_type: 'individual',
        });
        if (!response.error) {
            await updateAccount(response.token!);
            goToSuccess();
        } else {
            setErrorMessage(response.error.message || 'Please enter a valid bank account');
            setLoading(false);
        }
    }

    async function updateAccount(token: stripe.Token) {
        const response = await client('get-account/', {
            body: {
                stripe_token: token.id,
                id: user?.id,
                manual: true,
            },
        });
        track('Manual Account Setup', { status: response });
    }

    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const fieldSpacing = small ? 40 : 80;

    return (
        <ModalPage
            iconSource={<CheckIcon />}
            headerText="Manual Verification"
            subtitleText="Use your bank's account and routing number."
            showHeader={showHeaderAndFooter}
        >
            <PageContent>
                <FormArea>
                    <div style={{ marginBottom: fieldSpacing }}>
                        <Text variant="body2">
                            You&apos;ll receive two deposits and withdrawals, each less than $1, on your bank statement
                            over the next three days. Tandem will ask you to verify the amounts to finish connecting
                            your bank account.
                        </Text>
                    </div>
                    <div style={{ width: '95%' }}>
                        <Input
                            disabled={loading}
                            style={{ marginBottom: fieldSpacing }}
                            underlineColor={Colors.grey}
                            fullWidth
                            value={accountHolder}
                            onChange={onAccountHolderNameChanged}
                            placeholder="Account Holder Name*"
                            InputLabelProps={{ shrink: true }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Input
                                disabled={loading}
                                style={{ width: '46%' }}
                                underlineColor={Colors.grey}
                                value={routingNumber}
                                onChange={onRoutingNumberChanged}
                                placeholder="Routing Number*"
                                InputLabelProps={{ shrink: true }}
                            />
                            <Input
                                disabled={loading}
                                style={{ width: '46%' }}
                                underlineColor={Colors.grey}
                                value={accountNumber}
                                onChange={onAccountNumberChanged}
                                placeholder="Account Number*"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        {!showHeaderAndFooter && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                gap={2}
                                marginTop={4}
                            >
                                <PaymentPartners />
                                <PrimaryButton
                                    onClick={submit}
                                    buttonStyle={{ width: undefined, height: 40 }}
                                    disabled={loading || !valid}
                                >
                                    Save
                                </PrimaryButton>
                            </Grid>
                        )}
                        {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
                    </div>
                </FormArea>
            </PageContent>
            {showHeaderAndFooter && (
                <PageActionsFooter save={submit} goBack={goBack} loading={loading} disabled={loading || !valid} />
            )}
        </ModalPage>
    );
}
