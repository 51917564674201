import { BusinessSlot, ShiftProviderUser } from 'models';
import React, { useState } from 'react';
import { CompactButton } from 'library';
import { EventBusy } from '@mui/icons-material';
import CancelModal from './CancelModal';

interface CancelShiftModalTriggerProps {
    provider: ShiftProviderUser;
    slot: BusinessSlot;
    refresh: () => void;
    providerId?: number;
}

const CancelShiftModalTrigger = ({ provider, slot, refresh, providerId }: CancelShiftModalTriggerProps) => {
    const [cancelOpen, setCancelOpen] = useState(false);

    return (
        <>
            <CompactButton onClick={() => setCancelOpen(true)}>
                <EventBusy sx={{ fontSize: 18 }} /> Cancel this shift
            </CompactButton>
            <CancelModal
                open={cancelOpen}
                onClose={() => setCancelOpen(false)}
                slot={slot}
                providerName={provider?.first_name}
                refresh={refresh}
                providerId={providerId}
            />
        </>
    );
};

export default CancelShiftModalTrigger;
