import React from 'react';
import { Grid } from '@material-ui/core';
import { Text, Colors } from 'library';
import { formatMonthDayTime } from 'shared/Dates';
import moment from 'moment-timezone';

export default function ReportSummary({
    start,
    end,
    breakStart,
    breakEnd,
    hoursWorked,
    timezone,
}: {
    start?: moment.Moment;
    end?: moment.Moment;
    breakStart?: moment.Moment;
    breakEnd?: moment.Moment;
    hoursWorked: number;
    timezone: string;
}) {
    return (
        <Grid>
            <Text variant="h1" bold>
                Report Times As Entered:
            </Text>
            <Text>Start Time: {start ? formatMonthDayTime(start.toDate(), timezone, true) : null}</Text>
            <Text>Break Start: {breakStart ? formatMonthDayTime(breakStart.toDate(), timezone, true) : null}</Text>
            <Text>Break End: {breakEnd ? formatMonthDayTime(breakEnd.toDate(), timezone, true) : null}</Text>
            <Text>End Time: {end ? formatMonthDayTime(end.toDate(), timezone, true) : null}</Text>
            <Text textStyle={{ color: hoursWorked <= 0 ? Colors.mediumRed : Colors.darkNavy }}>
                Hours Worked: {hoursWorked}
            </Text>
        </Grid>
    );
}
