import React, { useState } from 'react';
import { Grid, styled } from '@mui/material';
import { Link, Text } from 'library';
import LocationAdministrationItem from './LocationAdministrationItem';
import AddAdministratorDialog from './Modals/AddAdministratorDialog';
import { ILocationAdministration } from 'models';
import {
    addAdministration,
    addAdministrationPhoto,
    editAdministration,
    removeAdministration,
} from '../businessLocationProfileApi';
import ConfirmDialog from 'reusableComponents/confirmDialog';
import UploadPhotoDialog from './Modals/UploadPhotoDialog';
import { consoleLogInDev } from 'shared';
export default function LocationAdministrationSection({
    locationId,
    locationAdministration,
    refreshLocation,
}: {
    locationId: number;
    locationAdministration: ILocationAdministration[];
    refreshLocation: () => void;
}) {
    const DIRECTOR = 'Director';
    const ASSISTANT_DIRECTOR = 'Assistant Director';

    const [addAdministrationModal, setAddAdministrationModal] = useState<{
        show: boolean;
        staticRole?: string;
        editing?: ILocationAdministration;
    }>({
        show: false,
    });
    const [deletingId, setDeletingId] = useState<number>();
    const [addPhotoId, setAddPhotoId] = useState<number>();

    const director = locationAdministration.find((it) => it.role === DIRECTOR);
    const assistantDirector = locationAdministration.find((it) => it.role === ASSISTANT_DIRECTOR);
    const otherRoles = locationAdministration.filter((it) => it.id !== director?.id && it.id !== assistantDirector?.id);

    const save = (name: string, role: string) => {
        return addAdministration(locationId, name, role)
            .then(() => {
                refreshLocation();
            })
            .catch(consoleLogInDev);
    };

    const edit = (id: number, name: string, role: string) => {
        return editAdministration(id, name, role).then(refreshLocation);
    };

    const remove = () => {
        if (deletingId) {
            removeAdministration(deletingId)
                .then(() => {
                    refreshLocation();
                    setDeletingId(undefined);
                })
                .catch(consoleLogInDev);
        }
    };

    const addPhoto = async (files: File[]) => {
        const image = files[0];
        if (image && addPhotoId) {
            return addAdministrationPhoto(addPhotoId, image).then(refreshLocation);
        }
    };

    return (
        <Grid container direction="column">
            <Text variant="body1" textStyle={{ marginBottom: 10 }} bold>
                Administration
            </Text>
            <AdministrationContainer container direction="row">
                <LocationAdministrationItem
                    placeholderRole={DIRECTOR}
                    administration={director}
                    onAddPhoto={setAddPhotoId}
                    onAdd={() => setAddAdministrationModal({ show: true, staticRole: DIRECTOR })}
                    onEdit={() => setAddAdministrationModal({ show: true, staticRole: DIRECTOR, editing: director })}
                    onRemove={setDeletingId}
                />

                <LocationAdministrationItem
                    placeholderRole={ASSISTANT_DIRECTOR}
                    administration={assistantDirector}
                    onAddPhoto={setAddPhotoId}
                    onAdd={() => setAddAdministrationModal({ show: true, staticRole: ASSISTANT_DIRECTOR })}
                    onEdit={() =>
                        setAddAdministrationModal({
                            show: true,
                            staticRole: ASSISTANT_DIRECTOR,
                            editing: assistantDirector,
                        })
                    }
                    onRemove={setDeletingId}
                />

                {otherRoles.map((other) => (
                    <LocationAdministrationItem
                        key={other.id}
                        administration={other}
                        onAddPhoto={setAddPhotoId}
                        onAdd={() => setAddAdministrationModal({ show: true })}
                        onEdit={() => setAddAdministrationModal({ show: true, editing: other })}
                        onRemove={setDeletingId}
                    />
                ))}
            </AdministrationContainer>
            <Link onClick={() => setAddAdministrationModal({ show: true })}>Add Additional</Link>

            <AddAdministratorDialog
                isOpen={addAdministrationModal.show}
                editing={addAdministrationModal.editing}
                onClose={() => setAddAdministrationModal({ show: false })}
                staticRole={addAdministrationModal.staticRole}
                onSave={save}
                onEdit={edit}
            />

            <ConfirmDialog
                open={!!deletingId}
                onClose={() => setDeletingId(undefined)}
                onConfirm={remove}
                title="Delete"
                message="Are you sure you want to delete this administrator?"
            />

            <UploadPhotoDialog
                isOpen={!!addPhotoId}
                onClose={() => setAddPhotoId(undefined)}
                singleImage
                onUpload={addPhoto}
            />
        </Grid>
    );
}

const AdministrationContainer = styled(Grid)({
    gap: 20,
    marginBottom: 20,
});
