export enum Colors {
    navy = '#28516b',
    cloud = '#e9f1fa',
    earth = '#968886',
    coral = '#ff7b67',
    tangerine = '#ffbf78',
    dullTangerine = '#FCBE7889',
    blush = '#f9b7bb',
    aqua = '#b4e1e3',
    olive = '#809b7d',
    disabledGrey = '#D6D2D0',
    dullCoral = '#F37A6A',
    lightBlue = '#E6F5FF',
    tan = '#F5F0ED',
    grey = '#807E7D',
    darkGrey = '#656565',
    mediumGrey = '#807E7D', // start of new color palette
    lightGrey = '#EFEFEF',
    green = '#C4FF12',
    mediumGreen = '#E7FFA0',
    lightGreen = '#F3FFD0',
    turquoise = '#00A68D',
    mediumTurq = '#A1DBD3',
    lightTurq = '#E8F6F4',
    red = '#880D30',
    mediumRed = '#E31650',
    lightRed = '#F9D9DC',
    darkNavy = '#002F4D',
    mediumNavy = '#335971',
    pressedNavy = '#001C2E',
    white = '#ffffff',
    white70percent = '#ffffffb3',
    terraCotta = '#ED6A5A',
    mediumTerraCotta = '#F39C91',
    lightTerraCotta = '#F9CEC8',
    mediumLime = '#E7FFA0',
    error = '#FF0000',
    pink = '#F490AC',
    warning = '#FD7E2F',
    mediumBlue = '#43d2d9',
}
