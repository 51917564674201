import TeacherBaseCard from 'parent-portal/providers/cards/shared/TeacherBaseCard';
import React from 'react';
import ApplicantApprovalCardHeader from './ApplicantApprovalCardHeader';
import { ApplicantApproval } from '../models';
import { Colors, PrimaryButton, SecondaryButton, Text } from 'library';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import Rating from 'parent-portal/providers/cards/shared/Rating';
import { Grid } from '@mui/material';
import { client } from 'shared';
import ApplicantApprovalTimes from './ApplicantApprovalTimes';

export default function ApplicantApprovalWorkerCard({
    applicant,
    refresh,
    selected,
    toggleSelected,
}: {
    applicant: ApplicantApproval;
    refresh: () => void;
    selected: boolean;
    toggleSelected: (apptIds: number[]) => void;
}) {
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [approvedAppointmentIds, setApprovedAppointmentIds] = React.useState<number[]>(
        applicant.days_applied.map((d) => d.appointment.id),
    );

    const reviewTimesCount = applicant.autobook_reasons.attributes.review_times.length;
    const firstReviewTime = reviewTimesCount > 0 ? applicant.autobook_reasons.attributes.review_times[0].start : null;
    let reviewTimes = firstReviewTime ? `${formatShortWeekdayMonthDay(firstReviewTime)}` : null;
    if (reviewTimes && reviewTimesCount > 1) {
        reviewTimes += ` and ${reviewTimesCount - 1} other${reviewTimesCount === 2 ? '' : 's'}`;
    }

    const unmetQualificationsCount = applicant.autobook_reasons.attributes.unqualified.length;
    const firstUnmetQualification =
        unmetQualificationsCount > 0 ? applicant.autobook_reasons.attributes.unqualified[0] : null;
    let unmetQualifications = firstUnmetQualification;
    if (unmetQualifications && unmetQualificationsCount > 1) {
        unmetQualifications += ` and ${unmetQualificationsCount - 1} other${unmetQualificationsCount === 2 ? '' : 's'}`;
    }

    function decline() {
        client(`api/applicant-approval/${applicant.id}/`, {
            method: 'PATCH',
            body: { rejected_at: new Date() },
        }).then(refresh);
    }

    function approve() {
        client(`api/applicant-approval/${applicant.id}/`, {
            method: 'PATCH',
            body: {
                approved_at: new Date(),
                approved_appointment_ids: approvedAppointmentIds,
            },
        }).then(refresh);
    }

    return (
        <>
            <TeacherBaseCard
                color={applicant.approved_at ? '#fcfcfc' : Colors.white}
                worker={applicant.applied_user}
                header={
                    <ApplicantApprovalCardHeader
                        applicant={applicant}
                        selected={selected}
                        onChange={() => toggleSelected(approvedAppointmentIds)}
                    />
                }
                details={
                    <Grid container paddingRight={2} paddingLeft={2}>
                        <Rating reviews={applicant.applied_user.credentials.reviews} />
                        <Grid height="75px" minWidth={10} paddingTop={2}>
                            {unmetQualifications && (
                                <Text variant="caption">
                                    <b>Missing Requirements:</b> {unmetQualifications}
                                </Text>
                            )}
                            {reviewTimes && (
                                <Text variant="caption">
                                    <b>Requested Time Changes:</b> {reviewTimes}
                                </Text>
                            )}
                            {applicant.autobook_reasons.attributes.has_requested_workers && (
                                <Text variant="caption">
                                    <b>Requested Workers:</b> We have not booked {applicant.applied_user.name} because
                                    we are waiting for a response from your requested workers.
                                </Text>
                            )}
                        </Grid>
                    </Grid>
                }
                footer={
                    <Grid
                        container
                        flexDirection="row"
                        justifyContent="flex-end"
                        gap={2}
                        marginTop={3}
                        paddingRight={3}
                    >
                        {!applicant.approved_at && (
                            <SecondaryButton buttonStyle={{ width: undefined }} onClick={decline}>
                                Decline
                            </SecondaryButton>
                        )}
                        {applicant.approved_at ? (
                            <>
                                <Text variant="body2" bold>
                                    Approved on {formatShortWeekdayMonthDay(applicant.approved_at)}
                                </Text>
                                <Text variant="caption" color={Colors.darkGrey}>
                                    We have sent a notification to {applicant.applied_user.name} to confirm their
                                    availability, and we are awaiting their response.
                                </Text>
                            </>
                        ) : (
                            <PrimaryButton
                                buttonStyle={{
                                    width: undefined,
                                    backgroundColor: applicant.approved_at ? Colors.darkGrey : Colors.darkNavy,
                                }}
                                onClick={() => setConfirmOpen(true)}
                                disabled={!!applicant.approved_at}
                            >
                                See Details
                            </PrimaryButton>
                        )}
                    </Grid>
                }
            />
            <ApplicantApprovalTimes
                applicants={[{ applicant, approvedAppointmentIds }]}
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={approve}
                toggleAppointmentApproved={(applicantId: number, appointmentId: number) => {
                    setApprovedAppointmentIds((prev) =>
                        prev.includes(appointmentId)
                            ? prev.filter((id) => id !== appointmentId)
                            : [...prev, appointmentId],
                    );
                }}
            />
        </>
    );
}
