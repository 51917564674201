import React, { useEffect, useState, useMemo } from 'react';
import theme from 'theme';
import { styled, useMediaQuery } from '@mui/material';
import { debounce } from 'lodash';
import { client, queryStringifyObject } from 'shared';
import { useUserContext } from 'UserContext';

import StaffSendJob from '../ProviderSearch/StaffSendJob';
import { WORKED, STAFF } from './constants';
import { Filters, Search, Results } from './components';

import type { PagingResult } from 'models';
import type { FilterProps, IProviderSearch } from './models';

export function ProvidersSearchAndFilter() {
    const { user } = useUserContext();

    const businessId = user?.businesses_active?.[0]?.id;

    const initialPageResults: PagingResult<IProviderSearch> = {
        count: 0,
        per_page: 0,
        num_pages: 0,
        results: [],
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [pageResult, setPageResult] = useState<PagingResult<IProviderSearch>>(initialPageResults);
    const [selectedProviders, setSelectedProviders] = useState<IProviderSearch[]>([]);
    const [formValues, setFormValues] = useState<FilterProps>({
        page: 1,
        searchQuery: '',
        workerType: WORKED,
        includeBlocked: false,
        showPreferred: false,
        address: '-1',
    });

    const small = useMediaQuery(theme.breakpoints.down('md'));

    const getProviders = useMemo(
        () =>
            debounce((params: string) => {
                client(`api/providers/?${params}`)
                    .then((data: PagingResult<IProviderSearch>) => {
                        setError(false);
                        formValues.page > 1
                            ? setPageResult({ ...data, results: [...pageResult.results, ...data.results] })
                            : setPageResult(data);
                    })
                    .catch(() => setError(true))
                    .finally(() => {
                        setLoading(false);
                    });
            }, 500),
        [formValues.page, pageResult.results],
    );

    function handleChange(value: string | boolean, formKey: string) {
        setLoading(true);
        getProviders.cancel();

        if (formKey === 'page') {
            setFormValues({ ...formValues, page: formValues.page + 1 });
        } else if (formKey === 'workerType' && value === STAFF) {
            setFormValues({ ...formValues, page: 1, workerType: value, includeBlocked: false });
        } else {
            setFormValues({ ...formValues, page: 1, [formKey]: value });
        }
    }

    function handleSelect(checked: boolean, provider: IProviderSearch) {
        setSelectedProviders(
            selectedProviders.includes(provider) && !checked
                ? selectedProviders.filter((p) => p.id !== provider.id)
                : [...selectedProviders, provider],
        );
    }

    useEffect(() => {
        const searchParams = formValues?.searchQuery?.length ? { text: formValues.searchQuery } : {};

        const params = queryStringifyObject({
            ...searchParams,
            myproviders: formValues.workerType,
            page: formValues.page,
            page_size: 12,
            include_blocked_workers: formValues.includeBlocked,
            show_preferred_workers: formValues.showPreferred,
            address: formValues.address !== '-1' ? formValues.address : null,
            business_id: businessId,
        });

        getProviders(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    return (
        <Container>
            <InnerContainer>
                <Search handleChange={handleChange} />
                <ResultsAndFiltersContainer small={small}>
                    <Filters formValues={formValues} handleChange={handleChange} />
                    <ResultsContainer small={small}>
                        <Results
                            loading={loading}
                            error={error}
                            providers={pageResult.results}
                            selectedProviders={selectedProviders}
                            handleSelect={handleSelect}
                            loadMore={formValues.page < pageResult.num_pages}
                            handleChange={handleChange}
                            workerType={formValues.workerType}
                        />
                    </ResultsContainer>
                </ResultsAndFiltersContainer>
            </InnerContainer>
            <StaffSendJob
                selectedProviders={selectedProviders}
                resetSelectedProviders={() => setSelectedProviders([])}
            />
        </Container>
    );
}

const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    paddingBottom: 40,
});

const InnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
});

const ResultsAndFiltersContainer = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    flexDirection: small ? 'column' : 'row',
}));

const ResultsContainer = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginLeft: small ? 0 : 15,
    flex: 1,
}));
