import { Button, Checkbox } from '@material-ui/core';
import { BasicDialog, Colors, Text } from 'library';
import React, { useState } from 'react';
import { WORKER_FILTERS, WorkerFilter } from '../types';

interface Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
    filterWorkers: (selectedFilters: WorkerFilter[] | null) => void;
}

const FilterWorkersModal = ({ isOpen, setIsOpen, filterWorkers }: Props) => {
    const [selectedFilters, setSelectedFilters] = useState<WorkerFilter[]>([]);

    const onClose = () => {
        setIsOpen(false);
    };

    const updateFilters = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const value = event.target.value as WorkerFilter;
        if (checked) {
            setSelectedFilters([...selectedFilters, value]);
        } else {
            setSelectedFilters(selectedFilters.filter((f) => f !== value));
        }
    };

    const applyFilters = async () => {
        // TODO: pass in filters
        filterWorkers(selectedFilters);
        setIsOpen(false);
    };

    return (
        <BasicDialog closeButton isOpen={isOpen} onClose={onClose}>
            <div style={{ marginBottom: 8 }}>
                <Text variant="h1">Filter Workers</Text>
            </div>
            <div style={{ margin: 12 }}>
                {WORKER_FILTERS.map((filter, index) => {
                    return (
                        <div key={index}>
                            <Checkbox
                                checked={selectedFilters.includes(filter)}
                                onChange={updateFilters}
                                value={filter}
                            />
                            <Text
                                variant="body2"
                                inline
                                textStyle={{ color: Colors.darkNavy, marginTop: 2, whiteSpace: 'break-spaces' }}
                                title={filter}
                            >
                                {filter}
                            </Text>
                        </div>
                    );
                })}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
                <Button
                    style={{
                        maxHeight: 40,
                        marginRight: 8,
                        backgroundColor: Colors.darkNavy,
                        color: Colors.white,
                        textTransform: 'none',
                        borderRadius: 70,
                        padding: 16,
                    }}
                    onClick={applyFilters}
                >
                    <Text variant="body2" color={Colors.white}>
                        Apply Filters
                    </Text>
                </Button>
            </div>
        </BasicDialog>
    );
};

export default FilterWorkersModal;
