import { NotificationType, NotificationInteractionType } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { useHistory } from 'react-router-dom';
import { handleNotificationAction } from './NotificationActionManager';

interface NotificationContextType {
    notifications: NotificationType[];
    clickNotification: (notification: NotificationType) => void;
    viewedNotifications: (notifications?: NotificationType[]) => void;
    acknowledgeNotifications: () => void;
    markAsReadNotifications: () => void;
    markNotificationRead: (notification: NotificationType, onFinally?: () => void) => void;
    unacknowledgedCount: number;
    loaded: boolean;
    loadMore: () => void;
    actionMessage?: string;
    setActionMessage: (message: string | undefined) => void;
    modalsOpen: { [key: string]: boolean };
    openModal: (name: string) => void;
    closeModal: (name: string) => void;
}

export const NotificationContext = React.createContext<NotificationContextType>({
    notifications: [],
    clickNotification: () => {},
    viewedNotifications: () => {},
    acknowledgeNotifications: () => {},
    markAsReadNotifications: () => {},
    markNotificationRead: () => {},
    unacknowledgedCount: 0,
    loaded: false,
    loadMore: () => {},
    setActionMessage: () => {},
    modalsOpen: {},
    openModal: () => {},
    closeModal: () => {},
});

export function NotificationContextProvider({ children }: { children: React.ReactNode }) {
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [unviewedNotifications, setUnviewedNotifications] = useState<NotificationType[]>([]);
    const [unacknowledgedCount, setUnacknowledgedCount] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [loadedMore, setLoadedMore] = useState(false);
    const [numberOfLoadMores, setNumberOfLoadMores] = useState(0);
    const [actionMessage, setActionMessage] = useState<string | undefined>();
    const [modalsOpen, setModalsOpen] = useState({ discoverySource: false, communityRepresentative: false });

    function openModal(name: string) {
        setModalsOpen({ ...modalsOpen, [name]: true });
    }

    function closeModal(name: string) {
        setModalsOpen({ ...modalsOpen, [name]: false });
    }

    const history = useHistory();

    function getNotifications() {
        client('notification/api/notification/')
            .then((res) => {
                setNotifications(res);
            })
            .catch((err) => {
                consoleLogInDev(err);
            })
            .finally(() => setLoaded(true));
    }

    function getUnacknowledgedCount() {
        client('notification/api/notification/unacknowledged-count/')
            .then((res) => {
                setUnacknowledgedCount(res.count);
            })
            .catch((err) => {
                consoleLogInDev(err);
            });
    }

    function loadMore() {
        if (notifications.length > 0) {
            client(`notification/api/notification/?index=${notifications[notifications.length - 1].id}`)
                .then((res) => {
                    if (res.length > 0) {
                        setUnviewedNotifications(res);
                        setNotifications([...notifications, ...res]);
                        setLoadedMore(true);
                        setNumberOfLoadMores(numberOfLoadMores + 1);
                    }
                })
                .catch((err) => {
                    consoleLogInDev(err);
                });
        }
    }

    function clickNotification(notification: NotificationType) {
        client('notification/api/notification-interaction', {
            body: {
                notification_id: notification.id,
                type: 'Clicked',
            },
        })
            .then(() => {
                handleNotificationAction(notification, history, setActionMessage, openModal);
            })
            .catch((err) => {
                consoleLogInDev(err);
            });
    }

    function viewedNotifications(notificationArray: NotificationType[] = unviewedNotifications) {
        const notificationsToView = notifications.filter((notification) => {
            return notificationArray.includes(notification);
        });

        client('notification/api/notification-interaction', {
            body: {
                notification_ids: notificationsToView.map((notification) => notification.id),
                type: 'Viewed',
            },
        })
            .then(() => {
                setNotifications(
                    notifications.map((notification) => {
                        notification.viewed = true;
                        return notification;
                    }),
                );
                setUnviewedNotifications([]);
            })
            .catch((err) => {
                consoleLogInDev(err);
            });
    }

    function massUpdateNotifications(type: NotificationInteractionType, onComplete?: () => void) {
        if (notifications.length > 0) {
            client(
                `notification/api/notification-interaction/mass-update-notifications/?type=${type}&last_notification_id=${notifications[0].id}`,
            )
                .then(() => {
                    if (onComplete) {
                        onComplete();
                    }
                })
                .catch((err) => {
                    consoleLogInDev(err);
                });
        }
    }

    function acknowledgeNotifications() {
        massUpdateNotifications('Acknowledged', () => setUnacknowledgedCount(0));
    }

    function markNotificationRead(notification: NotificationType, onFinally?: () => void) {
        client('notification/api/notification-interaction/', {
            body: {
                notification_id: notification.id,
                type: 'Marked as Read',
            },
        })
            .then(() => {
                setNotifications(
                    notifications.map((n) => {
                        if (n.id === notification.id) {
                            return { ...n, marked_as_read: true };
                        }
                        return n;
                    }),
                );
            })
            .catch((err) => {
                consoleLogInDev(err);
            })
            .finally(() => onFinally?.());
    }

    function markAsReadNotifications() {
        const markAsRead = () => {
            setNotifications(
                notifications.map((notification) => {
                    notification.marked_as_read = true;
                    return notification;
                }),
            );
        };

        massUpdateNotifications('Marked as Read', markAsRead);
    }

    useEffect(() => {
        Promise.all([getNotifications(), getUnacknowledgedCount()]);
    }, []);

    useEffect(() => {
        if (loadedMore) {
            viewedNotifications();
            setLoadedMore(false);
        }
    }, [loadedMore]);

    const contextValue = {
        notifications,
        clickNotification,
        viewedNotifications,
        acknowledgeNotifications,
        markAsReadNotifications,
        markNotificationRead,
        unacknowledgedCount,
        loaded,
        loadMore,
        actionMessage,
        setActionMessage,
        modalsOpen,
        openModal,
        closeModal,
    };
    return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
}

export const useNotificationContext = () => {
    return useContext(NotificationContext);
};
