import React from 'react';
import { Colors, Checkbox, Text } from 'library';
import { WorkerAvatar } from 'parent-portal/providers/cards/shared/WorkerAvatar';
import { formatShortWeekdayMonthDay, format12HourTime } from 'shared/Dates';
import { ApplicantSelection } from '../models';
import { Grid } from '@mui/material';

export default function ApplicantApprovalDetails({
    applicantSelection,
    toggleAppointmentApproved,
}: {
    applicantSelection: ApplicantSelection;
    toggleAppointmentApproved: (applicantId: number, appointmentId: number) => void;
}) {
    const { applicant, approvedAppointmentIds } = applicantSelection;
    return (
        <Grid
            border={`1px solid ${Colors.darkNavy}`}
            sx={{ borderRadius: '18px', marginLeft: 3, marginRight: 3, minHeight: 500 }}
        >
            <Grid
                container
                sx={{
                    backgroundColor: Colors.lightRed,
                    height: 80,
                    marginBottom: 6,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                }}
                padding={2}
            >
                <WorkerAvatar src={applicant.applied_user.pictureUrl} />
                <Text variant="h1" bold textStyle={{ marginTop: 16, marginLeft: 10 }}>
                    {applicant.applied_user.name}
                </Text>
            </Grid>
            <Grid container flexDirection="column" padding={2} marginTop={4}>
                <Text bold>Days Requested</Text>
                {applicant.days_applied.map((day) => (
                    <Text variant="body2" key={day.id}>
                        {formatShortWeekdayMonthDay(day.start)}
                    </Text>
                ))}
            </Grid>
            {applicant.autobook_reasons.attributes.review_times.length > 0 && (
                <Grid container flexDirection="column" padding={2}>
                    <Text bold>Schedule Changes Requested</Text>
                    <Text variant="body2" textStyle={{ marginBottom: 10, maxWidth: 480 }}>
                        Uncheck any days for which you don&apos;t want to accept schedule changes.{' '}
                        {applicant.applied_user.name} will not be approved to accept those shifts.
                    </Text>
                    {applicant.autobook_reasons.attributes.review_times.map((time) => (
                        <>
                            <Text variant="body2" key={time.start}>
                                <Checkbox
                                    style={{ display: 'inline', width: 'auto' }}
                                    label={<></>}
                                    checked={approvedAppointmentIds.includes(time.appointment_id)}
                                    onChange={() => toggleAppointmentApproved(applicant.id, time.appointment_id)}
                                />{' '}
                                {formatShortWeekdayMonthDay(time.start)}, {format12HourTime(time.start, time.timezone)}{' '}
                                - {format12HourTime(time.end, time.timezone, true)}{' '}
                            </Text>
                            <Text
                                variant="caption"
                                color={Colors.mediumRed}
                                textStyle={{ marginLeft: 46, lineHeight: 0.7 }}
                            >
                                posted time{' '}
                                {`${format12HourTime(time.appointment_start, time.timezone)} - ${format12HourTime(time.appointment_end, time.timezone)}`}
                            </Text>
                        </>
                    ))}
                </Grid>
            )}
            <Grid container flexDirection="column" padding={2}>
                <Text bold>Missing Requirements</Text>
                {applicant.autobook_reasons.attributes.unqualified.length === 0 ? (
                    <Text variant="body2">{applicant.applied_user.name} meets all base requirements for this job.</Text>
                ) : (
                    applicant.autobook_reasons.attributes.unqualified.map((qualification) => (
                        <Text variant="body2" key={qualification}>
                            {qualification}
                        </Text>
                    ))
                )}
            </Grid>
            {applicant.autobook_reasons.attributes.has_requested_workers && (
                <Grid container flexDirection="column" padding={2} maxWidth={480}>
                    <Text bold>Requested Workers</Text>
                    <Text variant="body2">
                        We have not booked {applicant.applied_user.name} because we are waiting for a response from your
                        requested workers.
                    </Text>
                </Grid>
            )}
        </Grid>
    );
}
