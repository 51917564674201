import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Grid } from '@material-ui/core';
import { Provider } from 'models';
import { downloadFile, getBackGroundCheckLink } from './DocumentShared';
import Colors from 'constants/Colors';
import { useUserContext } from 'UserContext';
import DocumentDownloadInfo from 'parent-portal/jobs/upcoming/BusinessCalendar/ShiftCard/DocumentDownloadInfo';

export default function DownloadAllDocuments({ provider }: { provider: Provider }) {
    const { user } = useUserContext();
    const [showDocDownloadInfo, setShowDocDownloadInfo] = useState(false);
    function download() {
        downloadFile(`api/providers/${provider.id}/documents/`);
        if (user?.address?.[0]?.state === 'Illinois') setShowDocDownloadInfo(true);

        const backgroundCheckLink = getBackGroundCheckLink(provider);
        const hasBackgroundPDF = provider.compliance_documents?.some(
            (x) => x.friendly_name === 'Background Check Document',
        );
        if (backgroundCheckLink && !hasBackgroundPDF) {
            window.open(backgroundCheckLink, '_blank');
        }
    }
    return (
        <>
            <Grid onClick={download} style={{ cursor: 'pointer' }}>
                <DownloadIcon htmlColor={Colors.darkNavy} />
            </Grid>
            <DocumentDownloadInfo
                showDocDownloadInfo={showDocDownloadInfo}
                setShowDocDownloadInfo={setShowDocDownloadInfo}
                filed718bAutomatically={provider.filed_718b_automatically}
            />
        </>
    );
}
