import React from 'react';
import { Text } from 'library';
import { styled, Grid } from '@mui/material';
import { ExplanatoryCredentialPill } from '../ExplanatoryCredentialPill';

export function BaseCredentials() {
    return (
        <Grid container style={{ marginTop: 3 }}>
            <Grid container item xs={12}>
                <Text variant="body2">• Cleared Fingerprints</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• 3 Character References</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Verified HS Education</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Medical Form</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Gateways Registry Card</Text>
            </Grid>
            <ExplanatoryCredentialPill
                label="Other Trainings According to DCFS Timeline"
                title={
                    <Hover>
                        <Text variant="caption" bold>
                            Day 30
                        </Text>
                        <Text variant="caption">- SBS</Text>
                        <Text variant="caption">- Sids</Text>
                        <Text variant="caption">- Mandated Reporter Training and Acknowledgement</Text>
                        <Text variant="caption" bold>
                            Optional
                        </Text>
                        <Text variant="caption">- Food Handlers</Text>
                        <Text variant="caption">- First Aid/CPR</Text>
                    </Hover>
                }
            />
        </Grid>
    );
}

const Hover = styled('div')({
    padding: 5,
});
