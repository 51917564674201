import React from 'react';
import logo from 'assets/images/tandem_path.png';

const ComplianceLogo = ({ width = 366 }: { width?: number }) => {
    const aspectRatio = 366 / 244;
    const calculatedHeight = width / aspectRatio;

    return (
        <div
            style={{ width, height: calculatedHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <img src={logo} alt="Tandem Path" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
        </div>
    );
};

export default ComplianceLogo;
