import React, { useEffect, useState, useTransition } from 'react';
import Onboarding from './onboarding/Onboarding';
import { complianceAppIsVisible, getCentersForUser } from './api';
import { CentersForOnboarding } from './types';
import { LoadingIndicator } from 'library';
import { consoleLogInDev } from 'shared';
import { useHistory } from 'react-router';
import { useUserContext } from 'UserContext';

const adaptCentersForForm = (centers: CentersForOnboarding[]) => {
    return centers.map((center) => ({
        id: center.id,
        centerName: center.name,
        address: center.address,
        city: center.city,
        state: center.state,
        programId: center.program_id,
        zipCode: center.zip_code,
    }));
};

export default function Compliance() {
    const [data, setData] = useState<CentersForOnboarding[]>();
    const [isPending, startTransition] = useTransition();
    const history = useHistory();
    const { user } = useUserContext();
    const userFirstName = user?.first_name;
    if (!complianceAppIsVisible(user)) {
        history.goBack();
    }

    useEffect(() => {
        startTransition(() => {
            getCentersForUser()
                .then((response: { centers: CentersForOnboarding[]; code: number }) => {
                    if (response.code === 208) {
                        history.push('/compliance/locations');
                    } else {
                        setData(response.centers);
                    }
                })
                .catch((error: unknown) => {
                    consoleLogInDev(error);
                    setData([]);
                });
        });
    }, [startTransition]);

    if (isPending) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100vw',
                    height: '85vh',
                }}
            >
                <LoadingIndicator size={48} />
            </div>
        );
    }

    if (data) {
        const adaptedCenterData = adaptCentersForForm(data);
        return <Onboarding existingCenters={adaptedCenterData} userFirstName={userFirstName} />;
    }
    return <></>;
}
