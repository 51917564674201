import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Colors, CompactButton, PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { IShift, ShiftProviderUser } from 'models';
import { ILeanShift } from '../../BusinessJob/models';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { client } from 'shared';
import GppBadIcon from '@mui/icons-material/GppBad';

interface MarkNoShowButtonProps {
    shift: IShift | ILeanShift;
    provider?: ShiftProviderUser;
    onComplete: () => void;
    providerId: number;
}

export default function MarkNoShowButton({ shift, provider, onComplete, providerId }: MarkNoShowButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <CompactButton onClick={() => setIsModalOpen(true)}>
                <GppBadIcon sx={{ fontSize: 18 }} />
                Mark as no show
            </CompactButton>
            <NoShowModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onComplete={() => {
                    setIsModalOpen(false);
                    onComplete();
                }}
                shift={shift}
                providerFirstName={provider?.first_name || 'your worker'}
                providerId={providerId}
            />
        </>
    );
}

export function NoShowModal({
    open,
    onClose,
    onComplete,
    shift,
    providerFirstName,
    providerId,
}: {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
    shift: IShift | ILeanShift;
    providerFirstName?: string;
    providerId: number;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();
    function submit() {
        setLoading(true);
        client(`api/sub-shifts/remove-provider/`, {
            method: 'POST',
            body: {
                comments: 'Business cancelled: no call, no show',
                is_no_show: true,
                appointment_id: shift.id,
                provider_id: providerId,
            },
        })
            .then(onComplete)
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Is {providerFirstName} not at their shift?</Text>

                <Text variant="body1" bold>
                    {providerFirstName}&apos;s shift has started and they are not clocked in. Unfortunately, unless{' '}
                    {providerFirstName} is there, this is a no call, no show.
                </Text>
                <Text bold>
                    We are so sorry for the inconvenience and take no shows very seriously.{' '}
                    <Text bold textStyle={{ color: Colors.error, display: 'inline' }}>
                        Every no show results in the worker losing 50 reliability points and is grounds for immediate
                        termination from our platform.
                    </Text>
                </Text>
                <PrimaryButton
                    disabled={loading}
                    loading={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Confirm No Show
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
