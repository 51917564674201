import React, { useEffect, useState } from 'react';
import type { TProviderRecentlyWorked } from '../../home/new/types';
import { Avatar, Colors, Link, LoadingIndicator, SmallIcon, Text, TextArea } from 'library';
import DefaultProfileImage from 'assets/icons/Profile.svg';
import { Grid, Rating, styled } from '@mui/material';
import { IProviderReviewOption, ProviderReviewSubmitResponse } from 'models';
import { Controller, useForm } from 'react-hook-form';
import ReviewOptionSelect from './ReviewOptionsSelect';
import { useClient } from 'shared';
import { Check } from '@material-ui/icons';

interface ReviewFormValues {
    text?: string;
    options?: number[];
    score?: number;
}

export default function ProviderReviewModalLine({
    worker,
    options,
    businessLocationId,
}: {
    worker: TProviderRecentlyWorked;
    options: IProviderReviewOption[];
    businessLocationId?: number;
}) {
    const [isOpen, setOpen] = useState(false);
    const { send, loading, data, error } = useClient<ProviderReviewSubmitResponse>({
        url: 'api/provider-review/',
        options: { sendImmediately: false },
    });
    const {
        control,
        formState: { isDirty },
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm<ReviewFormValues>();

    const totalScore = watch('score');
    const isPositive = totalScore && totalScore > 2;
    const selectedOptions = watch('options');

    const validOptions = totalScore
        ? options.filter(isPositive ? (it) => it.show_on_positive_reviews : (it) => it.show_on_negative_reviews)
        : [];

    const onSubmit = (values: ReviewFormValues) =>
        send({
            body: {
                reviewee: worker.id,
                total_score: values.score,
                text: values.text,
                review_results: validOptions
                    .filter((option) => values.options?.includes(option.id))
                    .map((it) => ({ option: it.id })),
                business_location: businessLocationId,
            },
        }).then(() => {
            setOpen(false);
        });

    useEffect(() => {
        reset({
            text: data?.text,
            score: data?.total_score,
            options: data?.review_results?.map((it) => it.option),
        });
    }, [data, reset]);

    useEffect(() => {
        setValue(
            'options',
            validOptions.filter((option) => selectedOptions?.includes(option.id)).map((it) => it.id),
        );
    }, [totalScore]);

    const buttonDetails = (() => {
        if (!isOpen) {
            return {
                text: data ? 'Edit' : 'Review',
                action: () => setOpen(true),
            };
        }
        if (isDirty) {
            return {
                text: 'Submit',
                action: totalScore && selectedOptions?.length ? handleSubmit(onSubmit) : () => {},
                disabled: !totalScore || !selectedOptions?.length,
            };
        }

        return {
            text: 'Close',
            action: () => setOpen(false),
        };
    })();

    return (
        <Grid key={worker.id} container gap={3}>
            <Grid container item gap={2} direction="row" alignItems="center" wrap="nowrap">
                <Grid item>
                    {worker.profile_image?.image ? (
                        <Avatar src={worker.profile_image?.image} style={{ height: 70, width: 70 }} />
                    ) : (
                        <DefaultProfileImage style={{ height: 70, width: 70 }} />
                    )}
                </Grid>
                <Grid container item>
                    <Text variant="h1">
                        {worker.user.first_name} {worker.user.last_name}
                    </Text>
                </Grid>
                <Grid item>
                    {!!data && (
                        <Grid container direction="row" gap={1} flexWrap="nowrap">
                            <Text variant="h2" bold>
                                Reviewed
                            </Text>
                            <SmallIcon icon={<Check />} backgroundColor={Colors.turquoise} color={Colors.white} />
                        </Grid>
                    )}
                    {loading ? (
                        <LoadingIndicator />
                    ) : (
                        <Link onClick={buttonDetails.action}>
                            <Text
                                bold
                                variant="h2"
                                textStyle={{ color: !buttonDetails.disabled ? Colors.darkNavy : Colors.disabledGrey }}
                            >
                                {buttonDetails.text}
                            </Text>
                        </Link>
                    )}
                </Grid>
                {!!error && (
                    <Text color={Colors.error}>
                        There was an error submitting your review. Please refresh your page and try again.
                    </Text>
                )}
            </Grid>
            {isOpen && (
                <Grid gap={2} container direction="column">
                    <Grid>
                        <Text bold>Provide a star rating</Text>
                        <Controller
                            control={control}
                            name="score"
                            render={({ field }) => (
                                <Rating
                                    size="large"
                                    value={field.value ?? 0}
                                    name="Overall"
                                    onChange={(_, value) => field.onChange(value)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid>
                        <Text variant="caption" bold>
                            {totalScore ? 'Select at least one to continue' : ''}
                        </Text>
                        <Controller
                            control={control}
                            name="options"
                            render={({ field }) => (
                                <ReviewOptionSelect
                                    value={field.value ?? []}
                                    onChange={field.onChange}
                                    options={validOptions}
                                    isScorePositive={isPositive}
                                />
                            )}
                        />
                    </Grid>
                    <Grid>
                        <Text bold>Additional feedback</Text>
                        <Controller
                            control={control}
                            name="text"
                            render={({ field }) => (
                                <FeedbackText value={field.value} onChange={field.onChange} rowsMax={8} />
                            )}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

const FeedbackText = styled(TextArea)({
    width: '100%',
});
