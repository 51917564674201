import React, { useState, useCallback, useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import {
    PrimaryButton,
    SecondaryButton,
    SizeableRoundedDialog,
    Text,
    TextArea,
    CompactButton,
    OutlinedDropdown,
    Colors,
} from 'library';
import { IShift, ShiftProviderUser } from 'models';
import { ILeanShift } from '../../BusinessJob/models';
import theme from 'theme';
import { client, consoleLogInDev } from 'shared';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { DynamicCancellationReason } from '../../models';

enum KnownFormFields {
    SelectAValue = 'SELECT_A_VALUE',
    Other = 'OTHER',
}

const PLACEHOLDER = {
    id: -1,
    key: KnownFormFields.SelectAValue,
    value: 'Select a reason',
} as const;

export default function RemoveProviderFromShfit({
    shift,
    provider,
    provider_id,
    setBanModal,
}: {
    provider: ShiftProviderUser;
    provider_id: number;
    shift: IShift | ILeanShift;
    setBanModal: (b: boolean) => void;
}) {
    const [formFields, setFormFields] = useState<{ key: string; value: string; id: number }[]>([PLACEHOLDER]);
    const [reason, setReason] = useState<string>(PLACEHOLDER.key);
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [cancelShiftModal, setCancelShiftModal] = useState(false);
    const [comments, setComments] = useState('');
    const [inputError, setInputError] = useState<string | null>(null);

    const fetchCancellationQuestions = useCallback(() => {
        const businessCalendarRemoveProvider = 'BUSINESS_CALENDAR_REMOVE_PROVIDER';

        client(`api/reason-items?type=${businessCalendarRemoveProvider}`).then((res: DynamicCancellationReason[]) => {
            const items = res.map((item: DynamicCancellationReason) => ({
                key: item.raw_value,
                value: item.display_text,
                id: item.id,
            }));
            setFormFields([PLACEHOLDER, ...items]);
        });
    }, [setFormFields]);

    function removeProvider() {
        const reasonNotSelected = !reason || reason === KnownFormFields.SelectAValue;
        const otherSelected = reason === KnownFormFields.Other;
        const otherWithNoComments = otherSelected && !comments;

        const shouldError = reasonNotSelected || otherWithNoComments;

        if (shouldError) {
            setInputError(`You must provide a reason for removing ${provider.first_name}.`);
            return;
        }

        const reasonId = formFields.find((x) => x.key === reason)?.id;

        client('api/sub-shifts/remove-provider/', {
            body: {
                provider_id: provider_id,
                appointment_id: shift.id,
                reason_id: reasonId,
                comments,
            },
        })
            .then(() => {
                setBanModal(true);
                setInputError(null);
            })
            .catch(consoleLogInDev)
            .finally(() => setCancelShiftModal(false));
    }

    useEffect(() => {
        if (cancelShiftModal) {
            fetchCancellationQuestions();
        }
    }, [fetchCancellationQuestions, cancelShiftModal]);

    return (
        <>
            <CompactButton onClick={() => setCancelShiftModal(true)}>
                <PersonRemoveIcon sx={{ fontSize: 18 }} /> Remove {provider.first_name}
            </CompactButton>
            <SizeableRoundedDialog
                style={{ maxWidth: small ? '100%' : '50%' }}
                open={cancelShiftModal}
                onClose={() => setCancelShiftModal(false)}
                title={``}
                closeButton
            >
                <Text textStyle={{ paddingLeft: 20 }} variant="display">
                    Remove {provider?.first_name} from this shift?
                </Text>
                <Grid item container direction="column" style={{ padding: 20, gap: 20 }}>
                    <Text variant="body1">
                        We will inform {provider?.first_name} that you no longer need them to work on this day. This
                        action will reopen the shift to be filled by a different provider. If you need to cancel the
                        shift completely, you can select{' '}
                        <Text inline bold>
                            Cancel this shift
                        </Text>{' '}
                        instead.
                    </Text>
                    <OutlinedDropdown
                        required
                        value={reason}
                        fields={formFields}
                        onChange={(x) => setReason(x.target.value)}
                    />

                    <TextArea
                        style={{ width: '100%' }}
                        multiline
                        rows={5}
                        value={comments}
                        placeholder={
                            reason === KnownFormFields.Other ? 'Enter Your Reason for Cancelling*' : 'Comments'
                        }
                        onChange={(e) => setComments(e.target.value)}
                        inputProps={{ maxLength: 500 }}
                        helperText="Comments will only be seen by The Tandem Team. 500 Character limit."
                    />
                    {inputError && <Text textStyle={{ color: Colors.red }}>{inputError}</Text>}
                    <Grid item container direction="row" justify="flex-end" style={{ gap: 20 }}>
                        <SecondaryButton onClick={() => setCancelShiftModal(false)} buttonStyle={{ width: 'unset' }}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton onClick={removeProvider} buttonStyle={{ width: 'unset' }}>
                            Confirm
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </SizeableRoundedDialog>
        </>
    );
}
