import React from 'react';
import { Email } from '@mui/icons-material';
import { IconButton } from 'library';
import { ChatwootAccountId, ChatwootUrl } from 'environmentVariables';
import { EmailUser } from 'models';

export default function EmailButton({ contacts, userId }: { contacts?: EmailUser[]; userId?: number }) {
    const chatwootContactId = contacts?.find((contact) => contact.user_id === userId)?.chatwoot_contact_id;
    function handleEmailClick() {
        const url = `${ChatwootUrl}/app/accounts/${ChatwootAccountId}/contacts/${chatwootContactId}`;
        const newWindow = window.open(url, '_blank');
        if (!newWindow) {
            alert('Unable to open chatwoot window. This is possibly due to a popup blocker.');
            return;
        }
    }

    return <IconButton onClick={handleEmailClick} icon={Email} tooltip="Open chatwoot to send email to this center" />;
}
