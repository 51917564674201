import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Moment } from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IMaterialDateInputProps {
    label?: string;
    value?: Moment | Date | string | null;
    onChange: (time: MaterialUiPickersDate, value?: string | null) => void;
    minDate?: Date;
    maxDate?: Date;
}

export default function MaterialDateInput({ label, value, onChange, minDate, maxDate }: IMaterialDateInputProps) {
    const today = new Date();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                label={label}
                autoOk
                variant="inline"
                format="MM/DD/YYYY"
                mask="__/__/____"
                value={value}
                onChange={onChange}
                openTo="date"
                showTodayButton={false}
                minDate={minDate || today}
                maxDate={maxDate}
                style={{ maxWidth: 150 }}
            />
        </MuiPickersUtilsProvider>
    );
}
