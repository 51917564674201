import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors } from 'library';
import { useUserContext } from 'UserContext';
import FamilyLetter from './FamilyLetter';
import { Dashboard } from './new/Dashboard';

const HomeDashboard = () => {
    const { role } = useUserContext();

    if (role === 'business_active') {
        return <Dashboard />;
    }

    return (
        <div style={{ backgroundColor: Colors.white, height: '100%' }}>
            <Grid container direction="row" style={{ paddingTop: '2%', paddingLeft: 50, paddingRight: 50 }}>
                <FamilyLetter />
            </Grid>
        </div>
    );
};

export default HomeDashboard;
