import React, { useState, useEffect, useCallback } from 'react';
import { Grid, useMediaQuery, styled } from '@mui/material';
import { OutlinedTextInput, OutlinedDropdown, PrimaryButton, Text, SizeableRoundedDialog, Colors } from 'library';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { DynamicCancellationReason } from '../../models';
import { client } from 'shared';
import { isBefore } from 'date-fns';
import { BusinessSlot } from 'models';
import * as Sentry from '@sentry/react';

enum KnownFormFields {
    SelectAValue = 'SELECT_A_VALUE',
    Other = 'OTHER',
}

const PLACEHOLDER = {
    id: -1,
    key: KnownFormFields.SelectAValue,
    value: 'Select a reason',
} as const;

export default function CancelModal({
    open,
    onClose,
    slot,
    providerName,
    providerId,
    refresh,
}: {
    open: boolean;
    onClose: () => void;
    slot: BusinessSlot;
    providerName?: string;
    providerId?: number;
    refresh: () => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();

    const [formFields, setFormFields] = useState<{ key: string; value: string; id: number }[]>([PLACEHOLDER]);
    const [reason, setReason] = useState<string>(PLACEHOLDER.key);
    const [comment, setComment] = useState<string>();
    const [inputError, setInputError] = useState<string | null>(null);

    const shift = slot.appointments?.length ? slot.appointments[0] : undefined;
    const hourReport = shift?.hour_reports?.find((r) => r.provider.id === providerId);
    const hasReportLateTimeChangeRequest =
        shift?.worker_initiated_appointment_time_change_request?.late_reason &&
        shift?.worker_initiated_appointment_time_change_request?.status.toLowerCase() !== 'accepted';
    const workerReportedLate = hourReport?.late_minutes || hasReportLateTimeChangeRequest;

    const fetchCancellationQuestions = useCallback(() => {
        const businessCalendarCancelShift = 'BUSINESS_CALENDAR_CANCEL_SHIFT';

        client(`api/reason-items/?type=${businessCalendarCancelShift}`)
            .then((res: DynamicCancellationReason[]) => {
                const items = res?.map((item: DynamicCancellationReason) => ({
                    key: item.raw_value,
                    value: item.display_text,
                    id: item.id,
                }));
                setFormFields([PLACEHOLDER, ...items]);
            })
            .catch((e) => {
                Sentry.captureException(e);
            });
    }, [setFormFields]);

    useEffect(() => {
        if (open) {
            fetchCancellationQuestions();
        }
    }, [fetchCancellationQuestions, open]);

    function submit() {
        setLoading(true);
        setInputError(null);

        const shouldError = (() => {
            if (reason === KnownFormFields.SelectAValue) {
                return true;
            }
            if (reason === KnownFormFields.Other && !comment) {
                return true;
            }
            if (!reason) {
                return true;
            }

            return false;
        })();

        if (shouldError) {
            setInputError('You must provide a reason for cancelling.');
            setLoading(false);
            return;
        }

        const reasonId = formFields.find((x) => x.key === reason)?.id;

        const apiToCall = shift ? `api/business-appointment/${shift.id}/close/` : `api/business-slot/${slot.id}/`;
        const method = shift ? 'POST' : 'DELETE';
        client(apiToCall, {
            method,
            body: { reason_id: reasonId, comment },
        })
            .then(refresh)
            .catch((e) => {
                Sentry.captureException(e);
            })
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    function isWithin2Hours() {
        if (workerReportedLate) {
            return false;
        }

        const twoHoursFromNow = new Date();
        twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2);
        return isBefore(new Date(slot.start_date), twoHoursFromNow);
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Cancel shift for {formatShortWeekdayMonthDay(slot.start_date)}?</Text>
                {isWithin2Hours() && providerName ? (
                    <Grid
                        container
                        style={{
                            borderRadius: 12,
                            backgroundColor: Colors.lightRed,
                            borderWidth: 1,
                            borderColor: Colors.mediumRed,
                            borderStyle: 'solid',
                            padding: '2%',
                        }}
                    >
                        <Text variant="body1" bold textStyle={{ color: Colors.mediumRed }}>
                            You&apos;re cancelling within 2 hours of the shift starting. You will be charged for 2 hours
                            of this shift due to the last minute cancellation.
                        </Text>
                    </Grid>
                ) : null}
                {workerReportedLate ? (
                    <Grid
                        container
                        style={{
                            borderRadius: 12,
                            backgroundColor: Colors.lightTerraCotta,
                            borderWidth: 1,
                            borderColor: Colors.terraCotta,
                            borderStyle: 'solid',
                            padding: '2%',
                        }}
                    >
                        <Text variant="body1" bold textStyle={{ color: Colors.darkNavy }}>
                            Since {providerName} is going to be late, you will not be charged for cancelling this shift.
                        </Text>
                    </Grid>
                ) : null}
                <Text>
                    {providerName
                        ? `We will inform ${providerName} not to come this day, and we will not attempt to refill this shift.`
                        : 'We will no longer attempt to fill this shift.'}
                </Text>

                <OutlinedDropdown
                    required
                    value={reason}
                    fields={formFields}
                    onChange={(x) => setReason(x.target.value)}
                />
                <OutlinedTextInput
                    required={reason === KnownFormFields.Other}
                    label={reason === KnownFormFields.Other ? 'Enter Your Reason for Cancelling' : 'Comments'}
                    style={{ marginTop: 10 }}
                    multiline
                    fullWidth
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                />
                {inputError && <ErrorText>{inputError}</ErrorText>}
                <PrimaryButton
                    disabled={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Submit Cancellation
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}

const ErrorText = styled('p')({
    color: Colors.red,
});
