import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { Text } from 'library';
import { styled, useMediaQuery } from '@mui/material';
import { useClient } from 'shared';
import { useUserContext } from 'UserContext';

import { PostAJob } from './components/PostAJob';
import { ReferralBanner } from './components/ReferralBanner';
import { NotificationsAndPayments } from './components/NotificationsAndPayment';
import { Reviews } from './components/Reviews';
import { TeacherShowcase } from './components/TeacherShowcase';
import { Timesheets } from './components/Timesheets';
import { WelcomeModal } from './components/WelcomeModal';

import type { DashboardSummary } from './types';

export function Dashboard() {
    const { user } = useUserContext();
    const [showRefer, setShowRefer] = useState<boolean>(true);
    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(!!user?.welcome_attribute);
    const { data, loading, error, refresh } = useClient<DashboardSummary>({
        url: 'api/dashboard/summary/',
    });

    const small = useMediaQuery(theme.breakpoints.down('md'));

    const reviewsAndTimesheets = !!data?.reviews?.length || !!data?.timesheets?.length;

    useEffect(() => setShowWelcomeModal(!!user?.welcome_attribute), [user?.welcome_attribute]);

    return (
        <Page>
            {data?.show_refer && showRefer && <ReferralBanner small={small} setShowRefer={setShowRefer} />}
            <Container small={small.toString()}>
                <Text variant="display" textStyle={{ width: '100%' }} id="dashboard-welcome-title">
                    {user?.first_name ? `Hi ${user.first_name}!` : 'Hi!'}
                </Text>
                <Row small={small.toString()}>
                    <PostAJob data={data} loading={loading} error={!!error} refresh={refresh} small={small} />
                    <NotificationsAndPayments
                        data={data}
                        loading={loading}
                        error={!!error}
                        refresh={refresh}
                        small={small}
                    />
                </Row>
                {reviewsAndTimesheets && (
                    <Row small={small.toString()}>
                        <Timesheets loading={loading} small={small} timesheets={data?.timesheets} refresh={refresh} />
                        <Reviews loading={loading} small={small} workers={data?.reviews} />
                    </Row>
                )}
                <TeacherShowcase small={small} />
                <WelcomeModal open={showWelcomeModal} onClose={() => setShowWelcomeModal(false)} />
            </Container>
        </Page>
    );
}

const Page = styled('div')({
    width: '100%',
    height: '100%',
});

const Container = styled('div')(({ small }: { small: string }) => ({
    padding: 20,
    paddingLeft: small === 'true' ? 20 : '2%',
    paddingBottom: '5%',
}));

const Row = styled('div')(({ small }: { small: string }) => ({
    display: 'flex',
    flexDirection: small === 'true' ? 'column' : 'row',
    paddingTop: 20,
    width: '100%',
}));
