import { TextField } from '@material-ui/core';
import { BasicDialog, Colors, PrimaryButton, Text } from 'library';
import React, { useEffect, useState } from 'react';
import { AddStaffInput, AddCenterInput, SubscriptionStatus } from '../types';
import { formatE164USPhoneNumber, formatPhoneAsYouType, validateEmail } from 'shared';
import { Grid } from '@mui/material';
import CenterSelect from './OnboardingCenterSelect';
import SubscriptionBanner from '../components/SubscriptionBanner';

interface Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
    centers: AddCenterInput[];
    addOnOnboarding: (input: AddStaffInput, staffToEdit?: AddStaffInput) => void;
    staffToEdit?: AddStaffInput;
    subscriptionStatus?: SubscriptionStatus;
}

const AddStaffModal = ({ isOpen, setIsOpen, centers, addOnOnboarding, staffToEdit, subscriptionStatus }: Props) => {
    const [firstName, setFirstName] = useState(staffToEdit?.firstName || '');
    const [lastName, setLastName] = useState(staffToEdit?.lastName || '');
    const [email, setEmail] = useState(staffToEdit?.email || '');
    const [phone, setPhone] = useState(staffToEdit?.phoneNumber.toString() || '');
    const [selectedCenters, setSelectedCenters] = useState<string[]>([]);

    useEffect(() => {
        if (staffToEdit) {
            setFirstName(staffToEdit.firstName);
            setLastName(staffToEdit.lastName);
            setEmail(staffToEdit.email);
            setPhone(staffToEdit.phoneNumber.toString());
            setSelectedCenters(staffToEdit.centerNames);
        }
    }, [staffToEdit]);

    const onClose = () => {
        setIsOpen(false);
        clearForm();
    };

    const clearForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSelectedCenters([]);
    };

    const addStaff = () => {
        const input: AddStaffInput = {
            firstName,
            lastName,
            email,
            phoneNumber: formatE164USPhoneNumber(phone).toString(),
            centerNames: selectedCenters,
        };
        addOnOnboarding(input, staffToEdit);
        setIsOpen(false);
        clearForm();
    };

    function formatPhoneNumber(phone?: string) {
        if (!phone) {
            return setPhone('');
        }
        setPhone(formatPhoneAsYouType(phone));
    }

    const buttonDisabled =
        !firstName || !lastName || !email || !validateEmail(email) || selectedCenters.length === 0 || !phone;
    const buttonText = staffToEdit ? 'Update Staff' : 'Add Staff';

    return (
        <BasicDialog closeButton isOpen={isOpen} onClose={onClose}>
            <div>
                <Text variant="h1">Invite Staff Member</Text>
                <SubscriptionBanner subscriptionStatus={subscriptionStatus} />
                <Text variant="h2" bold textStyle={{ marginTop: 16, marginBottom: 2 }}>
                    Account information*
                </Text>
                <Text variant="caption">We will send the staff member an invite link to get started.</Text>
            </div>
            <div style={{ margin: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 16 }}>
                    <TextField value={firstName} label="First Name" onChange={(e) => setFirstName(e.target.value)} />
                    <TextField value={lastName} label="Last Name" onChange={(e) => setLastName(e.target.value)} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 16 }}>
                    <Grid container item direction="column" xs={6}>
                        <TextField value={email} label="Email" onChange={(e) => setEmail(e.target.value)} />
                        {email && !validateEmail(email) && (
                            <Text variant="caption" textStyle={{ color: Colors.mediumRed }}>
                                Please enter a valid email
                            </Text>
                        )}
                    </Grid>
                    <TextField value={phone} label="Phone Number" onChange={(e) => formatPhoneNumber(e.target.value)} />
                </div>
                <CenterSelect
                    centers={centers.map((it) => it.centerName)}
                    selectedCenters={selectedCenters}
                    setSelectedCenters={setSelectedCenters}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
                <PrimaryButton buttonStyle={{ maxWidth: 200 }} onClick={addStaff} disabled={buttonDisabled}>
                    {buttonText}
                </PrimaryButton>
            </div>
        </BasicDialog>
    );
};

export default AddStaffModal;
