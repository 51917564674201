import { consoleLogInDev } from 'shared/Utils';

import ApiClient from '../../shared/ApiClient';

import moment from 'moment';
import { plusOneDay, timeWithDate } from './time-operations';

export async function submitRequest(form, familyId, jobId) {
    const formValues = form.toValues();
    const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const ongoingRequest = await createOngoingRequest(now, formValues, familyId);
    let newJob = null;

    const addresses = formValues.address;
    for (let i = 0; i < addresses.length; i++) {
        const onGoingToSubmit = {
            ...ongoingRequest,
            address: addresses[i].id,
            business_location: addresses[i].businessLocationId,
        };
        const submittedRequest = await submitOngoingRequest(onGoingToSubmit, i === 0 ? jobId : 0);

        if (submittedRequest) {
            if (i === 0) {
                newJob = submittedRequest;
            }
        }
    }

    const { startDay, endDay } = formatDates(formValues);
    newJob.start_date = startDay;
    newJob.end_date = endDay;
    return newJob;
}

function formatDates(formValues) {
    const { start, end } = formValues.jobTimes;

    let startDate = formValues.startDate || moment();
    let endDate = formValues.endDate || moment();

    let startDay;
    let endDay;
    if (!start || !end) {
        if (formValues.multiDay) {
            startDay = startDate.toISOString();
            endDay = endDate.toISOString();
        } else {
            startDay = startDate.startOf('day').toISOString();
            endDay = startDate.endOf('day').toISOString();
        }
    } else {
        const endBeforeStart = end?.isBefore(start);
        endDate = endBeforeStart ? plusOneDay(startDate) : startDate;
        startDay = timeWithDate(start, startDate || moment().startOf('day')).toISOString();
        endDay = timeWithDate(end, endDate || moment().startOf('day')).toISOString();
    }

    return { startDay, endDay };
}

async function submitOngoingRequest(request, jobId) {
    try {
        const res = await ApiClient(`api/ongoing/${jobId ? jobId : ''}`, {
            method: jobId ? 'PATCH' : 'POST',
            body: request,
        });
        return res;
    } catch (error) {
        consoleLogInDev(error);
    }
}

function getPayRangeValues(pay) {
    const { payMin, payMax } = pay;
    const payIsNotRange = payMin === payMax;

    return payIsNotRange ? nonRangePay(pay) : rangedPay(pay);
}

function nonRangePay(pay) {
    const { payMin } = pay;

    return {
        pay: payMin,
        payMin: null,
        payMax: null,
    };
}

function rangedPay(pay) {
    return {
        pay: null,
        payMin: pay.payMin,
        payMax: pay.payMax,
    };
}

export async function createOngoingRequest(now, formValues, familyId, status = 'ACTIVE') {
    const { pay, payMin, payMax } = getPayRangeValues(formValues.pay, formValues.isQuickFill);
    const { start, end } = formValues.jobTimes;
    const startDate = formValues.startDate;
    const day = startDate.toDate().toLocaleString('en-us', { weekday: 'long' });
    let selectedWeekDays = [day];
    let ongoingTimesOfDay = [
        {
            [day]: {
                start: start.format('HH:mm:ss'),
                end: end.format('HH:mm:ss'),
                slots: formValues.slotsAvailable,
            },
        },
    ];

    let ongoing = {
        family: familyId,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: startDate.format('YYYY-MM-DD'),
        last_hire_offset_minutes: formValues.lastHireOffsetMinutes,
        pay: pay,
        pay_is_fixed: formValues.pay.isFixedPay,
        rate_min: payMin,
        rate_max: payMax,
        multiple_providers: true,
        status: status,
        family_comments: formValues.comments,
        days_of_week: selectedWeekDays,
        preferred_sitters: getPreferredSittersObject(formValues.preferredWorkers, formValues.selectedSpotlightWorkers),
        times_of_day: ongoingTimesOfDay,
        ongoing: false,
        headline: formValues.headline,
        pay_type: formValues.payType,
        business_job_type: formValues.businessJobType,
        address: formValues.address?.length ? formValues.address[0].id : undefined,
        business_location: formValues.address?.length ? formValues.address[0].businessLocationId : undefined,
        job_times: { start, end },
        job_details: formValues.jobDetails.map((detail) => ({ ...detail, option: detail.option_id })),
        pay_scales: formValues.payScales.filter((x) => x.description),
        break_required: formValues.breakRequired,
        break_length: formValues.breakLength,
        break_required_every_n_minutes: formValues.breakEveryNHours ? formValues.breakEveryNHours * 60 : undefined,
        trial_run_benefits: formValues.trial_run_benefits,
    };
    return ongoing;
}

function getPreferredSittersObject(preferredProviders, spotlightWorkers) {
    return {
        preferred_sitters: [
            ...preferredProviders.map((preferredProvider) => ({
                sitter: preferredProvider.id,
                spotlight: false,
            })),
            ...spotlightWorkers.map((spotlightWorker) => ({
                sitter: spotlightWorker.id,
                spotlight: true,
            })),
        ],
    };
}
