import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { List, ListItem, ListItemText, styled } from '@mui/material';
import { PayBackgroundPaper } from '../PayPapers';
import PaySectionHeader from '../PaySectionHeader';
import { consoleLogInDev } from 'shared';
import { Text } from 'library';
import { IBusinessUserDiscount } from 'models/Discount';
import { listForBusiness } from 'api/DiscountApi';
import { formatMonthDayYear } from 'shared/Dates';

const LocationDiscounts = () => {
    const [discounts, setDiscounts] = useState<IBusinessUserDiscount[]>([]);

    useEffect(() => {
        getProviderOrCenerLocationOptions();
    }, []);

    const getProviderOrCenerLocationOptions = () => {
        listForBusiness()
            .then((response) => {
                setDiscounts(response.results.sort((a, b) => (a?.address?.id || 0) - (b?.address?.id || 0)));
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    };

    if (!discounts.length) {
        return <></>;
    }

    return (
        <Grid item xs={12}>
            <PayBackgroundPaper>
                <PaySectionHeader>Credits</PaySectionHeader>
                <StyledList>
                    {discounts.map((discount, i) => (
                        <StyledListItem key={i}>
                            <ListItemText
                                primary={
                                    <Grid>
                                        <Text>
                                            ${discount.value.toFixed(2)}
                                            {discount.notes ? ` - ${discount.notes}` : ''}
                                            {discount.expires_at
                                                ? ` - Expires ${formatMonthDayYear(discount.expires_at)}`
                                                : ''}
                                        </Text>
                                        <Text variant="caption">Added: {formatMonthDayYear(discount.added_at)}</Text>
                                    </Grid>
                                }
                                secondary={`${discount.address?.description} - ${discount.address?.street} ${discount.address?.city} ${discount.address?.state} ${discount.address?.zip_code}`}
                            ></ListItemText>
                        </StyledListItem>
                    ))}
                </StyledList>
            </PayBackgroundPaper>
        </Grid>
    );
};

export default LocationDiscounts;

const StyledList = styled(List)({
    listStyle: 'none',
});

const StyledListItem = styled(ListItem)({
    padding: 5,
});
