import { client } from 'shared';

export function createOutlaw(userId: number, locationId: number | undefined, businessId: number, memo?: string) {
    return client(`api/business-outlaws/`, {
        body: {
            user: userId,
            business: businessId,
            business_location: locationId,
            memo: memo,
        },
    });
}

export function deleteOutlaw(businessOutlawId: number) {
    return client(`api/business-outlaws/${businessOutlawId}/`, {
        method: 'DELETE',
    });
}

export function findOutlawed(userId: number) {
    return client(`api/business-outlaws/?user_id=${userId}`);
}

export function findByBusinessId(userId: number, businessId: number) {
    return client(`api/business-outlaws/?user_id=${userId}&business_id=${businessId}`);
}

export function findByBusinessName(userId: number, businessName: string) {
    return client(`api/business-outlaws/?user_id=${userId}&business_name=${businessName}`);
}

export function getBusinessOutlawLocations(userId: number, businessIdOrName: string | number | null) {
    const params = businessIdOrName
        ? `?user_id=${userId}&business_id_or_name=${businessIdOrName}`
        : `?user_id=${userId}`;

    return client(`internal/business-outlaw/${params}`);
}
