import React from 'react';
import { Grid } from '@mui/material';
import { HourReportTimestamp } from '../HourReportModels';
import { Colors, TextButton, TimePicker } from 'library';
import moment from 'moment-timezone';
import { AccessTimeFilled } from '@mui/icons-material';
import { UpdatedTimes } from './TimestampModal';

export function EditTimes({
    accessor,
    currentValue,
    selectedTimestamps,
    updatedTimes,
    updateTimestamps,
    timezone,
    startDate,
}: {
    accessor: string;
    currentValue?: string;
    selectedTimestamps: HourReportTimestamp[] | undefined;
    updatedTimes: UpdatedTimes;
    updateTimestamps: (d: moment.Moment | null, accessor: string) => void;
    timezone: string;
    startDate: string;
}) {
    const defaultDate = selectedTimestamps?.length ? moment(selectedTimestamps[0].reported_at) : moment(startDate);
    const blankDateWithCorrectDay = moment(startDate);
    blankDateWithCorrectDay.date(defaultDate.date());
    const currentDate = currentValue ? moment(currentValue) : undefined;
    const date = updatedTimes?.[accessor] ?? currentDate;
    if (accessor.includes('altered') && (accessor.includes('admin') || accessor.includes('business'))) return <></>;
    return (
        <Grid item container justifyContent="flex-end">
            {date ? (
                <TimePicker
                    value={date}
                    onChange={(d) => d && updateTimestamps(d, accessor)}
                    slots={{
                        openPickerIcon: () => (
                            <AccessTimeFilled htmlColor={Colors.darkNavy} sx={{ width: 16, height: 16 }} />
                        ),
                    }}
                    timezone={timezone}
                />
            ) : (
                <TextButton
                    onClick={() => {
                        updateTimestamps(blankDateWithCorrectDay, accessor);
                    }}
                >
                    Add
                </TextButton>
            )}
        </Grid>
    );
}
