import React, { ReactNode } from 'react';
import { Checkbox as MaterialCheckbox, withStyles } from '@material-ui/core';
import { Colors } from '../../theme';
import { Text } from '../text';
interface CheckboxProps {
    label: ReactNode;
    checked: boolean;
    onChange: (checked: boolean) => void;
    bold?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    labelTitle?: string;
    subLabel?: string;
    subLabelStyle?: React.CSSProperties;
    checkboxStyle?: React.CSSProperties;
}

export default function Checkbox({
    disabled,
    bold,
    label,
    checked,
    onChange,
    style,
    labelTitle,
    subLabel,
    subLabelStyle,
    checkboxStyle,
}: CheckboxProps) {
    const isTextLabel = typeof label === 'string' || label instanceof String;

    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', width: '100%', ...style }}>
            <StyledMuiCheckbox
                disabled={disabled}
                color="primary"
                checked={checked}
                onChange={(e, checked) => onChange(checked)}
                style={{ ...(checkboxStyle ?? {}) }}
            />
            {isTextLabel ? (
                <div style={{ display: 'inline-block' }}>
                    <Text
                        variant="body2"
                        inline
                        bold={bold}
                        textStyle={{ color: Colors.darkNavy, marginTop: 2, whiteSpace: 'break-spaces' }}
                        title={labelTitle}
                    >
                        {label}
                    </Text>
                    {!!subLabel && (
                        <Text
                            variant="body2"
                            inline
                            bold={bold}
                            textStyle={{ marginTop: 2, whiteSpace: 'break-spaces', marginLeft: 5, ...subLabelStyle }}
                            title={labelTitle}
                        >
                            {subLabel}
                        </Text>
                    )}
                </div>
            ) : (
                label
            )}
        </div>
    );
}

const StyledMuiCheckbox = withStyles({
    root: {
        color: Colors.darkNavy,
        marginBottom: -7,
        marginTop: -7,
    },
})(MaterialCheckbox);
