import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { client, formatAddress, consoleLogInDev } from 'shared';
import { Chip, Colors, IconButton, Menu, Text } from 'library';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { Address } from 'models';
import { WeeklyJobFeedback } from '../BusinessJob/models';
import { CalendarColors } from '../BusinessCalendar/HiredShift';
import { useUserContext } from 'UserContext';
import { Assignment, MoreVert, DeleteForever } from '@mui/icons-material';
import { useHistory } from 'react-router';
import CancelAllModal from '../BusinessJob/CancelAllModal';

export interface IBusinessOngoing {
    id: number;
    headline: string;
    family_comments: string;
    status: string;
    start_date: string;
    end_date: string;
    business_job_type: string;
    address: Address;
    filled_count: number;
    shift_count: number;
    weekly_job_feedback: WeeklyJobFeedback[];
    expired?: boolean;
}

interface IShiftListProps {
    addressId?: number;
    active: boolean;
    expired: boolean;
}

export default function ShiftList({ addressId, active, expired }: IShiftListProps) {
    const [loading, setLoading] = useState(true);
    const [ongoingRequests, setOngoingRequests] = useState<IBusinessOngoing[]>([]);
    const [selectedId, setSelectedId] = useState<number>();
    const { getDisplayText } = useUserContext();
    const history = useHistory();

    const classes = useStyles();

    function getUpcomingShifts() {
        if (addressId) {
            client(`api/business-ongoing/?address_id=${addressId}&show_active=${active}&show_expired=${expired}`)
                .then((response: IBusinessOngoing[]) => {
                    setOngoingRequests(response);
                })
                .catch(consoleLogInDev)
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        getUpcomingShifts();
    }, [addressId, active, expired]);

    function getStatus(ongoingRequest: IBusinessOngoing) {
        if (!ongoingRequest.filled_count) {
            return 'Awaiting Placement';
        }

        if (ongoingRequest.shift_count && ongoingRequest.shift_count === ongoingRequest.filled_count) {
            return 'Filled';
        } else if (ongoingRequest.shift_count !== ongoingRequest.filled_count) {
            return 'Partially Filled';
        }

        return 'Awaiting Placement';
    }

    if (loading) {
        return (
            <Grid container item xs={9} style={{ height: '100vh' }}>
                <Grid container alignItems="center" justify="center">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container item direction="row" xs={12} style={{ margin: '20px 0', borderRadius: 18 }}>
            {!ongoingRequests.length ? (
                <Text>
                    You currently have no confirmed shifts, post a job or reach out to us at{' '}
                    <a
                        style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}
                        href="mailto:support@join-tandem.com"
                    >
                        support@join-tandem.com
                    </a>{' '}
                    to get started!
                </Text>
            ) : (
                ongoingRequests.map((ongoingRequest) => (
                    <Grid
                        key={ongoingRequest.id}
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.listItem}
                    >
                        <Grid container item sm={6}>
                            <Grid container direction="row" alignItems="center">
                                <Text variant="h2" bold>
                                    {ongoingRequest.headline}
                                </Text>
                                <Chip
                                    label={getDisplayText(
                                        ongoingRequest.business_job_type.replace('_', ' ').toLowerCase(),
                                    )}
                                    style={{
                                        textTransform: 'capitalize',
                                        marginLeft: 10,
                                    }}
                                    backgroundColor={CalendarColors[ongoingRequest.business_job_type]}
                                />
                                {ongoingRequest.expired && (
                                    <Chip
                                        label="Expired"
                                        style={{
                                            textTransform: 'capitalize',
                                            marginLeft: 10,
                                        }}
                                        backgroundColor={Colors.mediumTerraCotta}
                                    />
                                )}
                            </Grid>
                            <Text variant="body1">{formatAddress(ongoingRequest.address)}</Text>
                        </Grid>
                        <Grid container item sm={3} justify="center">
                            <Text variant="body2">
                                <b>Start Date</b>:{' '}
                                {formatShortWeekdayMonthDay(ongoingRequest.start_date.replaceAll('-', '/'))}
                            </Text>
                        </Grid>
                        <Grid item sm={2}>
                            <Text variant="body2">
                                <b>Status</b>: {getStatus(ongoingRequest)}
                            </Text>
                        </Grid>
                        <Grid item sm={1} style={{ alignSelf: 'flex-start' }}>
                            <Menu
                                options={[
                                    {
                                        text: 'Manage Job',
                                        icon: <Assignment />,
                                        onClick: () => history.push(`/jobs/${ongoingRequest.id}`),
                                    },
                                    {
                                        text: 'Cancel Entire Job',
                                        icon: <DeleteForever />,
                                        onClick: () => setSelectedId(ongoingRequest.id),
                                    },
                                ]}
                                anchor={<IconButton icon={MoreVert} />}
                            />
                        </Grid>
                    </Grid>
                ))
            )}
            <CancelAllModal
                open={Boolean(selectedId)}
                onClose={() => setSelectedId(undefined)}
                ongoingRequestId={selectedId}
                refresh={getUpcomingShifts}
            />
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    listItem: {
        margin: '5px 0',
        boxShadow: 'grey 0px 0px 5px',
        borderRadius: 10,
        padding: 10,
    },
    provideContainer: {
        padding: '15px 10px',
        backgroundColor: Colors.lightGrey,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    link: {
        textDecoration: 'underline',
        color: Colors.darkNavy,
    },
    cancelledContainer: {
        display: 'inline-flex',
    },
}));
