import React from 'react';
import { Colors } from 'library';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircle,
    faCircleCheck,
    faCircleXmark,
    faShieldHalved,
    faTriangleExclamation,
    faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

const iconsByStatus = {
    SATISFIED: {
        default: <FontAwesomeIcon icon={faCircleCheck} size={'xl'} color={Colors.turquoise} />,
        small: <FontAwesomeIcon icon={faCircleCheck} size={'sm'} color={Colors.turquoise} />,
    },
    READY_TO_WORK: {
        default: <FontAwesomeIcon icon={faShieldHalved} size={'xl'} color={Colors.turquoise} />,
        small: <FontAwesomeIcon icon={faShieldHalved} size={'sm'} color={Colors.turquoise} />,
    },
    BLOCKER: {
        default: <FontAwesomeIcon icon={faCircleXmark} size={'xl'} color={Colors.mediumRed} />,
        small: <FontAwesomeIcon icon={faCircleXmark} size={'sm'} color={Colors.mediumRed} />,
    },
    WARNING: {
        default: <FontAwesomeIcon icon={faTriangleExclamation} size={'xl'} color={Colors.terraCotta} />,
        small: <FontAwesomeIcon icon={faTriangleExclamation} size={'sm'} color={Colors.terraCotta} />,
    },
    INCOMPLETE: {
        default: <FontAwesomeIcon icon={faCircle} size={'xl'} color={Colors.disabledGrey} />,
        small: <FontAwesomeIcon icon={faCircle} size={'sm'} color={Colors.disabledGrey} />,
    },
    INVITED: {
        default: <FontAwesomeIcon icon={faPaperPlane} size={'xl'} color={Colors.mediumBlue} />,
        small: <FontAwesomeIcon icon={faPaperPlane} size={'sm'} color={Colors.mediumBlue} />,
    },
};

const infoByStatus = {
    SATISFIED: 'Compliant',
    READY_TO_WORK: 'Compliant',
    BLOCKER: 'Action Needed to become compliant',
    WARNING: 'Action needed soon to remian compliant',
    INCOMPLETE: 'Task not started',
    INVITED: 'Invited to join',
};

export default function IconByStatus({ status, size = 'default' }: { status: string; size?: 'small' | 'default' }) {
    const statusKey = status as keyof typeof iconsByStatus;
    const icon = iconsByStatus[statusKey]?.[size];
    const title = infoByStatus[statusKey];
    const display = (
        <Tooltip title={title} placement="top">
            <div>{icon}</div>
        </Tooltip>
    );
    return icon ? display : null;
}
