import { Grid } from '@mui/material';
import { Colors, Text, TextButton } from 'library';
import React from 'react';
import { styled } from 'styled-components';
const Chip = styled('div')(() => ({
    borderRadius: 18,
    display: 'flex',
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: Colors.white,
    padding: 20,
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'fit-content',
    gap: 10,
}));

export default function UserDetailChip({
    title,
    buttonText,
    onClick,
    children,
    titleIcon,
}: {
    title: string;
    buttonText?: string;
    onClick?: () => void;
    children: React.ReactNode;
    titleIcon?: React.ReactNode;
}) {
    return (
        <Chip>
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item container direction="row" alignItems="center" style={{ gap: 10 }} xs={6}>
                    <Text variant="h2" bold>
                        {title}
                    </Text>
                    {titleIcon}
                </Grid>
                {buttonText && onClick ? (
                    <TextButton onClick={onClick} buttonStyle={{ width: 'unset' }} textStyle={{ fontSize: 14 }}>
                        {buttonText}
                    </TextButton>
                ) : null}
            </Grid>
            {children}
        </Chip>
    );
}
