import React from 'react';
import { ApplicantSelection } from '../models';
import { Text, PrimaryButton, SizeableRoundedDialog, IconButton } from 'library';
import { Grid } from '@mui/material';
import ApplicantApprovalDetails from './ApplicantApprovalDetails';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export default function ApplicantApprovalTimes({
    applicants,
    open,
    onClose,
    onConfirm,
    toggleAppointmentApproved,
}: {
    applicants: ApplicantSelection[];
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    toggleAppointmentApproved: (applicantId: number, appointmentId: number) => void;
}) {
    const [visibleApplicantIndex, setVisibleApplicantIndex] = React.useState(0);

    function closeModal() {
        setVisibleApplicantIndex(0);
        onClose();
    }

    function confirm() {
        onConfirm();
        closeModal();
    }

    if (!applicants.length) return null;

    return (
        <SizeableRoundedDialog open={open} onClose={onClose} closeButton contentStyle={{ padding: 30 }}>
            <Text variant="display" textStyle={{ marginBottom: 20 }}>
                Confirm Details
            </Text>
            <ApplicantApprovalDetails
                key={applicants[visibleApplicantIndex].applicant.id}
                applicantSelection={applicants[visibleApplicantIndex]}
                toggleAppointmentApproved={toggleAppointmentApproved}
            />
            {applicants.length > 1 && (
                <Grid container justifyContent="center" gap={2} alignItems="center" marginTop={2} paddingRight={3}>
                    <IconButton
                        icon={ChevronLeft}
                        onClick={() => setVisibleApplicantIndex((prev) => prev - 1)}
                        disabled={visibleApplicantIndex === 0}
                        size={40}
                    />
                    <Text variant="body2">
                        {visibleApplicantIndex + 1} of {applicants.length}
                    </Text>
                    <IconButton
                        icon={ChevronRight}
                        size={40}
                        onClick={() => setVisibleApplicantIndex((prev) => prev + 1)}
                        disabled={visibleApplicantIndex >= applicants.length - 1}
                    />
                </Grid>
            )}
            <Grid container justifyContent="flex-end">
                <PrimaryButton onClick={confirm} buttonStyle={{ width: 'auto', margin: 20 }}>
                    Approve{applicants.length > 1 ? ' All' : ''}
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
