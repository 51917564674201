import React, { useState } from 'react';
import theme from 'theme';

import DefaultProfileImage from 'assets/icons/Profile.svg';
import ListingName from 'parent-portal/providers/cards/SearchListing/ListingName';
import DocumentLinks from 'parent-portal/provider-profile/tabs/documents/DocumentShared';
import DownloadAllDocuments from 'parent-portal/provider-profile/tabs/documents/DownloadAllDocuments';
import PreferWorkerModal from 'parent-portal/shared/UserActions/PreferWorkerModal';
import BanWorkerModal from 'parent-portal/shared/UserActions/BanWorkerModal';
import { PromptForReviewModal } from 'parent-portal/shared/UserActions/PromptForReviewModal';

import { Avatar, Checkbox, Text } from 'library';
import { Card, Divider, styled, useMediaQuery } from '@mui/material';
import { PermissionType, useUserContext } from 'UserContext';
import { PreferredButton } from 'parent-portal/shared/UserActions/PreferWorkerModal/PreferredButton';
import { BannedButton } from 'parent-portal/shared/UserActions/BanWorkerModal/BannedButton';

import { WORKED } from '../constants';

import type { BusinessLocationPreferredWorker, BusinessLocationBannedWorker } from 'models';
import type { IProviderSearch } from '../models';

export function ProviderRow({
    provider,
    checked,
    handleCheck,
    workerType,
}: {
    provider: IProviderSearch;
    checked: boolean;
    handleCheck: (checked: boolean) => void;
    workerType: string;
}) {
    const userAvatar = provider.profile_image?.image;
    const small = useMediaQuery(theme.breakpoints.down('md'));

    const { user, hasPermission } = useUserContext();
    const canPostJobs = hasPermission(PermissionType.PostJob);
    const businessId = user?.businesses_active?.[0].id;

    const [openBanModal, setOpenBanModal] = useState<boolean>(false);
    const [openPreferredModal, setOpenPreferredModal] = useState<boolean>(false);
    const [openPromptForReviewModal, setOpenPromptForReviewModal] = useState<boolean>(false);

    const [bannedLocations, setBannedLocations] = useState<number>(provider.business_outlaws?.length ?? 0);
    const [bannedAll, setBannedAll] = useState<boolean>(
        !!provider.business_outlaws?.length && !provider.business_outlaws?.[0]?.business_location,
    );

    const [preferredLocations, setPreferredLocations] = useState<number>(provider.preferred_workers?.length ?? 0);
    const [preferredAll, setPreferredAll] = useState<boolean>(
        !!provider.preferred_workers?.length && !provider.preferred_workers?.[0]?.business_location,
    );

    function handleBan(bannedWorkers: BusinessLocationBannedWorker[], prompt: boolean = false) {
        const banned: number = bannedWorkers.reduce(
            (total, bannedWorker) => total + (bannedWorker.is_business_outlaw ? 1 : 0),
            0,
        );
        const allBanned = banned === bannedWorkers.length;

        setBannedAll(allBanned);
        setBannedLocations(banned);
        setOpenPromptForReviewModal(prompt);
    }

    function handlePrefer(preferredWorkers: BusinessLocationPreferredWorker[], prompt: boolean = false) {
        const preferred: number = preferredWorkers.reduce(
            (total, preferredWorker) => total + (preferredWorker.is_preferred_worker ? 1 : 0),
            0,
        );
        const allPreferred = preferred === preferredWorkers.length;

        setPreferredAll(allPreferred);
        setPreferredLocations(preferred);
        setOpenPromptForReviewModal(prompt);
    }

    return (
        <>
            <Container>
                {canPostJobs && (
                    <CheckboxContainer>
                        <StyledCheckbox label="" checked={checked} onChange={handleCheck} />
                    </CheckboxContainer>
                )}
                <ContentContainer small={small}>
                    <Container>
                        {userAvatar ? (
                            <Avatar src={userAvatar} style={{ height: 82, width: 82 }} />
                        ) : (
                            <StyledProfileImage />
                        )}
                        <StyledName>
                            <ListingName provider={provider} />
                        </StyledName>
                    </Container>
                    <DocumentAndButtonsContainer>
                        <DocumentsContainer>
                            <TitleContainer>
                                <Text>Documents</Text>
                                <DownloadAllDocuments provider={provider} />
                            </TitleContainer>
                            <DocumentLinks provider={provider} />
                        </DocumentsContainer>
                        <ButtonsContainer small={small}>
                            {workerType === WORKED && (
                                <BannedButton
                                    bannedAll={bannedAll}
                                    bannedLocations={bannedLocations}
                                    setOpenBanModal={setOpenBanModal}
                                    buttonStyle={{ width: 'fit-content', marginTop: 10 }}
                                />
                            )}
                            <PreferredButton
                                preferredAll={preferredAll}
                                preferredLocations={preferredLocations}
                                setOpenPreferredModal={setOpenPreferredModal}
                                buttonStyle={{
                                    width: 'fit-content',
                                    marginLeft: 10,
                                    marginTop: 10,
                                }}
                            />
                        </ButtonsContainer>
                    </DocumentAndButtonsContainer>
                </ContentContainer>
            </Container>
            <StyledDivider />
            {businessId && (
                <BanWorkerModal
                    open={openBanModal}
                    provider={{
                        id: provider.user.id,
                        first_name: provider.user.first_name,
                        last_name: provider.user.last_name,
                        profile_picture: { image: userAvatar ?? '' },
                    }}
                    onSuccess={handleBan}
                    onClose={() => setOpenBanModal(false)}
                />
            )}
            {businessId && (
                <PreferWorkerModal
                    open={openPreferredModal}
                    provider={{
                        id: provider.user.id,
                        first_name: provider.user.first_name,
                        last_name: provider.user.last_name,
                        profile_picture: { image: userAvatar ?? '' },
                    }}
                    onSuccess={handlePrefer}
                    onClose={() => setOpenPreferredModal(false)}
                />
            )}
            {businessId && (
                <PromptForReviewModal
                    open={openPromptForReviewModal}
                    onClose={() => setOpenPromptForReviewModal(false)}
                    userId={provider.user.id}
                />
            )}
        </>
    );
}

const Container = styled('div')({
    display: 'flex',
});

const CheckboxContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const StyledCheckbox = styled(Checkbox)({
    paddingRight: 15,
});

const StyledName = styled('div')({
    marginLeft: 15,
});

const StyledProfileImage = styled(DefaultProfileImage)({
    height: 82,
    width: 82,
    borderRadius: '50%',
    flexShrink: 0,
});

const ContentContainer = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    flexDirection: small ? 'column' : 'row',
    justifyContent: 'space-between',
    width: '100%',
}));

const DocumentAndButtonsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%',
});

const DocumentsContainer = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    marginTop: 10,
});

const TitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
});

const ButtonsContainer = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: small ? 'column' : 'row',
}));

const StyledDivider = styled(Divider)({
    margin: '20px 0',
});
