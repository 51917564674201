import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PrimaryButton, Text } from 'library';
import { ITeacher } from 'models';
import { useClient } from 'shared';
import { AddCircleRounded } from '@mui/icons-material';
import { useModalsContext } from 'ModalsContext';

import type { ISpotlightWorker } from '../../Models/DashboardData';
import TeacherShowcaseCard from './TeacherShowcaseCard';
import { PermissionType, useUserContext } from 'UserContext';

interface ITeacherShowcase {
    workers: ITeacher[];
}

export function TeacherShowcase({ small }: { small: boolean }) {
    const { data, error, loading } = useClient<ITeacherShowcase>({ url: 'api/dashboard/worker-spotlight/' });
    const { openJobForm } = useModalsContext();
    const { hasPermission } = useUserContext();
    const canPostJobs = hasPermission(PermissionType.PostJob);
    const [selectedWorkers, setSelectedWorkers] = useState<ITeacher[]>([]);

    function toggleSelected(worker: ITeacher) {
        if (selectedWorkers.some((selectedWorker: ITeacher) => selectedWorker.id === worker.id)) {
            setSelectedWorkers(selectedWorkers.filter((x) => x.id !== worker.id));
        } else {
            setSelectedWorkers([...selectedWorkers, worker]);
        }
    }

    function normalizeCredentials(credentials: ITeacher['credentials']) {
        const experience = `experience: ${credentials.experience.split(' ')[0]}`;
        const education = credentials.education.map((ed: string) => `education: ${ed}`);

        return Object.values({ education, experience }).flat();
    }

    function normalize(worker: ITeacher) {
        return {
            id: worker.id,
            name: worker.name,
            pictureUrl: worker.pictureUrl,
            bio: worker.bio,
            credentials: normalizeCredentials(worker.credentials),
        };
    }

    function request() {
        const normalizedSelectedWorkers: ISpotlightWorker[] = selectedWorkers.map(normalize);
        const normalizedWorkers: ISpotlightWorker[] = selectedWorkers.map(normalize);

        setSelectedWorkers([]);

        openJobForm('Teacher Showcase', {
            selectedSpotlightWorkers: normalizedSelectedWorkers,
            allSpotlightWorkers: normalizedWorkers,
        });
    }

    if (error || data?.workers?.length === 0 || loading) return null;

    return (
        <Container>
            <Header small={small.toString()}>
                <Text variant="h1">Available Tandem Teachers</Text>
                {canPostJobs && (
                    <PrimaryButton
                        onClick={request}
                        buttonStyle={{
                            width: 'auto',
                            padding: 0,
                            marginRight: small ? 0 : 20,
                            marginTop: small ? 20 : 0,
                        }}
                        boldText={false}
                        textStyle={{
                            padding: '6px 12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        disabled={selectedWorkers.length === 0}
                    >
                        <AddCircleRounded style={{ fontSize: 20, marginRight: 10 }} />
                        Request Selected Teachers
                    </PrimaryButton>
                )}
            </Header>
            <Showcase small={small.toString()}>
                {data?.workers?.map((worker: ITeacher) => (
                    <TeacherShowcaseCard
                        key={worker.id}
                        worker={worker}
                        selected={selectedWorkers.some((selectedWorker: ITeacher) => worker.id === selectedWorker.id)}
                        onChange={toggleSelected}
                    />
                ))}
            </Showcase>
        </Container>
    );
}

const Container = styled('div')({
    paddingTop: 30,
    width: 'fit-content',
});

const Showcase = styled('div')(({ small }: { small: string }) => ({
    display: 'flex',
    flexDirection: small === 'true' ? 'column' : 'row',
    paddingTop: 20,
}));

const Header = styled('div')(({ small }: { small: string }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: small === 'true' ? 'column' : 'row',
    justifyContent: 'space-between',
}));
