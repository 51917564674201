import { Grid } from '@mui/material';
import { Text, SizeableRoundedDialog, Colors, PrimaryButton } from 'library';
import React from 'react';

export default function DocumentDownloadInfo({
    showDocDownloadInfo,
    setShowDocDownloadInfo,
    filed718bAutomatically,
}: {
    showDocDownloadInfo: boolean;
    setShowDocDownloadInfo: (show: boolean) => void;
    filed718bAutomatically?: boolean;
}) {
    const clickableTandemEmail = (
        <a href="mailto:team@join-tandem.com" style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}>
            team@join-tandem.com
        </a>
    );

    const filedTextOne = (
        <Text>
            When the teacher is booked, Tandem files the teacher&apos;s 718-B-DC to the state with your program ID.
        </Text>
    );
    const notFiledTextOne = (
        <Text>
            As per your instructions, Tandem did{' '}
            <Text inline bold textStyle={{ color: Colors.error }}>
                not
            </Text>{' '}
            file the teacher&apos;s 718-B-DC to the state with your program ID.
        </Text>
    );

    const filedTextTwo = (
        <Text>
            If you&apos;d prefer Tandem not file booked teachers&apos; 718-B-DC , please email {clickableTandemEmail}.
        </Text>
    );
    const notFiledTextTwo = (
        <Text>
            If you&apos;d prefer Tandem to file booked teachers&apos; 718-B-DC, please email {clickableTandemEmail}.
        </Text>
    );

    const textOne = filed718bAutomatically ? filedTextOne : notFiledTextOne;
    const textTwo = filed718bAutomatically ? filedTextTwo : notFiledTextTwo;

    return (
        <SizeableRoundedDialog
            open={showDocDownloadInfo}
            onClose={() => setShowDocDownloadInfo(false)}
            contentStyle={{ padding: 40 }}
            closeButton={true}
        >
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Text variant="h1">Heads up!</Text>
                </Grid>
                <Grid item>{textOne}</Grid>
                <Grid item>{textTwo}</Grid>
                <PrimaryButton
                    buttonStyle={{ maxWidth: 200, alignSelf: 'flex-end' }}
                    onClick={() => setShowDocDownloadInfo(false)}
                >
                    Close
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
