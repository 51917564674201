import React from 'react';
import { Grid, styled } from '@mui/material';
import { Checkbox, Text } from 'library';
import { IBusinessLocation } from 'models';

interface ISelectPermissionsProps {
    availableLocations: IBusinessLocation[];
    selectedLocations: number[];
    onLocationSelect: (id: number) => void;
    onSelectAll: () => void;
}

export default function SelectLocations({
    availableLocations,
    selectedLocations,
    onLocationSelect,
    onSelectAll,
}: ISelectPermissionsProps) {
    return (
        <SelectionContainer container>
            <Text variant="h2" bold>
                Select Location Access*
            </Text>
            <Grid item xs={12}>
                <Checkbox
                    label="All Location"
                    checked={selectedLocations.length > 0 && selectedLocations.length === availableLocations.length}
                    onChange={() => onSelectAll()}
                    style={{ alignItems: 'flex-start' }}
                />
            </Grid>
            {availableLocations.map((x) => (
                <Grid key={x.id} item sm={6} xs={12}>
                    <Checkbox
                        label={x.name}
                        checked={selectedLocations.includes(x.id)}
                        onChange={() => onLocationSelect(x.id)}
                    />
                </Grid>
            ))}
        </SelectionContainer>
    );
}

const SelectionContainer = styled(Grid)({
    padding: '10px 0',
});
