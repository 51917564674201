import React from 'react';
import { Text, IconButton, TimePicker } from 'library';
import { Grid } from '@material-ui/core';
import { TimeChange } from './jobChangeModels';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { formatMonthDayYearWith12HourTime, formatShortWeekdayMonthDayYear, toTimeZoneAbbreviation } from 'shared/Dates';
import moment from 'moment-timezone';

export default function JobTimeChange({
    removedTimeSlots,
    timeChange,
    updateJobChangeTime,
    index,
    setRemovedTimeSlots,
    timezone,
}: {
    removedTimeSlots: number[];
    timeChange: TimeChange;
    updateJobChangeTime: (d: moment.Moment | null, i: number, field: keyof TimeChange) => void;
    index: number;
    setRemovedTimeSlots: (n: number[]) => void;
    timezone: string;
}) {
    const start = moment(timeChange.start);
    const end = moment(timeChange.end);
    const removed = removedTimeSlots.includes(timeChange.appointment);
    const timezoneAbbreviation = toTimeZoneAbbreviation(timezone);

    const startAsDate = start.toDate();

    return (
        <Grid key={timeChange.appointment} item container direction="row" alignItems="center" justify="space-between">
            <Grid item container direction="column" style={{ opacity: removed ? 0.5 : 1, width: '90%' }}>
                <Grid item container direction="row" alignItems="center" justify="space-between">
                    <Grid item container direction="column" xs={4}>
                        <Text textStyle={{ width: 200 }}>{formatShortWeekdayMonthDayYear(startAsDate)}</Text>
                    </Grid>
                    <TimePicker
                        value={start}
                        onChange={(d) => updateJobChangeTime(d, index, 'start')}
                        disabled={removed}
                        label={`Start time (${timezoneAbbreviation})`}
                        timezone={timezone}
                        sx={{ width: 160 }}
                    />
                    <TimePicker
                        value={end}
                        onChange={(d) => updateJobChangeTime(d, index, 'end')}
                        disabled={removed}
                        label={`End time (${timezoneAbbreviation})`}
                        timezone={timezone}
                        sx={{ width: 160 }}
                    />
                </Grid>
                {!!timeChange.last_hire_date && (
                    <Text variant="caption">
                        Last time to fill this appointment:{' '}
                        {formatMonthDayYearWith12HourTime(timeChange.last_hire_date, timezone, true)}
                    </Text>
                )}
            </Grid>
            <IconButton
                icon={removed ? AddIcon : CloseIcon}
                onClick={() =>
                    removed
                        ? setRemovedTimeSlots(removedTimeSlots.filter((i) => i !== timeChange.appointment))
                        : setRemovedTimeSlots([...removedTimeSlots, timeChange.appointment])
                }
            />
        </Grid>
    );
}
