import React from 'react';
import { Card, styled } from '@mui/material';
import { Colors, LoadingSpinner } from 'library';

export function BasicCard({
    loading,
    small,
    right,
    children,
}: {
    loading: boolean;
    small: boolean;
    right: boolean;
    children: React.ReactNode;
}) {
    return (
        <StyledCard small={small.toString()} right={right.toString()}>
            {loading ? <LoadingSpinner loadingStyle={{ color: Colors.darkNavy }} /> : children}
        </StyledCard>
    );
}

const StyledCard = styled(Card)(({ small, right }: { small: string; right: string }) => ({
    borderRadius: 10,
    padding: 20,
    width: small === 'true' ? '100%' : '48%',
    marginLeft: small === 'true' || right === 'false' ? 0 : 20,
    marginTop: small === 'true' && right === 'true' ? 20 : 0,
}));
