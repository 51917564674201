import React, { useState } from 'react';
import { Colors, SizeableRoundedDialog, Text, TextButton } from 'library';
import { useMediaQuery, Grid } from '@mui/material';
import theme from 'theme';
import { BusinessSlot } from 'models';
import { BusinessJobShiftSlot } from '../BusinessJob/components/BusinessJobShiftSlot';
import { ApplicantApprovalSummary } from './BusinessCalendar';
import { isAfter, isToday, isTomorrow, subHours } from 'date-fns';
import _ from 'lodash';
import UnfilledShiftsWithApprovals from './UnfilledShiftsWithApprovals';
export default function UnfilledShifts({
    slots,
    title,
    refresh,
    approvals,
}: {
    slots: BusinessSlot[];
    title: string;
    refresh: () => void;
    approvals: ApplicantApprovalSummary[];
}) {
    const [viewShifts, setViewShifts] = useState(false);
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const unfilledShifts = slots.reduce((unfilledJobsWithSlots: { [key: string]: number }, slot: BusinessSlot) => {
        const job = slot.ongoing_request.id.toString();
        unfilledJobsWithSlots[job] = unfilledJobsWithSlots[job] || 0;
        unfilledJobsWithSlots[job] =
            slot.appointments.length === 0
                ? unfilledJobsWithSlots[job] + 1
                : unfilledJobsWithSlots[job] + slot.appointments.filter((appt) => appt.providers.length === 0).length;

        return unfilledJobsWithSlots;
    }, {});
    const date = new Date(slots[0].start_date);
    const isTodayOrTomorrow = (isToday(date) && isAfter(date, subHours(new Date(), 4))) || isTomorrow(date);
    const [unfilledShiftsWithApprovals, unfilledShiftsWithoutApprovals] = _.partition(
        Object.entries(unfilledShifts),
        ([jobId]) =>
            isTodayOrTomorrow &&
            approvals.some((approval) => approval.job.toString() === jobId && approval.applicants.length > 0),
    );
    const totalUnfilledWithoutApprovals = unfilledShiftsWithoutApprovals.reduce((acc, [, count]) => acc + count, 0);

    return (
        <Grid item container direction="column" alignContent="center">
            {unfilledShiftsWithApprovals.map(([jobId, count]) => (
                <UnfilledShiftsWithApprovals
                    key={jobId}
                    jobId={jobId}
                    count={count}
                    approvalsForJob={approvals.find(
                        (approval) => approval.job.toString() === jobId && approval.applicants.length > 0,
                    )}
                    onViewShifts={() => setViewShifts(true)}
                />
            ))}
            {totalUnfilledWithoutApprovals > 0 && (
                <TextButton buttonStyle={styles.unfilledShiftButton} onClick={() => setViewShifts(true)}>
                    {totalUnfilledWithoutApprovals} unfilled shift
                    {totalUnfilledWithoutApprovals === 1 ? '' : 's'}
                </TextButton>
            )}
            <SizeableRoundedDialog
                open={viewShifts}
                onClose={() => setViewShifts(false)}
                closeButton
                style={{ maxWidth: smDown ? '100%' : '50%' }}
                disableScrollLock={true}
            >
                <Grid item container direction="column" style={{ padding: 20 }}>
                    <Text variant="display" bold>{`Unfilled shifts for ${title}`}</Text>
                    <Grid item container direction="row" style={{ paddingTop: 20, gap: 20 }}>
                        {slots.map((slot) => (
                            <BusinessJobShiftSlot
                                key={slot.id}
                                slot={slot}
                                job={slot.ongoing_request}
                                getJob={refresh}
                                md={slots.length === 1 ? 8 : 5}
                                businessJobType={slot.ongoing_request.business_job_type}
                            />
                        ))}
                    </Grid>
                </Grid>
            </SizeableRoundedDialog>
        </Grid>
    );
}

const styles = {
    unfilledShiftButton: {
        backgroundColor: Colors.lightRed,
        borderTopLeftRadius: 18,
        borderTopRightRadius: 18,
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18,
        width: '100%',
    },
};
