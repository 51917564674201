import {
    SendWorkerInput,
    AddCenterInput,
    AddStaffInput,
    OnboardingInput,
    ComplianceSummary,
    ManagedWorker,
    WorkerFilters,
    SubscriptionEvent,
    SubscriptionStatus,
    SubscriptionEventType,
    ComplianceSummaryEntry,
} from './types';
import { rawResponseClient, useClient, client } from 'shared';
import { BusinessLocation, PagingResult, UserContextUser } from 'models';
import { useCallback, useEffect, useState, useTransition } from 'react';
import IProvider from 'models/Providers/IProvider';
import { addDays } from 'date-fns';
import { exampleManageWorkerRes } from '../example-data/managed_workers';
import { exampleCriticalActionsById } from 'parent-portal/example-data/critical_actions';

export function useSendInviteToWorker() {
    async function sendInvite(workerInput: SendWorkerInput) {
        return await client('compliance/worker/compliance-enrollment/', { body: workerInput });
    }
    return {
        sendInvite,
    };
}

export function useCenters() {
    const { data, loading, error, refresh } = useClient<BusinessLocation[]>({
        url: 'api/business-location/lean-locations/',
    });

    return {
        centers: data,
        centersLoading: loading,
        centersError: error,
        refreshCenters: refresh,
    };
}

export function useWorkers() {
    const [data, setData] = useState<PagingResult<ManagedWorker>>();
    const [error, setError] = useState<Error>();
    const [isPending, startTransition] = useTransition();
    const [loading, setLoading] = useState(false);

    const baseUrl = 'compliance/worker/';

    function filtersToURLParams(filters?: WorkerFilters): string {
        const params = new URLSearchParams();
        params.append('include_compliance', 'true');
        if (!filters) return params.toString();

        Object.keys(filters).forEach((key) => {
            const value = filters[key as keyof WorkerFilters];
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    if (value.length) params.append(key, value.join(','));
                } else {
                    params.append(key, value.toString());
                }
            }
        });

        return params.toString();
    }

    const fetchWorkers = useCallback((filters?: WorkerFilters) => {
        const hasNoFilters = !filters || Object.keys(filters).length === 0;
        const urlFilters = filtersToURLParams(filters);
        const fetchUrl = `${baseUrl}?${urlFilters}`;
        setLoading(true);
        client(fetchUrl)
            .then((res) => {
                const hasData = res?.results?.length > 0;
                const data = !hasData && hasNoFilters ? exampleManageWorkerRes : res;
                startTransition(() => {
                    setData(data);
                });
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);
    function fireFetch() {
        fetchWorkers();
    }
    useEffect(fireFetch, []);

    return {
        workers: data,
        workersLoading: loading || isPending,
        workersError: error,
        refreshWorkers: fetchWorkers,
    };
}

export const getCentersForUser = async () => {
    const result = await rawResponseClient('compliance/onboarding');
    if (!result.ok) {
        return Promise.reject();
    }
    const text = await result.response.text();
    const data = text ? JSON.parse(text) : null;
    return { centers: data, code: result.response.status };
};

export const finishOnboarding = async (centers: AddCenterInput[], staff: AddStaffInput[]) => {
    const input = adaptOnboardingInput(centers, staff);
    return await client(`compliance/onboarding/`, { body: input });
};

const adaptOnboardingInput = (centers: AddCenterInput[], staff: AddStaffInput[]): OnboardingInput => {
    return {
        centers: centers.map((center) => ({
            id: center.id,
            center_name: center.centerName,
            address: center.address,
            city: center.city,
            state: center.state,
            zip_code: center.zipCode,
            program_id: center.programId,
        })),
        staff: staff.map((staff) => ({
            first_name: staff.firstName,
            last_name: staff.lastName,
            email: staff.email,
            phone_number: staff.phoneNumber,
            center_names: staff.centerNames,
        })),
    };
};

export function useGetWorkerComplianceSummary(id: number | string) {
    const exampleWorker = exampleManageWorkerRes.results.find((w) => w.user_id === id);

    const { data, loading, error, refresh } = useClient<ComplianceSummary>({
        url: `compliance/worker/compliance-summary/?user_id=${id}`,
        options: { sendImmediately: !exampleWorker },
    });

    if (exampleWorker) {
        return {
            complianceSummary: exampleWorker.compliance_summary,
            complianceLoading: false,
            complianceError: undefined,
            refreshCompliance: () => {},
        };
    }

    return {
        complianceSummary: data,
        complianceLoading: loading,
        complianceError: error,
        refreshCompliance: refresh,
    };
}

export function useGetRequiredActions(user_id: number | string) {
    const exampleWorker = exampleManageWorkerRes.results.find((w) => w.user_id === user_id);
    const { data, loading } = useClient<ComplianceSummaryEntry[]>({
        url: `compliance/worker/critical-action/?user_id=${user_id}`,
        options: { sendImmediately: !exampleWorker },
    });

    if (exampleWorker) {
        return {
            requiredActions: exampleCriticalActionsById[user_id as keyof typeof exampleCriticalActionsById],
            requiredActionsLoading: false,
        };
    }

    return {
        requiredActions: data,
        requiredActionsLoading: loading,
    };
}

export function useGetWorkerDocuments(user_id: number | string) {
    const exampleWorker = exampleManageWorkerRes.results.find((w) => w.user_id === user_id);
    const { data, loading, error, refresh } = useClient<IProvider>({
        url: `api/providers/${user_id}/`,
        options: { sendImmediately: !exampleWorker },
    });
    // TODO: ADD FAKE WOKER DOCUMENTS

    return {
        documents: data,
        documentsLoading: loading,
        documentsError: error,
        refreshDocuments: refresh,
    };
}

export const getSubscriptionStatus = async () => {
    const result: SubscriptionEvent[] = await client('compliance/subscription-event/');
    const latestEvent = result?.shift();
    let status: SubscriptionStatus = 'Inactive';

    if (latestEvent && latestEvent.event !== 'Cancel') {
        status = 'Active';
    }

    return status;
};

export const startFreeTrial = () => {
    const eventType: SubscriptionEventType = 'Credit';
    const expiration = addDays(new Date(), 14);

    return client('compliance/subscription-event/', {
        body: { event_type: eventType, expires_at: expiration },
    });
};

export const complianceAppIsVisible = (user: UserContextUser | undefined) => {
    if (!user) {
        return false;
    }
    const ohioRegionIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 76];
    return ohioRegionIds.includes(user.region_info.area_id);
};
