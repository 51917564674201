import React from 'react';

import { Radio, styled } from '@mui/material';
import { Colors, Text } from 'library';
import { format12HourTime, formatShortMonthDayYear } from 'shared/Dates';

import type { LeanAppointment } from '../hooks';

export function AppointmentBox({
    appointment,
    selectedAppointment,
    setSelectedAppointment,
}: {
    appointment: LeanAppointment;
    selectedAppointment: number | null;
    setSelectedAppointment: (appt: LeanAppointment) => void;
}) {
    return (
        <Appointment key={appointment.id} onClick={() => setSelectedAppointment(appointment)}>
            <AppointmentTopRow>
                <Text variant="body2" bold textStyle={{ alignSelf: 'center', paddingLeft: 5, paddingTop: 5 }}>
                    {appointment.id}
                </Text>
                <Radio
                    checked={appointment.id === selectedAppointment}
                    onChange={() => setSelectedAppointment(appointment)}
                    style={{ height: 'fit-content', color: Colors.darkNavy }}
                />
            </AppointmentTopRow>
            <AppointmentMiddleRow>
                <Text variant="body2">{formatShortMonthDayYear(appointment.start_date)}</Text>
            </AppointmentMiddleRow>
            <AppointmentBottomRow>
                <Text variant="body2">
                    {format12HourTime(appointment.start_date, appointment.timezone)}-
                    {format12HourTime(appointment.end_date, appointment.timezone, true)}
                </Text>
            </AppointmentBottomRow>
        </Appointment>
    );
}

const Appointment = styled('div')({
    borderRadius: 5,
    backgroundColor: Colors.white,
    cursor: 'pointer',
    marginLeft: 5,
    width: 'fit-content',
});

const AppointmentTopRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
});

const AppointmentMiddleRow = styled('div')({
    display: 'flex',
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
});

const AppointmentBottomRow = styled('div')({
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
});
