import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CompactButton, PrimaryButton, SizeableRoundedDialog, Text, TimePicker, Colors } from 'library';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { client, consoleLogInDev } from 'shared';
import moment from 'moment-timezone';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

interface ClockInButtonProps {
    onComplete: () => void;
    appointmentId: number;
    startDate: string;
    providerName: string;
    providerUserId: number;
    timezone: string;
}

export default function ClockInButton({
    onComplete,
    appointmentId,
    startDate,
    providerName,
    providerUserId,
    timezone,
}: ClockInButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <CompactButton onClick={() => setIsModalOpen(true)}>
                <WatchLaterIcon style={{ fontSize: 18 }} />
                Clock in
            </CompactButton>
            <ClockInModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onComplete={() => {
                    setIsModalOpen(false);
                    onComplete();
                }}
                appointmentId={appointmentId}
                startDate={startDate}
                providerName={providerName}
                providerUserId={providerUserId}
                timezone={timezone}
            />
        </>
    );
}

export function ClockInModal({
    open,
    onClose,
    onComplete,
    appointmentId,
    startDate,
    providerName,
    providerUserId,
    timezone,
}: {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
    appointmentId: number;
    startDate: string;
    providerName: string;
    providerUserId: number;
    timezone: string;
}) {
    const [clockInTime, setClockInTime] = useState<moment.Moment | null | undefined>(moment(startDate));
    const [isClockInValid, setIsClockInValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setClockInTime(moment(startDate));
    }, [startDate]);

    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();
    function submit() {
        setLoading(true);
        const clockInIsoString = clockInTime?.toISOString();

        client(`api/hour-report/clock-in/`, {
            method: 'POST',
            body: {
                appointment_id: appointmentId.toString(),
                provider_user_id: providerUserId?.toString(),
                provider_clock_in: clockInIsoString,
                business_clocked_provider_in: clockInIsoString,
            },
        })
            .then(onComplete)
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage(error.message || 'There was an error clocking the provider in');
            })
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    function onClockInChange(value: moment.Moment | null) {
        setIsClockInValid(value?.isValid() || false);
        setClockInTime(moment(value));
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Clock {providerName} in</Text>
                <Text variant="body1" bold>
                    Ensure {providerName} is clocked in by entering their arrival time below.
                </Text>
                <Grid item>
                    <TimePicker
                        value={clockInTime}
                        onChange={onClockInChange}
                        label="Clock in time"
                        timezone={timezone}
                        sx={{ maxWidth: 200 }}
                    />
                </Grid>
                <PrimaryButton
                    disabled={loading || !clockInTime || !isClockInValid}
                    loading={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Clock In
                </PrimaryButton>
                <Text textStyle={{ color: Colors.error }}>{errorMessage}</Text>
            </Grid>
        </SizeableRoundedDialog>
    );
}
