import React, { useEffect, useState } from 'react';
import { Tabs } from 'library';
import { AdminProvider } from 'models';
import ProfileInfo from './components/ProfileInfo/ProfileInfo';
import WorkerHistory from './components/WorkerHistory/WorkerHistory';
import ComplianceProgress from './components/ComplianceProgress/ComplianceProgress';
import Matching from './components/Matching';
import WorkerShifts from './components/WorkerShifts/WorkerShifts';

export default function ProviderTabs({
    providerUserId,
    provider,
    userRefreshError,
    refreshProvider,
}: {
    providerUserId?: number;
    provider?: AdminProvider;
    userRefreshError: string;
    refreshProvider: () => void;
}) {
    const [selectedTab, setSelectedTab] = useState(!provider?.ready_to_work ? '4' : '0');
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized && provider) {
            setSelectedTab(!provider?.ready_to_work ? '4' : '0');
            setInitialized(true);
        }
    }, [provider]);

    const tabs = ['Profile', 'History', 'Matching', 'Shifts', 'Compliance Progress'];
    const values = [
        <ProfileInfo
            key="Profile"
            provider={provider}
            refreshProvider={refreshProvider}
            userRefreshError={userRefreshError}
        />,
        <WorkerHistory key="History" providerUserId={providerUserId} />,
        <Matching key="Matching" provider={provider} refreshProvider={refreshProvider} />,
        <WorkerShifts key="Shifts" providerUserId={providerUserId} />,
        <ComplianceProgress key="ComplianceProgress" providerUserId={providerUserId} />,
    ];

    return <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} values={values} />;
}
