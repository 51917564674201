import React, { useState } from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';
import { isAfter } from 'date-fns';
import { formatMonthYearWith12HourTime } from 'shared/Dates';
import { useHistory } from 'react-router';

import ProviderReviewModal from 'parent-portal/home/providerReivewModal/ProviderReviewModal';
import { BasicCard } from './BasicCard';
import { WorkerTab } from './WorkerTab';

import type { TProviderRecentlyWorked } from 'parent-portal/home/new/types';

type IAppointment = TProviderRecentlyWorked['appointments'][0];

export function Reviews({
    loading,
    small,
    workers,
}: {
    loading: boolean;
    small: boolean;
    workers?: TProviderRecentlyWorked[];
}) {
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);

    function lastWorked(appointments: IAppointment[]) {
        const now = new Date();

        const lastWorkedAppoinment = appointments.reduce((acc: IAppointment, curr: IAppointment) => {
            const date = new Date(curr.start_date);
            return !isAfter(date, now) && isAfter(new Date(acc.start_date), date) ? curr : acc;
        }, appointments[0]);

        return lastWorkedAppoinment
            ? formatMonthYearWith12HourTime(lastWorkedAppoinment.start_date, lastWorkedAppoinment.timezone)
            : 'Unknown';
    }

    if (!workers || workers.length === 0) return null;

    return (
        <BasicCard loading={loading} small={small} right={true}>
            <Row>
                <Text variant="h1">Reviews</Text>
                <ViewAll onClick={() => setOpen(true)}>View All</ViewAll>
            </Row>
            <Body small={small.toString()}>
                {workers.map((worker: TProviderRecentlyWorked, index: number) => (
                    <WorkerTab
                        key={worker.id}
                        worker={{
                            user: worker.user,
                            profile_image: worker.profile_image,
                            middle: (
                                <>
                                    <b>Last Worked:</b> {lastWorked(worker.appointments)}
                                </>
                            ),
                        }}
                        small={small}
                        onClick={() => history.push(`/providers/review/${worker.user.id}`)}
                        style={{ marginRight: index % 2 === 0 ? '2%' : 0 }}
                    />
                ))}
            </Body>
            <ProviderReviewModal workers={workers} open={open} onCloseModal={() => setOpen(false)} />
        </BasicCard>
    );
}

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: 'space-between',
    width: '100%',
});

const Body = styled('div')(({ small }: { small: string }) => ({
    display: 'flex',
    flexDirection: small === 'true' ? 'column' : 'row',
    flexWrap: 'wrap',
    width: '100%',
}));

const ViewAll = styled('div')({
    textDecorationLine: 'underline',
    cursor: 'pointer',
    fontSize: 14,
    fontFamily: 'Outfit',
    color: Colors.darkNavy,
    '&:hover': {
        color: Colors.darkGrey,
    },
});
