import React, { ReactElement, ReactNode } from 'react';
import PageHeader from './PageHeader';

export default function ModalPage({
    children,
    iconSource,
    headerText,
    subtitleText,
    showHeader,
}: {
    children?: ReactNode | ReactNode[];
    iconSource?: ReactElement;
    headerText?: ReactNode;
    subtitleText?: ReactNode;
    showHeader: boolean;
}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {showHeader && <PageHeader iconSource={iconSource} headerText={headerText} subtitleText={subtitleText} />}
            {children}
        </div>
    );
}
