import React from 'react';
import { Colors, Text } from 'library';
import { Rating as MuiRating } from '@mui/material';
import { StarOutlineRounded, StarRounded } from '@mui/icons-material';
import { PaddedRow } from './StyledComponents';

export default function Rating({ reviews }: { reviews: { average_score: number; total_reviews: number } | null }) {
    return reviews?.average_score && reviews.average_score > 3 ? (
        <PaddedRow>
            <MuiRating
                name="read-only"
                value={reviews.average_score}
                readOnly
                icon={<StarRounded style={{ height: 32, width: 32, color: '#ffe200' }} />}
                emptyIcon={<StarOutlineRounded style={{ height: 32, width: 32, color: Colors.disabledGrey }} />}
                precision={0.1}
            />
            <Text variant="body2" textStyle={{ paddingLeft: 5 }}>
                ({reviews.total_reviews})
            </Text>
        </PaddedRow>
    ) : (
        <PaddedRow>
            <Text textStyle={{ lineHeight: '24pt' }}>No reviews yet!</Text>
        </PaddedRow>
    );
}
