import { Checkbox, Colors, Text } from 'library';
import React from 'react';
import TeacherCardBaseHeader from 'parent-portal/providers/cards/shared/TeacherCardBaseHeader';
import { ITeacher } from 'models';
import { ApplicantApproval } from '../models';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { Grid } from '@mui/material';

export default function ApplicantApprovalCardHeader({
    applicant,
    selected,
    onChange,
}: {
    applicant: ApplicantApproval;
    selected: boolean;
    onChange: (choice: ITeacher) => void;
}) {
    const firstDay = applicant.days_applied[0];
    const additionalDaysCount = applicant.days_applied.length - 1;
    return (
        <TeacherCardBaseHeader
            color={applicant.approved_at ? Colors.lightGrey : Colors.lightRed}
            worker={applicant.applied_user}
            leftHeaderComponent={
                <Grid>
                    <Text variant="caption" bold>
                        Requested Days:
                    </Text>
                    <>
                        <Text variant="caption">{formatShortWeekdayMonthDay(firstDay.start)}</Text>
                        {additionalDaysCount > 0 ? (
                            <Text
                                variant="caption"
                                textStyle={{ lineHeight: 1 }}
                            >{` and ${additionalDaysCount} more`}</Text>
                        ) : null}
                    </>
                </Grid>
            }
            rightHeaderComponent={
                applicant.approved_at ? null : (
                    <Checkbox
                        label=""
                        labelTitle="Select this teacher"
                        checked={selected}
                        onChange={() => onChange(applicant.applied_user)}
                        style={{ width: 'auto', marginRight: 10 }}
                        checkboxStyle={{ backgroundColor: Colors.white }}
                    />
                )
            }
        />
    );
}
