import { Grid } from '@mui/material';
import React from 'react';
import { DownloadTheAppButtons, Text } from 'library';

export default function RegistrationSuccessScreen() {
    return (
        <Grid item container xs={12} justifyContent="center" style={{ height: 'fit-content', gap: 20 }}>
            <Text variant="h1">Thank you for registering!</Text>
            <Text>
                To finish setting up your account, please download the Tandem app from the App Store or Google Play
                Store.
            </Text>
            <Text>
                Once you have downloaded the app, please log in using the email and password you just created. You will
                go through the rest of the signup flow there and be redirected to the steps to become fully compliant.
            </Text>
            <Text>
                Please remember to turn on notifications so you get important updates about your account and compliance
                status.
            </Text>
            <DownloadTheAppButtons />
        </Grid>
    );
}
