import { Grid, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Text, Colors } from 'library';

export default function PasswordInput({
    placeHolder,
    value,
    onChange,
    error,
    errorText,
}: {
    placeHolder: string;
    value: string;
    onChange: (value: string) => void;
    error: boolean;
    errorText: string;
}) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Grid container item direction="column" xs={12} md={5}>
            <TextField
                type={showPassword ? 'text' : 'password'}
                placeholder={placeHolder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" onClick={() => setShowPassword((prev) => !prev)}>
                            {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </InputAdornment>
                    ),
                }}
            />
            {error && errorText && (
                <Text variant="caption" color={Colors.red}>
                    {errorText}
                </Text>
            )}
        </Grid>
    );
}
