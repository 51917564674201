import React from 'react';
import { Colors } from 'library';
import { Grid, styled } from '@mui/material';
import { differenceInMinutes } from 'date-fns';

import ShiftCardMenu from '../../BusinessCalendar/ShiftCard/ShiftCardMenu';
import { BusinessSlotHeader } from './BusinessSlotHeader';
import { BusinessSlotSchedule } from './BusinessSlotSchedule';
import { BusinessSlotProgressDetails } from './BusinessSlotProgressDetails';

import { BusinessSlot, ShiftJob } from 'models';
import type { BusinessJobResponse } from '../models';

export function BusinessJobShiftSlot({
    slot,
    job,
    getJob,
    md = 4,
    businessJobType,
}: {
    slot: BusinessSlot;
    job: BusinessJobResponse['job_details'] | ShiftJob;
    getJob: () => void;
    md?: number;
    businessJobType?: string;
}) {
    const appt = slot.appointments.length ? slot.appointments[0] : undefined;
    const shiftProvider = appt && appt.providers.length ? appt.providers[0] : undefined;
    const provider = shiftProvider?.provider;
    const provider_id = shiftProvider?.provider_id;
    const status = appt?.providers?.[0]?.status ?? 'UNFILLED';
    const minutesSincePosted = differenceInMinutes(new Date(), new Date(slot.added_at)) ?? 0;
    const recruitmentStatuses = [
        { title: 'Shift Posted', show: !provider && minutesSincePosted < 5 },
        {
            title: 'Actively Recruiting',
            show: !provider && minutesSincePosted > 5,
        },
        { title: 'Worker Booked', show: !!provider },
    ];

    return (
        <CardContainer key={slot.id} item container direction="column" xs={12} md={md}>
            <Grid item container direction={'row'} alignItems="center" justifyContent="space-between">
                <BusinessSlotHeader provider={provider} headline={job.headline} businessJobType={businessJobType} />
                <Grid
                    xs={1}
                    container
                    item
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ paddingRight: 10 }}
                >
                    <ShiftCardMenu
                        provider_id={provider_id}
                        provider={provider ?? undefined}
                        slot={slot}
                        ongoing_request={slot.ongoing_request}
                        status={status}
                        refresh={getJob}
                        filed718bAutomatically={shiftProvider?.filed_718b_automatically}
                    />
                </Grid>
            </Grid>
            <BusinessSlotSchedule slot={slot} job={job} provider={provider} recruitmentStatuses={recruitmentStatuses} />
            <BusinessSlotProgressDetails
                appt={appt}
                recruitmentStatuses={recruitmentStatuses}
                shiftProvider={shiftProvider}
            />
        </CardContainer>
    );
}

const CardContainer = styled(Grid)({
    backgroundColor: Colors.lightTurq,
    padding: 20,
    borderRadius: 18,
    boxShadow: '0px 3px 6px 0px #00000029',
});
