import React from 'react';
import { Text, Colors } from 'library';
import { ManagedWorker } from '../types';
import { Tooltip } from '@mui/material';
import NavyLogo from 'assets/images/tandem-blocks-logo.svg';

interface Props {
    worker: ManagedWorker;
}

const WorkerNameWithIcon = ({ worker }: Props) => {
    const fullName = `${worker.first_name} ${worker.last_name}`;

    const TandemIcon = () => {
        if (!worker.compliance_enrollment_id) {
            return (
                <Tooltip title="Tandem worker on your schedule" placement="top">
                    <div>
                        <NavyLogo style={{ height: 16, width: 16 }} />
                    </div>
                </Tooltip>
            );
        } else {
            return <div style={{ width: 16, height: 16 }} />;
        }
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TandemIcon />
            <Text
                variant="body2"
                color={Colors.darkNavy}
                textStyle={{
                    fontSize: 16,
                    marginLeft: 8,
                }}
            >
                {fullName}
            </Text>
        </div>
    );
};

export default WorkerNameWithIcon;
