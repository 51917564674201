import React from 'react';
import { LoadingSpinner, Text, Colors, SmallIcon } from 'library';
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import { useClient } from 'shared';

import type { SelectChangeEvent } from '@mui/material';
import type { IBusinessLocationWithNotes, RegionAdmin, RegionAdminUser } from 'models';

export function CustomerServiceContact({ businessLocation }: { businessLocation?: IBusinessLocationWithNotes }) {
    const { data, error, loading, send } = useClient<{ customer_service_contact: RegionAdminUser }>({
        url: `api/business-location/${businessLocation?.id}/`,
        method: 'PATCH',
        options: { sendImmediately: false },
    });

    const {
        data: admins,
        error: contactsError,
        loading: contactsLoading,
    } = useClient<RegionAdmin[]>({
        url: `internal/region-admin/`,
    });

    const retrievedContact = { ...(businessLocation ?? {}), ...(data ?? {}) }?.customer_service_contact;
    const currentContact: { key: number; value: string } | null = retrievedContact
        ? {
              key: retrievedContact.id,
              value: `${retrievedContact.first_name} ${retrievedContact.last_name}`,
          }
        : {
              key: 0,
              value: 'Default Region Admin',
          };
    const contacts: { key: number; value: string }[] =
        admins?.map((contact: RegionAdmin) => ({
            key: contact.user.id,
            value: `${contact.user.first_name} ${contact.user.last_name}`,
        })) ?? [];

    if (loading) return <LoadingSpinner />;

    return (
        <FormControl fullWidth style={{ marginBottom: 20 }}>
            <InputLabel htmlFor="customer-service-label">Admin</InputLabel>
            <Select
                variant="outlined"
                onChange={(event: SelectChangeEvent<number>) =>
                    send({
                        body: { customer_service_contact_id: event.target.value === 0 ? null : event.target.value },
                        method: 'PATCH',
                    })
                }
                value={currentContact?.key}
                style={{ minWidth: '30%', maxWidth: '50%' }}
                renderValue={() => <Text>{currentContact ? `${currentContact.value}` : 'None Set'}</Text>}
                multiple={false}
                label="customer-service-label"
                id="customer-service-select"
                disabled={contactsLoading}
            >
                {[{ key: 0, value: 'Default Region Admin' }, ...contacts]?.map(
                    ({ key, value }: { key: number; value: string }) => (
                        <MenuItem key={key} value={key}>
                            {key === currentContact?.key ? (
                                <SmallIcon
                                    icon={<CheckBox />}
                                    style={{ color: Colors.darkNavy, backgroundColor: 'transparent' }}
                                />
                            ) : (
                                <SmallIcon
                                    icon={<CheckBoxOutlineBlank />}
                                    style={{ color: Colors.darkNavy, backgroundColor: 'transparent' }}
                                />
                            )}
                            <Text>{value}</Text>
                        </MenuItem>
                    ),
                )}
            </Select>
            {!!contactsError && (
                <Text variant="caption" color={Colors.error}>
                    There was a problem getting other possible admins.
                </Text>
            )}
            {!!error && (
                <Text variant="caption" color={Colors.error}>
                    There was a problem retrieving/updating the admin.
                </Text>
            )}
        </FormControl>
    );
}
