import React from 'react';
import { Avatar, Card, styled } from '@mui/material';
import { Colors, IconButton, Text } from 'library';
import { Create } from '@mui/icons-material';

import type { IWorkerTab } from '../types';

export function WorkerTab({
    worker,
    small,
    onClick,
    style,
    action,
}: {
    worker: IWorkerTab;
    small: boolean;
    onClick: () => void;
    style?: React.CSSProperties;
    action?: React.ReactNode;
}) {
    return (
        <StyledCard small={small.toString()} style={{ ...(style ?? {}) }}>
            <Row>
                <Row>
                    <WorkerAvatar src={worker.profile_image?.image} />
                    <Info>
                        <Text variant="body1">
                            {worker.user.first_name} {worker.user.last_name}
                        </Text>
                        {!!worker.middle && (
                            <Text variant="caption" textStyle={{ paddingRight: 5 }}>
                                {worker.middle}
                            </Text>
                        )}
                        {!!worker.bottom && (
                            <Text variant="caption" textStyle={{ paddingRight: 5 }}>
                                {worker.bottom}
                            </Text>
                        )}
                    </Info>
                </Row>
                {action ?? (
                    <IconButton
                        icon={Create}
                        backgroundColor={Colors.darkNavy}
                        color={Colors.white}
                        onClick={onClick}
                    />
                )}
            </Row>
        </StyledCard>
    );
}

const StyledCard = styled(Card)(({ small }: { small: string }) => ({
    border: `1px solid ${Colors.turquoise}`,
    borderRadius: 10,
    marginBottom: 10,
    padding: 10,
    width: small === 'true' ? '100%' : '49%',
}));

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const Info = styled('div')({
    marginLeft: 10,
});

const WorkerAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'inline-flex',
});
