import { Grid, styled } from '@mui/material';
import { SizeableRoundedDialog, Colors, Text, PrimaryButton } from 'library';
import React from 'react';
import { useHistory } from 'react-router';
import { formatCurrency } from 'shared';

export default function PaymentStatusModal({
    isOpen,
    onClose,
    paymentStatus,
    amountDue,
}: {
    isOpen: boolean;
    onClose: () => void;
    paymentStatus?: string;
    amountDue?: number;
}) {
    const history = useHistory();

    const linkToPayments = () => {
        onClose();
        history.push('/payments');
    };

    const text = (() => {
        if (paymentStatus === 'BLOCKED') {
            return {
                title: 'Services Paused Due to Repeat Failed Payments!',
                topText: 'You are no longer able to post jobs and active recruitment for any jobs have been paused.',
                bottomText: 'To resume service please go update your payment method and retry payment.',
            };
        }

        return {
            title: 'Oh no! Your payment failed.',
            topText: "To avoid interuption of Tandem's services please update your payment method and retry payment.",
            bottomText:
                'Unresolved payments will lead to an inability to post jobs and active recruitment will stop on any previous posted jobs.',
        };
    })();

    return (
        <SizeableRoundedDialog closeButton open={isOpen} onClose={onClose} style={{ backgroundColor: Colors.lightRed }}>
            <Container container direction="column">
                <Text variant="h1">{`${text.title} `}</Text>
                {amountDue && (
                    <Text variant="body1" bold textStyle={{ color: Colors.error }}>
                        You have an outstanding balance of {formatCurrency(amountDue)}.
                    </Text>
                )}
                <Text variant="body1" bold>
                    {text.topText}
                </Text>
                <Text variant="body1" bold>
                    {text.bottomText}
                </Text>

                <Text variant="caption">Note: Reprocessing payments may take 3-5 days to verify.</Text>
                <PrimaryButton onClick={linkToPayments} buttonStyle={{ width: 'fit-content', alignSelf: 'flex-end' }}>
                    Update Payment & Retry
                </PrimaryButton>
            </Container>
        </SizeableRoundedDialog>
    );
}

const Container = styled(Grid)({
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    gap: 10,
});
