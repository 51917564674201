import { Text, Colors } from 'library';
import { SlimEmploymentHistory } from 'models';
import React from 'react';
import { formatMonthYear } from 'shared/Dates';
import { Check } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export default function PreviousChildcareCenterJobExperience({ employment }: { employment: SlimEmploymentHistory[] }) {
    return (
        <div style={{ borderRadius: 25, backgroundColor: Colors.lightTurq }}>
            <div style={{ marginTop: '3%', padding: 20 }}>
                <Text color={Colors.turquoise} variant="body1" bold textStyle={{ letterSpacing: 3.2 }}>
                    EMPLOYMENT HISTORY
                </Text>
                {employment.length > 0 ? (
                    employment.map((e, idx) => {
                        const showVerified = e.verified_at && e.type !== 'Alternate History';
                        return (
                            <div key={idx} style={{ marginTop: '2%', marginBottom: '2%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    {showVerified && (
                                        <Tooltip title={'Verified'} placement="left">
                                            <div style={{ color: Colors.turquoise }}>
                                                <Check />
                                            </div>
                                        </Tooltip>
                                    )}
                                    <Text>
                                        <b>{e.title}</b> {e.start_date ? formatMonthYear(e.start_date) : ''}
                                        {e.end_date ? ` - ${formatMonthYear(e.end_date)}` : ''}
                                    </Text>
                                </div>
                                <Text variant="body2">{e.employer}</Text>
                                <Text variant="body2">{e.description}</Text>
                            </div>
                        );
                    })
                ) : (
                    <Text variant="caption">
                        No employment reported on file. This doesn&apos;t mean they have no previous employment. It
                        means they haven&apos;t added it to their Tandem profile yet!
                    </Text>
                )}
            </div>
        </div>
    );
}
