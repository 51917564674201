import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useUserContext } from 'UserContext';
import { Text, Colors, PrimaryButton, LoadingIndicator, DownloadTheAppButtons } from 'library';
import { client, useClient, validatePassword } from 'shared';
import PasswordInput from './PasswordInput';
import { ComplianceEnrollment } from '../types';

export default function RegisterWithLocation({ setPasswordChanged }: { setPasswordChanged: (v: boolean) => void }) {
    const { user } = useUserContext();
    const [loading, setLoading] = useState(false);
    const welcomeMessage = user?.first_name ? `Welcome ${user.first_name}!` : 'Welcome!';
    const [formFields, setFormFields] = useState({
        password: '',
        confirmPassword: '',
    });
    function updateFormField(field: string, value: string) {
        setFormFields((prev) => ({ ...prev, [field]: value }));
    }

    const {
        data,
        loading: enrollmentLoading,
        error,
    } = useClient<ComplianceEnrollment>({
        url: 'compliance/worker/compliance-enrollment/',
    });

    async function submitPassword() {
        try {
            setLoading(true);
            await client('change-password', {
                method: 'PATCH',
                body: {
                    password: formFields.password,
                },
            });
            await client(`compliance/worker/compliance-enrollment/${data?.id}`, {
                method: 'PATCH',
                body: {
                    enrolled_at: new Date(),
                },
            });
            setPasswordChanged(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            alert('Error setting password, please try again.');
        }
    }
    if (enrollmentLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '95vh' }}>
                <LoadingIndicator />
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '95vh' }}>
                <Text variant="h2">Sorry, an error occurred. Please try again later.</Text>
            </Grid>
        );
    }

    if (!data) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '95vh' }}>
                <Text variant="h2">
                    We were unable to find a center for your registration. If you are interested in joining Tandem, or
                    if you have already confirmed via this page, please download the app below.
                </Text>
                <Grid item container justifyContent="center">
                    <DownloadTheAppButtons />
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid item container xs={12} justifyContent="center" style={{ height: 'fit-content', gap: 20 }}>
            <Text variant="h1">Worker Registration</Text>
            <Grid container item xs={12} style={{ height: 'fit-content', gap: 20 }}>
                <Text bold variant="h2">
                    {welcomeMessage}
                </Text>
                <Text variant="body1">
                    {data?.business_location.name} uses Tandem to manage their compliance and has added you as a staff
                    member. Please complete the registration process by setting a password below. If you have any
                    questions or need help, please contact your administrator or email us at{' '}
                    <a href="mailto:support@join-tandem.com" style={{ color: Colors.darkNavy }}>
                        support@join-tandem.com
                    </a>
                    .
                </Text>

                <Text>Email: {user?.email}</Text>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    style={{ overflowWrap: 'anywhere', gap: 20 }}
                >
                    <PasswordInput
                        placeHolder="Password"
                        value={formFields.password}
                        onChange={(value) => {
                            updateFormField('password', value);
                        }}
                        error={!!(formFields.password && !validatePassword(formFields.password))}
                        errorText={'Password must be at least 8 characters'}
                    />
                    <PasswordInput
                        placeHolder="Confirm Password"
                        value={formFields.confirmPassword}
                        onChange={(value) => updateFormField('confirmPassword', value)}
                        error={!!(formFields.confirmPassword && formFields.password !== formFields.confirmPassword)}
                        errorText="Passwords do not match"
                    />
                </Grid>
                <Grid xs={12} item container justifyContent="flex-end">
                    <PrimaryButton
                        disabled={
                            !formFields.password ||
                            !validatePassword(formFields.password) ||
                            formFields.password !== formFields.confirmPassword ||
                            loading
                        }
                        onClick={submitPassword}
                        buttonStyle={{ maxWidth: 200 }}
                    >
                        Submit
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
