import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Colors, Text } from 'library';
import { ChevronRightRounded } from '@mui/icons-material';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';

import { Link } from 'react-router-dom';

export function PaymentSetup({ status, refresh }: { status: string; refresh: () => void }) {
    const [openConnectPayment, setOpenConnectPayment] = useState<boolean>(false);

    if (status === 'needs_verified') {
        return (
            <Body>
                <Header>
                    <Text variant="h1" bold>
                        Finish Account Set Up
                    </Text>
                    <Text variant="caption">2/2</Text>
                </Header>
                <Row to="/payments">
                    <Body>
                        <Header>
                            <Text variant="h2" bold>
                                Complete your Payment Setup
                            </Text>
                            <Text
                                variant="caption"
                                bold
                                textStyle={{
                                    backgroundColor: Colors.error,
                                    color: Colors.white,
                                    padding: '0px 10px',
                                    borderRadius: 20,
                                }}
                            >
                                Required
                            </Text>
                        </Header>
                        <Text variant="caption">Verify your account to complete payment setup.</Text>
                    </Body>
                    <ChevronRightRounded htmlColor={Colors.darkNavy} style={{ height: 36, width: 36 }} />
                </Row>
            </Body>
        );
    }

    if (status === 'needs_payment' || status === 'need_setup') {
        return (
            <Body>
                <Header>
                    <Text variant="h1" bold>
                        Finish Account Set Up
                    </Text>
                    <Text variant="caption">1/2</Text>
                </Header>
                <ModalRow onClick={() => setOpenConnectPayment(true)}>
                    <Body>
                        <Header>
                            <Text variant="h2" bold>
                                Add a Payment Method
                            </Text>
                            <Text
                                variant="caption"
                                bold
                                textStyle={{
                                    backgroundColor: Colors.error,
                                    color: Colors.white,
                                    padding: '0px 10px',
                                    borderRadius: 20,
                                }}
                            >
                                Required
                            </Text>
                        </Header>
                        <Text variant="caption">Set up a primary payment method on your account.</Text>
                    </Body>
                    <ChevronRightRounded htmlColor={Colors.darkNavy} style={{ height: 36, width: 36 }} />
                </ModalRow>
                <ConnectPaymentModal
                    isOpen={openConnectPayment}
                    onClose={() => {
                        setOpenConnectPayment(false);
                        refresh();
                    }}
                />
            </Body>
        );
    }

    return null;
}

const Header = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
});

const Body = styled('div')({
    height: '100%',
    width: '100%',
});

const Row = styled(Link)({
    alignItems: 'center',
    backgroundColor: Colors.lightRed,
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    padding: 15,
    width: '100%',
    '&:hover': {
        backgroundColor: Colors.lightGrey,
    },
});

const ModalRow = styled('div')({
    alignItems: 'center',
    backgroundColor: Colors.lightRed,
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    padding: 15,
    width: '100%',
    '&:hover': {
        backgroundColor: Colors.lightGrey,
        textDecorationLine: 'underline',
    },
});
