import { MenuItem, Select } from '@mui/material';
import React from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Colors, Text } from 'library';

const CenterSelect = ({
    centers,
    selectedCenters,
    setSelectedCenters,
}: {
    centers: string[];
    selectedCenters?: string[];
    setSelectedCenters: (value: string[]) => void;
}) => {
    if (centers.length === 0) {
        return <></>;
    }

    const updateSelectedCenters = (event: SelectChangeEvent<string | string[]>) => {
        const value = event.target.value;
        const chosenCenters = typeof value === 'string' ? value.split(',') : value;
        const updatedCenters = centers.filter((x) => chosenCenters.includes(x));
        setSelectedCenters(updatedCenters);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
            <Text variant="caption" color={Colors.darkNavy}>
                {'Select Locations'}
            </Text>
            <Select
                multiple={true}
                value={selectedCenters}
                onChange={updateSelectedCenters}
                style={{ height: 40, marginBottom: 20, width: 300 }}
                MenuProps={{
                    MenuListProps: {
                        sx: {
                            'li.MuiButtonBase-root': {
                                display: 'flex',
                                flexDirection: 'column',
                            },
                        },
                    },
                }}
            >
                {centers.map((it, i) => (
                    <MenuItem key={i} value={it}>
                        {it}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default CenterSelect;
