import React from 'react';
import { styled } from '@mui/material';
import { Colors, Text } from 'library';
import { ChevronRightRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import type { DashboardSummary } from '../types';

export function FillInCenterDetails({ data }: { data: DashboardSummary }) {
    const centers = data.centers_details.filter((center) => center.progress < 100);
    const centerText = centers.length === 1 ? 'your center' : `${centers.length} of your centers`;

    return (
        <CardBody>
            <Inner>
                <Text variant="h1" bold>
                    Finish Account Set Up
                </Text>
                <Action to="/manage-centers">
                    <Body>
                        <Text variant="h2" bold>
                            Add Center Information
                        </Text>
                        <Text variant="caption">
                            It looks like {centerText} could use some more details to ensure Tandem teachers are set up
                            for success.
                        </Text>
                    </Body>
                    <ChevronRightRounded htmlColor={Colors.darkNavy} style={{ height: 36, width: 36 }} />
                </Action>
            </Inner>
        </CardBody>
    );
}

const CardBody = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
});

const Inner = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
});

const Action = styled(Link)({
    alignItems: 'center',
    backgroundColor: Colors.lightRed,
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20,
    padding: 15,
    '&:hover': {
        backgroundColor: Colors.lightGrey,
    },
});

const Body = styled('div')({
    width: '80%',
});
