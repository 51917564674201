import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { HourReportTimestamp } from '../HourReportModels';
import Colors from 'constants/Colors';
import { RoleActions } from './RoleActions';
import { EditTimes } from './EditTimes';
import { UpdatedTimes, orderedTimestampNames } from './TimestampModal';
import moment from 'moment-timezone';

export function TimestampRow({
    index,
    row,
    selectedTimestamps,
    updateTimestamps,
    updatedTimes,
    resolve,
    updateAdminTimes,
    timezone,
    startDate,
}: {
    index: number;
    row: string;
    selectedTimestamps: HourReportTimestamp[] | undefined;
    updateTimestamps: (d: moment.Moment | null, accessor: string) => void;
    updatedTimes: UpdatedTimes;
    resolve: () => void;
    updateAdminTimes: (role: string) => void;
    timezone: string;
    startDate: string;
}): JSX.Element {
    return (
        <TableRow style={{ background: index % 2 === 0 ? '#ffffff' : Colors.lightGrey }} key={row}>
            <TableCell align="right">{row}</TableCell>
            {orderedTimestampNames?.map((timestampName: string) => {
                const type = `${row}_${timestampName}`;
                const timestamp = selectedTimestamps?.find((t) => t.time_type.type === type);
                return (
                    <TableCell align="right" key={type}>
                        <EditTimes
                            accessor={type}
                            currentValue={timestamp?.reported_at}
                            selectedTimestamps={selectedTimestamps}
                            updateTimestamps={updateTimestamps}
                            updatedTimes={updatedTimes}
                            timezone={timezone}
                            startDate={startDate}
                        />
                    </TableCell>
                );
            })}
            <TableCell align="right">
                <RoleActions
                    role={row}
                    times={updatedTimes}
                    resolve={resolve}
                    updateAdminTimes={updateAdminTimes}
                    selectedTimestamps={selectedTimestamps}
                />
            </TableCell>
        </TableRow>
    );
}
