import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useUserContext } from 'UserContext';
import { Text } from 'library';
import { client, consoleLogInDev } from 'shared';
import { IBusinessLocation } from 'models';
import CenterLocations from './CenterLocations';

export default function ManageCenters() {
    const { user } = useUserContext();
    const [business, setBusiness] = useState(user?.businesses_active?.length ? user.businesses_active[0] : null);
    const [businessLocations, setBusinessLocations] = useState<IBusinessLocation[]>([]);
    const [faqQuestions, setFaqQuestions] = useState([]);

    useEffect(() => {
        const updatedBusiness = user?.businesses_active?.length ? user.businesses_active[0] : null;
        setBusiness(updatedBusiness);
        loadProfileData();
    }, [user]);

    function loadProfileData() {
        client('api/business-profile/details/')
            .then((res) => {
                setBusinessLocations(res.locations);
                setFaqQuestions(res.business_faq_questions);
            })
            .catch(consoleLogInDev);
    }

    function refreshBusinessLocations() {
        client('api/business-locations/').then(setBusinessLocations).catch(consoleLogInDev);
    }

    if (!user || !business) return <></>;

    return (
        <Grid container direction="column" justify="center" style={{ marginTop: 40, padding: '0px 10%' }}>
            <Text variant="display">Manage Centers</Text>
            <Grid container item style={{ paddingTop: 40 }} justify="space-between">
                <Grid xs={12} md={9}>
                    <CenterLocations
                        businessLocations={businessLocations}
                        refreshBusinessLocations={refreshBusinessLocations}
                        faqQuestions={faqQuestions}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
