import { Grid } from '@mui/material';
import React from 'react';
import { LoadingSpinner, Text } from 'library';
import UserDetailChip from './UserDetailChip';
import { formatDueDate } from './utils';
import IconByStatus from './IconByStatus';
import { ManagedWorker } from '../types';
import { useGetRequiredActions } from '../api';

export default function RequiredActions({ selectedWorker }: { selectedWorker: ManagedWorker }) {
    const { requiredActions, requiredActionsLoading } = useGetRequiredActions(selectedWorker.user_id);

    if (!requiredActions || requiredActions?.length === 0) {
        return null;
    }

    return (
        <UserDetailChip title="Required Actions">
            <Grid item container direction="row" style={{ gap: 10 }}>
                {requiredActionsLoading ? (
                    <LoadingSpinner />
                ) : (
                    requiredActions.map((action) => (
                        <Grid item container direction="row" alignItems="center" key={action.name} style={{ gap: 10 }}>
                            <IconByStatus status={action.status} />
                            <Grid item container direction="column" xs={10}>
                                <Text key={action.name}>{action.name}</Text>
                                {action.due_date ? (
                                    <Text variant="caption">Due: {formatDueDate(action.due_date)}</Text>
                                ) : null}
                            </Grid>
                        </Grid>
                    ))
                )}
            </Grid>
        </UserDetailChip>
    );
}
