import { TableCell } from '@mui/material';
import Row from 'internal/shared/tables/Row';
import { MilestoneCheckin } from 'internal/substitutes/substituteModels';
import { Text } from 'library';
import React from 'react';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';

export default function TrialRunMilestoneRow({ checkin }: { checkin: MilestoneCheckin }) {
    const addedAt = checkin.history[checkin.history.length - 1].added_at;
    return (
        <Row>
            <TableCell>{checkin.user_flow.name}</TableCell>
            <TableCell>
                {checkin.user.name} ({checkin.user.id})
            </TableCell>
            <TableCell>
                {formatShortWeekdayMonthDay(addedAt)} {format12HourTime(addedAt)}
            </TableCell>
            <TableCell>
                {checkin.history.map((resp) => (
                    <>
                        <Text variant="caption" bold>
                            {resp.user_flow_sequence.user_flow_step.title}
                        </Text>
                        <Text variant="caption">
                            {resp.user_flow_choice.user_flow_action ? 'Action: ' : ''}
                            {resp.user_flow_choice.title}
                            {resp.other_info ? ` - ${resp.other_info}` : ''}
                        </Text>
                    </>
                ))}
            </TableCell>
        </Row>
    );
}
