import React from 'react';
import { Card, styled, useMediaQuery } from '@mui/material';
import { Colors, Text } from 'library';
import { School, StarRounded, WorkRounded } from '@mui/icons-material';

import type { ITeacher } from 'models';
import theme from 'theme';

export default function TeacherCard({
    worker,
    header,
    details,
    footer,
    color,
}: {
    worker: ITeacher;
    header: React.ReactNode;
    details: React.ReactNode;
    footer?: React.ReactNode;
    color?: string;
}) {
    const small = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <StyledCard small={small.toString()} color={color}>
            <MainColumn style={{ height: '45%' }}>
                {header}
                <Column>
                    <Text bold variant="h1">
                        {worker.name}
                    </Text>
                    {details}
                </Column>
            </MainColumn>
            <CredentialsContainer>
                <Credential>
                    <IconCircle>
                        <School htmlColor={Colors.white} style={{ height: 24, width: 24 }} />
                    </IconCircle>
                    <Text textStyle={{ lineHeight: '14pt' }} color={Colors.navy}>
                        {worker.credentials.education.join(', ')}
                    </Text>
                </Credential>
                {worker.credentials.experience && (
                    <Credential>
                        <IconCircle>
                            <WorkRounded htmlColor={Colors.white} style={{ height: 24, width: 24 }} />
                        </IconCircle>
                        <Text textStyle={{ lineHeight: '14pt' }} color={Colors.navy}>
                            {worker.credentials.experience ?? 'New to childcare'}
                        </Text>
                    </Credential>
                )}
                {worker.credentials.highlights && (
                    <Credential>
                        <IconCircle>
                            <StarRounded htmlColor={Colors.white} style={{ height: 24, width: 24 }} />
                        </IconCircle>
                        <Text textStyle={{ lineHeight: '14pt' }} color={Colors.navy}>
                            {worker.credentials.highlights.join(', ')}
                        </Text>
                    </Credential>
                )}
                {footer}
            </CredentialsContainer>
        </StyledCard>
    );
}

const StyledCard = styled(Card)(({ small, color }: { small: string; color?: string }) => ({
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: small === 'true' ? 0 : 20,
    marginBottom: small === 'true' ? 20 : 0,
    width: small === 'true' ? '100%' : '350px',
    backgroundColor: color,
}));

const Column = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
});

const MainColumn = styled(Column)({
    height: '45%',
    marginBottom: 20,
});

const CredentialsContainer = styled('div')({
    backgroundColor: '#EFEFEF',
    justifyContent: 'flex-start',
    paddingBottom: 20,
    paddingLeft: 30,
    paddingTop: 10,
});

const Credential = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    maxHeight: 56,
});

const IconCircle = styled('div')({
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: Colors.mediumNavy,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
});
