import React from 'react';
import { Colors, Text, TimePicker } from 'library';
import { styled } from '@mui/material';
import moment from 'moment-timezone';

export function SelectTimes({
    workerStart,
    setWorkerStart,
    workerEnd,
    setWorkerEnd,
    timezone,
}: {
    workerStart: moment.Moment | null;
    setWorkerStart: (date: moment.Moment | null) => void;
    workerEnd: moment.Moment | null;
    setWorkerEnd: (date: moment.Moment | null) => void;
    timezone: string;
}) {
    return (
        <div>
            <Text>Select the times the worker worked:</Text>
            <Row>
                <Container>
                    <TimePicker value={workerStart} onChange={setWorkerStart} timezone={timezone} />
                </Container>
                <Container>
                    <TimePicker value={workerEnd} onChange={setWorkerEnd} timezone={timezone} />
                </Container>
            </Row>
            {workerStart && workerEnd && workerStart > workerEnd && (
                <Text variant="body2" textStyle={{ color: Colors.error, paddingLeft: 5, paddingTop: 10 }}>
                    Start time should be before end time
                </Text>
            )}
        </div>
    );
}

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
});

const Container = styled('div')({
    display: 'flex',
    paddingLeft: 5,
    paddingRight: 5,
});
