import React, { useState } from 'react';
import { useUserContext } from 'UserContext';
import { Colors } from 'library';
import { useWidth } from 'reusableComponents/useWidth';
import { track } from 'shared';
import { Grid } from '@material-ui/core';
import DefaultProfileImage from 'assets/icons/Profile.svg';
import { useNotificationContext } from 'parent-portal/notifications/NotificationContext/NotificationContext';
import AccountMenu from './AccountMenu';
import BadgedNavItem from '../BadgedNavItem';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

export const ProfilePicInNav = () => {
    const { user } = useUserContext();
    const breakpoints = useWidth();
    const smallScreen = ['xs', 'sm'].includes(breakpoints);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { unacknowledgedCount } = useNotificationContext();
    const { trackSession } = usePublicSessionTracking('Top Navigation - Profile Picture');

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
        track(`Profile dropdown menu: ${!open ? 'opened' : 'closed'}`);
        trackSession('CLICK', `Dropdown menu: ${!open ? 'Open' : 'Close'}`, PublicSessionEventType.User);
    };

    return (
        <div style={{ marginRight: smallScreen ? 12 : 20 }}>
            <BadgedNavItem count={unacknowledgedCount}>
                <Grid container direction="row" justify="space-between" alignItems="center" alignContent="center">
                    {!smallScreen ? (
                        <div
                            style={{
                                borderLeft: `1px solid ${Colors.white}`,
                                height: 46,
                                marginRight: 20,
                                borderRadius: 50,
                            }}
                        ></div>
                    ) : null}
                    {user?.profile_picture?.image ? (
                        <img
                            alt="profile"
                            onClick={handleClick}
                            src={user.profile_picture.image}
                            id="profile-pic"
                            style={{
                                width: smallScreen ? 35 : 40,
                                height: smallScreen ? 35 : 40,
                                borderRadius: '50%',
                                objectFit: 'cover',
                                marginRight: 10,
                                cursor: 'pointer',
                            }}
                        />
                    ) : (
                        <DefaultProfileImage
                            id="profile-pic"
                            onClick={handleClick}
                            width={smallScreen ? 35 : 40}
                            height={smallScreen ? 35 : 40}
                        />
                    )}
                </Grid>
                <AccountMenu open={open} anchorEl={anchorEl} onClose={() => setOpen(false)} />
            </BadgedNavItem>
        </div>
    );
};
