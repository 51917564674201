import React, { useState } from 'react';
import { PointDetailResponse } from '../types';
import { BasicDialog, Tabs } from 'library';
import { EditPointsContent } from './EditPointsContent';
import PointHistory from './PointHistory';

export function ManagePointValueModal({
    open,
    onClose,
    pointDetails,
    userId,
}: {
    open: boolean;
    onClose: () => void;
    pointDetails: PointDetailResponse;
    userId: number;
}) {
    const [selectedTab, setSelectedTab] = useState('0');

    const tabs = ['Edit', 'History'];
    const values = [
        <EditPointsContent key="Profile" pointDetails={pointDetails} onClose={onClose} userId={userId} />,
        <PointHistory
            key="History"
            userId={userId}
            totalPoints={pointDetails.point.total}
            suspendedBy={pointDetails.suspended_by}
            ninetyDayProbationCount={pointDetails.ninety_day_probation_count}
            manualWarningCount={pointDetails.manual_warning_count}
        />,
    ];

    return (
        <BasicDialog closeButton onClose={onClose} isOpen={open} style={{ maxWidth: 'unset', width: '80%' }}>
            <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} values={values} />
        </BasicDialog>
    );
}
