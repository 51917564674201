import { HourReportTimestamp, IHourReport, IHourReportView } from 'internal/payments/HourReportModels';
import { client } from 'shared';
const ADMIN_RESOLVED_REPORTS = ['admin_resolved'];
import moment from 'moment-timezone';

export interface UpdateReportBody {
    [key: string]: moment.Moment | string | null | undefined;
}

function extractDateForSubmission(body: UpdateReportBody, field: keyof UpdateReportBody) {
    if (body[field] !== undefined) {
        // eslint-disable-next-line no-comments/disallowComments
        return moment(body[field]).toISOString();
    }
}

export function approveReport(
    report_id: number,
    updateBody: UpdateReportBody,
    appt_id?: number,
    provider_user_id?: number,
) {
    const url = report_id > 0 ? `api/hour-report/${report_id}/` : 'api/hour-report/clock-in/';
    const method = report_id > 0 ? 'PATCH' : 'POST';
    const body = Object.keys(updateBody).reduce(
        (acc: { [key: string]: string | undefined }, key) => {
            acc[key] = extractDateForSubmission(updateBody, key);
            return acc;
        },
        { appointment_id: appt_id?.toString(), provider_user_id: provider_user_id?.toString() },
    );
    return client(url, { method: method, body: body });
}

export function payHourReport(report_id: number, rate: string | number, overrideChecks: boolean) {
    return client(`api/hour-report/${report_id}/pay/`, { body: { rate, override_checks: overrideChecks } });
}

export function clearBreak(report_id: number) {
    return client(`api/hour-report/${report_id}/clear-break/`, { method: 'POST' });
}

export function getTimestampByType(report: IHourReport | undefined, type: string) {
    if (!report) return null;

    const timestamp = report.timestamps.find((t) => t.time_type.type === type);
    return timestamp ? timestamp.reported_at : null;
}

export function getTimestampsByPriority(timestamps: HourReportTimestamp[], types: string[]) {
    const filteredTimestamps = timestamps.filter((t) => types.includes(t.time_type.type));
    if (filteredTimestamps)
        return filteredTimestamps.sort((a, b) => types.indexOf(a.time_type.type) - types.indexOf(b.time_type.type))[0];
    return undefined;
}

export function getHourReportViewStatus(r: IHourReportView) {
    if (r.admin_resolved) return 'Resolved';
    if (r.paid) return 'Paid';
    if (r.business_clock_in || r.business_clock_out) return 'Disputed';
    if (r.business_approved) return 'Approved';
    if (r.is_late_cancel) return 'Late Cancel';
    return 'Awaiting Business';
}

export function getHourReportStatus(r: IHourReport) {
    if (r.timestamps.find((t) => ADMIN_RESOLVED_REPORTS.includes(t.time_type.type))) return 'Resolved';
    if (r.paid) return 'Paid';
    if (r.business_approved || r.timestamps.find((t) => ['business_approved'].includes(t.time_type.type)))
        return 'Approved';
    if (r.business_approved || r.timestamps.find((t) => ['business_cancelled_late'].includes(t.time_type.type)))
        return 'Late Cancel';
    return '';
}

export function getBreakStart(hourReport: IHourReport) {
    return (
        getTimestampByType(hourReport, 'business_break_start') ??
        getTimestampByType(hourReport, 'provider_altered_break_start') ??
        getTimestampByType(hourReport, 'provider_break_start')
    );
}

export function getBreakEnd(hourReport: IHourReport) {
    return (
        getTimestampByType(hourReport, 'business_break_end') ??
        getTimestampByType(hourReport, 'provider_altered_break_end') ??
        getTimestampByType(hourReport, 'provider_break_end')
    );
}
