import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Colors, LoadingIndicator, Text } from 'library';
import theme from 'theme';
import ProviderProfileTabs from './tabs/ProviderProfileTabs';
import ProviderProfileHeader from './ProviderProfileHeader';
import { makeStyles } from '@material-ui/core/styles';
import { ProviderProfileContextProvider, useProviderProfileContext } from './ProviderProfileContext';
import ProviderSummaryCard from './ProviderSummaryCard';
import { useUserContext } from 'UserContext';

const useStyles = makeStyles(() => ({
    '@global': {
        body: {
            backgroundColor: Colors.lightTurq,
        },
    },
}));

export default function ProviderProfile() {
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const { role } = useUserContext();
    if (role !== 'business_active') return null;
    return (
        <ProviderProfileContextProvider>
            <div style={{ padding: isSmall ? 0 : 60 }}>
                <Profile />
            </div>
        </ProviderProfileContextProvider>
    );
}

export function Profile() {
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const { provider, loading } = useProviderProfileContext();
    useStyles();

    return loading ? (
        <Grid container justify="center" alignItems="center" style={{ height: '95vh' }}>
            <LoadingIndicator />
        </Grid>
    ) : provider.id > 0 ? (
        <>
            <Grid container style={{ padding: 20 }}>
                <ProviderProfileHeader mobile={isSmall} />
                <Grid item xs={12} lg={2}>
                    <ProviderSummaryCard provider={provider} mobile={isSmall} />
                </Grid>
                <Grid item xs={12} lg={7} style={{ padding: isSmall ? 0 : 40 }}>
                    <ProviderProfileTabs />
                </Grid>
            </Grid>
        </>
    ) : (
        <Grid container justify="center" alignItems="center" style={{ height: '95vh' }}>
            <Text variant="h2">Sorry, this provider was either not found or is not in your service area.</Text>
        </Grid>
    );
}
