import { useCookies } from 'react-cookie';
import useQueryParams from 'shared/QueryParamsHook';
import useTrackingCookies from './EngagementSourceTracking';

export function useDeprecatedReferralCode(url: string) {
    useTrackingCookies(url);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies(['referral']);

    const query = useQueryParams();

    const deprecatedReferral = query.get('code') || getDeprecatedSource(query);
    const shouldSetCookie = !!deprecatedReferral;

    if (shouldSetCookie) setCookie('referral', deprecatedReferral!, { path: '/' });
}

const knownParams = ['referral', 'source', 'code'];

function getDeprecatedSource(query: URLSearchParams) {
    const deprecatedSource = bareParams(query).filter((key) => !knownParams.includes(key))[0];
    return deprecatedSource;
}

function bareParams(query: URLSearchParams) {
    return Array.from(query.keys()).filter((key) => query.get(key) === '');
}
