import React from 'react';
import { SubscriptionStatus } from '../types';
import { Colors, Text } from 'library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';
interface Props {
    subscriptionStatus?: SubscriptionStatus;
}

const SubscriptionBanner = ({ subscriptionStatus }: Props) => {
    if (subscriptionStatus && subscriptionStatus === 'Inactive') {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 8,
                }}
            >
                <div
                    style={{
                        padding: 8,
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        backgroundColor: Colors.turquoise,
                    }}
                >
                    <Text variant="body1" bold color={Colors.white}>
                        Activate Free Trial
                    </Text>
                    <Text variant="body2" color={Colors.white}>
                        Start your 14-day free trial when you invite your first team member. Experience firsthand how
                        Tandem streamlines your compliance processes.
                    </Text>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: Colors.white,
                        padding: 8,
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        border: `1px solid ${Colors.turquoise}`,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: Colors.darkNavy,
                            padding: 6,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 8,
                            borderRadius: 100,
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faMagnifyingGlassDollar}
                            color={Colors.white}
                            style={{ width: 14, height: 14 }}
                        />
                    </div>
                    <Text variant="body2" bold>
                        After the 14-day free trial: $20/month per program location
                    </Text>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default SubscriptionBanner;
