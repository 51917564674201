import React, { useState } from 'react';
import { BasicDialog, OutlineButton, OutlinedTextInput, PrimaryButton, Text } from 'library';
import { client, consoleLogInDev } from 'shared';
import { AdminProviderListUser } from 'models';

export default function OutreachSuspendedBtn({
    user,
    getProvider,
}: {
    user: AdminProviderListUser;
    getProvider: () => void;
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [details, setDetails] = useState('');

    const handleClick = () => {
        setIsModalOpen(true);
    };

    const onAddWarning = () => {
        client('api/manual-warning/', { body: { user: user.id, details } })
            .then(() => {
                getProvider();
                onModalClose();
            })
            .catch(consoleLogInDev);
    };

    function onModalClose() {
        setIsModalOpen(false);
        setDetails('');
    }

    return (
        <>
            <OutlineButton buttonStyle={{ marginRight: 20 }} onClick={() => handleClick()}>
                Add Warning
            </OutlineButton>
            <BasicDialog closeButton isOpen={isModalOpen} onClose={onModalClose}>
                <div>
                    <Text textStyle={{ marginTop: 10 }}>Include other details</Text>
                    <OutlinedTextInput
                        multiline={true}
                        value={details}
                        onChange={(event) => setDetails(event.target.value)}
                        rows={3}
                        style={{ width: '100%' }}
                    />
                    <PrimaryButton onClick={onAddWarning} buttonStyle={{ marginTop: 10 }}>
                        Warn
                    </PrimaryButton>
                </div>
            </BasicDialog>
        </>
    );
}
