import React, { useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import moment from 'moment';
import { styled } from '@mui/material';
import { Colors, LoadingSpinner, SizeableRoundedDialog, Text } from 'library';
import { ChevronRightRounded } from '@material-ui/icons';
import { format12HourTime } from 'shared/Dates';
import { approveReport, getBreakEnd, getBreakStart } from 'parent-portal/subs/HourReportMethods';

import type { IHourReport } from 'internal/payments/HourReportModels';

export function TimesheetOptionsModal({
    small,
    isOpen,
    onClose,
    onEditTimes,
    onDNW,
    onComplete,
    report,
}: {
    small: boolean;
    isOpen: boolean;
    onClose: () => void;
    onEditTimes: (changeReport: IHourReport) => void;
    onDNW: (dnw: IHourReport) => void;
    onComplete: () => void;
    report: IHourReport;
}) {
    const [reveal, setReveal] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    function onApprove() {
        setError(false);
        setLoading(true);
        approveReport(report.id, { business_approved_date: moment() })
            .then(() => {
                onClose();
                onComplete();
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }

    const breakStart = getBreakStart(report);
    const breakEnd = getBreakEnd(report);

    const breakMinutes = breakStart && breakEnd ? differenceInMinutes(new Date(breakEnd), new Date(breakStart)) : null;

    return (
        <SizeableRoundedDialog
            open={isOpen}
            onClose={onClose}
            title="Timesheet Options"
            fullScreen={false}
            contentStyle={{ padding: 0 }}
            style={{ width: small ? '100%' : '40%' }}
            closeButton
        >
            <Inner>
                <Text variant="h1">Timesheet Options</Text>
                <HoursContainer>
                    <Text textStyle={{ paddingBottom: 5 }}>
                        Clock In: <b>{format12HourTime(report.start, report.timezone)}</b>
                    </Text>
                    <Text textStyle={{ paddingBottom: 5 }}>
                        Clock Out: <b>{format12HourTime(report.end, report.timezone)}</b>
                    </Text>
                    <Text bold>{breakMinutes ? `${breakMinutes} minute break` : 'No break taken'}</Text>
                </HoursContainer>
                <MainRow>
                    <Button
                        backgroundColor={Colors.lightTerraCotta}
                        borderColor={Colors.terraCotta}
                        onClick={() => onEditTimes(report)}
                        disabled={loading}
                    >
                        <Text variant="body1" textStyle={{ textAlign: 'left' }}>
                            Adjust the times for this worker
                        </Text>
                        <ChevronRightRounded />
                    </Button>
                </MainRow>
                <MainRow>
                    {reveal ? (
                        <ButtonRow>
                            <Text variant="body1" textStyle={{ textAlign: 'left' }}>
                                Are you sure?
                            </Text>
                            <Row>
                                <SmallButton backgroundColor={Colors.turquoise} onClick={onApprove} disabled={loading}>
                                    {loading ? (
                                        <LoadingSpinner
                                            loadingStyle={{
                                                color: Colors.white,
                                                height: 18,
                                                width: 18,
                                                padding: 0,
                                                margin: '0 10px',
                                            }}
                                            style={{ padding: 0 }}
                                        />
                                    ) : (
                                        <Text color={Colors.white}>Yes, approve these hours</Text>
                                    )}
                                </SmallButton>
                                <SmallButton
                                    backgroundColor={Colors.mediumRed}
                                    onClick={() => setReveal(false)}
                                    disabled={loading}
                                >
                                    Cancel
                                </SmallButton>
                            </Row>
                        </ButtonRow>
                    ) : (
                        <Button
                            backgroundColor={Colors.lightTurq}
                            borderColor={Colors.turquoise}
                            onClick={() => setReveal(true)}
                        >
                            <Text variant="body1" textStyle={{ textAlign: 'left' }}>
                                Approve these times
                            </Text>
                            <ChevronRightRounded />
                        </Button>
                    )}
                </MainRow>
                <MainRow>
                    <Button
                        backgroundColor={Colors.lightGrey}
                        borderColor={Colors.darkGrey}
                        onClick={() => onDNW(report)}
                        disabled={loading}
                    >
                        <Text variant="body1" textStyle={{ textAlign: 'left' }}>
                            Mark that they did not work this shift
                        </Text>
                        <ChevronRightRounded />
                    </Button>
                </MainRow>
                {error && (
                    <Text color={Colors.error} textStyle={{ paddingTop: 20 }}>
                        Sorry, something went wrong. Please try again or contact support.
                    </Text>
                )}
            </Inner>
        </SizeableRoundedDialog>
    );
}

const Inner = styled('div')({
    padding: 20,
});

const Button = styled('button')(
    ({
        borderColor,
        padding,
        backgroundColor,
    }: {
        borderColor: string;
        padding?: number;
        backgroundColor?: string;
    }) => ({
        backgroundColor: backgroundColor ? backgroundColor : 'transparent',
        border: `2px solid ${borderColor}`,
        borderRadius: 8,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: padding ? 10 : 0,
        padding: padding ?? 20,
        width: padding ? 'fit-content' : '100%',
        '&:hover': {
            backgroundColor: Colors.white70percent,
        },
    }),
);

const HoursContainer = styled('div')({
    borderLeft: `4px solid ${Colors.darkNavy}`,
    margin: '10px 5px',
    height: 'max-content',
    paddingLeft: 10,
    width: '100%',
});

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
});

const ButtonRow = styled(Row)({
    justifyContent: 'space-between',
    width: '100%',
});

const MainRow = styled(ButtonRow)({
    justifyContent: 'space-between',
    marginTop: 15,
    width: '100%',
});

const SmallButton = styled('button')(({ backgroundColor }: { backgroundColor: string }) => ({
    backgroundColor,
    border: 'none',
    borderRadius: 8,
    color: Colors.white,
    cursor: 'pointer',
    marginLeft: 10,
    padding: 10,
}));
