import React, { useState } from 'react';
import ConnectBankAccountPage from './pages/ConnectBankAccountPage';
import ConnectCreditCardPage from './pages/ConnectCreditCardPage/ConnectCreditCardPage';

import IntroPage from './pages/IntroPage';
import LoadingInstantVerificationPage from './pages/LoadingInstantVerificationPage';
import ManualVerificationPage from './pages/ManualVerificationPage';
import PlaidDialog from './PlaidDialog';
import { StripeContextProvider } from './StripeContext';
import SuccessPage from './pages/SuccessPage';
import { useUserContext } from 'UserContext';

export interface ConnectPaymentProps {
    subtitle?: string;
    inJobForm?: boolean;
}

enum ModalPage {
    intro = 'intro',
    connectCreditCard = 'connect credit card',
    connectBankAccount = 'connect bank acccount',
    manualVerification = 'manual verification',
    instantVerification = 'instant verification',
    success = 'success',
}

export default function ConnectPayment({ subtitle, inJobForm = false }: ConnectPaymentProps) {
    const { user, refreshAlreadyLoggedInUser } = useUserContext();
    const paymentSetup = !!user?.payment_information && user?.payment_information?.length > 0;
    const [currentPage, setCurrentPage] = useState<ModalPage>(
        paymentSetup && inJobForm ? ModalPage.success : ModalPage.intro,
    );

    function goToConnectBankAccount() {
        goToPage(ModalPage.connectBankAccount);
    }
    function goToConnectCreditCard() {
        goToPage(ModalPage.connectCreditCard);
    }

    function goToManualVerification() {
        goToPage(ModalPage.manualVerification);
    }

    function goToInstantVerification() {
        goToPage(ModalPage.instantVerification);
    }

    function goToIntro() {
        goToPage(ModalPage.intro);
    }

    function goToSuccess() {
        goToPage(ModalPage.success);
        refreshAlreadyLoggedInUser();
    }

    function goToPage(page: ModalPage) {
        setCurrentPage(page);
    }

    const allPages = {
        [ModalPage.intro]: () => (
            <IntroPage
                goToConnectBankAccount={goToConnectBankAccount}
                goToConnectCreditCard={goToConnectCreditCard}
                subtitle={subtitle}
                showHeader={!inJobForm}
            />
        ),
        [ModalPage.connectCreditCard]: () => (
            <ConnectCreditCardPage goBack={goToIntro} goToSuccess={goToSuccess} showHeaderAndFooter={!inJobForm} />
        ),
        [ModalPage.connectBankAccount]: () => (
            <ConnectBankAccountPage
                goToInstantVerification={goToInstantVerification}
                goToManualVerification={goToManualVerification}
                goBack={goToIntro}
                showHeaderAndFooter={!inJobForm}
            />
        ),
        [ModalPage.manualVerification]: () => (
            <ManualVerificationPage
                goToSuccess={goToSuccess}
                goBack={goToConnectBankAccount}
                showHeaderAndFooter={!inJobForm}
            />
        ),
        [ModalPage.success]: () => <SuccessPage showHeaderAndFooter={!inJobForm} />,
        [ModalPage.instantVerification]: () => <LoadingInstantVerificationPage />,
    };

    return (
        <>
            {currentPage === ModalPage.instantVerification ? (
                <PlaidDialog onAccountSelected={goToSuccess} onAbort={goToIntro} />
            ) : null}
            <StripeContextProvider>{allPages[currentPage]()}</StripeContextProvider>
        </>
    );
}
