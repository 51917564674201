import React from 'react';
import { Colors } from 'library';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MaterialLink } from '@mui/material';

export function Link({
    to,
    children,
    style,
}: {
    to: string;
    children: string | React.ReactNode | React.ReactNode[];
    style?: React.CSSProperties;
}) {
    return (
        <MaterialLink
            component={RouterLink}
            to={to}
            sx={{
                ...{
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                    fontSize: 14,
                    fontFamily: 'Outfit',
                    color: Colors.darkNavy,
                    '&:hover': {
                        color: Colors.darkGrey,
                    },
                },
                ...style,
            }}
        >
            {children}
        </MaterialLink>
    );
}
