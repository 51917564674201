import { ChevronRight } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { TextButton, Text, Colors, Chip } from 'library';
import React from 'react';
import { useHistory } from 'react-router';
import { ApplicantApprovalSummary } from './BusinessCalendar';

export default function UnfilledShiftsWithApprovals({
    approvalsForJob,
    jobId,
    count,
    onViewShifts,
}: {
    approvalsForJob?: ApplicantApprovalSummary;
    jobId: string;
    count: number;
    onViewShifts: () => void;
}) {
    const history = useHistory();
    const newApprovalsCount = approvalsForJob?.applicants.filter((a) => !a.viewed).length || 0;
    const awaitingConfirmationCount = (approvalsForJob?.applicants.length || 0) - newApprovalsCount;
    return (
        <Grid key={jobId} container marginBottom={1}>
            <TextButton
                buttonStyle={{
                    ...styles.unfilledShiftButton,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
                onClick={onViewShifts}
            >
                {count} unfilled shift{count === 1 ? '' : 's'}
            </TextButton>
            <TextButton
                buttonStyle={{
                    ...styles.approvalsButton,
                    backgroundColor: newApprovalsCount > 0 ? Colors.mediumRed : Colors.lightRed,
                }}
                textStyle={styles.approvalsButtonText}
                onClick={() => history.push(`jobs/${jobId}/applicants`)}
            >
                <Grid
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap={1}
                >
                    <Grid>
                        <Grid container flexDirection="row" alignItems="center" marginBottom={1}>
                            {newApprovalsCount > 0 && (
                                <>
                                    <Text variant="body1" color={Colors.white} bold>
                                        To review ({newApprovalsCount})
                                    </Text>
                                    <Chip
                                        label="New!"
                                        backgroundColor={Colors.white}
                                        color="primary"
                                        style={styles.newChip}
                                    />
                                    <Text variant="body2" color={Colors.white}>
                                        Interested near-match workers available
                                    </Text>
                                </>
                            )}
                            {awaitingConfirmationCount > 0 && (
                                <Text
                                    variant="body2"
                                    color={newApprovalsCount ? Colors.white : Colors.darkNavy}
                                    textStyle={{ marginTop: 6 }}
                                >
                                    Approvals pending acceptance ({awaitingConfirmationCount})
                                </Text>
                            )}
                        </Grid>
                    </Grid>
                    <ChevronRight htmlColor={newApprovalsCount ? Colors.white : Colors.darkNavy} fontSize="large" />
                </Grid>
            </TextButton>
        </Grid>
    );
}

const styles = {
    newChip: {
        padding: '0px 2px',
        minHeight: 20,
        height: 24,
        marginLeft: 8,
    },
    approvalsButton: {
        backgroundColor: Colors.mediumRed,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18,
        padding: '10px 5px 10px 20px',
    },
    approvalsButtonText: {
        color: Colors.white,
        textDecoration: 'none',
        textAlign: 'left' as const,
        width: '100%',
    },
    unfilledShiftButton: {
        backgroundColor: Colors.lightRed,
        borderTopLeftRadius: 18,
        borderTopRightRadius: 18,
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18,
        width: '100%',
    },
};
