import { Grid, Snackbar, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Options } from '../Options';
import download from 'shared/Download';
import { IBusinessJobDetailsModel } from '../../BusinessJob/models';
import CancelModal from './CancelModal';
import { startOfTomorrow, isAfter, isSameDay, startOfToday } from 'date-fns';
import EditModal from './EditModal';
import {
    Download,
    Edit,
    EventBusy,
    ReadMore,
    Star,
    Close,
    Visibility,
    PersonAdd,
    AccessTime,
} from '@mui/icons-material';
import { useUserContext } from 'UserContext';
import RevealModal from './RevealModal';
import { client } from 'shared';
import { BusinessSlot, ShiftJob, ShiftProviderUser } from 'models';
import UpdatePreferredWorkerModal from './UpdatePreferredWorkersModal';
import AdjustTimesModal from './AdjustTimesModal/AdjustTimesModal';
import { IHourReport } from 'internal/payments/HourReportModels';
import { getTimestampByType } from '../../../../subs/HourReportMethods';
import DocumentDownloadInfo from './DocumentDownloadInfo';

export default function ShiftCardMenu({
    provider_id,
    slot,
    ongoing_request,
    provider,
    onClose,
    status,
    refresh,
    hourReport,
    filed718bAutomatically,
    businessLocationId,
}: {
    provider_id?: number;
    slot: BusinessSlot;
    ongoing_request: IBusinessJobDetailsModel | ShiftJob;
    provider?: ShiftProviderUser;
    onClose?: () => void;
    status: string;
    refresh: () => void;
    hourReport?: IHourReport;
    filed718bAutomatically?: boolean;
    businessLocationId?: number;
}) {
    const [snackOpen, setSnackOpen] = useState(false);
    const [cancelOpen, setCancelOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [ssnInputOpen, setSsnInputOpen] = useState(false);
    const [updatePreferredOpen, setUpdatePreferredOpen] = useState(false);
    const [adjustTimesModalOpen, setAdjustTimesModalOpen] = useState(false);
    const [showDocDownloadInfo, setShowDocDownloadInfo] = useState(false);

    const { user } = useUserContext();

    const shift = slot.appointments?.length ? slot.appointments[0] : undefined;
    const isOpen = status === 'UNFILLED';
    const isFilled = status === 'ON_STAFF';
    const isFutureShift = isAfter(new Date(slot.start_date), startOfTomorrow());
    const isTodayOrLater = isAfter(new Date(slot.start_date), startOfToday());
    const isToday = isSameDay(new Date(slot.start_date), new Date());

    const clockOut = getTimestampByType(hourReport, 'provider_clock_out');
    const clockIn = getTimestampByType(hourReport, 'provider_clock_in');

    const handleClose = () => {
        setSnackOpen(false);
    };

    const onDownload = async (providerId: number) => {
        try {
            setSnackOpen(true);
            await download(
                `api/providers/${providerId}/documents/${businessLocationId ? `?business_location_id=${businessLocationId}` : ''}`,
            );
            if (user?.address?.length && user?.address[0].state === 'Illinois') setShowDocDownloadInfo(true);
        } catch (error) {
            alert('No documents found');
        }
    };

    function showSSNReveal() {
        let showSSN = false;
        user?.address?.forEach((address) => {
            if (address.state === 'Florida') {
                showSSN = true;
            }
        });
        return showSSN;
    }

    const onSendCode = async () => {
        try {
            setSsnInputOpen(true);
            client(`api/business/reveal-code/`, { method: 'POST', body: { provider: provider?.id } });
        } catch (error) {
            alert('Cannot send SSN reveal code');
        }
    };

    const clockInProviderOption = {
        text: 'Adjust clocked hours',
        onClick: () => setAdjustTimesModalOpen(true),
        icon: <AccessTime />,
    };

    const documentOption = {
        text: 'Download documents',
        onClick: () => {
            if (provider_id) onDownload(provider_id);
        },
        icon: <Download />,
    };
    const detailOption = {
        text: 'Job details',
        to: `/jobs/${ongoing_request.id}`,
        icon: <ReadMore />,
    };

    const editOption = {
        text: 'Edit times',
        onClick: () => setEditOpen(true),
        icon: <Edit />,
    };
    const cancelOption = {
        text: 'Cancel this shift',
        onClick: () => setCancelOpen(true),
        icon: <EventBusy />,
    };
    const updatePreferredWorkerOption = {
        text: 'Request previous worker',
        onClick: () => setUpdatePreferredOpen(true),
        icon: <PersonAdd />,
    };
    const reviewOption = { text: 'Provide feedback', to: `/providers/review/${provider?.id}`, icon: <Star /> };
    const ssnOption = {
        text: 'Reveal SSN',
        onClick: () => onSendCode(),
        icon: <Visibility />,
    };

    const menuOptions = [
        { option: clockInProviderOption, show: isToday && !clockOut },
        { option: documentOption, show: !!provider_id },
        { option: detailOption, show: true },
        { option: editOption, show: shift && (isOpen || isFilled) && isTodayOrLater && !clockIn },
        {
            option: cancelOption,
            show: ((isOpen || isFilled) && isFutureShift) || (isToday && !shift?.hour_reports.length),
        },
        { option: updatePreferredWorkerOption, show: shift && isOpen && isFutureShift },
        { option: reviewOption, show: !!provider && status === 'COMPLETED' },
        { option: ssnOption, show: !!provider_id && showSSNReveal() },
    ];

    return (
        <Grid item xs={1}>
            <Options
                options={menuOptions.filter((option) => option.show).map((option) => option.option)}
                onClose={onClose}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Download Started"
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <CancelModal
                open={cancelOpen}
                onClose={() => setCancelOpen(false)}
                slot={slot}
                providerName={provider?.first_name}
                providerId={provider_id}
                refresh={refresh}
            />
            <DocumentDownloadInfo
                showDocDownloadInfo={showDocDownloadInfo}
                setShowDocDownloadInfo={setShowDocDownloadInfo}
                filed718bAutomatically={filed718bAutomatically}
            />

            {shift && (
                <>
                    <EditModal
                        open={editOpen}
                        onClose={() => setEditOpen(false)}
                        shift={shift}
                        providerName={provider?.first_name}
                        refresh={refresh}
                        timezone={ongoing_request.timezone}
                    />
                    <UpdatePreferredWorkerModal
                        open={updatePreferredOpen}
                        onClose={() => setUpdatePreferredOpen(false)}
                        shift={shift}
                        refresh={refresh}
                    />
                    {provider ? (
                        <RevealModal open={ssnInputOpen} onClose={() => setSsnInputOpen(false)} provider={provider} />
                    ) : null}
                    <AdjustTimesModal
                        open={adjustTimesModalOpen}
                        onClose={() => setAdjustTimesModalOpen(false)}
                        provider={provider}
                        shift={shift}
                        hourReport={hourReport}
                        providerId={provider_id}
                        refresh={refresh}
                        timezone={ongoing_request.timezone}
                    />
                </>
            )}
        </Grid>
    );
}
