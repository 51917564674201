import React from 'react';
import { styled } from '@mui/material';

import { FillInCenterDetails } from './FillInCenterDetails';
import { BasicCard } from './BasicCard';
import { Notifications } from './Notifications';
import { PaymentSetup } from './PaymentSetup';

import type { DashboardSummary } from '../types';

export function NotificationsAndPayments({
    data,
    loading,
    error,
    refresh,
    small,
}: {
    data?: DashboardSummary;
    loading: boolean;
    error: boolean;
    refresh: () => void;
    small: boolean;
}) {
    if (error || !data) return null;

    function callToAction() {
        if (loading || !data) return null;

        if (data?.payment?.status === 'complete') {
            if (data?.centers_details?.some((center) => center.progress < 100) && !data?.has_posted_jobs) {
                return <FillInCenterDetails data={data} />;
            }

            return <Notifications data={data} />;
        }

        return <PaymentSetup status={data?.payment?.status} refresh={refresh} />;
    }

    return (
        <BasicCard loading={loading} small={small} right={true}>
            <CardBody>{callToAction()}</CardBody>
        </BasicCard>
    );
}

const CardBody = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
});
