import { Typography } from '@material-ui/core';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
    Button,
    Navbar,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import theme from '../../theme';
import { Environment } from 'environmentVariables';
import ImpersonationCheck from './ImpersonationCheck';
import { useUserContext } from 'UserContext';
import { Colors } from 'library';
import TaskNavItem from 'internal/TaskList/TaskNavItem';

function Navigation() {
    const { user } = useUserContext();
    function handle_logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.reload();
    }

    return (
        <Navbar color="light" light expand="md" style={{ marginBottom: '0', zIndex: 999 }}>
            <ImpersonationCheck />
            <NavbarBrand>
                <NavLink to="/adminperson/dashboard">Tandem</NavLink>
            </NavbarBrand>
            {Environment === 'production' && (
                <Typography variant="h6" style={{ color: theme.palette.error.main }}>
                    <b>YOU ARE IN PRODUCTION!</b>
                </Typography>
            )}
            <Nav className="ml-auto">
                <div style={{ marginRight: 20, width: 130 }}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link" caret style={styles.dropdown}>
                            Engineering
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/chatwoot/contacts">
                                    Chatwoot Contacts
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/qualifications">
                                    Qualifications
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/dependability">
                                    Dependability Metric
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div style={{ marginRight: 20 }}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link" caret style={styles.dropdown}>
                            Sales
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/businesses">
                                    Businesses
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/region-map">
                                    Region Map
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/sales-leads">
                                    Sales Leads
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/demo">
                                    Demo
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div style={{ marginRight: 20 }}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link" caret style={styles.dropdown}>
                            Account Management
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/businesses">
                                    Businesses
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink
                                    style={styles.link}
                                    to={`/adminperson/business-locations${user?.default_regions?.length ? `?regions=${encodeURIComponent(user.default_regions.toString())}` : ''}`}
                                >
                                    Business Locations
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/substitutes">
                                    Substitutes
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/daily-applicant-feed">
                                    Daily Applicant Feed
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/applicant-approval">
                                    Applicant Feed
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/worker-matching">
                                    Worker Matching
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/region-map">
                                    Region Map
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/rideshares">
                                    Ride Shares
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/disputes">
                                    Disputes
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/flagged-for-suspension">
                                    Flagged for Suspension
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/messenger">
                                    Vetted Worker Messages
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/demo">
                                    Demo
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/autobook-history">
                                    Autobook History
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/file-background-check">
                                    Background Checks to File
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem style={{ display: 'none' }}>
                                <NavLink style={styles.link} to="/adminperson/gmail-auth">
                                    Gmail Auth
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div style={{ marginRight: 20 }}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link" caret style={styles.dropdown}>
                            Payments
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/dashboard">
                                    Dashboard
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/customers">
                                    Customers
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/invoices">
                                    Invoices
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/paystubs">
                                    Pay Stubs
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/pay-provider">
                                    Pay Provider
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/approve-hours">
                                    Approve Hours
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/discounts">
                                    Discounts
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/pq-hooks">
                                    PQ Webhooks
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/advances">
                                    TandemPay Advances
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/reviews">
                                    Stripe Reviews
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/blocked/invoices">
                                    Blocked Invoices
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/payments/blocked/paystubs">
                                    Blocked PayStubs
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div style={{ marginRight: 20 }}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link" caret style={styles.dropdown}>
                            Compliance
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/outreach-new">
                                    Messaging
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/documents-verify">
                                    Verify Documents
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/background-check-verify">
                                    Verify Background Checks
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/confirm-sesame-appts">
                                    Confirm Sesame Appt Requests
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/region-map">
                                    Region Map
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/provider-map">
                                    Provider Map
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/outreach/indeed-post">
                                    Post on Indeed
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/vetting-pipeline">
                                    Vetting Pipeline
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/health-check">
                                    External Services Status
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/first-aid-cpr-classes">
                                    First Aid Cpr Classes
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div style={{ marginRight: 20 }}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link" caret style={styles.dropdown}>
                            User Engagement
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/push-notifications">
                                    Push Notifications
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/mass-text">
                                    Text Messages
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/mass-email">
                                    Mass Email
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/label-loss-reasons">
                                    Label User Loss Reasons
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div style={{ marginRight: 20 }}>
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link" caret style={styles.dropdown}>
                            Deprecated
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/businesses/download">
                                    Businesses Download
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/biz-map">
                                    Business Map
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/highlight-jobs">
                                    Highlight Jobs
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/providers">
                                    Providers
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/share-provider">
                                    Share Providers
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/scheduled-jobs/2">
                                    Manage Scheduled Jobs
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/matching/inbox">
                                    Matching Inbox
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/account-management">
                                    Old Acct Management
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/impersonate">
                                    Impersonate
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/outreach/email">
                                    Email Outreach
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/email">
                                    Send Emails
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/unsubscribe">
                                    Unsubscribe
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/filtering">
                                    Public Application Filtering
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/messages">
                                    Messages
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/message-table">
                                    User Message Threads
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/business-message-table">
                                    Business Message Threads
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink style={styles.link} to="/adminperson/flag-review">
                                    Review Flags
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <TaskNavItem />
                <Button size="sm" style={{ marginTop: 6 }} outline color="secondary" onClick={handle_logout}>
                    Logout
                </Button>
            </Nav>
        </Navbar>
    );
}

export default withRouter(Navigation);

const styles = {
    link: {
        marginTop: 8,
        marginRight: 10,
        color: 'gray',
        fontFamily: 'Work Sans',
    },
    dropdown: {
        cursor: 'pointer',
        fontSize: 14,
    },
    tasksButton: {
        width: 60,
        height: 30,
        backgroundColor: Colors.mediumRed,
        marginRight: 20,
        alignSelf: 'center',
    },
};
