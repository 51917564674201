import { Button, TextField } from '@material-ui/core';
import { BasicDialog, Colors, Text } from 'library';
import React, { useEffect, useState } from 'react';
import { AddCenterInput } from '../types';

interface Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
    addCenter: (input: AddCenterInput, centerToEdit?: AddCenterInput) => void;
    centerToEdit?: AddCenterInput;
}

const AddCenterModal = ({ isOpen, setIsOpen, addCenter, centerToEdit }: Props) => {
    const [name, setName] = useState(centerToEdit?.centerName || '');
    const [address, setAddress] = useState(centerToEdit?.address || '');
    const [city, setCity] = useState(centerToEdit?.city || '');
    const [state, setState] = useState(centerToEdit?.state || '');
    const [zipCode, setZipCode] = useState(centerToEdit?.zipCode || '');
    const [programId, setProgramId] = useState(centerToEdit?.programId || '');

    useEffect(() => {
        if (centerToEdit) {
            setName(centerToEdit.centerName);
            setAddress(centerToEdit.address);
            setCity(centerToEdit.city);
            setState(centerToEdit.state);
            setProgramId(centerToEdit.programId);
        }
    }, [centerToEdit]);

    const onClose = () => {
        setIsOpen(false);
        reset();
    };

    const reset = () => {
        setName('');
        setAddress('');
        setCity('');
        setState('');
        setProgramId('');
    };

    const add = async () => {
        const input: AddCenterInput = {
            id: centerToEdit?.id,
            centerName: name,
            address: address,
            city: city,
            state: state,
            zipCode: zipCode,
            programId: programId,
        };
        addCenter(input, centerToEdit);
        setIsOpen(false);
        reset();
    };

    const buttonDisabled = !name || !address || !city || !state || !zipCode || !programId;
    const buttonText = centerToEdit ? 'Update Center' : 'Add Center';

    return (
        <BasicDialog closeButton isOpen={isOpen} onClose={onClose}>
            <div>
                <Text variant="h1">Add Center</Text>
                <Text variant="h2" bold textStyle={{ marginTop: 16, marginBottom: 2 }}>
                    Center information
                </Text>
            </div>
            <div style={{ margin: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 16 }}>
                    <TextField value={name} label="Name" onChange={(e) => setName(e.target.value)} />
                    <TextField value={address} label="Address" onChange={(e) => setAddress(e.target.value)} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 16 }}>
                    <TextField value={city} label="City" onChange={(e) => setCity(e.target.value)} />
                    <TextField value={state} label="State" onChange={(e) => setState(e.target.value)} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: 16 }}>
                    <TextField value={zipCode} label="Zip Code" onChange={(e) => setZipCode(e.target.value)} />
                    <TextField value={programId} label="Program ID" onChange={(e) => setProgramId(e.target.value)} />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
                <Button
                    style={{
                        maxHeight: 40,
                        marginRight: 8,
                        backgroundColor: buttonDisabled ? Colors.disabledGrey : Colors.darkNavy,
                        color: Colors.white,
                        textTransform: 'none',
                        borderRadius: 70,
                        padding: 16,
                    }}
                    onClick={add}
                    disabled={buttonDisabled}
                >
                    <Text variant="body2" color={Colors.white}>
                        {buttonText}
                    </Text>
                </Button>
            </div>
        </BasicDialog>
    );
};

export default AddCenterModal;
