import React, { useState } from 'react';
import { Colors, IconButton, Text } from 'library';
import { styled } from '@mui/material';
import { Close, Redeem } from '@mui/icons-material';
import { ReferralModal } from 'parent-portal/navigation/new/ReferralModal';

export function ReferralBanner({ small, setShowRefer }: { small: boolean; setShowRefer: (show: boolean) => void }) {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Banner>
            <SmallSection />
            <MiddleSection small={small.toString()}>
                <Row>
                    <Redeem style={{ color: Colors.white, marginRight: 5 }} />
                    <Text color={Colors.white} bold>
                        Loving Tandem?
                    </Text>
                </Row>
                <ReferLink onClick={() => setOpen(true)}>
                    <Text
                        textStyle={{
                            color: 'inherit',
                            textDecorationLine: 'underline',
                            marginLeft: 5,
                            cursor: 'pointer',
                        }}
                    >
                        Refer a center and you both get $50!
                    </Text>
                </ReferLink>
            </MiddleSection>
            <SmallSection>
                <IconButton
                    icon={Close}
                    backgroundColor="transparent"
                    color={Colors.white}
                    style={{ boxShadow: 'none', marginRight: 10 }}
                    onClick={() => setShowRefer(false)}
                />
            </SmallSection>
            <ReferralModal isOpen={open} onClose={() => setOpen(false)} small={small} />
        </Banner>
    );
}

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
});

const Banner = styled(Row)({
    backgroundColor: Colors.terraCotta,
    padding: 10,
    width: '100%',
});

const ReferLink = styled('div')({
    cursor: 'pointer',
    color: Colors.white,
    '&:hover': { color: Colors.lightGrey },
});

const SmallSection = styled(Row)({
    justifyContent: 'flex-end',
    width: '10%',
});

const MiddleSection = styled(Row)(({ small }: { small: string }) => ({
    flexDirection: small === 'true' ? 'column' : 'row',
    width: '80%',
}));
