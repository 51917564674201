import React from 'react';
import { Colors, IconButton, Text } from 'library';
import { styled } from '@mui/material';
import { format12HourTime } from 'shared/Dates';
import { Create } from '@mui/icons-material';

import type { TimesheetWorker, UnclockedInTimesheetWorker } from 'parent-portal/home/new/types';

export function needsReview(timesheet: TimesheetWorker | UnclockedInTimesheetWorker) {
    if (!timesheet.hour_report || !timesheet.hour_report.start) return null;

    if (timesheet.hour_report.business_approved)
        return (
            <Pill backgroundColor={Colors.turquoise}>
                <Text variant="caption" color={Colors.white}>
                    Approved
                </Text>
            </Pill>
        );

    if (timesheet.hour_report.end && !timesheet.hour_report.paid)
        return (
            <Pill backgroundColor={Colors.terraCotta}>
                <Text variant="caption" color={Colors.white}>
                    Needs Review
                </Text>
            </Pill>
        );
}

export function hoursReported(timesheet: TimesheetWorker | UnclockedInTimesheetWorker) {
    if (!timesheet.hour_report || !timesheet.hour_report.start)
        return {
            middle: (
                <Text variant="body2" bold>
                    Not clocked in
                </Text>
            ),
            bottom: null,
        };

    if (timesheet.hour_report.end) {
        return {
            middle: (
                <Text variant="body2" bold>
                    {format12HourTime(timesheet.hour_report.start, timesheet.timezone)} -{' '}
                    {format12HourTime(timesheet.hour_report.end, timesheet.timezone)}
                </Text>
            ),
            bottom: timesheet.hour_report.approved_break ? (
                <Text variant="body2">{timesheet.hour_report.approved_break} minute break</Text>
            ) : (
                <Text variant="body2">No break taken</Text>
            ),
        };
    }

    const clockedIn = {
        middle: (
            <Text variant="body2">
                <b>Clock In:</b> {format12HourTime(timesheet.hour_report.start, timesheet.timezone)}
            </Text>
        ),
        bottom: null,
    };

    if (timesheet.hour_report.approved_break) {
        return {
            ...clockedIn,
            bottom: <Text variant="body2">{timesheet.hour_report.approved_break} minute break</Text>,
        };
    }

    return clockedIn;
}

export function actions(
    timesheet: TimesheetWorker | UnclockedInTimesheetWorker,
    editAction: (timesheet: TimesheetWorker | UnclockedInTimesheetWorker) => void,
    small?: boolean,
) {
    if (timesheet?.hour_report?.paid) {
        return (
            <Pill>
                <Text variant="body2" color={Colors.white}>
                    Paid
                </Text>
            </Pill>
        );
    }

    if (timesheet?.hour_report?.business_approved) {
        return (
            <Container alignItems={small ? 'flex-end' : 'flex-start'}>
                {small && (
                    <Pill backgroundColor={Colors.turquoise} marginBottom={5}>
                        <Text variant="body2" color={Colors.white}>
                            Approved
                        </Text>
                    </Pill>
                )}
                <Pill>
                    <Text variant="body2" color={Colors.white}>
                        Pending
                    </Text>
                </Pill>
            </Container>
        );
    }

    return (
        <Container alignItems={small ? 'flex-end' : 'flex-start'}>
            {small && timesheet?.hour_report?.end && (
                <Pill backgroundColor={Colors.terraCotta} marginBottom={5}>
                    <Text variant="body2" color={Colors.white}>
                        Needs Review
                    </Text>
                </Pill>
            )}
            <IconButton
                icon={Create}
                backgroundColor={Colors.darkNavy}
                color={Colors.white}
                onClick={() => editAction(timesheet)}
            />
        </Container>
    );
}

const Container = styled('div')(({ alignItems }: { alignItems: string }) => ({
    alignItems,
    display: 'flex',
    flexDirection: 'column',
}));

const Pill = styled('div')(
    ({ backgroundColor, marginBottom }: { backgroundColor?: string; marginBottom?: number }) => ({
        backgroundColor: backgroundColor ?? Colors.darkNavy,
        borderRadius: 50,
        marginBottom,
        padding: '5px 10px',
        width: 'fit-content',
    }),
);
