import React, { useEffect } from 'react';
import { LeanBusinessLocation, ManagedWorker, WorkerFilters } from '../types';
import { PrimaryButton, SecondaryButton, SizeableRoundedDialog, Text } from 'library';
import { Grid } from '@mui/material';
import BusinessLocationSelect from '../components/BusinessLocationSelect';
import { client } from 'shared';

export default function ManageWorkerModal({
    open,
    setOpen,
    selectedWorker,
    setSelectedWorker,
    centers,
    refreshWorkers,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedWorker: ManagedWorker;
    setSelectedWorker: (worker?: ManagedWorker) => void;
    centers?: LeanBusinessLocation[];
    refreshWorkers: (filters: WorkerFilters) => void;
}) {
    const [selectedCenters, setSelectedCenters] = React.useState<number[]>([]);

    useEffect(() => {
        setSelectedCenters(selectedWorker.business_locations?.map((location) => location.id) ?? []);
    }, [selectedWorker]);

    async function manageWorker(body: Record<string, string | Date | number | number[] | undefined>) {
        try {
            await client(`compliance/worker/compliance-enrollment/${selectedWorker?.compliance_enrollment_id}`, {
                method: 'PATCH',
                body,
            });
            setOpen(false);
            if (body.unenrolled_at) {
                setSelectedWorker(undefined);
            }
            refreshWorkers({ clear_cache: new Date().toISOString() });
        } catch (error) {
            const errorMessage = body.unenrolled_at ? 'removing' : 'updating';
            alert(`Error ${errorMessage} worker, please try again.`);
        }
    }

    const removeWorker = () =>
        manageWorker({
            unenrolled_at: new Date(),
        });

    const updateLocations = () => manageWorker({ business_location_ids: selectedCenters });
    const currentCenters = selectedWorker.business_locations?.map((location) => location.id) ?? [];
    const hasNewCenters = selectedCenters.some((center) => !currentCenters.includes(center));
    const hasUpdatedCenters = hasNewCenters || selectedCenters.length !== currentCenters.length;
    return (
        <SizeableRoundedDialog
            open={open}
            onClose={() => setOpen(false)}
            title="Remove Worker"
            style={{ maxWidth: 800 }}
        >
            <Grid item container direction="column" style={{ gap: 20, padding: 20 }}>
                <Text variant="body1" bold>
                    Manage Staff: {selectedWorker.first_name} {selectedWorker.last_name}
                </Text>
                <Text>
                    You can manage your worker&apos;s selected business locations below. If this worker no longer works
                    at your center, remove them by clicking &quot;Remove Worker&quot;
                </Text>
                <Grid item container direction="column">
                    <Grid item container direction="row" alignItems="center" justifyContent={'space-around'}>
                        <BusinessLocationSelect
                            centers={centers}
                            selectedCenters={selectedCenters}
                            selectCenter={(center_ids) => {
                                setSelectedCenters(center_ids);
                            }}
                            selectStyle={{ width: '60%' }}
                        />
                        <PrimaryButton
                            buttonStyle={{ maxWidth: 200, height: 40 }}
                            onClick={updateLocations}
                            disabled={!hasUpdatedCenters || selectedCenters.length === 0}
                        >
                            Update Locations
                        </PrimaryButton>
                    </Grid>
                </Grid>

                <Grid item container direction="row" justifyContent="flex-end" style={{ gap: 20 }}>
                    <SecondaryButton buttonStyle={{ maxWidth: 200 }} onClick={() => setOpen(false)}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton buttonStyle={{ maxWidth: 200 }} onClick={removeWorker}>
                        Remove Worker
                    </PrimaryButton>
                </Grid>
            </Grid>
        </SizeableRoundedDialog>
    );
}
