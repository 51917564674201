import { Button } from '@material-ui/core';
import { BasicDialog, Colors, LoadingIndicator, Text } from 'library';
import React, { useState, useTransition } from 'react';
import { AddCenterInput, AddStaffInput, SubscriptionStatus } from '../types';
import { finishOnboarding, startFreeTrial } from '../api';
import { useHistory } from 'react-router';
import { List, ListItem } from '@mui/material';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    centers: AddCenterInput[];
    staff: AddStaffInput[];
    subscriptionStatus?: SubscriptionStatus;
}

const CompleteOnboardingModal = ({ isOpen, closeModal, centers, staff, subscriptionStatus }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPending, startTransition] = useTransition();
    const history = useHistory();

    const submit = () => {
        setIsLoading(true);
        if (subscriptionStatus === 'Inactive') {
            startFreeTrial();
        }
        startTransition(() => {
            finishOnboarding(centers, staff)
                .then(() => {
                    history.push('/compliance/locations');
                })
                .catch(() => {
                    setIsLoading(false);
                    alert('Error completing onboarding, please try again or contact support.');
                });
        });
    };

    const tadaEmoji = '\u{1F389}';

    const OrderedListItem = ({ item, details }: { item: string; details: string }) => {
        return (
            <ListItem sx={{ display: 'list-item', fontSize: 16, fontWeight: 'bold', color: Colors.darkNavy }}>
                <Text variant="body1" bold>
                    {item}
                </Text>
                <Text variant="body1">{details}</Text>
            </ListItem>
        );
    };

    return (
        <BasicDialog closeButton isOpen={isOpen} onClose={closeModal}>
            {isPending || isLoading ? (
                <LoadingIndicator size={48} />
            ) : (
                <>
                    <div>
                        <Text variant="h1">Welcome to Tandem Path! {tadaEmoji}</Text>
                        <Text variant="h2" bold textStyle={{ marginTop: 16, marginBottom: 2 }}>
                            Here&apos;s your path to hassle-free compliance:
                        </Text>
                    </div>
                    <List sx={{ listStyle: 'decimal', pl: 3 }}>
                        <OrderedListItem
                            item="Add your centers"
                            details="Enter your center's information and invite your team to join."
                        />
                        <OrderedListItem
                            item="Invite your team"
                            details="Add your first staff member to start your 14-day trial. They'll download the Tandem app to manage their compliance."
                        />
                        <OrderedListItem
                            item="We'll handle the rest"
                            details="Your team gets timely reminders and can book everything they need—from background checks to CPR training—right in the app, often at better rates."
                        />
                    </List>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
                        <Button
                            style={{
                                maxHeight: 40,
                                marginRight: 8,
                                backgroundColor: Colors.darkNavy,
                                color: Colors.white,
                                textTransform: 'none',
                                borderRadius: 70,
                                padding: 16,
                            }}
                            onClick={submit}
                        >
                            <Text variant="body2" color={Colors.white}>
                                {'Finish'}
                            </Text>
                        </Button>
                    </div>
                </>
            )}
        </BasicDialog>
    );
};

export default CompleteOnboardingModal;
