import { Box, ListItemIcon, MenuItem, Select } from '@mui/material';
import { BusinessLocation } from 'models';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { LeanBusinessLocation } from '../types';
import { Chip } from 'library';

export default function BusinessLocationSelect({
    centers,
    selectedCenters,
    selectCenter,
    viewAll,
    selectStyle,
}: {
    centers?: BusinessLocation[] | LeanBusinessLocation[];
    selectedCenters: number[];
    selectCenter: (center_ids: number[]) => void;
    viewAll?: boolean;
    selectStyle?: React.CSSProperties;
}) {
    if (!centers || centers.length === 0) {
        return <></>;
    }

    const centersToList = viewAll ? [{ id: -1, name: 'View All' }, ...centers] : centers;
    const customSelectStyle = selectStyle ?? {};

    function renderSelectedCenters(selected: number[]) {
        if (selected.length === 1 && selected[0] === -1) {
            return 'View All';
        }
        const selectedCentersToShow = centersToList.filter((it) => selected.includes(it.id) && it.id !== -1);
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, flexDirection: 'row' }}>
                {selectedCentersToShow.map((value) => (
                    <Chip
                        key={value.id.toString() + value.name}
                        label={value.name}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                        }}
                        onDelete={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            selectCenter(selectedCenters.filter((id) => id !== value.id));
                        }}
                    />
                ))}
            </Box>
        );
    }
    const value = selectedCenters?.length ? selectedCenters : [];
    return (
        <Select
            value={value}
            onChange={(e) => {
                const newSelectedCenters = e.target.value as unknown as number[];
                if (newSelectedCenters.includes(-1) && !value.includes(-1)) {
                    selectCenter([]);
                    return;
                }
                selectCenter(newSelectedCenters.filter((it) => it !== -1));
            }}
            multiple
            style={{ marginBottom: 20, marginTop: 20, width: 300, ...customSelectStyle }}
            MenuProps={{
                MenuListProps: {
                    sx: {
                        'li.MuiButtonBase-root': {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                },
            }}
            renderValue={renderSelectedCenters}
        >
            {centersToList?.map((it, i) => (
                <MenuItem key={i} value={it.id} style={{ display: 'flex', flexDirection: 'row' }}>
                    <ListItemIcon>{selectedCenters.includes(it.id) && <CheckIcon />}</ListItemIcon>
                    {it.name}
                </MenuItem>
            ))}
        </Select>
    );
}
