import { Avatar, styled } from '@mui/material';
import { Colors } from 'library';

export const WorkerAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 100,
    height: 100,
    borderRadius: '50%',
    marginTop: 10,
    display: 'inline-flex',
});
