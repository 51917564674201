import React from 'react';
import Notes from 'internal/shared/tables/Notes';
import { Grid } from '@mui/material';
export interface AutoVerifyResult {
    score: number;
    text: string;
    id: number;
}

const AutoVerifyResults = ({ results }: { results: AutoVerifyResult | null | undefined }) => {
    if (!results) return <></>;
    return (
        <Grid
            style={{ display: 'flex', flexDirection: 'column', margin: 10, fontSize: 12 }}
            container
            item
            xs={12}
            md={6}
        >
            <Grid container item direction="row" gap={1}>
                <span style={{ fontWeight: 'bold' }}>Auto Verify Findings</span>
                <Notes
                    tooltip="Add Notes for this Auto Verify"
                    name="Auto Verify"
                    type="auto-verify"
                    objectId={results.id}
                />
            </Grid>
            <span>Score: {results.score}</span>
            {results?.text.split('\n').map((line, index) => (
                <span key={index} style={{ wordWrap: 'break-word', display: 'block' }}>
                    {line}
                </span>
            ))}
        </Grid>
    );
};

export default AutoVerifyResults;
