import React from 'react';
import theme from 'theme';
import { useMediaQuery } from '@material-ui/core';
import { Search } from '@mui/icons-material';
import { ChatwootUrl } from 'environmentVariables';
import { IconButton, Colors } from 'library';

export default function SearchHelpCenter() {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{ position: 'fixed', width: 40, height: 40, right: 180, bottom: small ? 76 : 26 }}>
            <IconButton
                icon={Search}
                size={40}
                backgroundColor={Colors.darkNavy}
                color={Colors.white}
                onClick={() => window.open(`${ChatwootUrl}/hc/child-care-center-help-center/en`)}
            />
        </div>
    );
}
