import React, { useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { Chip, Colors, Text, TextButton } from 'library';
import { client, consoleLogInDev, formatPhoneNumber } from 'shared';
import { PagingResult } from 'models';
import { CircularProgress, styled } from '@mui/material';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import AddUser from './AddUser';
import EditUser from './EditUser';
import { useUserContext } from 'UserContext';
import ConfirmDialog from 'reusableComponents/confirmDialog';

interface IBusinessContactListPermission {
    user: number;
    locations: string;
    permissions: string;
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    last_login: string;
}

const SPLIT_CHAR = '||';

export default function ManageCenters() {
    const [contacts, setContacts] = useState<PagingResult<IBusinessContactListPermission>>();
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedContact, setSelectedContact] = useState<IBusinessContactListPermission>();
    const [contactToDelete, setContactToDelete] = useState<IBusinessContactListPermission>();
    const { user } = useUserContext();

    useEffect(() => {
        loadContacts(1);
    }, []);

    function loadContacts(page: number) {
        setIsLoading(true);
        client(`api/business-contact/?page=${page}`)
            .then((res) => setContacts(res))
            .catch(consoleLogInDev)
            .finally(() => setIsLoading(false));
    }

    function onPageChange(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        const newPageNumber = newPage + 1;
        setPageNumber(newPageNumber);
        loadContacts(newPageNumber);
    }

    function onContactAdded() {
        loadContacts(pageNumber);
    }

    function onDeleteContact() {
        client(`api/business-contact/delete-contact/`, {
            body: {
                user_id: contactToDelete?.user,
            },
        })
            .then(() => {
                const refreshPage = contacts!.results.length > 1 ? pageNumber : pageNumber - 1;
                loadContacts(refreshPage);
                setPageNumber(refreshPage);
                setContactToDelete(undefined);
            })
            .catch(consoleLogInDev);
    }

    function getActionButton(contact: IBusinessContactListPermission, hideActions: boolean) {
        if (hideActions) {
            return <></>;
        }

        return (
            <div>
                <TextButton
                    onClick={() => setSelectedContact(contact)}
                    buttonStyle={{ width: 'fit-content', display: 'inline' }}
                >
                    Edit
                </TextButton>
                |
                <TextButton
                    onClick={() => setContactToDelete(contact)}
                    buttonStyle={{ width: 'fit-content', display: 'inline' }}
                >
                    Delete
                </TextButton>
            </div>
        );
    }

    function getContactRow(contact: IBusinessContactListPermission, hideActions: boolean = false) {
        return (
            <ContactRow key={contact.user}>
                <TableCell>
                    <Text variant="body2">
                        {contact.first_name} {contact.last_name}
                    </Text>
                    <Text variant="caption">
                        {contact.last_login ? formatMonthDayYearWith12HourTime(contact.last_login) : ''}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text variant="body2">{contact.email}</Text>
                    <Text variant="body2">{contact.phone_number ? formatPhoneNumber(contact.phone_number) : ''}</Text>
                </TableCell>
                <TableCell>
                    {contact.locations ? (
                        contact.locations.split(SPLIT_CHAR).map((s, index) => (
                            <LocationContainer key={index}>
                                <Text variant="body2">{s}</Text>
                            </LocationContainer>
                        ))
                    ) : (
                        <Text variant="body2">All Locations</Text>
                    )}
                </TableCell>
                <TableCell>
                    <Text variant="body2">
                        {contact.permissions
                            ? contact.permissions
                                  .split(SPLIT_CHAR)
                                  .map((x) => <Chip key={x} label={x} style={{ margin: 5 }} />)
                            : ''}
                    </Text>
                </TableCell>
                <TableCell align="right">{getActionButton(contact, hideActions)}</TableCell>
            </ContactRow>
        );
    }

    return (
        <Grid container direction="column" justify="center" style={{ marginTop: 40, padding: '0px 10%' }}>
            <Grid container item xs={12} direction="row" justify="space-between">
                <Text variant="display">Manage Users</Text>
                <AddUser onContactAdded={onContactAdded} />
            </Grid>
            <ContactContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <HeaderRow>
                            <NewTableCell>Name (Last Login)</NewTableCell>
                            <NewTableCell>Contact Info</NewTableCell>
                            <NewTableCell>Access</NewTableCell>
                            <NewTableCell>Permissions</NewTableCell>
                            <NewTableCell align="right">Actions</NewTableCell>
                        </HeaderRow>
                    </TableHead>
                    <TableBody>
                        {user && user?.role !== 'BUSINESS_CONTACT' && pageNumber === 1
                            ? getContactRow(
                                  {
                                      ...user,
                                      user: user.id,
                                      locations: '',
                                      permissions: 'All',
                                      last_login: '',
                                  },
                                  true,
                              )
                            : null}
                        {contacts?.results.map((row) => getContactRow(row))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <FooterCell align="right" colSpan={6}>
                                <Grid container justify="flex-end" alignItems="center">
                                    {isLoading && <CircularProgress />}
                                    <TablePagination
                                        component="div"
                                        count={contacts?.count || 0}
                                        rowsPerPage={contacts?.per_page || 25}
                                        page={pageNumber - 1}
                                        onChangePage={onPageChange}
                                        rowsPerPageOptions={[]}
                                    />
                                </Grid>
                            </FooterCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </ContactContainer>
            <EditUser
                isModalOpen={Boolean(selectedContact)}
                onClose={() => setSelectedContact(undefined)}
                userId={selectedContact?.user}
                initialLocations={selectedContact?.locations ? selectedContact?.locations.split(SPLIT_CHAR) : []}
                initialPermissions={selectedContact?.permissions.split(SPLIT_CHAR)}
                onUserUpdated={() => {
                    setSelectedContact(undefined);
                    loadContacts(pageNumber);
                }}
                name={`${selectedContact?.first_name} ${selectedContact?.last_name}`}
            />
            <ConfirmDialog
                title="Confirm Delete"
                message="Are you sure you want to delete this contact?"
                open={Boolean(contactToDelete)}
                onClose={() => setContactToDelete(undefined)}
                onConfirm={onDeleteContact}
            />
        </Grid>
    );
}

const ContactRow = styled(TableRow)({
    verticalAlign: 'top',
});

const HeaderRow = styled(TableRow)({
    backgroundColor: Colors.lightGrey,
    color: Colors.darkNavy,
});

const NewTableCell = styled(TableCell)({
    color: Colors.darkNavy,
});

const ContactContainer = styled(TableContainer)({
    marginTop: 10,
    marginBottom: 40,
});

const LocationContainer = styled('div')({
    paddingBottom: 3,
});

const FooterCell = styled(TableCell)({
    borderBottom: 'none',
});
