import React, { useEffect, useState } from 'react';
import { Checkbox, Colors, PrimaryButton, Text } from 'library';
import { Divider, styled } from '@mui/material';
import { client } from 'shared';
import moment from 'moment';
import { add } from 'date-fns';

import FormField from 'parent-portal/forms/components/FormField';
import BusinessDateAndTimes from 'parent-portal/forms/components/pages/DateAndTimePage/BusinessDatesAndTimes';
import RequestedWorkersOnJob from 'parent-portal/forms/components/pages/ProviderPreferencesPage/RequestedWorkersOnJob';
import { useUserContext } from 'UserContext';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { tandemRecommendedDescription } from 'parent-portal/forms/components/pages/HeadlineDescriptionPage/JobDescriptionInput';
import { coerceHours } from 'parent-portal/forms/JobRequestSubmission';
import { timeWithDate } from 'parent-portal/forms/time-operations';
import { convertPreferredSitterObject } from 'parent-portal/forms/components/pages/ProviderPreferencesPage/preferredWorkersApi';
import { Warning } from '@mui/icons-material';

export function AutoHireWorkerInnerDialog({
    setOpen,
    addressId,
    defaultDay,
}: {
    setOpen: (open: boolean) => void;
    addressId?: number;
    defaultDay?: Date;
}) {
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [locationName, setLocationName] = useState<string | undefined>();
    const { form } = useJobFormContext();
    const { user } = useUserContext();
    const [confirmSelected, setConfirmSelected] = useState(false);
    const [workerName, setWorkerName] = useState<string | undefined>();

    useEffect(() => {
        const worker = form.fieldStates.preferredWorkers.value[0] ?? form.fieldStates.pastWorkers.value[0];
        setWorkerName(worker?.name);
    }, [form.fieldStates.preferredWorkers.value, form.fieldStates.pastWorkers.value]);

    useEffect(() => {
        form.fieldStates.businessJobType.setValue('SUBSTITUTE');
        form.fieldStates.businessJobType.setValid(true);
        form.fieldStates.lastHireOffsetMinutes.setValid(true);
        form.fieldStates.headline.setValue('Teacher');
        form.fieldStates.comments.setValue(tandemRecommendedDescription);
        form.fieldStates.startDate.setValue(defaultDay ? moment(defaultDay) : moment(add(new Date(), { days: 1 })));
        form.fieldStates.endDate.setValue(defaultDay ? moment(defaultDay) : moment(add(new Date(), { days: 1 })));
    }, []);

    useEffect(() => {
        const addresses = user?.address;
        if (addresses?.length) {
            const selectedAddress = addresses.find((x) => x.id === addressId);

            const mailingAddress = selectedAddress ?? addresses.find((x) => x.address_type === 'PRIMARY');
            const foundAddressId = mailingAddress ? mailingAddress.id : addresses[0].id;
            const busLocationId = mailingAddress?.business_location
                ? mailingAddress.business_location.id
                : addresses[0]?.business_location?.id;

            setLocationName(
                mailingAddress?.business_location?.name ?? addresses[0]?.business_location?.name ?? undefined,
            );

            form.fieldStates.address.setValue([{ id: foundAddressId, businessLocationId: busLocationId }]);
        }
    }, [user]);

    function onClick() {
        setError(undefined);
        setLoading(true);

        const {
            daysOfTheWeek,
            sameTimes,
            startDate,
            endDate,
            jobTimes,
            address,
            headline,
            comments,
            preferredWorkers,
            pastWorkers,
        } = form.toValues();

        const { selectedWeekDays, ongoingTimesOfDay } = coerceHours({
            daysOfTheWeek,
            sameTimes: sameTimes,
            start: sameTimes ? jobTimes.start && timeWithDate(jobTimes.start!, startDate!) : startDate,
            end: endDate,
            end_time: jobTimes.end,
            // NOTE: none of the below matter for the function
            selectedPastWorkers: [],
            selectedPreferredWorkers: [],
            paymentSchedule: undefined,
            faqs: [],
        });

        const chosenSitters = convertPreferredSitterObject(preferredWorkers, pastWorkers);

        client('api/ongoing/create-and-hire/', {
            method: 'POST',
            body: {
                start_date: startDate?.format('YYYY-MM-DD'),
                end_date: endDate?.format('YYYY-MM-DD'),
                days_of_week: selectedWeekDays,
                times_of_day: ongoingTimesOfDay,
                headline,
                family_comments: comments,
                preferred_sitters: chosenSitters,
                address_id: address?.[0]?.id,
                business_location_id: address?.[0]?.businessLocationId,
                family_id: user?.family?.[0]?.id,
                transportation: false,
            },
        })
            .then(() => {
                setLoading(false);
                setOpen(false);
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                setError(
                    `${error ? `The following went wrong creating the request: ${error} ` : ''}Please try again or contact support.`,
                );
            });
    }

    return (
        <InnerContainer>
            <Text variant="display">Automatically schedule worker</Text>
            {locationName && (
                <Text variant="body2" bold>
                    {locationName}
                </Text>
            )}

            <TextContainer>
                <Text textStyle={{ color: Colors.error }} bold>
                    <Warning /> <u>The person you select must have already agreed to this shift.</u> You must have
                    communicated already with them in person or via another communication medium. If you would like to
                    request a worker instead, please use the &#34;Post Shift&#34; button.
                </Text>
            </TextContainer>
            <BusinessDateAndTimes setIsValid={setValid} essentialOnly />
            <Divider />
            <FormField title={'4. Schedule worker'} style={{ marginTop: 30 }}>
                <RequestedWorkersOnJob multiple={false} />
            </FormField>
            {!!error && (
                <Text textStyle={{ color: Colors.error, marginTop: 10 }} variant="body2">
                    {error}
                </Text>
            )}
            <Checkbox
                label={`I have already communicated with ${workerName ?? 'the worker'} and they have agreed to work the entered times.`}
                checked={confirmSelected}
                onChange={() => setConfirmSelected(!confirmSelected)}
            />
            <SubmitRow error={(!!error)?.toString()}>
                <PrimaryButton
                    disabled={
                        !valid ||
                        !form.fieldStates.pastWorkers.isValid ||
                        !form.fieldStates.preferredWorkers.isValid ||
                        !confirmSelected
                    }
                    loading={loading}
                    onClick={onClick}
                    buttonStyle={{ height: 'fit-content', width: 'unset' }}
                >
                    Submit
                </PrimaryButton>
            </SubmitRow>
        </InnerContainer>
    );
}

const InnerContainer = styled('div')({
    paddingLeft: 20,
    paddingRight: 20,
});

const TextContainer = styled('div')({
    backgroundColor: Colors.lightRed,
    padding: 10,
    border: `1px solid ${Colors.error}`,
    borderRadius: 10,
    display: 'flex',
    marginTop: 10,
});

const SubmitRow = styled('div')(({ error }: { error?: string }) => ({
    alignItems: 'center',
    display: 'flex',
    marginTop: error === 'true' ? 5 : 20,
    justifyContent: 'space-between',
    width: '100%',
    ...(error === 'true' ? { border: `1px solid ${Colors.error}`, borderRadius: 10, padding: 10 } : {}),
}));
