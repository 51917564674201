import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors, Text } from 'library';
import { formatMonthDayYear } from 'shared/Dates';

interface IPayMethod {
    id: number;
    added_at: string;
    is_default: boolean;
    account_type: string;
    last_four: string;
    errorMessage: string;
    isLoading: boolean;
}

interface PayMethodProps {
    payMethod: IPayMethod;
}

export default function PayMethod({ payMethod }: PayMethodProps) {
    return (
        <Grid xs={12} md={6} key={payMethod.id} item alignItems="center" justify={'flex-start'}>
            <Text variant="body1">
                {payMethod.account_type} •••• {payMethod.last_four}
            </Text>
            <Text variant="caption">Added: {formatMonthDayYear(payMethod.added_at)}</Text>
            {payMethod.errorMessage ? (
                <Text variant="body1" color={Colors.terraCotta} bold>
                    {payMethod.errorMessage}
                </Text>
            ) : null}
        </Grid>
    );
}
