import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TopNavigation from '../components/TopNavigation';
import { Navigation } from 'parent-portal/navigation/new/Navigation';
import BusinessJobRequestModal from 'parent-portal/forms/BusinessJobRequestModal';
import { useUserContext } from 'UserContext';

export const Private = ({ component: Component, ...rest }) => {
    const { role } = useUserContext();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (localStorage.getItem('token') !== null) {
                    if (role && role !== 'admin') {
                        return (
                            <>
                                <Navigation>
                                    <BusinessJobRequestModal />
                                    <Component {...props} />
                                </Navigation>
                            </>
                        );
                    }
                    if (role === 'admin') {
                        return (
                            <>
                                <TopNavigation />
                                <BusinessJobRequestModal />
                                <Component {...props} />
                            </>
                        );
                    }
                    return <></>;
                }

                const pathname = props.location?.pathname?.indexOf('sitter/') > -1 ? '/sitter' : '/';

                return <Redirect to={{ pathname, search: props.location.search, state: { from: props.location } }} />;
            }}
        />
    );
};
