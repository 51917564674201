import { isMonday, previousMonday, startOfDay } from 'date-fns';

// EXAMPLE: month date year
export const formatDate = (date: string) => {
    const dateWithTimezone = date + 'T00:00:00';
    const options = { month: 'short', day: 'numeric', year: 'numeric' } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(dateWithTimezone));
    return formattedDate;
};

export const formatMonthDayYear = (date: Date | string) => {
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(date));
    return formattedDate;
};

// EXAMPLE: month (Aug) day year
export const formatShortMonthDayYear = (date: Date | string) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(date));
    return formattedDate;
};

// EXAMPLE: March 27
export const formatLongMonthDay = (date: Date | string, timezone?: string) => {
    const options = { month: 'long', day: 'numeric', timeZone: timezone } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(date));
    return formattedDate;
};

// EXAMPLE: 7/20, 12:00 PM
export const formatMonthYearWith12HourTime = (
    date: Date | string,
    timezone?: string,
    showTimezone: boolean = false,
) => {
    const dateOptions = {
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
        timeZoneName: showTimezone ? 'short' : undefined,
    } as const;

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    return dateFormatter.format(new Date(date));
};

// EXAMPLE: 7/11/20, 12:00 PM
export const formatMonthDayYearWith12HourTime = (
    date: Date | string,
    timezone?: string,
    showTimezone: boolean = false,
) => {
    const dateOptions = {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
        timeZoneName: showTimezone ? 'short' : undefined,
    } as const;

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    return dateFormatter.format(new Date(date));
};

// EXAMPLE: 3/20
export const formatMonthYear = (date: Date | string) => {
    const dateOptions = {
        month: 'numeric',
        year: 'numeric',
    } as const;

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    return dateFormatter.format(new Date(date));
};

// EXAMPLE: 3:00 PM
export const format12HourTime = (date: Date | string, timezone?: string, showTimezone: boolean = false) => {
    // NOTE: timezone should be a string like America/New_York
    const dateOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone, // NOTE: Use the provided timezone, or the local timezone if not provided
        timeZoneName: showTimezone ? 'short' : undefined,
    };

    const endTimeFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    return endTimeFormatter.format(new Date(date));
};

// EXAMPLE: 3:12:42 PM
export const format12HourTimeWithSeconds = (date: Date | string, timezone?: string, showTimezone: boolean = false) => {
    // NOTE: timezone should be a string like America/New_York
    const dateOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: timezone, // NOTE: Use the provided timezone, or the local timezone if not provided
        timeZoneName: showTimezone ? 'short' : undefined,
    };

    const endTimeFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    return endTimeFormatter.format(new Date(date));
};

// EXAMPLE: 3/20
export const formatMonthDay = (date: Date | string) => {
    const dateOptions = {
        month: 'numeric',
        day: 'numeric',
    } as const;

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    return dateFormatter.format(new Date(date));
};

export const formatMonthDayTime = (date: Date | string, timezone?: string, showTimezone: boolean = false) => {
    const dateOptions = {
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
        timeZoneName: showTimezone ? 'short' : undefined,
    } as const;

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    return dateFormatter.format(new Date(date));
};

// NOTE: takes argument of string or date and returns string of form "Fri, Nov 17"
export const formatShortWeekdayMonthDay = (date: Date | string) => {
    const options = { month: 'short', day: 'numeric', weekday: 'short' } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(date));
    return formattedDate;
};

export const formatLongWeekdayMonthDayYear = (date: Date | string, timezone?: string) => {
    const options = {
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
        year: 'numeric',
        timeZone: timezone,
    } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(date));
    return formattedDate;
};

export const formatLongWeekdayMonthDay = (date: Date | string) => {
    const options = { month: 'short', day: 'numeric', weekday: 'long' } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(date));
    return formattedDate;
};

export const formatShortWeekdayMonthDayYear = (date: Date | string) => {
    const options = { month: 'numeric', day: 'numeric', year: 'numeric', weekday: 'short' } as const;
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = dateFormatter.format(new Date(date));
    return formattedDate;
};

export function formatDateFromDateObject(d: Date) {
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
}

export function toTimeZoneAbbreviation(timezone: string) {
    const formatter = Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        timeZoneName: 'short',
    });
    return formatter.format(new Date()).split(' ')[1];
}

export function toISOLocal(d: Date) {
    // WARNING: This function does not handle timezones and should not be used for communicating with the api. Instead use toISOString
    return `${formatDateFromDateObject(d)}T${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(
        2,
        '0',
    )}:${String(d.getSeconds()).padStart(2, '0')}.${String(d.getMilliseconds()).padStart(3, '0')}`;
}

export enum DaysOfWeek {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export enum UpperDaysOfWeek {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

export const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function to12HourTime(time: string) {
    const hours = parseInt(time.split(':')[0]);
    const minutes = parseInt(time.split(':')[1]);
    const pm = hours >= 12 && hours < 24;
    return `${hours > 12 ? hours - 12 : hours}:${String(minutes).padStart(2, '0')}${pm ? 'pm' : 'am'}`;
}
export const midnightMinutes = 1440;
export const MILLISECONDS_PER_HOUR = 1000 * 60 * 60;

export function getThisWeeksMonday() {
    return isMonday(new Date()) ? startOfDay(new Date()) : startOfDay(previousMonday(new Date()));
}
