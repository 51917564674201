import usePlaid from 'parent-portal/payments/setup/components/PlaidHook';
import { PlaidAccount } from 'parent-portal/payments/setup/components/PlaidTypes';
import PlaidAccountSelection from 'parent-portal/payments/setup/PlaidAccountSelection';
import React, { useEffect } from 'react';

export default function PlaidDialog({
    onAccountSelected,
    onAbort,
}: {
    onAccountSelected: (accountId: PlaidAccount['id']) => void;
    onAbort: () => void;
}) {
    const { accounts, token: plaidToken, exited } = usePlaid();

    useEffect(() => {
        exited && onAbort();
    }, [exited]);

    return (
        <PlaidAccountSelection
            accounts={accounts}
            token={plaidToken}
            onSelectAccount={onAccountSelected}
            onError={onAbort}
        />
    );
}
