import React, { ReactNode, useEffect, useState } from 'react';
import {
    BasicDialog,
    Checkbox,
    Colors,
    OutlinedTextInput,
    PrimaryButton,
    Text,
    MaterialDateInput,
    TimePicker,
} from 'library';
import { BusinessJobWithAppointments, LeanUser } from 'models';
import { client, consoleLogInDev } from 'shared';
import moment from 'moment-timezone';
import { isAfter } from 'date-fns';
import { Grid, InputAdornment } from '@mui/material';
import { toTimeZoneAbbreviation } from 'shared/Dates';

interface HiredProvider {
    provider: LeanUser;
    pay: number | undefined;
}

export default function AddDaysModal({
    job,
    open,
    onClose,
    refreshJobs,
}: {
    job: BusinessJobWithAppointments;
    open: boolean;
    onClose: () => void;
    refreshJobs: () => void;
}) {
    const [preferredProviders, setPreferredProviders] = useState<HiredProvider[]>([]);
    const [saved, setSaved] = useState(false);
    const [userId, setUserId] = useState<number>();
    const [endDate, setEndDate] = useState<moment.Moment | null>(moment());
    const [startDate, setStartDate] = useState<moment.Moment | null>(moment());
    const [start, setStart] = useState<moment.Moment | null>(moment());
    const [end, setEnd] = useState<moment.Moment | null>(moment());
    const [provider, setProvider] = useState<LeanUser>();

    useEffect(() => {
        setPreferredProviders(getProviders());
    }, [job]);
    useEffect(() => setSaved(false), [open]);

    function addOrRemovePreferredProvider(add: boolean, provider: HiredProvider) {
        const { provider: p } = provider;
        if (add) {
            setPreferredProviders([provider, ...preferredProviders]);
        } else {
            setPreferredProviders(preferredProviders.filter(({ provider: pref }) => p.id !== pref.id));
        }
    }
    function updateProviderPay(provider: HiredProvider, updatedPay: number) {
        const { provider: p } = provider;
        const pIndex = preferredProviders.findIndex(({ provider: pref }) => p.id === pref.id);
        if (pIndex === -1) {
            setPreferredProviders([provider, ...preferredProviders]);
        } else {
            const currentProviders = Array.from(preferredProviders);
            currentProviders[pIndex] = { provider: p, pay: updatedPay };
            setPreferredProviders(currentProviders);
        }
    }

    function getProviders() {
        const hired = job.slots.reduce((allHiredProviders: HiredProvider[], currentSlot) => {
            currentSlot.appointments.forEach((appt) => {
                appt.pairings.forEach((pairing) => {
                    if (!allHiredProviders.some((provider) => provider.provider.id === pairing.hired_provider.id)) {
                        allHiredProviders.push({ provider: pairing.hired_provider, pay: pairing.pay_rate });
                    }
                });
            });
            return allHiredProviders;
        }, []);
        return hired;
    }

    function getProvider() {
        if (userId) client(`api/users/${userId}/`).then(setProvider).catch(consoleLogInDev);
    }

    function save() {
        // NOTE: - we elected not to use system time for this.
        const requestObj = {
            start_date: startDate?.format('YYYY-MM-DD'),
            end_date: endDate?.format('YYYY-MM-DD'),
            start: start?.tz(job.timezone).format('h:mm A'),
            end: end?.tz(job.timezone).format('h:mm A'),
            users_and_pay: preferredProviders.map(({ provider: p, pay }) => {
                return { id: p.id, pay: pay };
            }),
        };
        client(`api/ongoing/${job.id}/add-days/`, { body: requestObj })
            .then(() => {
                setSaved(true);
                refreshJobs();
            })
            .catch(consoleLogInDev);
    }

    const onCloseModal = () => {
        setStartDate(moment());
        setEndDate(moment());
        setStart(moment());
        setEnd(moment());
        onClose();
    };

    return (
        <BasicDialog closeButton isOpen={open} onClose={onCloseModal}>
            {saved ? (
                <Text variant="display">You did it!</Text>
            ) : (
                <Grid
                    item
                    container
                    style={{ padding: 20, backgroundColor: Colors.lightBlue, borderRadius: 18, marginTop: 20 }}
                    direction="column"
                >
                    <Container style={{ display: 'flex', flexDirection: 'row', gap: 40 }}>
                        <MaterialDateInput
                            label="Start Date"
                            value={startDate}
                            onChange={(d) => {
                                if (d && (!endDate || isAfter(d?.toDate(), endDate.toDate()))) setEndDate(d);
                                setStartDate(d);
                            }}
                        />
                        <MaterialDateInput label="End Date" value={endDate} onChange={(d) => setEndDate(d)} />
                    </Container>
                    <Container>
                        <TimePicker
                            label={`Start Time (${toTimeZoneAbbreviation(job.timezone)})`}
                            value={start}
                            onChange={setStart}
                            timezone={job.timezone}
                            sx={{ maxWidth: 200 }}
                        />
                    </Container>
                    <Container>
                        <TimePicker
                            label={`End Time (${toTimeZoneAbbreviation(job.timezone)})`}
                            value={end}
                            onChange={setEnd}
                            timezone={job.timezone}
                            sx={{ maxWidth: 200 }}
                        />
                    </Container>
                    <Text>Provider to set as hired</Text>
                    {preferredProviders.map(({ provider, pay }) => (
                        <Grid key={provider.id} container item direction="row" style={{ gap: 10 }} alignItems="center">
                            <Checkbox
                                key={provider.id}
                                label={`${provider.first_name} ${provider.last_name} (${provider.id})`}
                                checked={preferredProviders.some((pref) => pref.provider.id === provider.id)}
                                onChange={(checked) => {
                                    addOrRemovePreferredProvider(checked, { provider, pay });
                                }}
                                style={{ maxWidth: '50%' }}
                            />
                            <OutlinedTextInput
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                value={pay}
                                style={{ backgroundColor: 'white', padding: 0, borderRadius: 15, maxWidth: '50%' }}
                                placeholder="Pay Rate"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    updateProviderPay({ provider, pay }, parseInt(e.target.value));
                                }}
                                error={!pay}
                                helperText="Pay Rate/hr"
                                type="number"
                            />
                        </Grid>
                    ))}
                    <OutlinedTextInput
                        label="Provider User Id"
                        value={userId}
                        onChange={(e) => setUserId(parseInt(e.target.value) || undefined)}
                        onBlur={getProvider}
                        style={{ marginTop: 10 }}
                    />
                    {provider && (
                        <Checkbox
                            key={provider.id}
                            label={`${provider.first_name} ${provider.last_name} (${provider.id})`}
                            checked={preferredProviders.some((pref) => pref.provider.id === provider.id)}
                            onChange={(checked) => {
                                addOrRemovePreferredProvider(checked, { provider, pay: 0 });
                            }}
                        />
                    )}
                    <PrimaryButton rightAlign onClick={save} buttonStyle={{ marginTop: 10 }}>
                        Save
                    </PrimaryButton>
                </Grid>
            )}
        </BasicDialog>
    );
}

function Container({ children, style = {} }: { children: ReactNode | ReactNode[]; style?: React.CSSProperties }) {
    return (
        <div
            style={{
                marginBottom: 10,
                borderRadius: 18,
                padding: 20,
                ...style,
            }}
        >
            {children}
        </div>
    );
}
