import React from 'react';
import { styled, Grid } from '@mui/material';
import { Text } from 'library';

import { ExplanatoryCredentialPill } from '../ExplanatoryCredentialPill';

export function BaseCredentials() {
    return (
        <Grid container style={{ marginTop: 3 }}>
            <Grid container item xs={12}>
                <Text variant="body2">• Level 2 Background Check</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• 45 Hours Started</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Affidavit of Good Moral Character</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Mandated Reporter Document</Text>
            </Grid>
            <ExplanatoryCredentialPill
                label="Other Trainings According to DCY Timeline >"
                title={
                    <Hover>
                        <Text variant="caption" bold>
                            Day 30
                        </Text>
                        <Text variant="caption">- Safe Sleep/Shaken Baby Syndrome Training </Text>
                        <Text variant="caption" bold>
                            1 Year
                        </Text>
                        <Text variant="caption">- 45 Hours</Text>
                    </Hover>
                }
            />
        </Grid>
    );
}

const Hover = styled('div')({
    padding: 5,
});
