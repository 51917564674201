import React from 'react';
import { Text } from 'library';
import { styled, Grid } from '@mui/material';

import { ExplanatoryCredentialPill } from '../ExplanatoryCredentialPill';

export function BaseCredentials() {
    return (
        <Grid container style={{ marginTop: 3 }}>
            <Grid container item xs={12}>
                <Text variant="body2">• 1176 Filed to Program ID</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Medical Form</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Verified HS Education</Text>
            </Grid>
            <Grid container item xs={12}>
                <Text variant="body2">• Staff Orientation Training</Text>
            </Grid>
            <ExplanatoryCredentialPill
                label="Other Trainings According to DCY Timeline >"
                title={
                    <Hover>
                        <Text variant="caption" bold>
                            Day 30
                        </Text>
                        <Text variant="caption">- Ohio&apos;s Overview of Child Development</Text>
                        <Text variant="caption">- Ohio&apos;s Approach to Quality</Text>
                        <Text variant="caption" bold>
                            Day 60
                        </Text>
                        <Text variant="caption">- ODJFS Child Abuse Training</Text>
                        <Text variant="caption" bold>
                            Day 90
                        </Text>
                        <Text variant="caption">- First Aid/CPR</Text>
                    </Hover>
                }
            />
        </Grid>
    );
}

const Hover = styled('div')({
    padding: 5,
});
