import React from 'react';
import { OutlinedTextInput } from 'library';
import { Autocomplete } from '@material-ui/lab';

interface IAutoCompleteProps<T> {
    multiple: boolean;
    options: T[];
    selected: T | T[] | null;
    onSelect: (value: T | T[] | null) => void;
    getOptionLabel: (option: T) => string;
    getOptionSelected: (option: T, value: T) => boolean;
    getOptionDisabled: (option: T) => boolean;
    renderOption: (option: T) => React.ReactNode;
}

export default function AutoComplete<T>({
    multiple,
    options,
    selected,
    onSelect,
    getOptionLabel,
    getOptionSelected,
    getOptionDisabled,
    renderOption,
}: IAutoCompleteProps<T>) {
    return (
        <Autocomplete
            multiple={multiple}
            value={selected}
            options={options}
            onChange={(_, value) => onSelect(value)}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            getOptionDisabled={getOptionDisabled}
            renderOption={renderOption}
            renderInput={(params) => (
                <OutlinedTextInput
                    {...params}
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    placeholder="Click here to search"
                />
            )}
        />
    );
}
