import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import { BasicDialog, Colors, PrimaryButton, SecondaryButton, Text } from 'library';
import { IBusinessLocation, PagingResult } from 'models';
import SelectLocations from './SelectLocations';
import SelectPermissions from './SelectPermissions';
import { IPermission } from './models';

interface IEditUserProps {
    name: string;
    userId?: number;
    initialLocations?: string[];
    initialPermissions?: string[];
    isModalOpen: boolean;
    onClose: () => void;
    onUserUpdated: () => void;
}

export default function EditUser({
    name,
    userId,
    initialLocations,
    initialPermissions,
    isModalOpen,
    onClose,
    onUserUpdated,
}: IEditUserProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [availableLocations, setAvailableLocations] = useState<IBusinessLocation[]>([]);
    const [availablePermissions, setAvailablePermissions] = useState<IPermission[]>([]);

    useEffect(() => {
        if (!isModalOpen || (availableLocations.length && availablePermissions.length)) {
            return;
        }

        client(`api/business-location/?page_size=100`)
            .then((response: PagingResult<IBusinessLocation>) => {
                setAvailableLocations(response.results);
                setSelectedLocations(
                    response.results
                        .filter((x) => initialLocations?.includes(x.name) || initialLocations?.length === 0)
                        .map((x) => x.id),
                );
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
        client(`api/permissions/?page_size=50`)
            .then((response: PagingResult<IPermission>) => {
                setAvailablePermissions(response.results);
                setSelectedPermissions(
                    response.results.filter((x) => initialPermissions?.includes(x.name)).map((x) => x.id),
                );
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    }, [isModalOpen]);

    useEffect(() => {
        if (availableLocations.length) {
            if (initialLocations?.length === 0) {
                setSelectedLocations(availableLocations.map((x) => x.id));
            } else {
                setSelectedLocations(
                    availableLocations.filter((x) => initialLocations?.includes(x.name)).map((x) => x.id),
                );
            }
        }

        if (availablePermissions.length) {
            setSelectedPermissions(
                availablePermissions.filter((x) => initialPermissions?.includes(x.name)).map((x) => x.id),
            );
        }
    }, [initialLocations, initialPermissions]);

    function onSubmitClick() {
        setErrorMessage('');
        setIsSubmitting(true);
        client(`api/business-contact/update-contact/`, {
            body: {
                user_id: userId,
                business_locations: selectedLocations.length !== availableLocations.length ? selectedLocations : [],
                permissions: selectedPermissions,
            },
        })
            .then(() => {
                onUserUpdated();
            })
            .catch(() => {
                setErrorMessage('An error occurred while updating the contact.');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    function onLocationSelect(id: number) {
        if (selectedLocations.includes(id)) {
            setSelectedLocations(selectedLocations.filter((x) => x !== id));
        } else {
            setSelectedLocations([...selectedLocations, id]);
        }
    }

    function onPermissionSelect(id: number) {
        if (selectedPermissions.includes(id)) {
            setSelectedPermissions(selectedPermissions.filter((x) => x !== id));
        } else {
            setSelectedPermissions([...selectedPermissions, id]);
        }
    }

    function onLocationSelectAll() {
        if (!selectedLocations.length || selectedLocations.length < availableLocations.length) {
            setSelectedLocations(availableLocations.map((x) => x.id));
        } else {
            setSelectedLocations([]);
        }
    }

    function isSubmitDisabled() {
        return !selectedLocations.length || isSubmitting;
    }

    function closeModal() {
        setSelectedLocations([]);
        setSelectedPermissions([]);
        onClose();
    }

    return (
        <BasicDialog isOpen={isModalOpen} onClose={closeModal}>
            <Grid style={{ padding: 20 }} container item direction="column">
                <Text bold variant="h1">
                    Edit {name}&apos;s Account
                </Text>
                <Text bold variant="h2">
                    Account Information
                </Text>
                <SelectLocations
                    availableLocations={availableLocations}
                    selectedLocations={selectedLocations}
                    onLocationSelect={onLocationSelect}
                    onSelectAll={onLocationSelectAll}
                />
                <SelectPermissions
                    availablePermissions={availablePermissions}
                    selectedPermissions={selectedPermissions}
                    onPermissionSelect={onPermissionSelect}
                />
                <Grid container item xs={12} justify="space-around">
                    <SecondaryButton buttonStyle={{ maxWidth: '30%' }} onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        disabled={isSubmitDisabled()}
                        loading={isSubmitting}
                        buttonStyle={{ maxWidth: '30%' }}
                        onClick={onSubmitClick}
                    >
                        Save
                    </PrimaryButton>
                </Grid>
                <Text variant="caption" textStyle={{ color: Colors.error }}>
                    {errorMessage}
                </Text>
            </Grid>
        </BasicDialog>
    );
}
