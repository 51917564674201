import React, { ReactNode } from 'react';
import BubbleButton from './BubbleButton';
import { Colors } from '../../theme/Colors';

interface ITextButtonProps {
    children?: ReactNode | ReactNode[];
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const defaultStyle = {
    text: {
        color: Colors.darkNavy,
        padding: 0,
        fontSize: 12,
    },
    button: {
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 7,
        paddingRight: 7,
    },
    outline: 'none',
};

const enabledStyle = {
    button: {
        backgroundColor: Colors.white,
        '&:hover': {
            backgroundColor: Colors.pressedNavy,
        },
    },
};

const disabledStyle = {
    button: {
        backgroundColor: Colors.disabledGrey,
    },
};

export default function CompactButton({ children, onClick, ...props }: ITextButtonProps) {
    return (
        <BubbleButton
            onClick={onClick}
            defaultStyle={defaultStyle}
            enabledStyle={enabledStyle}
            disabledStyle={disabledStyle}
            {...props}
        >
            {children}
        </BubbleButton>
    );
}
