import React, { useEffect, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import useForm from './components/FormHook';
import { FormFields } from './components/FormTypes';
import { JobFormFields, IPayScale } from './JobRequestTypes';
import { initialForm } from './JobForm';
import submitJobRequest, { JobType } from './JobRequestSubmission';
import client from 'shared/ApiClient';
import { Job } from 'models';
import { track } from 'shared/Tracking';
import { timeWithDate } from './time-operations';
import { useUserContext } from 'UserContext';
import { useModalsContext } from 'ModalsContext';
import { JobFormPresets, setJobFormPresets } from './JobFormPresets';
import moment from 'moment';
import { submitRequest, createOngoingRequest as oneTimeCreateOngoingRequest } from './OnetimeJobSubmission';
import { consoleLogInDev } from 'shared';
import userAttributesApi from '../../shared/UserAttributesApi';
interface JobFormContextType {
    form: FormFields<JobFormFields>;
    currentPage: number;
    pageCount: number;
    setPage: Dispatch<SetStateAction<number>>;
    goToNext: (pageName: string, extraData?: { [key: string]: unknown }) => void;
    goToPrevious: () => void;
    loading: boolean;
    jobSubmissionCompleted: boolean;
    submit: (pageName: string) => Promise<Job | undefined>;
    createdJob: Job | undefined;
    setPageCount: (n: number) => void;
    reset: (preset?: JobFormPresets) => void;
    defaultPayScales: IPayScale[];
    updateDefaultPayScales: (payScales: IPayScale[]) => void;
    formErrors: Array<string>;
    setFormErrors: Dispatch<SetStateAction<never[]>>;
    hasSeenTrainingsModal: boolean;
    updateHasSeenTrainingsModal: () => void;
    setPresets: (presets: JobFormPresets) => void;
    recommendations: string[];
    setRecommendations: (recommendations: string[]) => void;
    ignoreRecommendations: boolean;
    setIgnoreRecommendations: (ignore: boolean) => void;
    datesConfirmed: boolean;
    setDatesConfirmed: Dispatch<SetStateAction<boolean>>;
    showModalClose: boolean;
    setShowModalClose: Dispatch<SetStateAction<boolean>>;
}
interface JobFormContextProps {
    children?: React.ReactNode;
}

const defaultPageCount = 8;

export const JobFormContext = createContext<JobFormContextType>({} as JobFormContextType);

export function JobFormContextProvider({ children }: JobFormContextProps) {
    const [formErrors, setFormErrors] = useState([]);

    const { user, role } = useUserContext();

    const [currentPage, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(6);
    const [lastPage, setLastPage] = useState(defaultPageCount - 1);
    const [createdJob, setCreatedJob] = useState<Job>();
    const [jobSubmissionCompleted, setJobSubmissionCompleted] = useState(false);

    const [familyId, setFamilyId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [showModalClose, setShowModalClose] = useState(true);
    const [jobId, setJobId] = useState(0);
    const [defaultPayScales, setDefaultPayScales] = useState<IPayScale[]>([]);
    const [hasSeenTrainingsModal, setHasSeenTrainingsModal] = useState(false);
    const [ignoreRecommendations, setIgnoreRecommendations] = useState(false);
    const [recommendations, setRecommendations] = useState<string[]>([]);
    const [datesConfirmed, setDatesConfirmed] = useState(false);

    const form = useForm<JobFormFields>(initialForm(role === 'business_active'));
    const { closeBusinessJobForm, jobFormPresets } = useModalsContext();

    useEffect(() => {
        setLastPage(pageCount - 1);
    }, [pageCount]);

    useEffect(() => {
        loadJobFormValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        jobFormPresets && setPresets(jobFormPresets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobFormPresets]);

    function loadJobFormValues() {
        Promise.all([getPayScales(), getUserAttribute()])
            .then(([payScaleResult, attrResult]) => {
                setDefaultPayScales(payScaleResult);
                setHasSeenTrainingsModal(attrResult);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    function getPayScales() {
        return client('api/pay-scale/');
    }

    const HAS_SEEN_TRAININGS_MODAL = 'Has Seen Job Form Trainings Modal';
    function getUserAttribute() {
        return userAttributesApi.exists(HAS_SEEN_TRAININGS_MODAL);
    }

    function updateHasSeenTrainingsModal() {
        setHasSeenTrainingsModal(true);
        userAttributesApi.create(HAS_SEEN_TRAININGS_MODAL).catch(consoleLogInDev);
    }

    function updateDefaultPayScales(payScales: IPayScale[]) {
        setDefaultPayScales(payScales);
    }

    function setPresets(presets: JobFormPresets) {
        setJobFormPresets(presets, form.fieldStates, setPage, setJobId);
    }

    async function goToNext(pageName: string, extraData?: { [key: string]: unknown }) {
        setFormErrors([]);
        try {
            if (currentPage > 0) {
                const res = await updateDraft();
                setJobId(res.id);
            }
            goToNextPage();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            consoleLogInDev(e);
            setFormErrors(e.errors);
        }
        track('Job Form next button clicked on page', { currentPage, pageName, ...extraData });
    }

    function goToNextPage() {
        if (currentPage < lastPage) {
            setPage(currentPage + 1);
        }
    }

    function reset(presets: JobFormPresets = {}) {
        form.reset(initialForm(role === 'business_active'));
        setPage(0);
        setCreatedJob(undefined);
        setJobSubmissionCompleted(false);
        setJobId(0);
        const addresses = user?.address.filter((x) => x.can_post_jobs);
        if (addresses?.length) {
            const mailingAddress = addresses.find((x) => x.address_type === 'PRIMARY');
            const addressId = mailingAddress ? mailingAddress.id : addresses[0].id;
            const busLocationId = mailingAddress?.business_location
                ? mailingAddress.business_location.id
                : addresses[0]?.business_location?.id;
            const timezone = mailingAddress?.business_location?.timezone;

            form.fieldStates.address.setValue([{ id: addressId, businessLocationId: busLocationId, timezone }]);
        }
        setJobFormPresets(presets, form.fieldStates, setPage, setJobId);
    }

    async function updateDraft() {
        const now = moment().toISOString();
        const family_id = familyId ?? user?.family[0].id ?? 0;
        const formValues = form.toValues();
        let request = null;
        request =
            formValues.requestType === 'onetime'
                ? await oneTimeCreateOngoingRequest(now, formValues, family_id, 'DRAFT')
                : await submitJobRequest({
                      familyId: family_id,
                      payMin: formValues.pay.payMin,
                      payMax: formValues.pay.payMax,
                      jobOptions: {
                          selectedPreferredWorkers: formValues.preferredWorkers,
                          selectedPastWorkers: formValues.pastWorkers,
                          daysOfTheWeek: formValues.daysOfTheWeek,
                          sameTimes: formValues.sameTimes,
                          start: formValues.sameTimes
                              ? formValues.jobTimes.start &&
                                timeWithDate(formValues.jobTimes.start!, formValues.startDate!)
                              : formValues.startDate,
                          end: formValues.endDate,
                          end_time: formValues.jobTimes.end,
                          lastHireOffsetMinutes: formValues.lastHireOffsetMinutes,
                          multipleProviders: formValues.multipleProviders,
                          comments: formValues.comments,
                          headline: formValues.headline,
                          address: formValues.address?.length ? formValues.address[0].id : undefined,
                          businessLocation: formValues.address?.length
                              ? formValues.address[0].businessLocationId
                              : undefined,
                          pay_type: formValues.payType,
                          business_job_type: formValues.businessJobType,
                          jobDetails: formValues.jobDetails.map((detail) => ({ ...detail, option: detail.option_id })),
                          payScales: formValues.payScales,
                          breakRequired: formValues.breakRequired,
                          breakLength: formValues.breakLength,
                          parentRequest: formValues.templateJobId,
                          faqs: formValues.faqs,
                          breakEveryNMinutes: formValues.breakEveryNHours
                              ? formValues.breakEveryNHours * 60
                              : undefined,
                          trialRunBenefits: formValues.trialRunBenefits,
                          selectedSpotlightWorkers: formValues.selectedSpotlightWorkers,
                      },
                      jobType: JobType.ongoing,
                      status: 'DRAFT',
                      jobId: jobId,
                  });
        const res = await client(`api/ongoing/${jobId ? jobId : ''}`, {
            method: jobId ? 'PATCH' : 'POST',
            body: request,
        });
        return res;
    }

    async function submit(pageName: string) {
        track('Job Form submit button clicked on page', { currentPage, pageName });
        const formValues = form.toValues();
        if (loading) {
            return;
        }

        setLoading(true);
        const family_id = familyId ?? user!.family[0].id!;

        let newJob;
        try {
            if (formValues.requestType === 'onetime') {
                newJob = await submitRequest(form, family_id, jobId);
                setCreatedJob(newJob);
            } else {
                const isTrialRun = formValues.businessJobType === 'TRIAL_RUN';
                let isOneTime = !isTrialRun && (formValues.endDate?.diff(formValues.startDate, 'd') || 0) < 6;
                if (formValues.businessJobType) {
                    if ((formValues.endDate?.diff(formValues.startDate, 'd') || 0) > 1) {
                        isOneTime = false;
                    }
                }
                const submissionResult = (await submitJobRequest({
                    familyId: family_id,
                    payMin: formValues.pay.payMin,
                    payMax: formValues.pay.payMax,
                    jobOptions: {
                        selectedPreferredWorkers: formValues.preferredWorkers,
                        selectedPastWorkers: formValues.pastWorkers,
                        daysOfTheWeek: formValues.daysOfTheWeek,
                        sameTimes: formValues.sameTimes,
                        start: formValues.sameTimes
                            ? formValues.jobTimes.start &&
                              timeWithDate(formValues.jobTimes.start!, formValues.startDate!)
                            : formValues.startDate,
                        end: formValues.endDate,
                        end_time: formValues.jobTimes.end,
                        lastHireOffsetMinutes: formValues.lastHireOffsetMinutes,
                        multipleProviders: formValues.multipleProviders,
                        comments: formValues.comments,
                        headline: formValues.headline,
                        address: formValues.address,
                        pay_type: formValues.payType,
                        business_job_type: formValues.businessJobType,
                        jobDetails: formValues.jobDetails.map((detail) => ({ ...detail, option: detail.option_id })),
                        payScales: formValues.payScales,
                        breakRequired: formValues.breakRequired,
                        breakLength: formValues.breakLength,
                        parentRequest: formValues.templateJobId,
                        trialRunCoverage: formValues.trialRunCoverage,
                        faqs: formValues.faqs,
                        breakEveryNMinutes: formValues.breakEveryNHours ? formValues.breakEveryNHours * 60 : undefined,
                        trialRunBenefits: formValues.trialRunBenefits,
                        selectedSpotlightWorkers: formValues.selectedSpotlightWorkers,
                    },
                    jobType: isOneTime ? JobType.onetime : JobType.ongoing,
                    status: 'ACTIVE',
                    jobId: jobId,
                })) as { job: Job };
                setCreatedJob(submissionResult.job);
                newJob = submissionResult.job;
            }
            setJobSubmissionCompleted(true);
        } finally {
            setLoading(false);
        }
        goToNextPage();
        return newJob;
    }

    function goToPrevious() {
        track('Job Form back button clicked on page', { currentPage });

        if (currentPage === 0) {
            reset();
            closeBusinessJobForm();
        } else {
            setPage(currentPage - 1);
        }
    }

    const contextValue = {
        form,
        currentPage,
        setPage,
        setPageCount,
        setFamilyId,
        pageCount,
        goToNext,
        goToPrevious,
        createdJob,
        loading,
        jobSubmissionCompleted,
        submit,
        reset,
        defaultPayScales,
        updateDefaultPayScales,
        formErrors,
        setFormErrors,
        hasSeenTrainingsModal,
        updateHasSeenTrainingsModal,
        setPresets,
        recommendations,
        setRecommendations,
        ignoreRecommendations,
        setIgnoreRecommendations,
        datesConfirmed,
        setDatesConfirmed,
        showModalClose,
        setShowModalClose,
    };

    return <JobFormContext.Provider value={contextValue}>{children}</JobFormContext.Provider>;
}

export function useJobFormContext() {
    return useContext(JobFormContext);
}
