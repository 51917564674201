import React, { ReactElement, ReactNode, CSSProperties } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Colors, Text } from 'library';
import theme from 'theme';

interface PageHeaderProps {
    iconSource?: ReactElement;
    headerText: ReactNode;
    subtitleText: ReactNode;
}

const largeContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: 55,
};

const smallContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
};

const largeIconStyle: CSSProperties = {
    width: 140,
    height: 140,
    marginRight: 23,
    marginLeft: 42,
    borderRadius: '50%',
};

const smallIconStyle: CSSProperties = {
    width: 40,
    height: 40,
    marginLeft: 16,
    borderRadius: '50%',
};

const smallHeaderStyle: CSSProperties = {
    marginLeft: 16,
    marginBottom: 10,
};

export default function PageHeader({ iconSource, headerText, subtitleText }: PageHeaderProps) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <div style={small ? smallContainerStyle : largeContainerStyle}>
                {iconSource && React.cloneElement(iconSource, { style: small ? smallIconStyle : largeIconStyle })}
                <Grid direction="column" style={small ? smallHeaderStyle : {}}>
                    <Text serif variant="h1">
                        {headerText}
                    </Text>
                </Grid>
            </div>
            <div style={{ marginRight: 20, marginLeft: small ? 20 : 40, marginBottom: 10 }}>
                <Text color={Colors.darkGrey} variant={small ? 'body2' : 'body1'}>
                    {subtitleText}
                </Text>
            </div>
        </>
    );
}
