import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import PayMethods from './Components/PayMethods';
import FailedPayments from './Components/FailedPayments';
import theme from 'theme';
import MicrodepositsEntry from './Components/MicrodepositsEntry';
import InvoiceDownload from './Components/business/InvoiceDownload';
import PaymentLocationQRCodes from './Components/PaymentLocationQRCodes';
import PaymentFaq from './Components/PaymentFaq';
import LocationDiscounts from './Components/business/LocationDiscounts';
import { PermissionType, useUserContext } from 'UserContext';

export default function BusinessPayments() {
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const { hasPermission } = useUserContext();

    const canManagePayments = hasPermission(PermissionType.ManagePayments);
    return (
        <Grid container justify="center">
            <Grid container item direction="row" lg={10} md={12} sm={9} xs={12}>
                <Grid item direction="column" lg={6} md={6} sm={9} xs={12} style={{ paddingRight: mdUp ? 20 : 0 }}>
                    <Grid item>
                        {canManagePayments ? <MicrodepositsEntry /> : null}
                        {canManagePayments ? <FailedPayments /> : null}
                        {canManagePayments ? <PayMethods /> : null}
                        <PaymentFaq />
                    </Grid>
                </Grid>
                <Grid item direction="column" lg={6} md={6} sm={9} xs={12} alignContent="flex-start">
                    <Grid item xs={12}>
                        {canManagePayments ? <InvoiceDownload /> : null}
                        <PaymentLocationQRCodes />
                        <LocationDiscounts />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
