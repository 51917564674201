import React, { useEffect, useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import moment from 'moment-timezone';
import { Grid, styled } from '@mui/material';
import {
    AutoComplete,
    Colors,
    DropDown,
    MaterialDateInput,
    PrimaryButton,
    SecondaryButton,
    SizeableRoundedDialog,
    Text,
    TimePicker,
} from 'library';
import { AddCircleOutline } from '@material-ui/icons';
import { client, consoleLogInDev } from 'shared';
import { IBusinessLocation, PagingResult } from 'models';
import {
    getPastWorkers,
    PastWorkerDTO,
} from 'parent-portal/forms/components/pages/ProviderPreferencesPage/preferredWorkersApi';

export function AddRetroHoursModal({
    small,
    onComplete,
    businessLocations,
}: {
    small: boolean;
    onComplete: () => void;
    businessLocations?: PagingResult<IBusinessLocation>;
}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<number>();
    const [date, setDate] = useState<moment.Moment | null>(moment());
    const [startTime, setStartTime] = useState<moment.Moment | null>(moment());
    const [endTime, setEndTime] = useState<moment.Moment | null>(moment());
    const [breakStart, setBreakStart] = useState<moment.Moment | null>();
    const [breakEnd, setBreakEnd] = useState<moment.Moment | null>();
    const [pastWorkers, setPastWorkers] = useState<PastWorkerDTO[]>([]);
    const [selectedWorker, setSelectedWorker] = useState<PastWorkerDTO | null>(null);

    useEffect(() => {
        if (!isOpen || pastWorkers.length) {
            return;
        }

        getPastWorkers(undefined).then(setPastWorkers).catch(consoleLogInDev);
    }, [isOpen]);

    function onAdd() {
        const startObj = date || startTime;
        if (!startObj) return;

        const dateObj = {
            year: startObj?.year(),
            month: startObj?.month(),
            date: startObj?.date(),
        };

        const start_time = startTime?.set(dateObj);
        const end_time = endTime?.set(dateObj);
        let break_start;
        let break_end;
        if (breakStart) {
            break_start = breakStart?.set(dateObj);
        }
        if (breakEnd) {
            break_end = breakEnd?.set(dateObj);
        }

        setErrorMessage('');
        setSubmitting(true);

        client('api/hour-report/retro-add-hours/', {
            body: {
                start_time,
                end_time,
                break_start,
                break_end,
                business_location_id: selectedLocation,
                provider_id: selectedWorker?.babysitter_id,
            },
        })
            .then(() => {
                onClose();
                onComplete();
            })
            .catch((error) =>
                setErrorMessage(error.message || 'Sorry, something went wrong. Please try again or contact support.'),
            )
            .finally(() => setSubmitting(false));
    }

    function onClose() {
        setIsOpen(false);
        setSelectedWorker(null);
        setSelectedLocation(undefined);
        setDate(moment());
        setStartTime(moment());
        setEndTime(moment());
        setBreakStart(undefined);
        setBreakEnd(undefined);
    }

    function areTimeValid() {
        if (endTime && startTime && endTime < startTime) {
            return false;
        }

        if (breakStart && breakEnd && breakEnd < breakStart) {
            return false;
        }

        if (
            (startTime && breakStart && breakStart < startTime) ||
            (startTime && breakEnd && breakEnd < startTime) ||
            (endTime && breakStart && breakStart > endTime) ||
            (endTime && breakEnd && breakEnd > endTime)
        ) {
            return false;
        }

        return true;
    }

    const loc = businessLocations?.results?.[0];
    const timezone = loc?.timezone;
    const startEndDiff = !!startTime && !!endTime ? differenceInMinutes(endTime.toDate(), startTime.toDate()) : 0;
    const breakDiff = !!breakStart && !!breakEnd ? differenceInMinutes(breakEnd.toDate(), breakStart.toDate()) : 0;
    const totalDiff = (startEndDiff - breakDiff) / 60;

    return (
        <>
            <PrimaryButton
                onClick={() => setIsOpen(true)}
                buttonStyle={{ width: 'fit-content', maxHeight: 45, padding: 10 }}
            >
                <AddCircleOutline />
                Add Hours
            </PrimaryButton>
            <SizeableRoundedDialog
                open={isOpen}
                onClose={onClose}
                fullScreen={false}
                contentStyle={{ padding: 0 }}
                style={{ width: small ? '100%' : '40%' }}
                closeButton
            >
                <Inner>
                    <Text variant="h1">Add Hours for Tandem Worker</Text>
                    <LocationCell item xs={12}>
                        <DropDown
                            placeholder="Select Location"
                            selected={selectedLocation}
                            onChange={({ target: { value } }: { target: { value: number } }) =>
                                setSelectedLocation(value)
                            }
                            style={{ width: small ? '100%' : '60%' }}
                            fields={businessLocations?.results.map((x) => {
                                return { key: x.id, value: x.name };
                            })}
                        />
                    </LocationCell>
                    <AutoCompleteCell item xs={12}>
                        <AutoComplete
                            multiple={false}
                            options={pastWorkers}
                            selected={selectedWorker}
                            onSelect={(value) =>
                                Array.isArray(value) ? setSelectedWorker(value[0]) : setSelectedWorker(value)
                            }
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionDisabled={() => false}
                            renderOption={(option) => (
                                <StyledOption>
                                    <Text>
                                        {option.first_name} {option.last_name}
                                    </Text>
                                </StyledOption>
                            )}
                        />
                    </AutoCompleteCell>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MaterialDateInput
                                label="Date"
                                value={date}
                                onChange={(t) => setDate(t)}
                                minDate={new Date(new Date().setDate(new Date().getDate() - 30))}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                label="Start Time"
                                format="hh:mm a"
                                value={startTime}
                                onChange={(t) => setStartTime(t)}
                                sx={{ width: 200, marginBottom: 0 }}
                                timezone={timezone}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                label="End Time"
                                format="hh:mm a"
                                value={endTime}
                                onChange={(t) => setEndTime(t)}
                                sx={{ width: 200, marginBottom: 0 }}
                                timezone={timezone}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                label="Break Start"
                                format="hh:mm a"
                                value={breakStart}
                                onChange={(t) => setBreakStart(t)}
                                sx={{ width: 200, marginBottom: 0 }}
                                timezone={timezone}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                label="Break End"
                                format="hh:mm a"
                                value={breakEnd}
                                onChange={(t) => setBreakEnd(t)}
                                sx={{ width: 200, marginBottom: 0 }}
                                timezone={timezone}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <Text variant="caption">Total Hours Worked: {totalDiff.toFixed(2)}</Text>
                    </Grid>

                    <MainRow>
                        <SecondaryButton onClick={onClose} buttonStyle={{ width: 'fit-content' }}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            disabled={submitting || !areTimeValid() || !selectedLocation || !selectedWorker}
                            loading={submitting}
                            onClick={onAdd}
                            buttonStyle={{ width: 'fit-content' }}
                        >
                            Submit
                        </PrimaryButton>
                    </MainRow>
                    <Text color={Colors.error} textStyle={{ paddingTop: 20 }}>
                        {!areTimeValid()
                            ? 'Times are invalid. Make sure start is before end, both breaks are between the start and end times, and the break start is before the break end.'
                            : ''}
                    </Text>
                    <Text color={Colors.error} textStyle={{ paddingTop: 20 }}>
                        {errorMessage}
                    </Text>
                </Inner>
            </SizeableRoundedDialog>
        </>
    );
}

const Inner = styled('div')({
    padding: 20,
});

const LocationCell = styled(Grid)({
    padding: '0 5px',
});

const AutoCompleteCell = styled(Grid)({
    padding: '5px 0',
});

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
});

const MainRow = styled(Row)({
    justifyContent: 'space-around',
    marginTop: 15,
    width: '100%',
});

const StyledOption = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
});
