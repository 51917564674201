import React from 'react';
import TeacherBaseCard from 'parent-portal/providers/cards/shared/TeacherBaseCard';
import TeacherShowcaseHeader from './TeacherShowcaseHeader';
import { ITeacher } from 'models';
import Rating from 'parent-portal/providers/cards/shared/Rating';

export default function TeacherShowcaseCard({
    worker,
    selected,
    onChange,
}: {
    worker: ITeacher;
    selected: boolean;
    onChange: (choice: ITeacher) => void;
}) {
    return (
        <TeacherBaseCard
            worker={worker}
            header={<TeacherShowcaseHeader worker={worker} selected={selected} onChange={onChange} />}
            details={<Rating reviews={worker.credentials.reviews} />}
        />
    );
}
