import React, { useState } from 'react';
import theme from 'theme';
import { useMediaQuery } from '@material-ui/core';
import { PrimaryButton, SecondaryButton, Text, TextButton } from 'library';
import { IBusinessJobDetailsModel, IShiftsByProvider } from '../models';
import { Grid } from '@mui/material';
import { BusinessSlotHeader } from './BusinessSlotHeader';
import TrialRunFeedbackModal from '../TrialRunFeedbackModal';
import { BoxContainer, ProgressBar } from './StyledComponents';
import { ReplaceWorkerModal } from './ReplaceWorkerModal';
import { HireWorkerModal } from './HireWorkerModal';

export default function TrialRunOverviewSection({
    providerShifts,
    job,
    getJob,
}: {
    providerShifts: IShiftsByProvider[];
    job: IBusinessJobDetailsModel;
    getJob: () => void;
}) {
    const [continueTrialRun, setContinueTrialRun] = useState(false);
    const [trialRunFeedbackOpen, setTrialRunFeedbackOpen] = useState(false);
    const [replaceWorker, setReplaceWorker] = useState(false);
    const [hireWorker, setHireWorker] = useState(false);

    const small = useMediaQuery(theme.breakpoints.down('md'));

    function trialRunAction(continueTrialRun: boolean) {
        return () => {
            setContinueTrialRun(continueTrialRun);
            setTrialRunFeedbackOpen(true);
        };
    }

    return (
        <Grid item container xs={12} style={{ gap: 20 }}>
            <Text variant="h1">Workers On Trial Run</Text>
            <Grid item container flexDirection="row" xs={12} style={{ gap: 20 }}>
                {providerShifts.map(({ provider, hours_required, hours_worked, permanently_hired }) => (
                    <BoxContainer
                        item
                        container
                        key={provider.id}
                        xs={12}
                        md={3}
                        style={{ gap: 20, ...{ minWidth: small ? 'unset' : 400 } }}
                    >
                        <BusinessSlotHeader provider={provider} headline="" />
                        <Grid item container flexDirection="column">
                            <ProgressBar
                                variant="determinate"
                                value={Math.min((hours_worked / hours_required) * 100, 100)}
                            />
                            <Text bold>{hours_worked.toFixed(2)} hours worked</Text>
                            <Text variant="caption">out of {hours_required} hour trial run</Text>
                        </Grid>
                        {permanently_hired ? (
                            <Text variant="caption">{provider.first_name} has been permanently hired! 🎉</Text>
                        ) : (
                            <Grid item container flexDirection="column" alignItems="flex-start">
                                <Grid item container flexDirection="row" justifyContent="space-between">
                                    {!job.weekly_job_feedback?.length ? (
                                        <PrimaryButton
                                            buttonStyle={{ width: 'unset', padding: '5px 20px', marginRight: 10 }}
                                            textStyle={{ fontSize: 12 }}
                                            onClick={trialRunAction(true)}
                                        >
                                            Continue Next Week
                                        </PrimaryButton>
                                    ) : null}
                                    {hours_worked >= hours_required && (
                                        <SecondaryButton
                                            buttonStyle={{
                                                width: 'unset',
                                                padding: '5px 20px',
                                                marginTop: small ? 10 : 0,
                                            }}
                                            textStyle={{ fontSize: 12 }}
                                            onClick={() => setHireWorker(true)}
                                        >
                                            Hire Worker
                                        </SecondaryButton>
                                    )}
                                </Grid>
                                <TextButton
                                    buttonStyle={{ width: 'unset', marginTop: 10, paddingLeft: 5 }}
                                    textStyle={{ fontSize: 12 }}
                                    onClick={() => setReplaceWorker(true)}
                                >
                                    Replace Worker
                                </TextButton>
                                <TrialRunFeedbackModal
                                    open={trialRunFeedbackOpen}
                                    onClose={() => {
                                        getJob();
                                        setTrialRunFeedbackOpen(false);
                                    }}
                                    provider={provider}
                                    initialContinueValue={continueTrialRun}
                                    ongoingRequestId={job.id}
                                />
                                <ReplaceWorkerModal
                                    open={replaceWorker}
                                    onClose={() => {
                                        setReplaceWorker(false);
                                        getJob();
                                    }}
                                    provider={provider}
                                    ongoingRequestId={job.id}
                                />
                                <HireWorkerModal
                                    open={hireWorker}
                                    onClose={() => {
                                        setHireWorker(false);
                                        getJob();
                                    }}
                                    provider={provider}
                                    job={job}
                                />
                            </Grid>
                        )}
                    </BoxContainer>
                ))}
            </Grid>
        </Grid>
    );
}
