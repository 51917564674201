import React from 'react';
import { Grid } from '@material-ui/core';
import { formatPhoneNumber } from 'shared';
import { Colors, Text } from 'library';
import { IBusinessContact } from 'models';

export default function BusinessContact({ businessContact }: { businessContact: IBusinessContact }) {
    return (
        <Grid
            item
            container
            direction="row"
            justify="space-between"
            style={{
                borderRadius: 18,
                padding: 20,
                margin: 10,
                backgroundColor: Colors.dullTangerine,
            }}
            xs={12}
            md={4}
        >
            <Grid item container direction="row" justify="space-between">
                <Text bold textStyle={{ maxWidth: '80%', overflow: 'auto' }}>
                    {businessContact.user.first_name} {businessContact.user.last_name}
                </Text>
            </Grid>
            {businessContact.user.phone_number ? (
                <Text bold variant="caption">
                    Phone Number:{' '}
                    <Text inline variant="caption">
                        {formatPhoneNumber(businessContact.user.phone_number)}{' '}
                    </Text>
                </Text>
            ) : null}
            {businessContact.user.email ? (
                <Text bold variant="caption">
                    Email:{' '}
                    <Text variant="caption" inline>
                        {businessContact.user.email}{' '}
                    </Text>
                </Text>
            ) : null}
        </Grid>
    );
}
