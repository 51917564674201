import React, { useEffect, useState } from 'react';
import { useModalsContext } from 'ModalsContext';
import { SizeableRoundedDialog as Dialog } from 'library';
import BusinessDateAndTimePage from './components/pages/DateAndTimePage/BusinessDateAndTimePage';
import BusinessJobHeadlinePage from './components/pages/HeadlineDescriptionPage/BusinessJobHeadlinePage';
import { Pay, Requirements } from './components/pages/RequirementsAndPayPage';
import { RequirementsAndPayProvider } from './components/pages/RequirementsAndPayPage/context';
import PaySetupPage from './components/pages/PaySetupPage/PaySetupPage';
import { useJobFormContext, JobFormContextProvider } from './JobFormContext';
import { useHistory } from 'react-router';
import { useUserContext } from 'UserContext';
import moment from 'moment';
import BusinessPaymentModelPage from './components/pages/BusinessPaymentModelPage/BusinessPaymentModelPage';
import BusinessLocationPage from './components/pages/BusinessLocationPage';
import BusinessJobSuccessPage from './components/pages/ConfirmationPage/BusinessJobSuccessPage';
import ProviderPreferencesJobFormPage from './components/pages/ProviderPreferencesPage/ProviderPreferencesJobFormPage';
import MissingMicrodepositVerificationModal from 'parent-portal/home/paymentVerificationWarning/MissingMicrodepositVerificationModal';
import useMicrodepositVerificationInfo from 'parent-portal/home/paymentVerificationWarning/useMicrodepositVerficationInfo';
import { TemplateOption } from './JobRequestTypes';
import SelectTemplatePage from './components/pages/SelectTemplatePage';
import JobSummaryPage from './components/pages/JobSummaryPage';
import TrialRunToSubJobPage from './components/pages/TrialRunToSubJobPage/TrialRunToSubJobPage';
import JobFaqPage from './components/pages/JobFaqPage/JobFaqPage';
import { client, consoleLogInDev } from 'shared';
import PaymentStatusModal from 'parent-portal/home/components/PaymentStatusModal';

function BusinessJobRequestForm() {
    const {
        form,
        reset,
        currentPage,
        createdJob,
        setPageCount,
        setFormErrors,
        showModalClose,
        setShowModalClose,
        defaultPayScales,
    } = useJobFormContext();
    const { user, role } = useUserContext();
    const { blockJobPostingOnVerification } = useMicrodepositVerificationInfo();
    const { businessJobFormOpen, closeBusinessJobForm } = useModalsContext();
    const [showPaymentSetup, setShowPaymentSetup] = useState(false);
    const [isOverduePaymentsLoading, setIsOverduePaymentsLoading] = useState(false);
    const [showOverduePayments, setShowOverduePayments] = useState(false);
    const [overduePaymentStatus, setOverduePaymentStatus] = useState('');

    const { fieldStates } = form;
    const history = useHistory();

    useEffect(() => {
        form.fieldStates.endDate.setValue(moment());
    }, []);

    useEffect(() => {
        if (businessJobFormOpen) {
            const business = user?.businesses_active?.[0];
            if (business) {
                setIsOverduePaymentsLoading(true);
                client(`api/business/${business.id}/`)
                    .then((response) => {
                        if (response?.payment_status === 'BLOCKED') {
                            setShowOverduePayments(true);
                            setOverduePaymentStatus(response.payment_status);
                        }
                    })
                    .catch(consoleLogInDev)
                    .finally(() => setIsOverduePaymentsLoading(false));
            }
        } else {
            setShowOverduePayments(false);
            setOverduePaymentStatus('');
        }
    }, [businessJobFormOpen]);

    useEffect(() => {
        if (role === 'business_active') {
            const paymentSetup = !!user?.payment_information && user?.payment_information?.length > 0;
            setShowPaymentSetup(!paymentSetup && !localStorage.getItem('old')); //TODO: need a better solution to this for admins
        }
    }, []);

    const completeFollowUps = () => {
        onClose();
        if (history.location.pathname.includes('/jobs')) history.go(0);
        else if (history.location.pathname.includes('/get-started')) history.push('/home');
    };

    const onClose = () => {
        reset();
        closeBusinessJobForm();
        setFormErrors([]);
    };

    const getPageFlow = () => {
        return fieldStates.useTemplate.value === TemplateOption.TEMPLATE
            ? [<SelectTemplatePage key="select-template-page" name="select-template-page" />]
            : [
                  <BusinessDateAndTimePage key="date-time-page" name="date-time-page" />,
                  <BusinessJobHeadlinePage
                      key="headline-description-page"
                      name="headline-description-page"
                      fieldStates={fieldStates}
                  />,
                  <BusinessLocationPage key="business-location-page" name="business-location-page" />,
                  <ProviderPreferencesJobFormPage
                      key="requested-workers-page"
                      name="requested-workers-page"
                      subtitle="Request workers you would like to prioritize when we book this job."
                  />,
                  <Requirements key="requirements-page" name="requirements-page" />,
                  <Pay key="pay-page" name="pay-page" />,
                  <JobFaqPage key="job-faq-page" name="job-faq-page" />,
                  showPaymentSetup ? <PaySetupPage key="pay-setup-page" name="pay-setup-page" /> : null,
              ];
    };

    const pages = [
        <BusinessPaymentModelPage
            key="business-job-type-page"
            name="business-job-type-page"
            fieldStates={fieldStates}
        />,
        ...getPageFlow(),
        <JobSummaryPage key="job-summary-page" name="job-summary-page" />,
        fieldStates.businessJobType.value === 'TRIAL_RUN' || fieldStates.trialRunCoverage.value ? (
            <TrialRunToSubJobPage key="trial-run-to-sub-job-page" name="trial-run-to-sub-job-page" />
        ) : null,
        <BusinessJobSuccessPage
            key="job-success-page"
            job={createdJob}
            onClose={completeFollowUps}
            setShowModalClose={setShowModalClose}
        />,
    ].filter((item) => item !== null);

    useEffect(() => {
        setPageCount(pages.length);
    }, [pages]);
    return (
        <>
            {!blockJobPostingOnVerification && !showOverduePayments && !isOverduePaymentsLoading ? (
                <Dialog
                    open={businessJobFormOpen}
                    onClose={createdJob ? completeFollowUps : onClose}
                    closeButton={showModalClose}
                    style={{ width: '60%', maxWidth: 800, maxHeight: '95vh' }}
                    contentStyle={{ marginTop: 20 }}
                >
                    <RequirementsAndPayProvider fieldStates={fieldStates} defaultPayScales={defaultPayScales}>
                        {pages[`${currentPage}`]}
                    </RequirementsAndPayProvider>
                </Dialog>
            ) : null}
            <MissingMicrodepositVerificationModal
                isOpen={blockJobPostingOnVerification && !showOverduePayments && businessJobFormOpen}
                onClose={() => closeBusinessJobForm()}
            />
            <PaymentStatusModal
                isOpen={showOverduePayments}
                onClose={() => closeBusinessJobForm()}
                paymentStatus={overduePaymentStatus}
            />
        </>
    );
}

export default function BusinessJobFormWithContext() {
    const { role } = useUserContext();
    if (role && role !== 'admin') {
        return (
            <JobFormContextProvider>
                <BusinessJobRequestForm />
            </JobFormContextProvider>
        );
    }
    return null;
}
