import { Grid, styled } from '@mui/material';
import { BusinessApplicant, BusinessApplicantWithJob } from 'internal/applicants/ApplicantModels';
import React from 'react';
import { formatMonthDayTime } from 'shared/Dates';
import { Text } from 'library';

export default function AutobookDetails({ applicant }: { applicant: BusinessApplicant | BusinessApplicantWithJob }) {
    return (
        <AutobookInfoContainer container direction="column">
            <Text variant="body2">
                <b>Autobook Status:</b> {applicant.autobook_status ?? 'None'}
            </Text>
            <Text variant="body2">
                <b>Reason:</b> {applicant.autobook_reason ?? 'None'}
            </Text>
            {applicant.approved_at && (
                <Text variant="body2">
                    <b>✅ Center approved:</b> {applicant.approved_by?.first_name} {applicant.approved_by?.last_name}{' '}
                    {formatMonthDayTime(applicant.approved_at)}
                </Text>
            )}
            {applicant.rejected_at && (
                <Text variant="body2">
                    <b>❌ Center declined:</b> {applicant.rejected_by?.first_name} {applicant.rejected_by?.last_name}{' '}
                    {formatMonthDayTime(applicant.rejected_at)}
                </Text>
            )}
        </AutobookInfoContainer>
    );
}

const AutobookInfoContainer = styled(Grid)({
    paddingTop: 10,
    paddingBottom: 10,
    whiteSpace: 'normal',
});
