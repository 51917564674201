import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Colors } from 'library';
import RegisterWithLocation from './components/RegisterWithLocation';
import RegistrationSuccessScreen from './components/RegistrationSuccessScreen';
import ComplianceLogo from './components/ComplianceLogo';

export default function WorkerRegistration() {
    const [passwordChanged, setPasswordChanged] = useState(false);

    return (
        <Grid
            container
            item
            alignSelf="center"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            style={{ padding: 20 }}
        >
            <Grid
                justifyContent="center"
                alignSelf="center"
                flexDirection="row"
                container
                item
                xs={12}
                md={6}
                style={{
                    margin: 20,
                    padding: 20,
                    borderRadius: 18,
                    backgroundColor: Colors.lightTurq,
                    boxShadow: '0px 3px 6px #00000029',
                    minHeight: '80%',
                    height: 'fit-content',
                }}
            >
                <ComplianceLogo />
                {passwordChanged ? (
                    <RegistrationSuccessScreen />
                ) : (
                    <RegisterWithLocation setPasswordChanged={setPasswordChanged} />
                )}
            </Grid>
        </Grid>
    );
}
