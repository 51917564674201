import React, { ChangeEvent } from 'react';
import { OutlinedDropdown } from 'library';
import { styled } from '@mui/material';
import { useHistory } from 'react-router';

import type { PagingResult, IBusinessLocation } from 'models';

export function LocationsDropDown({ data, small }: { data?: PagingResult<IBusinessLocation>; small: boolean }) {
    const history = useHistory();
    const paramLocation = new URLSearchParams(window.location.search).get('location');

    function handleLocationChange(e: ChangeEvent<{ name?: string; value: string }>) {
        const params = new URLSearchParams(window.location.search);
        params.set('location', e.target.value);
        const url = `timesheets?${params.toString()}`;
        history.push(url);
    }

    return (
        <Container small={small.toString()}>
            <OutlinedDropdown
                value={paramLocation ?? ''}
                fields={
                    data?.results.map((b) => {
                        return { key: b.id.toString(), value: b.name };
                    }) || []
                }
                onChange={handleLocationChange}
            />
        </Container>
    );
}

const Container = styled('div')(({ small }: { small: string }) => ({
    paddingTop: 30,
    width: small === 'true' ? '100%' : '30%',
}));
