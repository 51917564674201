import React, { useEffect } from 'react';
import { add } from 'date-fns';
import moment from 'moment';
import HorizontalOptionButton from './components/HorizontalOptionButton';
import JobFormPage from '../JobFormPage';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { BusinessJobTypes, JobFormFields, TemplateOption } from 'parent-portal/forms/JobRequestTypes';
import { FieldStates } from '../../FormTypes';
import FormField from '../../FormField';
import { Grid } from '@material-ui/core';
import { OptionType, usePaymentOptions } from 'parent-portal/payments/PaymentOptions';
import TandemHiringPolicy from './components/TandemHiringPolicy';
import { Colors } from 'library';

export default function BusinessPaymentModelPage({
    fieldStates,
    name,
}: {
    fieldStates: FieldStates<JobFormFields>;
    name: string;
}) {
    const { goToNext } = useJobFormContext();
    const { businessJobType, multipleProviders, startDate, endDate } = fieldStates;
    const paymentOptions = usePaymentOptions();

    useEffect(() => {
        if (businessJobType.value) {
            businessJobType.setValid(true);
        }
    }, [businessJobType.value]);

    const onChange = (value: BusinessJobTypes) => {
        if (value === 'SUBSTITUTE') {
            businessJobType.setValue(value);
            multipleProviders.setValue(true);
            if (!startDate.value) {
                startDate.setValue(moment(new Date()));
                endDate.setValue(moment(add(new Date(), { days: 28 })));
            }
        } else {
            businessJobType.setValue(value);
            multipleProviders.setValue(false);
            if (!startDate.value) {
                startDate.setValue(moment(new Date()));
            }
            endDate.setValue(null);
        }
    };

    function applyDynamicStyles(type: string) {
        return type === businessJobType.value ? styles.selected : {};
    }

    function onGoToNext() {
        if (fieldStates.useTemplate.value === TemplateOption.TEMPLATE) {
            startDate.setValue(null);
            endDate.setValue(null);
        }

        goToNext(name, { templateOption: fieldStates.useTemplate.value });
    }

    return (
        <JobFormPage
            title="Let's create your job"
            onNextClicked={onGoToNext}
            backText="Cancel"
            nextDisabled={!businessJobType.isValid}
            id={name}
        >
            <FormField style={{ marginTop: 40 }}>
                <Grid
                    container
                    item
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    style={{ marginTop: 20, gap: 20 }}
                >
                    {paymentOptions.map((o: OptionType) => (
                        <Grid item key={o.option} style={{ width: '100%' }}>
                            <HorizontalOptionButton
                                title={o.title}
                                subtitle={o.subtitle}
                                highlight={o.highlight}
                                listItems={o.listItems}
                                action={() => onChange(o.option as BusinessJobTypes)}
                                cardItems={o.cardItems}
                                icon={o.icon}
                                style={applyDynamicStyles(o.option)}
                                selected={o.option === businessJobType.value}
                            />
                        </Grid>
                    ))}

                    <Grid item style={{ width: '100%' }}>
                        <TandemHiringPolicy initialExpandedState={!businessJobType.value} />
                    </Grid>
                </Grid>
            </FormField>
        </JobFormPage>
    );
}

const styles = {
    selected: {
        backgroundColor: Colors.lightTurq,
        border: `1px solid ${Colors.turquoise}`,
    },
};
